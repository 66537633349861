// Disabled as it doesn't work for this module
/* eslint-disable prefer-destructuring */

export const ETH_NETWORK_CHAIN_ID = process.env.REACT_APP_ETH_NETWORK_CHAIN_ID!;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL!;
export const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME!;
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY!;
export const PAYMENT_ADDRESS = process.env.REACT_APP_PAYMENT_ADDRESS!;
export const INFURA_ETH_ENDPOINT = process.env.REACT_APP_INFURA_ETH_ENDPOINT!;
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!;
export const PURCHASE_RELATED_STUFF_VISIBLE =
  process.env.REACT_APP_PURCHASE_RELATED_STUFF_VISIBLE === 'true';
export const AFTER_LOGIN_PAGE = `/${
  PURCHASE_RELATED_STUFF_VISIBLE ? 'pod' : 'wardrobe'
}`;
