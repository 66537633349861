import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import OutlinedButton from 'app/components/OutlinedButton';
import useStore from 'app/common/useStore';
import { IAddress } from 'common/state/models/Address';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useRef, useState } from 'react';
import { AddressOption } from 'app/components/AddressOption';
import { EditAddress } from 'app/components/EditAddress';
import { AddNewAddress } from 'app/components/AddNewAddress';
import { formatPrice } from 'common/state/models/Price';
import { isEmpty } from 'lodash';
import Modal from 'react-modal';

const Address = () => {
  const {
    checkoutStore,
    userStore: {
      addresses,
      spreeAuth,
      addOrOverwriteAddress,
      deleteAddress,
      clearErrors,
    },
  } = useStore();

  const { logEvent } = useAnalytics();

  useEffect(() => {
    clearErrors();
    return () => {
      clearErrors();
    };
  });

  const [isEditAddressModalVisible, setIsEditAddressModalVisible] =
    useState(false);
  const [isDeleteAddressModalVisible, setIsDeleteAddressModalVisible] =
    useState(false);

  const addressIdRef = useRef<string>();

  const closeAddressEditModal = () => {
    setIsEditAddressModalVisible(false);
    addressIdRef.current = undefined;
  };

  const showAddressDeleteModal = () => {
    setIsDeleteAddressModalVisible(true);
  };

  const closeAddressDeleteModal = () => {
    setIsDeleteAddressModalVisible(false);
    addressIdRef.current = undefined;
  };

  const addAnotherAddress = () => {
    setIsEditAddressModalVisible(true);

    logEvent(AnalyticsEventName.AddAddress);
  };

  const onSave = async (address: IAddress) => {
    const result = await addOrOverwriteAddress(address);
    if (result) {
      closeAddressEditModal();
    }
  };

  const onAddressSelect = (addressId: string) => () => {
    checkoutStore.clearDeliveryTypes();
    checkoutStore.setAddressId(spreeAuth, addressId);

    logEvent(AnalyticsEventName.SelectAddress, {
      paymentId: checkoutStore.orderNumber,
      totalUsd: formatPrice(checkoutStore.totalAmount.usd),
    });
  };

  const onAddressDelete = async (addressId: string) => {
    await deleteAddress(addressId);

    logEvent(AnalyticsEventName.DeleteAddress, {
      paymentId: checkoutStore.orderNumber,
      totalUsd: formatPrice(checkoutStore.totalAmount.usd),
    });
  };

  return (
    <div>
      <div className="flex flex-col">
        <h2 className="mb-6 uppercase">Delivery address</h2>
        {Array.from(addresses.values()).map((address) => (
          <Fragment key={address.id}>
            <AddressOption
              address={address}
              value={checkoutStore.addressId === address.id}
              onPress={onAddressSelect(address.id!)}
              onEditPress={() => {
                addressIdRef.current = address.id;
                setIsEditAddressModalVisible(true);
              }}
              onDeletePress={() => {
                addressIdRef.current = address.id;
                showAddressDeleteModal();
              }}
            />
            <EditAddress
              editing
              initialValue={address}
              onSave={onSave}
              onClose={closeAddressEditModal}
              visible={
                isEditAddressModalVisible && addressIdRef.current === address.id
              }
            />
          </Fragment>
        ))}
        {!isEmpty(addresses) && (
          <OutlinedButton
            className="border-0 my-0 py-4 bg-coldGrey-200 bold16"
            onClick={addAnotherAddress}
          >
            Add another address
          </OutlinedButton>
        )}
        <EditAddress
          visible={isEditAddressModalVisible && !addressIdRef.current}
          editing={false}
          onSave={onSave}
          onClose={closeAddressEditModal}
        />
        {!addresses.size && <AddNewAddress />}
        <Modal
          overlayClassName="modal-bg"
          className="modal bg-coldGrey-200 rounded-lg p-8 w-4/5 sm:w-[400px] flex flex-col items-center text-center"
          isOpen={isDeleteAddressModalVisible}
          onRequestClose={closeAddressDeleteModal}
        >
          <h2 className="text-base mx-4 uppercase">
            Are you sure you want to delete this address?
          </h2>
          <h5 className="pt-4 pb-6">
            You won't be able to undo this operation.
          </h5>
          <div className="flex w-full justify-between">
            <OutlinedButton
              className="font-mussels flex-1 mr-2"
              onClick={closeAddressDeleteModal}
            >
              Cancel
            </OutlinedButton>
            <OutlinedButton
              className="font-mussels flex-1"
              onClick={() => {
                onAddressDelete(addressIdRef.current!);
                closeAddressDeleteModal();
              }}
              variant="delete"
            >
              Delete
            </OutlinedButton>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default observer(Address);
