import Banner from 'app/components/Banner';
import thanks from 'app/assets/thanks.png';
import token from 'app/assets/token.png';
import { useLocation } from 'react-router-dom';

const Thanks = () => {
  const {
    state: { exp },
  } = useLocation();

  return (
    <div className="flex flex-1">
      <div className="h-screen w-screen absolute bg-thanks" />
      <div className="flex flex-1 flex-col relative justify-center items-center">
        <Banner className="mt-16 mb-8">
          Your order has been placed successfully. To check your assets and
          order history check the mobile app.
        </Banner>
        <img
          src={thanks}
          alt="Avatar"
          className="my-6 h-80 w-80 rounded-full shadow-thanks"
        />
        <div className="my-12 bg-turquoise-400 rounded-full px-4 py-1 flex items-center flex-row">
          <img src={token} className="h-8 w-8 mr-4" alt="XP" />
          <h3>+ {exp.value}</h3>
        </div>
        <h2 className="py-4 text-3xl uppercase">!Vibe</h2>
        <div className="regular18 text-coldGrey-700 max-w-md text-center">
          You earned {exp.value} Transmutium (T+) for completing your purchase.
          You can find your Toad in your wardrobe.
        </div>
      </div>
    </div>
  );
};

export default Thanks;
