import { types } from 'mobx-state-tree';
const StringSet = types
    .model({
    values: types.map(types.null),
})
    .views((self) => ({
    includes: (value) => {
        return self.values.has(value);
    },
    get all() {
        return self.values.keys();
    },
    get isEmpty() {
        return self.values.size === 0;
    },
}))
    .actions((self) => ({
    push: (value) => {
        self.values.set(value, null);
    },
    remove: (value) => {
        return self.values.delete(value);
    },
}));
// ts-prune-ignore-next
export default StringSet;
