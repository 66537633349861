import useStore from 'app/common/useStore';
import { IAddress } from 'common/state/models/Address';
import { createRef, useEffect, useState } from 'react';
import LabeledInput from 'app/components/LabeledInput';
import WireButton from 'app/components/WireButton';
import { observer } from 'mobx-react-lite';
import { BsXLg } from 'react-icons/bs';
import Modal from 'react-modal';
import OutlinedButton from 'app/components/OutlinedButton';
import HighlightButton from 'app/components/HighlightButton';
import CountryPicker, { CountryOption } from 'app/components/CountryPicker';
import * as address from 'app/common/addressFormLabels';
import { getStatesAsync } from 'common/api/spree';

const _EditAddress = ({
  editing,
  onSave,
  onClose,
  initialValue = undefined,
  visible,
}: {
  editing: boolean;
  onSave: (address: IAddress) => Promise<void>;
  onClose: () => void;
  initialValue?: IAddress;
  visible: boolean;
}) => {
  const {
    userStore: { addressErrorsList },
    checkoutStore: { availableCountriesList },
  } = useStore();

  const [isSaveModal, setIsSaveModal] = useState(false);

  const [refs] = useState(() => {
    return address.idx.map((_) => createRef<HTMLInputElement>());
  });

  const [isSelectorFocused, setIsSelectorFocused] = useState(false);

  const [country, setCountry] = useState({
    name: initialValue?.country || '',
    iso: initialValue?.country_iso || '',
  });

  const [statesAvailable, setStatesAvailable] = useState(false);

  useEffect(() => {
    if (country.name) {
      getStates(country.iso);
    }
  }, [country]);

  const getStates = async (countryIso: string) => {
    const statesList = await getStatesAsync(countryIso);
    setStatesAvailable(statesList.length > 0);
  };

  const onClickSave = async () => {
    let values = Object.fromEntries(
      address.idx.map((i) => [
        address.addressKeys[i],
        refs[i].current?.value ?? '',
      ]),
    );
    values['country'] = country.name;
    values['country_iso'] = country.iso;
    if (initialValue) {
      values['id'] = initialValue.id!;
    }
    try {
      // @ts-ignore
      await onSave(values);
    } catch (err) {
      console.log(err);
    }
  };

  const onCountrySelect = (option: CountryOption) => {
    setCountry({
      name: option.label,
      iso: option.value,
    });
  };

  const countriesOptions = availableCountriesList
    .map((country) => {
      return {
        value: country.country_iso,
        label: country.name,
      };
    })
    .sort((obj, comp) => (obj.label < comp.label ? -1 : 1));

  const isCountryMissing = country.name === '' || country.iso === '';

  const renderSaveDiscardModal = () => {
    return (
      <Modal
        overlayClassName="modal-bg"
        className="modal bg-coldGrey-200 rounded-lg p-8 w-4/5 sm:w-[400px] flex flex-col items-center text-center"
        isOpen={isSaveModal}
        onRequestClose={() => setIsSaveModal(false)}
      >
        <h2 className="text-base mx-4 uppercase">Save address changes?</h2>
        <h5 className="pt-4 pb-6">
          Do you want to save changes made to the edited address?
        </h5>
        <div className="flex w-full justify-between">
          <OutlinedButton
            className="font-mussels flex-1"
            onClick={() => {
              setIsSaveModal(false);
              onClose();
            }}
          >
            DISCARD
          </OutlinedButton>
          <div className="w-4" />
          {!isCountryMissing && (
            <HighlightButton
              className="font-mussels flex-1"
              onClick={() => {
                setIsSaveModal(false);
                onClickSave();
              }}
            >
              SAVE
            </HighlightButton>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <Modal
      overlayClassName="modal-bg"
      className="hide-scrollbar center-xy w-3/4 sm:w-[600px] bg-coldGrey-100 max-h-[calc(100%-250px)] overflow-y-auto"
      isOpen={visible}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setIsSaveModal(true)}
    >
      <div className="border rounded-lg border-coldGrey-300 p-4">
        <div className="flex items-center justify-end gap-4">
          <div className="my-auto p-1">
            <button onClick={() => setIsSaveModal(true)}>
              <BsXLg size="20" />
            </button>
          </div>
        </div>
        {address.idx.map(
          (i) =>
            !(address.addressKeys[i] === 'state_name' && !statesAvailable) && (
              <LabeledInput
                initialValue={
                  initialValue && initialValue[address.addressKeys[i]]
                }
                key={i}
                ref={refs[i]}
                placeholder={address.addressLabels[i]}
                error={
                  addressErrorsList[address.errorKeys[i].toString()] !==
                  undefined
                }
              />
            ),
        )}
        <div className="relative">
          <div
            className={`absolute left-[14px] text-coldGrey-700 z-10 transition-all
                ${
                  isSelectorFocused ||
                  country.name !== '' ||
                  initialValue?.country !== undefined
                    ? ' top-[8px] text-xs'
                    : 'bottom-1/2 translate-y-1/2 text-base'
                }
              `}
          >
            Country
          </div>
          <CountryPicker
            options={countriesOptions}
            initialValue={initialValue}
            focused={isSelectorFocused || country.name !== ''}
            onSelect={onCountrySelect}
            onBlur={() => setIsSelectorFocused(false)}
            onFocus={() => setIsSelectorFocused(true)}
          />
        </div>
        <WireButton onClick={onClickSave} disabled={isCountryMissing}>
          {editing ? 'Update address' : 'Add address'}
        </WireButton>
        {renderSaveDiscardModal()}
      </div>
    </Modal>
  );
};

export const EditAddress = observer(_EditAddress);
