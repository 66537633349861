import '@ethersproject/shims'; // required for react native. call before importing ethers
import { ethers } from 'ethers';
import { MerkleTree } from 'merkletreejs';
import ABI from 'common/abi_frogs';
import { selectPreferredAllowlists } from './frogs_allowlists';
// For estimateGas errors,
// which mean that a transaction would likely revert
// and we need more ContractCheckErrors, or there was a race condition between some people
class TxWouldRevertError extends Error {
    extraParams;
    constructor(message, extraParams) {
        super(message);
        this.name = 'TxWouldRevertError';
        this.extraParams = JSON.stringify(extraParams);
    }
}
function createLeaf(address, quantity) {
    return ethers.utils.solidityKeccak256(['address', 'uint256'], [address, quantity]);
}
function generateMerkle(allowlist) {
    function toKeccak256(s) {
        return ethers.utils.keccak256(ethers.utils.arrayify(s));
    }
    const leaves = allowlist.content.merkleTree.map(({ address, quantity }) => createLeaf(address, quantity));
    const tree = new MerkleTree(leaves, toKeccak256, { sortPairs: true });
    const root = tree.getHexRoot();
    return { tree, root };
}
function calculateMerkleProof(
// allowlist is the json fetched from the ipfs link in props docs
allowlist, address, quantity) {
    const merkleData = generateMerkle(allowlist);
    const leaf = createLeaf(address, quantity);
    return merkleData.tree.getHexProof(leaf);
}
export async function fetchConfig(contract) {
    // at the time of writing, it returns something like:
    // {"0": [true, "0", "1701388800", "2070", "20", "0", "0"],
    // "1": [""],
    // "mintConfig": [true, "0", "1701388800", "2070", "20", "0", "0"],
    // "tokenConfig": [""]}
    return await contract.methods.config().call();
}
export async function fetchMintedAllowlist(contract, allowlists, address) {
    const promises = await Promise.all(allowlists.map(async (a) => {
        const result = await contract.methods
            .mintedByAllowlist(address, a.id)
            .call();
        return { used: parseInt(result), allowlistId: a.id };
    }));
    return promises;
}
export async function fetchMintedCount(contract, address) {
    return parseInt(await contract.methods.minted(address).call());
}
export async function isItemInStock(contract, name) {
    const [soFar, total] = await Promise.all([
        contract.methods.mintedByConfig(name).call(),
        contract.methods.configSupplyLimit(name).call(),
    ]);
    console.info('item stock', name, soFar, total);
    return parseInt(soFar) < parseInt(total);
}
// merges configs and updates quantities
// e.g.
// turns [[garment1, quantity: 1], [garment1, quantity: 1]]
// into [[garment1, quantity: 2]]
function mergeConfigs(data) {
    if (data._quantities.filter((x) => x != 1).length > 0) {
        return data;
    }
    //  to avoid bugs due to array reference equality
    const configMap = new Map();
    // string -> new quantity
    const countMap = new Map();
    const keys = [];
    for (let i = 0; i < data._config.length; i += 1) {
        const subject = data._config[i];
        const j = JSON.stringify(subject);
        if (!configMap.has(j)) {
            configMap.set(j, subject);
            keys.push(j);
        }
        if (!countMap.has(j)) {
            countMap.set(j, 1);
        }
        else {
            countMap.set(j, 1 + countMap.get(j));
        }
    }
    let newData = {
        contractType: data.contractType,
        _config: keys.map((k) => configMap.get(k)),
        _quantities: keys.map((k) => countMap.get(k)),
    };
    return newData;
}
// This function ultimately calls the `mint` function on the contract (type below)
// and prepares some of the params for it (like allowlistIds)
//
// function mint(
//   uint256[] calldata _quantities,
//     e.g. [1]
//   bytes32[][] calldata _proofs,
//     e.g. [] for public mint
//     e.g. [] for allowlist mint
//   uint256[] calldata _allotments,
//     e.g. [0]
//   uint256[] calldata _allowlistIds,
//     e.g. [0] for public mint
//   string[][] calldata _config
//     e.g. [['garment2', 'element1']]
// ) external payable
async function getFrogParams(web3Client, from, to, additionalData, allowlists, _allowlistUsage) {
    console.info('in get frog params');
    const { _config, _quantities } = mergeConfigs(additionalData);
    // deep copy
    const allowlistUsage = _allowlistUsage.map((x) => ({ ...x }));
    // web3 was expecting params as `string[]` type so I'm using ethers here (only for encoding params for `mint`)
    const iface = new ethers.utils.Interface(ABI);
    console.info('contract: ', to);
    const contract = new web3Client.eth.Contract(ABI, to);
    const selectedAllowlists = selectPreferredAllowlists(allowlists, allowlistUsage, _quantities, from);
    if (selectedAllowlists.length === 0) {
        // if not found, we can't mint
        throw new Error('Not on allowlist');
    }
    const _allowlistIds = selectedAllowlists.map((x) => x.id);
    console.info({ _allowlistIds: JSON.stringify(_allowlistIds) });
    const value = await contract.methods
        .calculateCost(_quantities, _allowlistIds, _config)
        .call();
    const rx = { from, to, value };
    console.info('initial tx:', JSON.stringify(rx));
    const proofsAndAllotments = _config.map((_x, i) => {
        const allowlist = selectedAllowlists[i];
        const isPublic = !allowlist?.content;
        if (isPublic) {
            return { proof: [], allotment: 0 };
        }
        // If we got to this point, all of these assertions should be safe
        const entry = allowlist.content.merkleTree.find((entry) => entry.address.toLowerCase() == from.toLowerCase());
        const proofs = calculateMerkleProof(allowlist, entry.address, entry.quantity);
        return { proof: proofs, allotment: entry.quantity };
    });
    const _proofs = proofsAndAllotments.map((x) => x.proof);
    const _allotments = proofsAndAllotments.map((x) => x.allotment);
    const params = [_quantities, _proofs, _allotments, _allowlistIds, _config];
    console.info({ params: JSON.stringify(params) });
    // @ts-ignore
    rx.data = iface.encodeFunctionData('mint', params);
    const chainId = await web3Client.eth.getChainId();
    const mintParams = {
        from,
        data: rx.data,
        to,
        value: rx.value,
    };
    try {
        rx.gas = await web3Client.eth.estimateGas(mintParams);
        console.info('estimated gas:', rx.gas);
    }
    catch (e) {
        console.error(e);
        if (e.message === 'Returned error: execution reverted') {
            if (chainId === 5) {
                // just let it fail, we'll se the error on goerli etherscan
                rx.gas = 350000 * _quantities.reduce((a, b) => a + b);
                console.warn('TX will fail');
            }
            else {
                // to be caught and reported to sentry including exact mint params
                throw new TxWouldRevertError('Returned error: execution reverted', {
                    mintParams,
                });
            }
        }
        else if (e.message.includes('insufficient funds for')) {
            const walletAddress = e.message.match(/0x.*?\s/)[0];
            const ethHanded = e.message.match(/(?:have\s)(\d+)/)[1];
            const ethRequired = e.message.match(/(?:want\s)(\d+)/)[1];
            const gas = e.message.match(/(?:gas\s)(\d+)/)[1];
            throw new TxWouldRevertError('Returned error: insufficient funds', {
                address: walletAddress,
                handed: ethHanded,
                required: ethRequired,
                gas,
            });
        }
        else {
            // something very weird
            throw e;
        }
    }
    return rx;
}
export default getFrogParams;
