import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AnalyticsPageChangeListener from './common/analytics/AnalyticsPageChangeListener';
import { StoreProvider } from './components/StoreProvider';
import EthereumHelper from './EthereumContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <StoreProvider>
      <EthereumHelper>
        <BrowserRouter>
          <AnalyticsPageChangeListener />
          <App />
        </BrowserRouter>
      </EthereumHelper>
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root') as HTMLElement,
);

Modal.setAppElement('#root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
