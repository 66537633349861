import { IBannerMessage } from 'common/state/models/BannerMessage';
import * as Icons from 'react-icons/bi';
import { get } from 'underscore';

interface Props {
  className?: string;
  visible: boolean;
  bannerMessage: IBannerMessage | undefined;
}

const NotificationBanner = ({ visible, bannerMessage }: Props) => {
  const BannerIcon =
    (bannerMessage?.iconName && get(Icons, bannerMessage.iconName)) ||
    Icons.BiCheck;

  const style = {
    backgroundColor: bannerMessage?.backgroundColor,
    color: bannerMessage?.color,
  };

  return (
    <div
      className={`overflow-hidden transition-all justify-center flex flex-row items-center regular16 z-10 px-4 ${
        visible ? 'py-3' : 'h-0 py-0'
      }`}
      style={style}
    >
      <BannerIcon className="mr-3" size="22" />
      <p>{bannerMessage?.message}</p>
    </div>
  );
};

export default NotificationBanner;
