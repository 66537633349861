const ABI = {
    inputs: [
        {
            components: [
                { internalType: 'string', name: 'uid', type: 'string' },
                { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                { internalType: 'string', name: 'name', type: 'string' },
                { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                {
                    internalType: 'address',
                    name: 'royaltyReceiver',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'primaryReceiver',
                    type: 'address',
                },
                {
                    internalType: 'uint8',
                    name: 'royaltyPercentage',
                    type: 'uint8',
                },
            ],
            internalType: 'struct IERC1155Token.ERC1155Token[]',
            name: '_token',
            type: 'tuple[]',
        },
        {
            components: [
                {
                    internalType: 'address[]',
                    name: 'accounts',
                    type: 'address[]',
                },
                {
                    internalType: 'uint32[]',
                    name: 'percentAllocations',
                    type: 'uint32[]',
                },
                {
                    internalType: 'uint32',
                    name: 'distributorFee',
                    type: 'uint32',
                },
            ],
            internalType: 'struct IPropsCreatorConfig.Split[]',
            name: '_primarySplit',
            type: 'tuple[]',
        },
        {
            components: [
                {
                    internalType: 'address[]',
                    name: 'accounts',
                    type: 'address[]',
                },
                {
                    internalType: 'uint32[]',
                    name: 'percentAllocations',
                    type: 'uint32[]',
                },
                {
                    internalType: 'uint32',
                    name: 'distributorFee',
                    type: 'uint32',
                },
            ],
            internalType: 'struct IPropsCreatorConfig.Split[]',
            name: '_royaltySplit',
            type: 'tuple[]',
        },
        {
            components: [
                {
                    internalType: 'string',
                    name: '_configuration',
                    type: 'string',
                },
                { internalType: 'uint256', name: '_quantity', type: 'uint256' },
                { internalType: 'uint256', name: '_price', type: 'uint256' },
                { internalType: 'string', name: '_nonce', type: 'string' },
                { internalType: 'bytes', name: '_signature', type: 'bytes' },
                { internalType: 'uint256', name: '_issuedOn', type: 'uint256' },
            ],
            internalType: 'struct IPropsCreatorConfig.CreateMintConfig[]',
            name: '_config',
            type: 'tuple[]',
        },
    ],
    name: 'createAndMint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
};
export default ABI;
