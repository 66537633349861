import { Orientation } from 'common/api/types';
import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { ImageStyle } from 'common/state/models/ImageStyle';
const OrientedImage = types
    .model({
    orientation: types.maybeNull(types.enumeration(Nodes.GarmentImageOrientation, Object.values(Orientation))),
    styles: types.array(ImageStyle),
})
    .views((self) => ({
    getStyleByName: (v) => self.styles.find((j) => j.version === v),
    urls: (styles) => {
        if (styles) {
            return self.styles
                .filter(({ version }) => styles.includes(version))
                .map((i) => i.url);
        }
        return self.styles.map((i) => i.url);
    },
}));
export const OrientedImages = types.array(OrientedImage);
