import { EventParams } from 'firebase/analytics';

export enum AnalyticsEventName {
  SelectGlyph = 'select_glyph',
  UnselectGlyph = 'unselect_glyph',
  PressExploreDigitalCreation = 'press_explore_digital_creation',
  PressExploreCarbonwear = 'press_explore_carbonwear',
  PressDigitalCreationTile = 'press_digital_creation_tile',
  PressCarbonwearTile = 'press_carbonwear_tile',
  PressViewMode = 'press_view_mode',
  PressDisplayOption = 'press_display_option',
  AddGlyph = 'add_glyph',
  EditGlyph = 'edit_glyph',
  AddToPod = 'add_to_pod',
  PressPreviewSwitch = 'press_preview_switch',
  AddDigitalUtility = 'add_digital_utility',
  AddCarbonwear = 'add_carbonwear',
  ClearAllGlyphs = 'clear_all_glyphs',
  DragGlyphModal = 'drag_glyph_modal',
  SelectDigitalUtility = 'select_digital_utility',
  UnselectDigitalUtility = 'unselect_digital_utility',
  SeePod = 'see_pod',
  PressDiscardSaveDraft = 'press_discard_save_draft',
  PressSaveDraft = 'press_save_draft',
  PressSeeDrafts = 'press_see_drafts',
  BorkPressKeepExploring = 'bork_press_keep_exploring',
  CopyWebPaymentLink = 'copy_web_payment_link',
  PressUpdateInPod = 'press_update_in_pod',
  DiscardUpdateCreation = 'discard_update_creation',
  PressUpdateCreation = 'press_update_creation',
  PressContinueToCheckout = 'press_continue_to_checkout',
  PressMoreOptions = 'press_more_options',
  PressSignUp = 'press_sign_up',
  PressLogin = 'press_login',
  SelectPaymentMethod = 'select_payment_method',
  PressAuthorizeInWallet = 'press_authorize_in_wallet',
  SelectAddress = 'select_address',
  DeleteAddress = 'delete_address',
  DeliveryAddressPressNext = 'delivery_address_press_next',
  SelectShipping = 'select_shipping',
  ShippingPressNext = 'shipping_press_next',
  PaymentPressNext = 'payment_press_next',
  ThankYouPressKeepExploring = 'thank_you_press_keep_exploring',
  MyPurchases = 'my_purchases',
  PressWebPaymentInfo = 'press_web_payment_info',
  PressCloseCheckout = 'press_close_checkout',
  EditPaymentMethod = 'edit_payment_method',
  EditShipping = 'edit_shipping',
  AddAddress = 'add_address',
  EditAddress = 'edit_address',
  RemoveFromPod = 'remove_from_pod',
  AddToDrafts = 'add_to_drafts',
  PressReattemptPleasing = 'press_reattempt_pleasing',
  RotateGarmentPreview = 'rotate_garment_preview',
  PressSizeGuidelines = 'press_size_guidelines',
  SelectSize = 'select_size',

  // Profile
  PressWardrobe = 'press_wardrobe',
  PressMyPurchases = 'press_my_purchases',
  ConnectWallet = 'connect_wallet',
  PressDrafts = 'press_drafts',
  PressSettings = 'press_settings',
  RemoveDraft = 'remove_draft',
  PressLogout = 'press_logout',
  DeleteAccount = 'delete_account',
  ProfileDeleteAddress = 'profile_delete_address',
  ProfileEditAddress = 'profile_edit_address',
  SaveUpdatedAddress = 'save_updated_address',
  PressSaveNewPassword = 'press_save_new_password',
  SendResetPassword = 'send_reset_password',
  ResendResetPassword = 'resend_reset_password',

  // Onboarding/Home
  PressExploreBeta = 'press_explore_beta',
  PressVisitDiscord = 'press_visit_discord',
  SignUpWithWallet = 'signup_with_wallet',
  PuzzlePasswordPressContinue = 'puzzle_password_press_continue',
  AddMailPressContinue = 'add_mail_press_continue',
  VerifyCodePressContinue = 'verify_code_press_continue',
  LogInWithWallet = 'login_with_wallet',
  PressForgotPassword = 'press_forgot_password',
  LogInWithEmailPassword = 'login_with_email_password',
  OnboardingGetStarted = 'onboarding_get_started',
  PressPoints = 'press_points',
  HomePressCreate = 'home_press_create',

  // ECommerce
  Purchase = 'purchase',
}

export interface AnalyticsEventParams {
  action?: string;
  paymentId?: string;
  paymentMethod?: string;
  glyphId?: string;
  garmentId?: string;
  garmentRow?: number;
  garmentPosition?: number;
  garmentType?: string;
  totalUsd?: string;
  userId?: string;
  buttonState?: string;
  previewSwitchType?: string;
  numberOfGarmentsInPod?: number;
  numberOfCarbonwear?: number;
  numberOfDigitalCreations?: number;
  xpAdded?: number;
  glyphPosition?: string;
  glyphRarity?: string;
  glyphRow?: number;
  viewModeType?: string;
  timeSpent?: number;
  modalPosition?: string;
  displayOptionType?: string;
  digitalUtilityRow?: number;
  digitalUtilityName?: string;
  creationPriceUSD?: string;
  step?: string;
  size?: string;
  success?: boolean;
}

export interface PurchaseEventParams {
  value?: EventParams['value'];
  currency?: EventParams['currency'];
  transaction_id: EventParams['transaction_id'];
  tax?: EventParams['tax'];
  shipping?: EventParams['shipping'];
  items?: EventParams['items'];
  coupon?: EventParams['coupon'];
  affiliation?: EventParams['affiliation'];
  [key: string]: any;
}
