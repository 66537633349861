import { isEmailValid } from 'common/utils/stringHelpers';
import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import ErrorBanner from 'app/components/ErrorBanner';
import LabeledInput from 'app/components/LabeledInput';
import WireButton from 'app/components/WireButton';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import { FormEventHandler, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageTypes } from 'common/state/models/BannerMessage';

const ForgotPassword = () => {
  const {
    userStore: { loading, sendPasswordRecoveryEmail },
    notificationStore: { pushNotification },
  } = useStore();

  const { logEvent } = useAnalytics();

  const emailRef = useRef<HTMLInputElement | null>(null);

  const [error, setError] = useState<string>();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [passwordRecoveryEmailSent, setPasswordRecoveryEmailSent] =
    useState(false);

  const onKeyPress = () => {
    const email = emailRef.current?.value ?? '';
    setButtonEnabled(isEmailValid(email));
  };

  const onForgotPasswordSubmit: FormEventHandler = useCallback(
    async (e) => {
      e.preventDefault();

      const event = passwordRecoveryEmailSent
        ? AnalyticsEventName.ResendResetPassword
        : AnalyticsEventName.SendResetPassword;
      logEvent(event);

      setError(undefined);
      const success = await sendPasswordRecoveryEmail(emailRef.current!.value);
      if (success) {
        setPasswordRecoveryEmailSent(true);
        pushNotification(
          MessageTypes.Notification,
          "We've sent an email with a reset password link at the address provided.",
        );
      } else {
        setError('Unable to send password recovery request');
      }
    },
    [
      logEvent,
      passwordRecoveryEmailSent,
      sendPasswordRecoveryEmail,
      pushNotification,
    ],
  );

  return (
    <div className="mx-auto max-w-xl">
      <div className="absolute left-0 right-0">
        <ErrorBanner error={!!error}>{error!}</ErrorBanner>
      </div>
      <h2 className="text-center pt-16 pb-8 uppercase">
        Forgot your password?
      </h2>
      <p className="text-center regular16">
        Write down your email address and we'll send you a link to reset your
        password if there is an existing account related to that email.
      </p>

      <form onSubmit={onForgotPasswordSubmit} className="pt-10">
        <LabeledInput
          ref={emailRef}
          onInput={onKeyPress}
          placeholder="E-mail address"
        />
        <WireButton
          type="submit"
          disabled={loading || !buttonEnabled}
          loading={loading}
          className="mt-8"
        >
          {passwordRecoveryEmailSent ? 'Resend' : 'Send'}
        </WireButton>
        <div className="h-4" />
        <Link className="text-turquoise-800" to={'/login'}>
          Back to logging in
        </Link>
      </form>

      {passwordRecoveryEmailSent && (
        <>
          <h3 className="mt-16 mb-3">Haven’t received an email?</h3>
          <ol className="text-coldGrey-800 regular16 list-inside">
            <li>Check your SPAM folder.</li>
            <li>Check provided email address.</li>
            <li>Wait a moment and try again.</li>
          </ol>
        </>
      )}
    </div>
  );
};

export default observer(ForgotPassword);
