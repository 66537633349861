import { BsXLg } from 'react-icons/bs';
import Price from 'app/components/Price';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import DesignPreview from './DesignPreview';

interface Props {
  setIdToRemove: (id: string) => void;
}

const CartItems = ({ setIdToRemove }: Props) => {
  const { scenesStore } = useStore();

  const [digitals, carbonwear] = scenesStore.cartSections;

  return (
    <div>
      {digitals.data.length + carbonwear.data.length === 0 && (
        <h4>No items yet</h4>
      )}
      {digitals.data.length > 0 && <h4 className="my-6">Digital creations</h4>}
      {digitals.data.map((item) => (
        <CartItem onX={setIdToRemove} key={item} id={item} />
      ))}
      {carbonwear.data.length > 0 && <h4 className="my-6">Carbonwear</h4>}
      {carbonwear.data.map((item) => (
        <CartItem onX={setIdToRemove} key={item} id={item} />
      ))}
    </div>
  );
};

const CartItem = observer(
  ({ id, onX }: { id: string; onX: (id: string) => void }) => {
    const {
      garmentStore,
      scenesStore,
      checkoutStore: { lineItemsForScene, sceneOutstandingPrice },
    } = useStore();
    const item = scenesStore.getSceneById(id)!;
    const garmentData = garmentStore.garmentById(item.garmentId!);

    const lineItems = lineItemsForScene(item);

    if (!item) return null;

    return (
      <>
        <div className="flex flex-row">
          <DesignPreview item={item} />
          <div className="ml-6 mr-2 relative flex flex-col flex-1 justify-between">
            <div>
              <div className="flex flex-row items-start justify-between">
                <h2 className="text-xl md:text-2xl uppercase">
                  {garmentData?.name}
                </h2>
                <button
                  onClick={() => item.id && onX(item.id)}
                  className="ml-4"
                >
                  <BsXLg size="20" />
                </button>
              </div>
              <div className="properties">
                {item
                  .checkoutWebDescription(item.id!, garmentData, lineItems)
                  ?.map((line, index) => (
                    <p key={index} className="regular16 text-sm md:text-base">
                      {line}
                    </p>
                  ))}
              </div>
            </div>
            <Price price={sceneOutstandingPrice(item)} />
          </div>
        </div>
        <Spacer />
      </>
    );
  },
);

const Spacer = () => {
  return <div className="h-0.5 my-6 w-full bg-coldGrey-300"></div>;
};

export default observer(CartItems);
