import * as spree from '@spree/storefront-api-v2-sdk';
export default class Drops extends spree.Http {
    drops_path = '/api/v2/drops';
    drop_path = (id) => `${this.drops_path}/${id}`;
    draw_path = (id) => `${this.drop_path(id)}/draw`;
    async draw(token, drop_id, params = {}) {
        return (await this.spreeResponse('post', this.draw_path(drop_id), token, params));
    }
    async show(token, params = {}) {
        return (await this.spreeResponse('get', this.drops_path, token, params));
    }
}
