import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import useStore from 'app/common/useStore';
import web3utils from 'web3-utils';

import { TxData } from 'common/crypto/tx_data';
import getFrogParams from 'common/crypto/frogs';
import getKeyParams from 'common/crypto/key';
import Web3 from 'web3';
import { isEmpty } from 'lodash';
import { MetaMaskInpageProvider } from '@metamask/providers';
import { observer } from 'mobx-react-lite';

declare global {
  interface Window {
    ethereum: MetaMaskInpageProvider;
  }
}

const ethereum = window.ethereum;

interface EthContext {
  account: string | null;
  connect: () => Promise<void>;
  sign: () => Promise<void>;
  pay: () => Promise<void>;
}

const EthereumContext = createContext<EthContext>({} as unknown as EthContext);

const EthereumHelper = ({ children }: { children: React.ReactNode }) => {
  const account = useRef<string | null>(null);
  const {
    checkoutStore: {
      getCryptoPaymentAttributes,
      finalizePayment,
      getMintParams,
      assumeCheckoutCompleted,
    },
    scenesStore: { cartSections },
    ethereumStore: { allowlists, allowlistUsage },
    userStore,
  } = useStore();

  const connect = async () => {
    const accounts = await ethereum.request<string[]>({
      method: 'eth_requestAccounts',
    });
    account.current = accounts![0]!;
  };

  const reconnect = async () => {
    const accounts = await ethereum.request<string[]>({
      method: 'eth_accounts',
    });
    account.current = accounts![0]!;
  };

  const sign = async () => {
    if (!account.current) return;

    userStore.setWalletAddress(account.current);
    const phrase = await userStore.getChallengePhrase();
    const phraseHex = web3utils.utf8ToHex(phrase);
    const signature = await ethereum.request<string>({
      method: 'personal_sign',
      params: [phraseHex, account.current],
    });
    userStore.setSignature(signature!);
    await userStore.logInWithWallet();
  };

  // TODO frog drop
  // use mintLimitReached and display a correct error here
  // also make sure that the errors from getKeyParams (e.g. 'Failed to find allowlists...')
  // are propagated correctly

  const pay = async () => {
    if (!account.current) {
      await connect();
    }

    const [digital] = cartSections;

    const { paymentAddress, lockedPrice } = await getCryptoPaymentAttributes();
    const value = '0x' + parseInt(lockedPrice).toString(16);
    let rx: null | TxData = null;

    const mintOnlyPayment = !isEmpty(digital.data);
    if (mintOnlyPayment) {
      const mintParams = await getMintParams();

      // @ts-ignore
      let web3Client = new Web3(ethereum);
      let contractType: string = 'frog'; // TODO FIXME // mintParams.contractType

      if (contractType === 'key') {
        for (let i = 0; i < mintParams._config.length; i += 1) {
          mintParams._config[i]._signature = web3utils.hexToBytes(
            mintParams._config[i]._signature,
          );

          mintParams._config[i]._price = web3utils.hexToBytes(
            '0x' + parseInt(mintParams._config[i]._price).toString(16),
          );
        }

        rx = getKeyParams(
          web3Client,
          account.current!,
          paymentAddress,
          value,
          mintParams,
        );
      } else if (contractType === 'frog') {
        rx = await getFrogParams(
          web3Client,
          account.current!,
          paymentAddress,
          mintParams,
          allowlists,
          allowlistUsage,
        );

        // web-specific formatting
        rx!.gas = '0x' + rx!.gas!.toString(16);
        // @ts-ignore // FIXME TODO Does eth estimateGas return a string (dec/hex?) or a number?
        rx.value = '0x' + parseInt(rx.value).toString(16);
      }
    }

    const transactionHash = await ethereum.request<string>({
      method: 'eth_sendTransaction',
      params: [rx!],
    });

    // At this point transaction went on blockchain
    // Let's create a new order on any API call that may fail
    try {
      await finalizePayment({
        transactionHash: transactionHash!,
        purchaser: account.current!,
      });
    } finally {
      await assumeCheckoutCompleted();
    }
  };

  useEffect(() => {
    ethereum.on('accountsChanged', (args) => {
      const accounts = args as string[];
      userStore.setWalletAddress(accounts[0] ?? '');
      account.current = accounts[0] ?? null;
    });

    // the initial attempt to reconnect:
    reconnect();
  }, [userStore]);

  const value: EthContext = {
    account: account.current,
    connect,
    sign,
    pay,
  };

  return (
    <EthereumContext.Provider value={value}>
      {children}
    </EthereumContext.Provider>
  );
};

export const useEthereum = () => useContext(EthereumContext);

const requireEthereum = <T,>(Component: React.FC<T>) => {
  if (ethereum === undefined) {
    return ({ children }: { children?: ReactNode }) => <>{children}</>;
  } else {
    return Component;
  }
};

export default observer(requireEthereum(EthereumHelper));
