import { IAddress } from 'common/state/models/Address';
import RadioOption from 'app/components/RadioOption';
import { useCallback, useState } from 'react';
import useStore from 'app/common/useStore';
import Icon from './Icon';
import { Color } from 'common/styles';
import OptionsMenu from './OptionsMenu';
import { RiPencilFill } from 'react-icons/ri';
import { CgTrash } from 'react-icons/cg';
import { HiDotsVertical } from 'react-icons/hi';
import AddressDetails from 'app/components/AddressDetails';

export const AddressOption = ({
  onPress,
  value,
  address,
  onEditPress,
  onDeletePress,
}: {
  value: boolean;
  onPress: () => void;
  address: IAddress;
  onEditPress: () => void;
  onDeletePress: () => void;
}) => {
  const {
    checkoutStore: { lockedAddressId },
  } = useStore();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownClose = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  return (
    <RadioOption
      key={address.id}
      showCircles
      className="relative"
      value={value}
      onPress={onPress}
    >
      <div className="flex-col">
        <AddressDetails address={address} />
      </div>
      <OptionsMenu
        className="absolute top-3 right-3"
        visible={dropdownVisible}
        close={dropdownClose}
        optionItems={[
          {
            text: 'Edit',
            icon: RiPencilFill,
            onPress: () => {
              dropdownClose();
              onEditPress();
            },
          },
          {
            text: 'Delete',
            textColor: Color.red80,
            icon: CgTrash,
            iconColor: Color.red80,
            onPress: () => {
              dropdownClose();
              onDeletePress();
            },
          },
        ]}
      >
        <Icon
          disabled={address.id === lockedAddressId}
          onPress={() => {
            setDropdownVisible((visible) => !visible);
          }}
          icon={HiDotsVertical}
        />
      </OptionsMenu>
    </RadioOption>
  );
};
