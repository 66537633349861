import { forwardRef, HTMLInputTypeAttribute, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import InputAlertBox, { InputAlert } from './InputAlertBox';
import { camelCase, isEmpty } from 'lodash';

interface Props {
  onInput?: () => void;
  initialValue?: string;
  error?: boolean;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  messages?: InputAlert[];
  disabled?: boolean;
  className?: string;
}

const LabeledInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      onInput = undefined,
      error = false,
      type,
      placeholder,
      initialValue = '',
      messages = [],
      disabled = false,
      className = '',
    },
    ref,
  ) => {
    const inputStyles = `labeled flex-1 px-3 pt-6 pb-2 my-2 border-2 border-transparent focus:outline-none focus:border-turquoise-800 rounded-lg bg-coldGrey-200 transition-all ${
      error ? 'border-red-700' : ''
    } ${className}`;
    const [showPassword, setShowPassword] = useState(false);

    return (
      <>
        <div className="flex flex-1 relative flex-col">
          <input
            ref={ref}
            type={showPassword ? 'text' : type}
            defaultValue={initialValue}
            onInput={onInput}
            className={inputStyles}
            placeholder=" "
            disabled={disabled}
          />
          <div className="absolute pointer-events-none">{placeholder}</div>
          {type === 'password' && (
            <div
              onClick={() => setShowPassword((x) => !x)}
              className={`h-8 w-8 center-y right-2 rounded-full transition-all text-coldGrey-700 ${
                showPassword ? 'bg-coldGrey-400' : ''
              } click-highlight `}
            >
              {showPassword ? (
                <AiFillEyeInvisible className="mx-auto mt-1" size={24} />
              ) : (
                <AiFillEye className="mx-auto mt-1" size={24} />
              )}
            </div>
          )}
        </div>
        {!isEmpty(messages) &&
          messages.map((item) => (
            <InputAlertBox key={camelCase(item.message)} {...item} />
          ))}
      </>
    );
  },
);

export default LabeledInput;
