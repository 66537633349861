import { capitalize } from 'lodash';
import { Severity } from 'common/errorHelpers';
import { MdInfoOutline } from 'react-icons/md';

const textColor: { [key in Severity]: string } = {
  [Severity.Info]: 'text-coldGrey-700',
  [Severity.Error]: 'text-red-900',
  [Severity.Success]: 'text-green-700',
};

const iconColor: { [key in Severity]: string } = {
  [Severity.Info]: 'text-turquoise-800',
  [Severity.Error]: 'text-red-900',
  [Severity.Success]: 'text-green-700',
};

export interface InputAlert {
  severity: Severity;
  message: string;
}

const InputAlertBox: React.FC<InputAlert> = ({ severity, message }) => {
  return (
    <div className="flex flex-row items-center regular14">
      <MdInfoOutline className={iconColor[severity]} size={20} />
      <p className={`ml-1 ${textColor[severity]}`}>{capitalize(message)}</p>
    </div>
  );
};

export default InputAlertBox;
