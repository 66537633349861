import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import RadioOption from 'app/components/RadioOption';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import { formatPrice } from 'common/state/models/Price';
import PaymentOptionBar from 'app/components/PaymentOptionBar';
import { isEmpty } from 'lodash';

const Summary = () => {
  const { checkoutStore } = useStore();

  const { logEvent } = useAnalytics();

  const renderPaymentOptionButton = (method) => (
    <RadioOption
      key={method.id}
      value={checkoutStore.selectedPaymentMethodId === method.id}
      showCircles
      onPress={async () => {
        await checkoutStore.setSelectedPaymentMethod(method.id);

        logEvent(AnalyticsEventName.SelectPaymentMethod, {
          paymentId: checkoutStore.orderNumber,
          paymentMethod: method.name,
          totalUsd: formatPrice(checkoutStore.totalAmount.usd),
        });
      }}
    >
      <PaymentOptionBar method={method} />
    </RadioOption>
  );

  return (
    <div className="flex flex-col">
      <h2 className="mb-6 uppercase">Payment method</h2>
      <h4 className="mb-6">Crypto payment methods</h4>
      {Object.values(checkoutStore.cryptoPaymentMethods).map(
        renderPaymentOptionButton,
      )}
      {!isEmpty(checkoutStore.fiatPaymentMethods) && (
        <h4 className="mb-6">Standard payment methods</h4>
      )}
      {Object.values(checkoutStore.fiatPaymentMethods).map(
        renderPaymentOptionButton,
      )}
    </div>
  );
};

export default observer(Summary);
