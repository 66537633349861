import CartItems from 'app/components/CartItems';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import RemoveItemModal from 'app/components/RemoveItemModal';
import Price from 'app/components/Price';
import { uniq } from 'underscore';
import { IShipment } from 'common/state/models/Shipment';
import PaymentOptionBar from 'app/components/PaymentOptionBar';
import AddressDetails from 'app/components/AddressDetails';

function formatDeliveryTypes(shipments: IShipment[]): string {
  return uniq(
    shipments
      .filter(({ selectedShippingRate }) => !!selectedShippingRate)
      .map(({ selectedShippingRate }) => selectedShippingRate?.name),
  ).join(', ');
}

const Complete = () => {
  const {
    userStore: { getAddressById },
    checkoutStore: {
      shipments,
      deliveryAmount,
      addressId,
      selectedPaymentMethod,
    },
  } = useStore();
  const [idToRemove, setIdToRemove] = useState<string | null>(null);

  const address = getAddressById(addressId!)!;

  const deliveryType = formatDeliveryTypes(Array.from(shipments.values()));

  return (
    <div className="flex-1">
      <h2 className="uppercase">Your order</h2>
      <CartItems setIdToRemove={setIdToRemove} />

      {!!addressId && (
        <>
          <div className="mt-16">
            <h2>Address</h2>
            <div className="bg-coldGrey-200 py-5 px-6 rounded-lg">
              <AddressDetails address={address} />
            </div>
          </div>
          <div className="mt-16">
            <h2>Shipping</h2>
            <div className="bg-coldGrey-200 py-5 px-6 rounded-lg">
              <div className="flex justify-between">
                <p>{deliveryType}</p>
                <Price showAllPrices price={deliveryAmount} />
              </div>
            </div>
          </div>
        </>
      )}
      {selectedPaymentMethod && (
        <div className="mt-16">
          <h2>Payment method</h2>
          <div className="bg-coldGrey-200 mt-6 py-5 px-6 rounded-lg flex">
            <PaymentOptionBar method={selectedPaymentMethod} />
          </div>
        </div>
      )}
      <RemoveItemModal id={idToRemove} setId={setIdToRemove} />
    </div>
  );
};

export default observer(Complete);
