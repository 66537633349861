import React, { PropsWithChildren, useRef } from 'react';
import OptionItem, { Props as OptionItemProps } from './OptionItem';

interface Props {
  visible: boolean;
  optionItems: OptionItemProps[];
  close: () => void;
  className: string;
}

const OptionsMenu: React.FC<PropsWithChildren<Props>> = ({
  visible,
  optionItems,
  children,
  close,
  className,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const optionWidth = divRef.current?.parentElement?.offsetWidth!;
  // Check whether there is enough space to shop OptionsMenu
  const optionMenuOffsetClass =
    window.innerWidth < 1.5 * optionWidth ? 'left-[-92px]' : '';

  return (
    <div
      ref={divRef}
      className={`${visible ? 'block' : 'inline-block'} ${className}`}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          close();
        }
      }}
    >
      {children}

      <div
        className={`absolute z-30 bg-coldGrey-200 border border-coldGrey-400 rounded-lg py-1 flex flex-col ${
          visible ? 'block' : 'hidden'
        } ${optionMenuOffsetClass}`}
      >
        {optionItems.map((props) => (
          <OptionItem key={props.text} {...props} />
        ))}
      </div>
    </div>
  );
};

export default OptionsMenu;
