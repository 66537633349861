import { getStockStatusAsync, getStickersAsync } from 'common/api/spree';
import { GlyphState } from 'common/api/types';
import { handleError } from 'common/errorHelpers';
import { Color } from 'common/styles';
import { Nodes } from 'common/state/nodes';
import { partition } from 'lodash';
import { flow, types } from 'mobx-state-tree';
import { Sticker } from 'common/state/models/Sticker';
import { StickerUniverse } from 'common/state/models/StickerUniverse';
import { getRootStore } from 'common/state/RootStore';
export const GlyphStateDescription = {
    [GlyphState.Popular]: { text: 'Popular', color: Color.turquoise90 },
    [GlyphState.RunningLow]: { text: 'Running Low', color: Color.pink90 },
    [GlyphState.SoldOut]: { text: 'Sold Out', color: Color.coldGrey90 },
    [GlyphState.ComingSoon]: { text: 'Coming Soon', color: Color.coldGrey90 },
    [GlyphState.Taken]: { text: 'In use', color: Color.coldGrey90 },
};
const StickerStore = types
    .model(Nodes.StickerStore, {
    stickers: types.map(Sticker),
    universes: types.map(StickerUniverse),
    stickerPositions: types.array(types.string),
    loading: types.boolean,
})
    .actions((self) => ({
    fetchContractStock: flow(function* () {
        const { ethereumStore: { checkStock }, } = getRootStore(self);
        yield Promise.all(Array.from(self.stickers.values()).map(async (sticker) => {
            if (sticker.limitedByContract && !(await checkStock(sticker.slug))) {
                sticker.setStockStatus(GlyphState.SoldOut);
            }
        }));
    }),
    replace: ({ stickers, universes, stickersPositions }, specialStickerProductId) => {
        stickers.forEach((sticker) => {
            if (specialStickerProductId &&
                sticker.productId === specialStickerProductId) {
                sticker.special = true;
            }
            self.stickers.put(sticker);
        });
        universes.forEach((universe) => {
            self.universes.put(universe);
        });
        const allUniverse = {
            id: 'all',
            name: 'All',
            exclusive: true,
            imageUrl: '',
            stickers: stickers.map(({ id }) => id),
        };
        self.universes.put(allUniverse);
        stickersPositions.forEach((position) => self.stickerPositions.push(position));
    },
}))
    .views((self) => ({
    getStickerWithId: (stickerId) => {
        return self.stickers.get(stickerId);
    },
    glyphsWithProductId: (productId) => {
        return Array.from(self.stickers.values()).filter((glyph) => glyph.productId === productId);
    },
}))
    .views((self) => ({
    glyphsWithSameProductId: (glyphId) => {
        const productId = self.getStickerWithId(glyphId)?.productId;
        return self.glyphsWithProductId(productId);
    },
}))
    .actions((self) => ({
    fetch: flow(function* (spreeAuth, specialStickerProductId) {
        self.loading = true;
        try {
            const stickerResponse = yield getStickersAsync(spreeAuth);
            self.replace(stickerResponse, specialStickerProductId);
        }
        catch (err) {
            console.warn("Couldn't fetch glyphs", err);
            handleError(err, self);
            return false;
        }
        self.loading = false;
        return true;
    }),
    updateGlyphStates: flow(function* (spreeAuth) {
        const newStates = yield getStockStatusAsync(spreeAuth);
        Object.keys(newStates).forEach((productId) => {
            self.glyphsWithProductId(productId).forEach((glyph) => {
                glyph.setStockStatus(newStates[productId]);
            });
        });
    }),
}))
    .views((self) => ({
    get availableGlyphs() {
        const availableGlyphs = self.universes
            .get('all')
            ?.stickers.filter((sticker) => !!sticker.imageUrl)
            .map((sticker) => ({
            price: sticker.price,
            id: sticker.id,
            productId: sticker.productId,
            url: sticker.imageUrl,
            state: sticker.state,
            author: {
                name: sticker.author,
                link: sticker.authorLink,
            },
            stickerName: sticker.name,
        })) || [];
        return availableGlyphs;
    },
    getUrlForSticker: (stickerId) => {
        const sticker = self.stickers.get(stickerId);
        return sticker?.imageUrl;
    },
    getStickerUrlByVersion: (stickerId, imageStyle) => {
        const sticker = self.stickers.get(stickerId);
        return sticker?.getImageByVersion(imageStyle);
    },
    get availableUniverses() {
        const universesArray = Array.from(self.universes.values());
        const universes = universesArray.map(({ id, imageUrl, stickers }) => ({
            id,
            exclusive: true,
            data: stickers,
            image: imageUrl,
        }));
        const [exclusiveUniverses, commonSpaces] = partition(universes, ({ exclusive }) => !!exclusive);
        return [
            {
                title: 'Locked Vaults',
                data: exclusiveUniverses,
            },
            {
                title: 'Unlocked Vaults',
                data: commonSpaces,
            },
        ];
    },
    getStickersWithUniverseId: (universeId, stickerPositionId, specialStickers = false) => {
        const universe = self.universes.get(universeId);
        const stickers = universe?.stickers
            .filter(({ positionId, special, available }) => available &&
            (stickerPositionId ? stickerPositionId === positionId : true) &&
            specialStickers === special)
            .map(({ id }) => id) || [];
        return stickers;
    },
    getUniverseWithId: (universeId) => {
        return self.universes.get(universeId);
    },
}))
    .views((self) => ({
    isStickerSpecial: (stickerId) => {
        return !!self.getStickerWithId(stickerId)?.special;
    },
    checkIfStickerExclusive: (universeId) => {
        const universe = self.getUniverseWithId(universeId);
        return !!universe?.exclusive;
    },
}));
export default StickerStore;
