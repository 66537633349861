import { useEffect, useRef } from 'react';
import { BsPerson } from 'react-icons/bs';
import CartBadge from 'app/components/CartBadge';
import useStore from 'app/common/useStore';
import logo from 'app/assets/logo.png';
import { observer } from 'mobx-react-lite';
import ErrorBanner from 'app/components/ErrorBanner';
import { useEthereum } from 'app/EthereumContext';
import Basket from 'app/assets/basket.svg';
import NotificationBanner from 'app/components/NotificationBanner';
import useNetworkListener from 'app/common/useNetworkListener';
import { MessageTypes } from 'common/state/models/BannerMessage';
import { Link } from 'react-router-dom';
import LogoutPopup from 'app/components/LogoutPopup';
import { isEmpty } from 'lodash';
import { PURCHASE_RELATED_STUFF_VISIBLE } from 'app/common/config';

interface PopoutHandle {
  toggle: () => void;
}

const _Header = () => {
  const {
    userStore: { spreeAuth, addressErrorsList, error, loggedIn },
    notificationStore: { bannerMessage, bannerVisible, pushNotification },
  } = useStore();
  const signedIn = !!spreeAuth.token?.bearerToken;

  const popoutRef = useRef<PopoutHandle>();
  const ethereum = useEthereum();
  const online = useNetworkListener();

  useEffect(() => {
    const message = online
      ? MessageTypes.InternetBack
      : MessageTypes.InternetOff;
    pushNotification(message);
  }, [online, pushNotification]);

  const onProfileClick = () => popoutRef.current?.toggle();

  const formatErrorMessage = (err: string) => {
    return err[0].charAt(0).toUpperCase() + err.slice(1);
  };

  const errors = Object.getOwnPropertyNames(addressErrorsList);
  const errorMessage =
    errors.length !== 0
      ? formatErrorMessage(errors[0] + ' ' + addressErrorsList[errors[0]])
      : '';

  return (
    <>
      <nav className="bg-coldGrey-200 py-6 px-4 z-40">
        <div className="mx-auto max-w-screen-lg justify-between flex flex-row">
          <img src={logo} alt="RSTLSS logo" className="h-[28px]" />
          <div className="flex-row flex items-center">
            {signedIn ? (
              <>
                {PURCHASE_RELATED_STUFF_VISIBLE && (
                  <Link to="/pod" className="relative">
                    <img alt="" width={26} height={26} src={Basket} />
                    <CartBadge />
                  </Link>
                )}
                <button className="relative ml-6" onClick={onProfileClick}>
                  <BsPerson size={26} color="white" />
                </button>
              </>
            ) : (
              <Link to="/login" className="flex flex-row">
                <BsPerson size={26} color="white" />
                <div className="bold16 ml-2">Log in</div>
              </Link>
            )}
          </div>
        </div>
        {process.env.REACT_APP_ENV === 'staging' && (
          <div className="absolute top-0 left-0 text-white z-0">
            {process.env.REACT_APP_HASH}
          </div>
        )}
      </nav>
      <ErrorBanner className="z-50" error={errors.length !== 0}>
        {errorMessage}
      </ErrorBanner>
      <ErrorBanner error={isEmpty(Object.keys(ethereum)) && loggedIn}>
        No available ethereum provider. Install a web extension or use a browser
        built into your wallet app.
      </ErrorBanner>
      <ErrorBanner error={error !== ''}>{error}</ErrorBanner>
      <NotificationBanner
        visible={bannerVisible}
        bannerMessage={bannerMessage}
      />
      <LogoutPopup ref={popoutRef} />
    </>
  );
};

const Header = observer(_Header);

export default Header;
