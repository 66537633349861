export var Color;
(function (Color) {
    Color["black"] = "#000000";
    Color["white"] = "#FFFFFF";
    Color["transparent"] = "transparent";
    Color["coldGrey10"] = "#15161C";
    Color["coldGrey20"] = "#2A2C39";
    Color["coldGrey30"] = "#3F4355";
    Color["coldGrey40"] = "#575B71";
    Color["coldGrey50"] = "#70758E";
    Color["coldGrey60"] = "#8C91AA";
    Color["coldGrey70"] = "#ABB0C6";
    Color["coldGrey80"] = "#CDD1E3";
    Color["coldGrey90"] = "#F1F3FF";
    Color["primaryBlue10"] = "#001422";
    Color["primaryBlue20"] = "#002744";
    Color["primaryBlue40"] = "#004E88";
    Color["primaryBlue70"] = "#49A8EE";
    Color["primaryBlue80"] = "#80C5F6";
    Color["primaryBlue90"] = "#B8E1FF";
    Color["turquoise10"] = "#00181C";
    Color["turquoise20"] = "#002F39";
    Color["turquoise30"] = "#004755";
    Color["turquoise40"] = "#005E71";
    Color["turquoise70"] = "#40B0C6";
    Color["turquoise80"] = "#77D1E3";
    Color["turquoise90"] = "#B8F3FF";
    Color["darkBlue40"] = "#000071";
    Color["darkBlue70"] = "#5353C6";
    Color["darkBlue90"] = "#C2C2FF";
    Color["violet40"] = "#260071";
    Color["violet70"] = "#7953C6";
    Color["violet90"] = "#E8DDFF";
    Color["pink40"] = "#710071";
    Color["pink70"] = "#C653C6";
    Color["pink90"] = "#FFC2FF";
    Color["green30"] = "#005539";
    Color["green70"] = "#31E8AB";
    Color["green90"] = "#C2FFEB";
    Color["red40"] = "#710013";
    Color["red70"] = "#F12F4F";
    Color["red80"] = "#FF5C77";
    Color["red90"] = "#FFC2CC";
})(Color || (Color = {}));
export const DEFAULT_STICKER_TEMPLATE = {
    width: 1000,
    height: 1000,
};
