import React from 'react';
import * as Sentry from '@sentry/react';
import { IStore, RootStore } from 'common/state/RootStore';
import StoreEnv from 'common/state/StoreEnv';
import { IExperienceIn } from 'common/state/models/Experience';
import { API_BASE_URL, NETWORK_NAME, ALCHEMY_API_KEY } from 'app/common/config';
import Web3 from 'web3';

export const StoreContext = React.createContext<IStore | null>(null);

const env: StoreEnv = {
  sentry: Sentry,
  // @ts-ignore
  web3Client: new Web3(window.ethereum),
  config: {
    API_BASE_URL,
    NETWORK_NAME,
    ALCHEMY_API_KEY,
  },
  navigation: {
    verifyEmailWithWallet: (_walletAddress: string, _signature: string) => {},
    addYourEmailWithWallet: (_walletAddress: string, _signature: string) => {},
    initial: () => {},
    home: () => {},
    congratsSignIn: (_experience: IExperienceIn) => {},
    congratsSignUp: (_experience: IExperienceIn) => {},
    congratsThankYou: (_experience: IExperienceIn) => {},
  },
  storage: {
    setValue: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
    // eslint-disable-next-line require-await
    getValue: async (key: string) => localStorage.getItem(key),
    // eslint-disable-next-line require-await
    getValues: async (keys: readonly string[]) =>
      keys.map((key) => localStorage.getItem(key) ?? ''),
    resetValues: (keys: readonly string[]) =>
      keys.forEach((key) => localStorage.removeItem(key)),
  },
};

const store = RootStore.create(
  {
    appLoading: true,
    splashScreenPlaying: true,
  },
  env,
);

export const StoreProvider: React.FC = ({ children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
