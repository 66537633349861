import * as SpreeAccount from '@spree/storefront-api-v2-sdk/src/endpoints/Account';
import routes, { storefrontPath, } from '@spree/storefront-api-v2-sdk/src/routes';
export default class Account extends SpreeAccount.default {
    async status() {
        return this.spreeResponse('get', '/api/v2/status');
    }
    async version() {
        return this.spreeResponse('get', '/api/v2/version');
    }
    async resendConfirmationCode(params) {
        this.spreeResponse('post', `${storefrontPath}/account_confirmations`, {}, params);
    }
    async ordersList(token, params = {}) {
        return this.spreeResponse('get', '/api/v2/storefront/account/orders', token, params);
    }
    async lockOrder(orderNumber, token, params = {}) {
        return this.spreeResponse('patch', `${routes.accountCompletedOrderPath(orderNumber)}/lock`, token, params);
    }
    async disconnectWallet(token) {
        return this.spreeResponse('patch', '/api/v2/wallet/disconnect', token);
    }
    async delete(token, userId) {
        return this.spreeResponse('delete', `${routes.accountPath()}/${userId}`, token);
    }
}
