import { FaEthereum } from 'react-icons/fa';
import { formatPrice, IPrice } from 'common/state/models/Price';
import { observer } from 'mobx-react-lite';

interface Props {
  price: IPrice;
  showAllPrices?: boolean;
}

const Price = ({ price, showAllPrices = false }: Props) => {
  if (!price) {
    return null;
  }
  const dollarPrice = formatPrice(price.usd, true);
  const dollarPriceStyle = `ml-1 ${
    price.showEthPrice() ? 'regular' : 'bold'
  }16`;

  return (
    <div className="flex-row flex items-center">
      {price.showEthPrice() && (
        <>
          <FaEthereum />
          <div className="ml-1 bold16">{formatPrice(price.eth, false, 6)}</div>
        </>
      )}
      {(price.showDollarPrice() || showAllPrices) && (
        <div className={dollarPriceStyle}>
          {price.showEthPrice() ? `(${dollarPrice})` : dollarPrice}
        </div>
      )}
    </div>
  );
};

export default observer(Price);
