import Select from 'react-select';
import { IAddress } from 'common/state/models/Address';

export interface CountryOption {
  value: string;
  label: string;
}

interface Props {
  options: CountryOption[];
  initialValue?: IAddress;
  focused: boolean;
  label?: string;
  onSelect: (option: any) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const CountryPicker: React.FC<Props> = ({
  options = [],
  initialValue = undefined,
  focused = false,
  label = 'Country',
  onSelect,
  onFocus,
  onBlur,
}) => {
  return (
    <div className="relative">
      <div
        className={`absolute left-[14px] text-coldGrey-700 z-10 transition-all
                ${
                  focused
                    ? ' top-[8px] text-xs'
                    : 'bottom-1/2 translate-y-1/2 text-base'
                }
              `}
      >
        {label}
      </div>
      <Select
        options={options}
        styles={styles}
        onChange={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        className="selector"
        classNamePrefix="selector"
        defaultValue={{
          value: initialValue?.country_iso,
          label: initialValue?.country,
        }}
      />
    </div>
  );
};

export default CountryPicker;

const styles: React.ComponentProps<Select>['styles'] = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#3F4355' : '#2A2C39',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#2A2C39',
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 12,
    marginTop: 8,
    marginBottom: 24,
    borderWidth: 2,
    borderColor: state.isFocused ? 'white' : '#2A2C39',
    boxShadow: 'none',
    outlineWidth: 0,
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    backgroundColor: '#2A2C39',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 100,
    overflow: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    height: 24,
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#ABB0C6',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
  }),
};
