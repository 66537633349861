import Cookies from './Cookies';
import Privacy from './Privacy';
import Terms from './Terms';

const Policies = {
  Cookies,
  Privacy,
  Terms,
};

export default Policies;
