import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
const SpreeAuth = types
    .model(Nodes.SpreeAuth, {
    bearerToken: types.maybe(types.string),
    orderToken: types.maybe(types.string),
    refreshToken: types.maybe(types.string),
})
    .views((self) => ({
    get token() {
        const currentToken = {};
        if (self.bearerToken) {
            currentToken.bearerToken = self.bearerToken;
        }
        if (self.orderToken) {
            currentToken.orderToken = self.orderToken;
        }
        return currentToken;
    },
    get loggedIn() {
        return !!self.bearerToken;
    },
}));
export default SpreeAuth;
