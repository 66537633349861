/* eslint-disable func-names */
import { getScenesListAsync } from 'common/api/spree';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { GarmentType } from 'common/api/types';
import { handleError } from 'common/errorHelpers';
import Scene from 'common/state/models/Scene';
import { Nodes } from 'common/state/nodes';
import { flow, types } from 'mobx-state-tree';
import { getRootStore } from 'common/state/RootStore';
const getSpecificIds = (scenes, sceneStatus) => scenes
    .filter(({ id, status }) => !!id && status === sceneStatus)
    .map(({ id }) => id);
function numberValueDescComparator(a, b) {
    return Number(b) - Number(a);
}
const ScenesStore = types
    .model(Nodes.ScenesStore, {
    loading: types.boolean,
    scenes: types.array(Scene),
})
    .views((self) => ({
    getByGarmentType: (garmentType) => {
        return self.scenes.filter((scene) => {
            if (!garmentType)
                return true;
            const garment = scene.getGarmentData();
            return garment.garmentType === garmentType;
        });
    },
    getSceneById: (id) => {
        return self.scenes.find((i) => i.id === id);
    },
}))
    .actions((self) => ({
    reset: () => {
        self.scenes.clear();
    },
    replace: (newData) => {
        self.scenes.splice(0, self.scenes.length, ...newData);
    },
    removeItemById: (id) => {
        const index = self.scenes.findIndex((elem) => elem.id === id);
        self.scenes.splice(index, 1);
    },
}))
    .actions((self) => ({
    fetchContent: flow(function* () {
        try {
            self.loading = true;
            const { userStore: { spreeAuth }, stickerStore: { stickers }, } = getRootStore(self);
            const scenes = yield getScenesListAsync(spreeAuth);
            self.replace(scenes);
            self.scenes.forEach((scene) => {
                stickers.forEach((sticker) => {
                    scene.privateStickers.delete(sticker.id);
                });
            });
        }
        catch (err) {
            handleError(err, self);
        }
        finally {
            self.loading = false;
        }
    }),
    // TODO: refactor to accept only sets with ids (typing)
    upsertScene: (scene) => {
        const oldScene = self.getSceneById(scene.id);
        if (oldScene) {
            oldScene.loadSnapshot(scene);
        }
        else {
            self.scenes.push(scene);
        }
    },
}))
    .actions((self) => ({
    upsert: (scenes) => {
        scenes.forEach((scene) => self.upsertScene(scene));
    },
    deleteScene: flow(function* (spreeAuth, sceneId, removedFromCart = false) {
        const scene = self.getSceneById(sceneId);
        if (scene && scene.status !== SceneStatus.purchased) {
            if (!removedFromCart ||
                scene.getGarmentData().garmentType !== GarmentType.Carbonwear) {
                try {
                    yield scene.deleteScene(spreeAuth);
                }
                catch (e) {
                    handleError(e, self);
                    return false;
                }
            }
            self.removeItemById(sceneId);
            yield self.fetchContent();
            return true;
        }
        return false;
    }),
}))
    .views((self) => ({
    sceneAlreadyInCart(sceneId) {
        if (sceneId === undefined) {
            return false;
        }
        return self.getSceneById(sceneId)?.status === SceneStatus.cart;
    },
    get cartItems() {
        return self.scenes.filter(({ status }) => status === SceneStatus.cart);
    },
    getVariantIdScenes: (variantId) => {
        return self.scenes.filter(({ variantId: vId }) => vId === variantId);
    },
    get wardrobeIds() {
        return getSpecificIds(self.getByGarmentType(GarmentType.Customizable), SceneStatus.purchased).sort(numberValueDescComparator);
    },
    get draftIds() {
        return getSpecificIds(self.scenes, SceneStatus.draft).sort(numberValueDescComparator);
    },
}))
    .views((self) => ({
    get cartSections() {
        const getSection = (type) => self
            .getByGarmentType(type)
            .filter((x) => x.status === SceneStatus.cart)
            .map((x) => x.id);
        return [
            {
                sectionType: GarmentType.Customizable,
                title: 'PERSONALIZED CREATIONS',
                data: getSection(GarmentType.Customizable),
            },
            {
                sectionType: GarmentType.Carbonwear,
                title: 'CARBONWEAR COLLECTION',
                data: getSection(GarmentType.Carbonwear),
            },
        ];
    },
    scenesWithGlyph: (glyphId) => {
        return self
            .getByGarmentType(GarmentType.Customizable)
            .filter((scene) => scene.containsGlyph(glyphId));
    },
}));
export default ScenesStore;
