import { RiErrorWarningLine } from 'react-icons/ri';

interface Props {
  children: string;
  className?: string;
  error: boolean;
}

const ErrorBanner = ({ children, error, className }: Props) => {
  return (
    <div
      className={`flex items-center overflow-hidden justify-center flex-row bg-red-400 text-base text-red-900 z-10 transition-all px-4 ${
        error ? 'py-3' : 'h-0 py-0'
      } ${className}`}
    >
      <RiErrorWarningLine className="mr-3 min-w-[22px]" size="22" />
      {children}
    </div>
  );
};

export default ErrorBanner;
