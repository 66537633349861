import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from './Layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Cart from './screens/cart';
import ForgotPassword from './screens/ForgotPassword';
import Login from './screens/Login';
import NoMatch from './screens/NoMatch';
import Policies from './screens/policy';
import ResetPassword from './screens/ResetPassword';
import Thanks from './screens/Thanks';
import useStore from 'app/common/useStore';
import ConfirmEmail from './screens/ConfirmEmail';
import Token from './screens/Token';
import CarbonwearPreview from './components/CarbonwearPreview';
import WebWardrobe from './components/WebWardrobe';
import ServerErrorScreen from './screens/ServerErrorScreen';
import {
  AFTER_LOGIN_PAGE,
  PURCHASE_RELATED_STUFF_VISIBLE,
} from './common/config';
import ContactUs from './screens/ContactUs';

const AppRoutes = observer(() => {
  const makeAuthGate = (authorized: boolean) => (element: JSX.Element) => {
    if (!authorized) {
      return <Navigate to="/login" replace />;
    } else {
      return element;
    }
  };

  const {
    inMaintenance,
    serverResponding,
    userStore: { spreeAuth },
  } = useStore();
  const requireAuth = makeAuthGate(!!spreeAuth.token?.bearerToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (inMaintenance) {
      navigate('/maintenance');
    }
    if (!serverResponding) {
      navigate('/no-connection');
    }
  }, [navigate, inMaintenance, serverResponding]);

  return (
    <Routes>
      <Route path="wardrobe" element={<WebWardrobe />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={AFTER_LOGIN_PAGE} />} />
        <Route path="token/:id" element={<Token />} />
        <Route path="thanks" element={requireAuth(<Thanks />)} />
        <Route path="login" element={<Login />} />
        <Route path="confirm" element={<ConfirmEmail />} />
        <Route path="password">
          <Route index element={<NoMatch />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="reset" element={<ResetPassword />} />
        </Route>
        {PURCHASE_RELATED_STUFF_VISIBLE && (
          <Route path="pod" element={requireAuth(<Cart.Layout />)}>
            <Route index element={requireAuth(<Cart.Initial />)} />
            <Route path="address" element={requireAuth(<Cart.Address />)} />
            <Route path="delivery" element={requireAuth(<Cart.Shipping />)} />
            <Route path="payment" element={requireAuth(<Cart.Payment />)} />
            <Route path="complete" element={requireAuth(<Cart.Complete />)} />
          </Route>
        )}
        <Route path="privacy" element={<Policies.Privacy />} />
        <Route path="terms" element={<Policies.Terms />} />
        <Route path="cookies" element={<Policies.Cookies />} />
        <Route path="support" element={<ContactUs />} />

        <Route
          path="maintenance"
          element={
            <ServerErrorScreen
              title="Down for maintenance"
              subtitle="The app is down for maintenance and will be back up soon. Sorry for the inconvenience."
            />
          }
        />
        <Route
          path="no-connection"
          element={
            <ServerErrorScreen
              title="Servers are currently unavailable"
              subtitle="The app is down and will be back up soon. Sorry for the inconvenience."
            />
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/mobile/carbonwear" element={<CarbonwearPreview />} />
      <Route
        path="/mobile/terms"
        element={<Policies.Terms showHeader={false} />}
      />
      <Route
        path="/mobile/privacy"
        element={<Policies.Privacy showHeader={false} />}
      />
    </Routes>
  );
});

export default AppRoutes;
