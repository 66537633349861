import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import Price from 'app/components/Price';
import { ReactNode } from 'react';
import { getPlural } from 'common/utils/stringHelpers';

interface Props {
  children?: ReactNode;
}

const SummaryBox = ({ children }: Props) => {
  const {
    checkoutStore: {
      deliveryAmount,
      totalAmount,
      itemsAmount,
      isDeliveryAddressRequired,
      isShippingRateSelected,
    },
    scenesStore: { cartItems: scenesInCart },
  } = useStore();

  const cartSize = scenesInCart.length;

  return (
    <div className="bg-coldGrey-200 px-6 py-8 flex flex-col">
      <div className="mb-4">
        <div className="flex flex-row justify-between">
          <div className="regular16 text-white">
            {cartSize} item{getPlural(cartSize)}
          </div>
          <Price price={itemsAmount} />
        </div>
      </div>
      {isDeliveryAddressRequired && (
        <div className="flex flex-row justify-between">
          <div className="regular16 text-white">Shipping</div>
          {isShippingRateSelected ? (
            <Price showAllPrices price={deliveryAmount} />
          ) : (
            <p className="text-coldGrey-800 regular16 pl-2 text-right">
              To choose on later stage
            </p>
          )}
        </div>
      )}
      <div className="mt-8 mb-8 flex flex-row justify-between">
        <div className="bold18 pr-2">TOTAL</div>
        <Price price={totalAmount} />
      </div>
      {children}
    </div>
  );
};

export default observer(SummaryBox);
