/* eslint-disable no-console */
/* eslint-disable func-names */
import { createOrUpdateAddressAsync, createNewUserAsync, deleteUserAsync, getOrderTokenAsync, getUserInfoAsync, loginAsync, verifyEmailAsync, verifyChallengePhraseAsync, createXpEventAsync, deleteAddressAsync, isExpandedSpreeError, resendConfirmationCode, sendPasswordRecoveryEmailAsync, updateUserAsync, resetPassword, isBasicSpreeError, isSpreeFieldErrors, getChallengePhraseAsync, refreshAccessTokenAsync, fetchCurrentOrder, fetchLastCartOrder, verifyAppleId, } from 'common/api/spree';
import { Nodes } from 'common/state/nodes';
import { applySnapshot, cast, flow, getSnapshot, types, getEnv, } from 'mobx-state-tree';
import Address from 'common/state/models/Address';
import SpreeAuth from 'common/state/models/SpreeAuth';
import { Prize } from 'common/state/models/Prize';
import { handleError } from 'common/errorHelpers';
import { XpEventName } from 'common/api/types';
import { getRootStore } from 'common/state/RootStore';
import { UserSettings } from 'common/state/models/UserSettings';
const ORDER_TOKEN_KEY = 'order_token_key';
function validAddress(address) {
    return (address.firstname &&
        address.lastname &&
        address.address1 &&
        address.city &&
        address.phone &&
        address.zipcode);
}
function extractCredentialError(error, fieldName) {
    const fieldError = error.getErrors([fieldName]);
    if (isSpreeFieldErrors(fieldError)) {
        return fieldError.join(', ');
    }
    return undefined;
}
const UserStore = types
    .model(Nodes.UserStore, {
    id: types.string,
    username: types.string,
    email: types.string,
    password: types.string,
    error: types.string,
    usernameError: types.maybe(types.string),
    passwordError: types.maybe(types.string),
    loading: types.boolean,
    incorrectCode: types.boolean,
    spreeAuth: SpreeAuth,
    loginError: types.string,
    walletAddress: types.maybe(types.string),
    signature: types.optional(types.string, ''),
    addresses: types.map(Address),
    addressFetchInProgress: types.optional(types.boolean, false),
    addressErrors: types.map(types.string),
    stickerPrizeFromLottery: types.maybe(Prize),
    xpPoints: types.number,
    passwordRecoveryEmailSent: types.optional(types.boolean, false),
    resetPasswordError: types.maybe(types.string),
    settings: UserSettings,
    appleId: types.maybe(types.string),
})
    .actions((self) => ({
    handleCredentialValidationError: (error) => {
        if (isExpandedSpreeError(error)) {
            self.error = error.summary;
            self.passwordError = extractCredentialError(error, 'password');
            self.usernameError = extractCredentialError(error, 'email');
        }
    },
    handleAddressValidationError: (error) => {
        if (!isExpandedSpreeError(error)) {
            return;
        }
        Object.entries(error.errors).forEach(([key, value]) => {
            self.addressErrors.set(key, value.toString());
        });
    },
    sendXpEvent: flow(function* (eventName, orderId) {
        const experience = yield createXpEventAsync(self.spreeAuth, eventName, orderId);
        self.xpPoints = experience.userExperience;
        return experience;
    }),
}))
    .actions((self) => {
    const storage = getEnv(self).storage;
    return {
        setValue: storage.setValue,
        resetValues: storage.resetValues,
        getValue: storage.getValue,
    };
})
    .actions((self) => {
    return {
        fetchOrCreateCart: flow(function* () {
            const { checkoutStore } = getRootStore(self);
            const spreeAuth = self.spreeAuth;
            const bearerToken = self.spreeAuth.bearerToken;
            if (!spreeAuth.bearerToken) {
                return {};
            }
            let orderNumber = null;
            let orderToken = null; // yield self.getValue(ORDER_TOKEN_KEY);
            // if (!spreeAuth.orderToken && orderToken) {
            //   spreeAuth.orderToken = orderToken;
            // }
            try {
                if (spreeAuth) {
                    const r = yield fetchCurrentOrder(spreeAuth);
                    orderNumber = r.number;
                    orderToken = r.token;
                }
            }
            catch (e) {
                // fetching by orderToken failed
                // try finding order by all orders query:
                try {
                    const result = yield fetchLastCartOrder(spreeAuth, 1);
                    if (result.token && result.number) {
                        orderToken = result.token;
                        orderNumber = result.number;
                    }
                }
                catch { }
                if (!orderNumber) {
                    const r = yield getOrderTokenAsync(bearerToken);
                    orderNumber = r.orderNumber;
                    orderToken = r.orderToken;
                }
            }
            if (orderToken) {
                self.spreeAuth.orderToken = orderToken;
                self.setValue(ORDER_TOKEN_KEY, orderToken);
            }
            if (orderNumber) {
                checkoutStore.setOrderNumber(orderNumber);
            }
        }),
    };
})
    .actions((self) => {
    let initialState = {};
    return {
        afterCreate: () => {
            initialState = getSnapshot(self);
        },
        reset: () => {
            applySnapshot(self, initialState);
        },
        resetAppleCredentials: () => {
            self.appleId = undefined;
            self.email = '';
        },
        setPasswordError: (error) => {
            self.passwordError = error;
        },
        clearErrors: () => {
            self.error = '';
            self.passwordError = '';
            self.usernameError = '';
            self.addressErrors.clear();
        },
        createAccount: flow(function* (email, password, appleId) {
            self.email = email;
            self.password = password;
            self.loading = true;
            try {
                yield createNewUserAsync(email, password, self.settings, appleId);
                const keychain = getEnv(self).keychain;
                if (keychain) {
                    yield keychain.set({ username: email, password });
                }
            }
            catch (error) {
                self.handleCredentialValidationError(error);
                throw error;
            }
            finally {
                self.loading = false;
            }
        }),
        resendConfirmationCode: flow(function* () {
            self.loading = true;
            try {
                yield resendConfirmationCode(self.email);
            }
            finally {
                self.loading = false;
            }
        }),
        updatePassword: flow(function* (updateParams) {
            try {
                const data = yield updateUserAsync(self.spreeAuth, updateParams);
                const keychain = getEnv(self).keychain;
                if (keychain) {
                    yield keychain.set({
                        username: updateParams.email,
                        password: updateParams.password,
                    });
                }
                return { success: true, data };
            }
            catch (error) {
                console.warn(error);
                return { success: false, error };
            }
        }),
        updateAccount: flow(function* (updateParams) {
            yield updateUserAsync(self.spreeAuth, updateParams);
        }),
        setSignature: (signature) => {
            self.signature = signature;
        },
        setWalletAddress: (walletAddress) => {
            self.walletAddress = walletAddress?.toLowerCase();
        },
        getChallengePhrase: flow(function* () {
            const resp = yield getChallengePhraseAsync(self.walletAddress);
            return resp;
        }),
        addOrOverwriteAddress: flow(function* (address) {
            const { checkoutStore } = getRootStore(self);
            try {
                self.addressFetchInProgress = true;
                const oldAddress = address.id ? self.addresses.get(address.id) : null;
                self.addressErrors.clear();
                const createdAddress = yield createOrUpdateAddressAsync(self.spreeAuth, address, !!oldAddress);
                if (oldAddress) {
                    applySnapshot(oldAddress, createdAddress);
                }
                else {
                    self.addresses.put(createdAddress);
                    checkoutStore.setAddressId(self.spreeAuth, createdAddress.id);
                }
                self.addressFetchInProgress = false;
                return true;
            }
            catch (error) {
                self.handleAddressValidationError(error);
                self.addressFetchInProgress = false;
                return false;
            }
        }),
        deleteAddress: flow(function* (addressId) {
            try {
                yield deleteAddressAsync(self.spreeAuth, addressId);
                self.addresses.delete(addressId);
            }
            catch (error) {
                handleError(error, self);
            }
        }),
        setEmail: (value) => {
            self.email = value;
        },
        setAppleId: (value) => {
            self.appleId = value;
        },
        /**
         * Loads user data on the app start or login
         */
        loadUserInfo: flow(function* () {
            self.loading = true;
            try {
                const { id, email, experience, addresses, prize, walletAddress, settings, appleId, } = yield getUserInfoAsync(self.spreeAuth);
                self.id = id;
                self.appleId = appleId;
                self.email = email;
                self.xpPoints = experience;
                self.walletAddress = walletAddress?.toLowerCase();
                self.settings.changeLocal(settings);
                addresses.forEach((address) => {
                    if (validAddress(address)) {
                        self.addresses.put(address);
                    }
                });
                if (prize) {
                    self.stickerPrizeFromLottery = cast(prize);
                    self.stickerPrizeFromLottery.readyToShow();
                }
                else {
                    self.stickerPrizeFromLottery = undefined;
                }
            }
            finally {
                self.loading = false;
            }
        }),
        refreshExperience: flow(function* () {
            const { experience } = yield getUserInfoAsync(self.spreeAuth);
            self.xpPoints = experience;
        }),
        setUsername: (name) => {
            self.username = name;
        },
        setOrderToken: (orderToken) => {
            self.spreeAuth.orderToken = orderToken;
        },
        setStickerPrizeFromLottery: (stickerPrize) => {
            self.stickerPrizeFromLottery = stickerPrize;
        },
        getAndSetSpreeTokens: flow(function* (bearerToken, refreshToken) {
            self.spreeAuth.bearerToken = bearerToken;
            self.spreeAuth.refreshToken = refreshToken;
            const storage = getEnv(self).storage;
            if (storage && refreshToken) {
                storage.setValue('refresh_token', refreshToken);
            }
            yield self.fetchOrCreateCart();
        }),
        resetCart: flow(function* () {
            const { checkoutStore } = getRootStore(self);
            const { orderToken, orderNumber } = yield getOrderTokenAsync(self.spreeAuth.bearerToken);
            self.spreeAuth.orderToken = orderToken;
            checkoutStore.setOrderNumber(orderNumber);
            if (orderToken) {
                self.setValue(ORDER_TOKEN_KEY, orderToken);
            }
        }),
        completeLogging: flow(function* (topLevel, sendXpEvent) {
            const navigation = getEnv(self).navigation;
            if (topLevel) {
                navigation.home();
            }
            if (sendXpEvent) {
                const experience = yield self.sendXpEvent(XpEventName.login);
                if (experience.value > 0) {
                    navigation.congratsSignIn(experience);
                }
            }
        }),
    };
})
    .views((self) => ({
    loginErrorView: () => self.loginError,
    getAddressList: () => Array.from(self.addresses.values()),
    getAddressById: (id) => self.addresses.get(id),
    get addressErrorsList() {
        return Object.fromEntries(self.addressErrors);
    },
    get loggedIn() {
        return self.spreeAuth.loggedIn;
    },
}))
    .actions((self) => ({
    createAccountWithWalletConnect: flow(function* (walletAddress, signature, email) {
        self.loading = true;
        try {
            self.setEmail(email);
            yield verifyChallengePhraseAsync(walletAddress, signature, email);
            getEnv(self).navigation.verifyEmailWithWallet(walletAddress, signature);
        }
        catch (error) {
            if (error.summary === 'invalid_email_format') {
                self.loginError = 'Invalid email format';
            }
            else {
                self.loginError = 'Challenge phrase not available!';
            }
            throw error;
        }
        finally {
            self.loading = false;
        }
        return { success: true };
    }),
    logInWithWallet: flow(function* (walletAddress = self.walletAddress, signature = self.signature) {
        const topLevel = !self.loading;
        self.loading = true;
        try {
            const { error, bearerToken, refreshToken } = yield verifyChallengePhraseAsync(walletAddress, signature, self.email);
            if (error && error === 'invalid_email') {
                self.error =
                    'There is no account related to the wallet. Please, sign up and add your wallet from the app.';
                throw new Error(self.error);
            }
            yield self.getAndSetSpreeTokens(bearerToken, refreshToken);
            yield self.completeLogging(topLevel, true);
        }
        catch (error) {
            handleError(error, self);
            self.loginError = 'Login failed!';
        }
        finally {
            if (topLevel) {
                self.loading = false;
            }
        }
    }),
    loginWithAppleId: flow(function* (appleId, token) {
        const topLevel = !self.loading;
        self.loading = true;
        try {
            const { bearerToken, refreshToken } = yield verifyAppleId(appleId, token);
            yield self.getAndSetSpreeTokens(bearerToken, refreshToken);
            yield self.completeLogging(topLevel, true);
        }
        catch (error) {
            self.loginError = 'Login failed!';
            throw error;
        }
        finally {
            if (topLevel) {
                self.loading = false;
            }
        }
    }),
    login: flow(function* (username, password, sendXpEvent = false, verifyCredentials = false) {
        self.loginError = '';
        const topLevel = !self.loading;
        self.loading = true;
        try {
            const { access_token: bearerToken, refresh_token: refreshToken, } = yield loginAsync(username, password);
            yield self.getAndSetSpreeTokens(bearerToken, refreshToken);
            const keychain = getEnv(self).keychain;
            if (keychain) {
                yield keychain.set({ username, password });
            }
            if (topLevel) {
                self.loading = false;
            }
            if (!verifyCredentials)
                yield self.completeLogging(topLevel, sendXpEvent);
            return true;
        }
        catch (error) {
            if (error?.serverResponse?.status < 500) {
                self.passwordError = 'E-mail and password do not match.';
            }
            else {
                handleError(error, self);
            }
            self.username = '';
            self.password = '';
            self.loginError = 'Login failed!';
            if (topLevel) {
                self.loading = false;
            }
            return false;
        }
    }),
    refreshToken: flow(function* () {
        const storage = getEnv(self).storage;
        try {
            if (!self.spreeAuth.refreshToken) {
                throw new Error('Missing refresh token');
            }
            const { access_token: bearerToken, refresh_token: refreshToken, } = yield refreshAccessTokenAsync(self.spreeAuth.refreshToken);
            self.spreeAuth.bearerToken = bearerToken;
            self.spreeAuth.refreshToken = refreshToken;
            if (storage && refreshToken) {
                storage.setValue('refresh_token', refreshToken);
            }
        }
        catch (err) {
            console.warn(err);
        }
    }),
    logout: flow(function* () {
        const { checkoutStore, scenesStore, orderStore, wardrobeStore } = getRootStore(self);
        const sentry = getEnv(self).sentry;
        sentry.configureScope((scope) => scope.setUser(null));
        checkoutStore.reset();
        scenesStore.reset();
        orderStore.reset();
        wardrobeStore.reset();
        self.reset();
        self.spreeAuth.bearerToken = undefined;
        self.email = '';
        self.walletAddress = undefined;
        const keychain = getEnv(self).keychain;
        const storage = getEnv(self).storage;
        if (keychain) {
            yield keychain.reset();
        }
        if (storage) {
            storage.resetValues([ORDER_TOKEN_KEY, 'credentials', 'refresh_token']);
        }
    }),
    /**
     * Sets the bearer token on the loading of the app
     */
}))
    .actions((self) => ({
    setSpreeTokens: flow(function* () {
        try {
            const storage = getEnv(self).storage;
            if (storage) {
                const refreshToken = yield storage.getValue('refresh_token');
                if (refreshToken) {
                    self.spreeAuth.refreshToken = refreshToken;
                    try {
                        yield self.refreshToken();
                        yield self.fetchOrCreateCart();
                        yield self.completeLogging(true, true);
                    }
                    catch (e) { }
                }
            }
            if (!self.spreeAuth.loggedIn) {
                const keychain = getEnv(self).keychain;
                const credentials = keychain
                    ? yield keychain.get()
                    : false;
                if (credentials && credentials.username && credentials.password) {
                    yield self.login(credentials.username, credentials.password);
                }
            }
            if (!self.spreeAuth.loggedIn) {
                yield self.getAndSetSpreeTokens();
            }
        }
        catch (err) {
            handleError(err, self);
            console.warn("Couldn't login with credentials", err);
        }
    }),
}))
    .actions((self) => ({
    verifyConfirmationCode: flow(function* (verificationCode, walletAddress, signature) {
        self.loading = true;
        try {
            yield verifyEmailAsync(verificationCode);
            if (walletAddress && signature) {
                yield self.logInWithWallet(walletAddress, signature);
            }
            else if (self.password) {
                yield self.login(self.email, self.password);
            }
            const experience = yield self.sendXpEvent(XpEventName.createAccount);
            const navigation = getEnv(self).navigation;
            if (experience.value > 0) {
                navigation.congratsSignUp(experience);
            }
            else {
                navigation.home();
            }
        }
        catch (error) {
            self.incorrectCode = true;
        }
        finally {
            self.loading = false;
        }
    }),
    sendPasswordRecoveryEmail: flow(function* (email) {
        self.loading = true;
        try {
            yield sendPasswordRecoveryEmailAsync(email);
            return true;
        }
        catch (error) {
            console.warn('error', error);
            return false;
        }
        finally {
            self.loading = false;
            self.passwordRecoveryEmailSent = true;
        }
    }),
    resetPassword: flow(function* (resetPasswordToken, password, passwordConfirmation) {
        try {
            self.resetPasswordError = undefined;
            yield resetPassword(resetPasswordToken, password, passwordConfirmation);
            return true;
        }
        catch (error) {
            if (isBasicSpreeError(error)) {
                self.resetPasswordError = error.summary;
            }
            return false;
        }
    }),
    validatePassword: (text) => {
        const regex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;
        return !text.match(regex);
    },
    deleteAccount: flow(function* () {
        try {
            self.loading = true;
            if (!self.id)
                yield self.loadUserInfo();
            yield deleteUserAsync(self.spreeAuth, self.id);
            self.logout();
            const navigation = getEnv(self).navigation;
            navigation.initial();
        }
        catch (err) {
            handleError(err, self);
            throw err;
        }
        finally {
            self.loading = false;
        }
    }),
}));
export default UserStore;
