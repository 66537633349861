import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
export const parseAddressFromSpreeResponse = (address) => {
    return {
        id: address.id,
        firstname: address.attributes.firstname,
        lastname: address.attributes.lastname,
        address1: address.attributes.address1,
        address2: address.attributes.address2,
        // @ts-ignore // incorrect spree type definitions
        country: address.attributes.country_name,
        country_iso: address.attributes.country_iso,
        city: address.attributes.city,
        state_name: address.attributes.state_name,
        phone: address.attributes.phone,
        zipcode: address.attributes.zipcode,
    };
};
const Address = types
    .model(Nodes.Address, {
    id: types.maybe(types.identifier),
    firstname: types.string,
    lastname: types.string,
    address1: types.string,
    address2: types.maybeNull(types.string),
    city: types.string,
    phone: types.string,
    zipcode: types.string,
    state_name: types.maybeNull(types.string),
    state_id: types.maybeNull(types.string),
    country: types.string,
    country_iso: types.string,
})
    .views((self) => ({
    get shippingAddressText() {
        const addl2 = self.address2 ? `${self.address2}\n` : '';
        return `${self.address1}\n${addl2}${self.city} ${self.zipcode}, ${self.country_iso}`;
    },
    get fullName() {
        return `${self.firstname} ${self.lastname}`;
    },
    get addressLine() {
        const firstLine = self.address1 ? `${self.address2} ,` : '';
        return `${firstLine}\n${self.city}, ${self.country}`;
    },
}));
export const emptyAddress = () => ({
    id: '',
    firstname: '',
    lastname: '',
    address1: '',
    address2: '',
    city: '',
    phone: '',
    zipcode: '',
    state_name: '',
    state_id: '',
    country: '',
    country_iso: '',
    shippingAddressText: '',
    fullName: '',
    addressLine: '',
});
export default Address;
