import { initSentry } from './common/sentry';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import AppRoutes from './AppRoutes';
import useTitle from './common/useTitle';
import useStore from 'app/common/useStore';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { useLocation } from 'react-router-dom';

initSentry();

const App = () => {
  const { userStore, loadAppData } = useStore();
  const [loginAttemptFinished, setLoginAttemptFinished] = useState(false);

  const { pathname } = useLocation();

  const isMobileLink = pathname.startsWith('/mobile');
  const isSingleScreen = pathname.endsWith('/wardrobe');

  useTitle();

  useEffect(() => {
    (async () => {
      try {
        await loadAppData();
      } catch (err) {
        console.warn(err);
      }
      setLoginAttemptFinished(true);
    })();
  }, [userStore, loadAppData]);

  return (
    <div
      className={`flex ${
        isSingleScreen ? 'h-screen overflow-hidden' : 'min-h-screen'
      } flex-col`}
    >
      {!isMobileLink && <Header />}
      {loginAttemptFinished || isMobileLink ? (
        <AppRoutes />
      ) : (
        <div className="spinner h-12 w-12 center-xy" />
      )}
      {!isMobileLink && <Footer />}
    </div>
  );
};

export default observer(App);
