import { Nodes } from 'common/state/nodes';
import { applySnapshot, flow, types, } from 'mobx-state-tree';
import { updateUserAsync } from 'common/api/spree';
export const UserSettings = types
    .model(Nodes.UserSettings, {
    marketing_emails: types.optional(types.boolean, false),
})
    .actions((self) => ({
    changeLocal: (settings) => {
        applySnapshot(self, { ...self, ...settings });
    },
}))
    .actions((self) => ({
    update: flow(function* (spreeAuth, settings) {
        yield updateUserAsync(spreeAuth, {
            public_metadata: { user_settings: settings },
        });
        self.changeLocal(settings);
    }),
}));
