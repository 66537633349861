const Terms = ({ showHeader = true }) => {
  return (
    <div className="">
      {showHeader && (
        <h1 className="text-center mt-16 mb-12 uppercase">
          Terms &amp; conditions
        </h1>
      )}
      <ol className="my-4 medium18 text-coldGrey-500 uppercase">
        <p className="my-4 medium18 text-coldGrey-500 uppercase">
          <span>Part 1 - Introduction</span>
        </p>
        <li className="my-4">
          <span>Our terms</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                These are the terms and conditions under which you can access
                the RSTLSS platform currently available via rstlss.xyz (the
                &#39;
              </span>
              <span className="bold16">Platform</span>
              <span>
                &#39;). Through the Platform, under these Terms, you can
                purchase wearables and digital wearable NFTs (&#39;
              </span>
              <span className="bold16">NFTs</span>
              <span>
                &#39;), and operate your NFTs on the Platform and through a
                range of metaverse platforms and online environments.
              </span>
            </li>
            <li className="my-4">
              <span>
                By using the Platform in any way, you agree to be bound by these
                Terms. If you do not accept these Terms, do not use the
                Platform.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Who we are and how to contact us</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>We are RSTLSS, Inc. (&#39;</span>
              <span className="bold16">RSTLSS</span>
              <span>
                &#39;) a company registered in Delaware, USA, whose principal
                place of business is at 129, 14 New Crane Place, London.
              </span>
            </li>
            <li className="my-4">
              <span>You can contact us via email at support@rstlss.xyz</span>
            </li>
            <li className="my-4">
              <span>
                If we need to contact you, we will do so by using the email
                address you have provided to us via the Platform, or via your
                account on the Platform.
              </span>
            </li>
          </ol>
        </li>
        <p className="my-4 medium18 text-coldGrey-500 uppercase">
          <span>Part 2 &ndash; access to and use of the Platform</span>
        </p>
        <li className="my-4">
          <span>The Platform</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                We provide the Platform to you on the basis of these Terms.
                These Terms may be updated by us from time to time by posting
                the updated Terms on the Platform without prior notification to
                you. When you access the Platform at any time in the future,
                such updated Terms then in force shall apply to your use of the
                Platform. You should check the Terms for updates each time you
                access the Platform. Please note that the Terms in force when
                you place any order with us on the Platform will apply in
                relation to the relevant order(s) placed with us.
              </span>
            </li>
            <li className="my-4">
              <span>
                We may update and change the Platform from time to time to
                reflect our users&rsquo; needs and our business priorities.
              </span>
            </li>
            <li className="my-4">
              <span>
                The Platform is provided to you at no extra charge. We do not
                guarantee that the Platform will always be available or be
                uninterrupted. We may suspend or restrict of availability of all
                or any part of the Platform for business or operational reasons.
                Any support provided to you is for information purposes only. We
                reserve the right to make changes to the Platform at any time;
                or not to reply to any queries; or not provide support in
                connection with the Platform. &nbsp;
              </span>
            </li>
            <li className="my-4">
              <span>
                We reserve the right to decline or cancel a Platform
                registration or account at any time. You are also responsible
                for ensuring that all persons who access the Platform through
                your internet connection are aware of these Terms and that they
                comply with them.
              </span>
            </li>
            <li className="my-4">
              <span>
                If you choose, or you are provided with, a user identification
                code, password or any other piece of information as part of the
                Platform&#39;s security procedures, you must treat such
                information as confidential. You must not disclose it to any
                third party.
              </span>
            </li>
            <li className="my-4">
              <span>
                Although we make reasonable efforts to update the information on
                the Platform, we make no representations, warranties or
                guarantees, whether express or implied, that the content on our
                site is accurate, complete or up to date.
              </span>
            </li>
            <li className="my-4">
              <span>
                We are not responsible for websites we link to and we have no
                control over the contents of those sites or resources. Where the
                Platform contains links to other sites and resources provided by
                third parties, these links are provided for your information
                only. Such links should not be interpreted as approval by us of
                those linked websites or information you may obtain from them
                and it is your responsibility to review any terms and conditions
                for any linked websites.
              </span>
            </li>
            <li className="my-4">
              <span>
                The Platform may include information and materials supplied by
                third parties (including our partners). This information and
                these materials have not been verified for accuracy or
                completeness, nor have they been endorsed or approved by RSTLSS.
                The views expressed by third parties on the Platform do not
                necessarily represent our views or values.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Use of the Platform</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                All material on the Platform is the property of RSTLSS and/or
                its licensors and is protected by copyright and other
                intellectual property rights. You are authorised to view and
                download the materials for your personal, non-commercial use
                only.
              </span>
            </li>
            <li className="my-4">
              <span>
                You are not permitted to use the Platform for any immoral,
                illegal, abusive or harmful purposes, or in any way that harms
                the reputation of RSTLSS or its partners.
              </span>
            </li>
            <li className="my-4">
              <span>
                RSTLSS reserves the right to at any time prevent you from using
                the Platform and/or your NFTs on the Platform and/or on any
                Third Party Environments (as defined below) (including making
                any purchases via the Platform) if it reasonably suspects that
                you are in breach of these Terms and/or any applicable laws.
              </span>
            </li>
            <li className="my-4">
              <span>
                You are responsible for configuring your information technology,
                computer programs and platform to access our site. You should
                use your own virus protection software. We do not guarantee that
                our Platform will be secure or free from bugs or viruses.
              </span>
            </li>
            <li className="my-4">
              <span>
                You must not misuse the Platform by knowingly introducing
                viruses, trojans, worms, logic bombs or other material that is
                malicious or technologically harmful. You must not attempt to
                gain unauthorised access to the Platform, the server on which
                the Platform is stored or any server, computer or database
                connected to the Platform. You must not attack the Platform via
                a denial-of-service attack or a distributed denial-of service
                attack. By breaching this provision, you would commit a criminal
                offence under the Computer Misuse Act 1990. We will report any
                such breach to the relevant law enforcement authorities and we
                will co-operate with those authorities by disclosing your
                identity to them. In the event of such a breach, your right to
                use the Platform will cease immediately.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Linking to the Platform</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                You may link to the home page of the Platform, provided you do
                so in a way that is fair and legal and does not damage our
                reputation or take advantage of it.
              </span>
            </li>
            <li className="my-4">
              <span>
                You must not establish a link in such a way as to suggest any
                form of association, approval or endorsement on our part where
                none exists.
              </span>
            </li>
            <li className="my-4">
              <span>
                You must not establish a link to the Platform in any website
                that is not owned by you.
              </span>
            </li>
            <li className="my-4">
              <span>
                The Platform must not be framed on any other site, nor may you
                create a link to any part of the Platform other than the home
                page without our written permission.
              </span>
            </li>
            <li className="my-4">
              <span>
                We reserve the right to withdraw linking permission without
                notice.
              </span>
            </li>
          </ol>
        </li>
        <p className="my-4 medium18 text-coldGrey-500 uppercase">
          <span>Part 3 &ndash; making purchases via the Platform </span>
        </p>
        <li className="my-4">
          <span>Our contract with you</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                When you place an order on the Platform to purchase wearables
                and NFTs, our acceptance of your order will take place when we
                email you to accept it, at which point a contract will come into
                existence between you and us.
              </span>
            </li>
            <li className="my-4">
              <span>
                If we are unable to accept your order, we will inform you of
                this and will not charge you. This might be because a product is
                out of stock, because of unexpected limits on our resources
                which we could not reasonably plan for, because we have
                identified an error in the price or description of the product
                or because we are unable to meet a delivery deadline you have
                specified.
              </span>
            </li>
            <li className="my-4">
              <span>
                We will assign an order number to your order and tell you what
                it is when we accept your order. It will help us if you can tell
                us the order number whenever you contact us about your order.
              </span>
            </li>
            <li className="my-4">
              <span>
                You are required to have a UK delivery address for us to be able
                to deliver any wearables ordered through the Platform. We
                unfortunately do not ship wearables ordered to addresses outside
                of the UK.
              </span>
            </li>
            <li className="my-4">
              <span>
                Where you have ordered a wearable and NFT displaying a bespoke
                image to be created in collaboration with an artist, we will
                connect you with the artist you have selected to work with
                through the Platform. Once your design has been completed to
                your satisfaction we will be able to process your order. For all
                other wearables and NFTs ordered, we will be able to process
                your order promptly after we accept your order, unless we notify
                you otherwise. &nbsp;
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Our wearables</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                The images of wearables on the Platform are for illustrative
                purposes only. Although we have made every effort to display the
                colours accurately, we cannot guarantee that a device&#39;s
                display of the colours accurately reflects the colours of the
                wearables. Your final wearable product may vary slightly from
                those images.
              </span>
            </li>
            <li className="my-4">
              <span>
                The packaging of wearables may vary from that shown in images on
                the Platform.
              </span>
            </li>
            <li className="my-4">
              <span>
                If we are making the wearables to measurements you have given us
                you are responsible for ensuring that these measurements are
                correct. You can find information and tips on how to measure by
                contacting us.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Our NFTs</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                The images of NFTs on the Platform are for illustrative purposes
                only. Although we have made every effort to display the colours
                accurately, we cannot guarantee that a device&#39;s display of
                colours accurately reflects the colours of the NFT and the
                colours of the minted NFT may vary from these illustrative
                images and may vary across different devices you use to
                display/access/utilise the NFT.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please be aware that by purchasing an NFT you are not purchasing
                any intellectual property rights in the NFT, including the right
                to reproduce or recreate part or all of the image or design in
                the NFT in a third party work for resale. You are not permitted
                to create any works (including other items of digital artwork)
                containing part or all of the image or design contained within
                the NFT without our express written permission.
              </span>
            </li>
            <li className="my-4">
              <span>
                The NFTs provided to you under these Terms are a form of digital
                content which is not on a tangible medium. We make our NFTs
                available to you on the date we dispatch your wearables to you.
                This means you are able to immediately enjoy and use your NFTs
                on the Platform. However this also means that you are waiving
                your right to cancel any order of NFTs you make if you change
                your mind in the 14 day period after you receive your NFTs from
                us, and by placing an order for any NFTs via the Platform you
                hereby acknowledge that you are waiving this right.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Making changes to an order</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                Where you are working with a third party artist through the
                Platform to create a bespoke image, once the artist has
                completed the design of the image we will contact you to confirm
                you are happy with the image. We will only begin to manufacture
                your wearable(s) and/or begin the process of minting your NFT
                once you have confirmed to us that you are happy with the image.
              </span>
            </li>
            <li className="my-4">
              <span>
                If you wish to make a change to an order you have placed please
                contact us. We will let you know if the change is possible, if
                it will change the price or the timing of supply, and of
                anything else you need to know regarding your request.
              </span>
            </li>
            <li className="my-4">
              <span>
                We may need change part of an order as a result of changes in
                applicable laws and/or regulatory requirements, or to implement
                technical adjustments and improvements (for example, to address
                as security threat).
              </span>
            </li>
            <li className="my-4">
              <span>
                If we need to make changes to part of an order you have placed
                that will affect your use of the products ordered, we will
                notify you and you may then contact us to end your contract
                before the changes take place. If you do choose to end your
                contract you will receive a full refund for any products paid
                for but not received.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Providing the wearables and NFTs</span>
          <ol className="regular16 normal-case mb-12">
            <p className="underline mt-4">
              <span>Delivery of wearables</span>
            </p>
            <li className="my-4">
              <span>
                The costs of delivery for the wearables will be as displayed to
                you on the Platform.
              </span>
            </li>
            <li className="my-4">
              <span>
                We will deliver the wearables to you as soon as reasonable
                possible and in any event within 30 days after the day on which
                we accept your order. However please note that where bespoke
                wearables are being created our delivery timelines may be
                altered due to delays in the process of the creation of bespoke
                images.
              </span>
            </li>
            <li className="my-4">
              <span>
                If our supply of the wearables is delayed by an event outside
                our control then we will contact you as soon as possible to let
                you know and we will take steps to minimise the effect of the
                delay. Provided we do this we will not be liable for delays
                caused by the event, but if there is a risk of substantial delay
                you may contact us to end the contract and receive a refund for
                any wearables you have paid for but not received.
              </span>
            </li>
            <li className="my-4">
              <span>
                If no one is available at your address to take delivery and the
                wearables cannot be posted through your letterbox, we will leave
                you a note informing you of how to rearrange delivery. If after
                a failed delivery to you, you do not re-arrange delivery of the
                wearables or collect them from a delivery depot we will contact
                you for further instructions and may charge you for storage
                costs and any further delivery costs.
              </span>
            </li>
            <p className="underline mt-4">
              <span>Delivery of NFTs</span>
            </p>
            <li className="my-4">
              <span>There is no cost to you for the delivery of the NFT.</span>
            </li>
            <li className="my-4">
              <span>
                In order to use receive NFTs through the Platform you will need
                a cryptocurrency wallet which is supported by the Platform
                (&#39;
              </span>
              <span className="bold16">Wallet</span>
              <span>
                &#39;). You will need to connect your Wallet to the Platform
                before you can receive any NFTs from us.
              </span>
            </li>
            <li className="my-4">
              <span>
                We will deliver the NFT by minting the NFT and simultaneously
                making it available in your Wallet via your account on the
                Platform, on the date we dispatch your wearables to you under
                clause 10.2. If you have any difficulty in accessing your NFTs
                via your Wallet please contact us, either via the Platform or by
                using the contact details above.
              </span>
            </li>
            <li className="my-4">
              <span>
                If you wish to add metaverse environments or other third party
                platforms to the functionality of your NFTs after the date your
                purchase them, you can do so by selecting the relevant options
                through your account on the Platform and following the
                instructions given. Any additional functionality will be
                provided to you as soon as we have received full payment from
                you for these additions.
              </span>
            </li>
            <p className="underline mt-4">
              <span>
                Other important information on the supply of wearables and NFTs
              </span>
            </p>
            <li className="my-4">
              <span>
                The wearables will be your responsibility from the time we
                deliver the wearables to the address you have given us. The NFTs
                will be your responsibility from the time the NFT becomes
                available on your account on the Platform. You will own your
                wearables and NFTs once we have received payment in full for the
                relevant items.
              </span>
            </li>
            <li className="my-4">
              <span>
                We may need certain information from you so that we can supply
                wearables and/or NFTs to you. If this information is needed,
                this will have been stated on the relevant order page on the
                Platform and we will contact you to ask for this information.
                Please note that if you do not give us this information in full
                in a reasonable time period, we may need to end the contract (in
                which case you will receive a refund for any wearables and/or
                NFTs you have paid for but not received).
              </span>
            </li>
            <li className="my-4">
              <span>
                We will not be responsible for supplying wearables and/or NFTs
                late and/or not supplying any part of them, if this is caused by
                you not giving us the information we need within a reasonable
                time of us asking for it.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Price and payment method</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                You will find the relevant price information and methods of
                payment available on the relevant checkout pages of the
                Platform.
              </span>
            </li>
            <li className="my-4">
              <span>
                Where you are making a payment in a permitted cryptocurrency,
                any fees or costs payable for the cryptocurrency transfer
                (including gas fees) are not included and are additionally
                payable by you.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that where you are making a payment from a Wallet,
                you will need to confirm the transaction from the Wallet before
                the payment can be processed.
              </span>
            </li>
            <li className="my-4">
              <span>
                We take all reasonable care to ensure that all prices on the
                Platform are correct. It is always possible that, despite this
                care, some items we sell may be incorrectly priced. We will
                normally check prices before accepting your order so that, where
                an item&#39;s correct price at your order date is less than our
                stated price at your order date, we will charge the lower
                amount. If an item&#39;s correct price at your order date is
                higher than the price stated to you, we will contact you for
                your instructions before we accept your order.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Your right to cancel your purchase of wearables</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                If you for any reason you change your mind and wish to cancel a
                purchase of wearables, you may do so within 14 days from
                receiving the wearables (or the final wearable item in your
                order for orders delivered in multiple deliveries). The easiest
                method to do so is to notify us using any of the contact details
                above.
              </span>
            </li>
            <li className="my-4">
              <span>
                When we have confirmed the cancellation, you will be provided
                with a return form for the relevant wearable(s). The cancelled
                wearable(s) must then be returned to the address notified to you
                by us, within 14 days after your receipt of the return form. The
                wearable(s) must be in the condition in which they were
                delivered and packed in accordance with our instructions. You
                may open the packaging and examine the wearable(s). However, the
                wearable(s) must be returned undamaged and properly packaged. If
                the wearable(s) is/are damaged after delivery or it/they is/are
                not properly packaged, we may deduct an amount from your refund
                to reflect any reduction in value of the wearable(s) as a result
                of your handling the wearable(s) in a way which would not be
                permitted in a shop. If only part of the wearable(s) is/are
                returned, only a corresponding part of the purchase price will
                be refunded to you.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note we will only pay the cost of return postage for the
                cancelled wearable(s) from the address to which we delivered the
                wearable(s) to, to our return address.
              </span>
            </li>
            <li className="my-4">
              <span>
                We will refund any payment for the purchase of cancelled
                wearable(s) as soon as practicable and at the latest within 14
                days of when we receives the wearable(s) back or, if earlier,
                within 14 days of the day on which you supply us with evidence
                that the wearable(s) have been sent back.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that you do not have the right to change your mind
                and cancel any purchase of any wearables bearing bespoke or
                one-off images, which have been made to order in collaboration
                with an artist via the Platform, or any other custom orders of
                wearables.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that you do not have the right to change your mind
                and cancel any purchase of NFTs made via the Platform.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>If there is a problem with your order</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                If you have any questions or complaints about your purchase,
                please contact us using any of the contact details above.
              </span>
            </li>
            <li className="my-4">
              <span>
                We are under a legal duty to supply products that comply with
                our contract with you. You have legal rights in respect of
                products that are faulty or not as described by us. Details of
                your rights are available through your local Trading Standards
                office (if you are resident in the UK) or equivalent national
                advisory body on consumer matters. If you wish to exercise your
                rights to return to us any product that is faulty or not as
                described, we will refund the price and pay the costs of postage
                from the address to which we delivered the products(s) to our
                return address, in accordance with your legal rights.
              </span>
            </li>
          </ol>
        </li>
        <p className="my-4 medium18 text-coldGrey-500 uppercase">
          <span>Part 4 &ndash; special terms regarding use of NFTs</span>
        </p>
        <li className="my-4">
          <span>Use of NFTs</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                You are able to enjoy and use your NFTs through the metaverse
                environments and other third party platforms available to you
                via the Platform, which you select as part of your order or
                otherwise choose to add to the functionality of your NFT at a
                later date (each a &#39;
              </span>
              <span className="bold16">Third Party Environment</span>
              <span>&#39;). </span>
            </li>
            <li className="my-4">
              <span>
                Through the Platform, we will provide you with instructions on
                how to integrate your NFTs&#39; metadata into the Third Party
                Environments available to you, so you can utilise and enjoy the
                functionality of the relevant digital wearable(s) on these Third
                Party Environments. This will include providing you with links
                to the relevant files stored on our servers for utilising the
                NFTs in these Third Party Environments. Please contact us,
                either via the Platform or using the contact details above, if
                you need any help on this integration.
              </span>
            </li>
            <li className="my-4">
              <span>
                There may be special terms governing the use rights for certain
                digital wearables forming part of your NFT(s) on certain Third
                Party Environments. If this is the case, we will notify you of
                these special terms through the Platform before you purchase,
                via the Platform, the functionality of your NFT(s) on the
                applicable Third Party Environment(s).
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that Third Party Environments are governed by their
                own terms and conditions which we are not responsible for. It is
                your responsibility for reading the terms and conditions for any
                Third Party Environments and for ensuring that you comply with
                these terms and conditions when using the relevant Third Party
                Environments.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that if you are no longer the owner of the NFT, we
                will remove your ability to use the respective NFT via the
                Platform and/or any applicable Third Party Environments via the
                Platform.
              </span>
            </li>
            <li className="my-4">
              <span>
                The Platform enables you to display your NFT as a digital work
                of art through its connectivity with third party digital display
                devices.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note that your purchase of NFTs does not include the
                purchase of any intellectual property rights in the NFTs. You
                are not permitted to create any works (including other items of
                digital artwork) containing part or all of the image or design
                contained within the NFT without our express written permission.
              </span>
            </li>
            <li className="my-4">
              <span>
                We do not warrant that you can use your NFTs in any way other
                than as described under these Terms. We do not warrant that your
                NFTs have any value outside of the Platform and/or any Third
                Party Environments utilising your NFT(s) via the Platform.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Resale of NFTs</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                The Platform offers users with a managed environment for the
                resale of NFTs to other users through the Platform&#39;s NFT
                marketplace (&#39;
              </span>
              <span className="bold16">Marketplace</span>
              <span>
                &#39;). If you wish to resell your NFTs via the Marketplace, you
                can do so via the instructions on the Platform.
              </span>
            </li>
            <li className="my-4">
              <span>
                Following the successful resale of NFTs we will credit your
                account with the sale proceeds within 7 days after receipt of
                the same from the purchaser, less our administration and
                commission fee of 5% of the resale price of the relevant NFT
                (which will be automatically deducted via the smart contract
                governing the sale, and which will be notified to you on the
                Marketplace when you place an NFT for sale) (&#39;
              </span>
              <span className="bold16">RSTLSS Fee</span>
              <span>&#39;).</span>
            </li>
            <li className="my-4">
              <span>
                You can also choose to resell NFTs outside of the Platform using
                another secondary marketplace provider. If you choose to do
                this, the RSTLSS Fee will be automatically deducted from the
                final sale price using the smart contract governing the sale.
              </span>
            </li>
            <li className="my-4">
              <span>
                If you use the Marketplace to offer an NFT for sale, you are
                entering into a contract with the customer purchasing the NFT
                (the &#39;
              </span>
              <span className="bold16">Customer</span>
              <span>
                &#39;) and are responsible for delivering the NFT to the
                customer under the terms and price specified in the listing. You
                also agree to pay the applicable RSTLSS Fee and to be
                responsible for any applicable sales taxes. The smart contract
                technology governing sales on the Marketplace automatically
                deducts any RSTLSS Fee payments due to us, to simplify this
                process for you.
              </span>
            </li>
            <li className="my-4">
              <span>
                Any sale on the Marketplace will be subject to any special terms
                and conditions, which we will notify you of via the Platform and
                which will be prominently displayed on your listing on the
                Marketplace.
              </span>
            </li>
            <li className="my-4">
              <span>
                Your relationship with RSTLSS when using the Marketplace is that
                of an independent person or entity and not an employee, agent or
                partner of RSTLSS.
              </span>
            </li>
            <li className="my-4">
              <span>
                When using the Marketplace you agree that you are responsible
                for determining and fulfilling your obligations under any
                applicable laws to report, collect and include in your price any
                applicable sales taxes.
              </span>
            </li>
            <li className="my-4">
              <span>
                Please note we may be required under applicable law to collect
                and/or report relevant tax information about you. We may also
                request certain tax information from you in accordance with our
                relevant obligations under applicable laws. Please note that we
                may be required to suspend your activity on the Marketplace
                and/or the Platform if you fail to promptly provide us with any
                such information that we require.
              </span>
            </li>
          </ol>
        </li>
        <p className="my-4 medium18 text-coldGrey-500 uppercase">
          <span>Part 5 &ndash; Other importation terms</span>
        </p>
        <li className="my-4">
          <span>Limitation of Liability</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                Nothing in these Terms shall limit our liability for: (i) death
                or personal injury caused by our negligence; (ii) fraud or
                fraudulent misrepresentation; or (iii) to the extent liability
                cannot be excluded or limited under any applicable law.
              </span>
            </li>
            <li className="my-4">
              <span>
                We are not liable for any losses, or any failure or delay in the
                performance of any obligations under these Terms, due to any
                actions beyond our control including, but not limited to,
                strikes, work stoppages, accidents, acts of war or terrorism,
                civil or military disturbances, nuclear or natural catastrophes,
                loss or malfunctions or utilities, communications or computer
                (software and hardware) services.
              </span>
            </li>
            <li className="my-4">
              <span>
                We are responsible for loss or damage you suffer that is a
                foreseeable result of our breaking our contract with you or our
                failing to use reasonable care and skill. Loss or damage is
                foreseeable when it is obvious that it will happen.
              </span>
            </li>
            <li className="my-4">
              <span>
                We are not liable for business losses. We have no liability to
                you for any loss of profit, loss of business, business
                interruption, or loss of business opportunity.
              </span>
            </li>
          </ol>
        </li>
        <li className="my-4">
          <span>Data Protection</span>
          <p className="regular16 normal-case mt-4 mb-12">
            <span>
              We will only use your personal information as set out in our
              privacy policy, which is available via our website at
              https://rstlss.xyz/.
            </span>
          </p>
        </li>
        <li className="my-4">
          <span>General terms and conditions</span>
          <ol className="regular16 normal-case mb-12">
            <li className="my-4">
              <span>
                These Terms are between you and us. No other person shall have
                any rights to enforce any of its terms.
              </span>
            </li>
            <li className="my-4">
              <span>
                You need our consent to transfer your rights under these Terms
                and/or in relation to any NFTs purchased via the Platform to
                someone else. You may only transfer such rights or obligations
                under these Terms to another person if we agree to the transfer
                in writing.
              </span>
            </li>
            <li className="my-4">
              <span>
                These Terms can only be varied by agreement between us and you.
                No variation of the Terms is effective unless in writing and
                agreed by the parties or their authorised representatives.
              </span>
            </li>
            <li className="my-4">
              <span>
                If a court finds part of these Terms illegal, the rest will
                continue in force. Each clause of the Terms operates separately.
                If any court or relevant authority decides that any of them are
                unlawful, the remaining clauses will remain in full force and
                effect.
              </span>
            </li>
            <li className="my-4">
              <span>
                These Terms are governed by English law and you can bring legal
                proceedings in respect of the Terms in the English courts.
                Additionally, if you are a consumer living in Scotland, you can
                bring legal proceedings in respect of the Terms in either the
                Scottish or the English courts; or if you are a consumer living
                in Northern Ireland, you can bring legal proceedings in respect
                of the Terms in either the Northern Irish or the English courts
              </span>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default Terms;
