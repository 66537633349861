import { Nodes } from 'common/state/nodes';
import { flow, types, getEnv } from 'mobx-state-tree';
import { Price, sumPrices } from 'common/state/models/Price';
import { GarmentType } from 'common/api/types';
import Address from 'common/state/models/Address';
import { getRootStore } from 'common/state/RootStore';
export var ShipmentState;
(function (ShipmentState) {
    ShipmentState["Digital"] = "digital";
    ShipmentState["Backordered"] = "backorder";
    ShipmentState["Cancelled"] = "cancelled";
    ShipmentState["Partial"] = "partial";
    ShipmentState["Pending"] = "pending";
    ShipmentState["Ready"] = "ready";
    ShipmentState["Shipped"] = "shipped";
})(ShipmentState || (ShipmentState = {}));
export var PaymentState;
(function (PaymentState) {
    PaymentState["BalanceDue"] = "balance_due";
    PaymentState["Checkout"] = "checkout";
    PaymentState["Completed"] = "completed";
    PaymentState["CreditOwed"] = "credit_owed";
    PaymentState["Failed"] = "failed";
    PaymentState["Paid"] = "paid";
    PaymentState["Pending"] = "pending";
    PaymentState["Processing"] = "processing";
    PaymentState["Void"] = "void";
})(PaymentState || (PaymentState = {}));
export const OrderStateOptions = { ...PaymentState, ...ShipmentState };
const OrderShipping = types.model({
    address: Address,
    deliveryDate: types.maybeNull(types.Date),
    tracking_url: types.maybeNull(types.string),
});
const OrderPayment = types.model({
    method: types.string,
    shipping: types.maybeNull(Price),
    status: types.enumeration(Nodes.PaymentState, Object.values(PaymentState)),
});
const Order = types
    .model({
    id: types.identifier,
    number: types.string,
    date: types.maybe(types.Date),
    state: types.enumeration(Nodes.OrderState, Object.values(OrderStateOptions)),
    token: types.string,
    sceneIds: types.array(types.string),
    price: Price,
    shipping: types.maybeNull(OrderShipping),
    payment: types.maybeNull(OrderPayment),
    expClaimed: types.boolean,
    payment_status: types.enumeration(Nodes.PaymentState, Object.values(PaymentState)),
})
    .views((self) => {
    return {
        sceneIdsByGarmentType(garmentType) {
            const sceneIdsSet = new Set(self.sceneIds);
            const { scenesStore } = getRootStore(self);
            const garmentScenes = scenesStore.getByGarmentType(garmentType);
            return garmentScenes
                .filter(({ id }) => sceneIdsSet.has(id))
                .map(({ id }) => id);
        },
    };
})
    .views((self) => {
    return {
        get personalisedScenesIds() {
            return self.sceneIdsByGarmentType(GarmentType.Customizable);
        },
        get carbonwearScenesIds() {
            return self.sceneIdsByGarmentType(GarmentType.Carbonwear);
        },
    };
})
    .views((self) => {
    return {
        get personalisedItemsPrice() {
            const { scenesStore } = getRootStore(self);
            return sumPrices(self.personalisedScenesIds.map((id) => scenesStore.getSceneById(id).totalPrice));
        },
        get carbonwearItemsPrice() {
            const { scenesStore } = getRootStore(self);
            return sumPrices(self.carbonwearScenesIds.map((id) => scenesStore.getSceneById(id).totalPrice));
        },
    };
})
    .actions((self) => {
    return {
        // eslint-disable-next-line func-names
        checkAndSendEvent: flow(function* (event) {
            if (self.payment_status !== PaymentState.Processing &&
                self.payment_status !== PaymentState.Failed &&
                !self.expClaimed) {
                const { userStore: { sendXpEvent }, } = getRootStore(self);
                const experience = yield sendXpEvent(event, self.id);
                if (experience.value > 0) {
                    const navigation = getEnv(self).navigation;
                    navigation.congratsThankYou(experience);
                }
            }
        }),
        setOrderState(state) {
            self.state = state;
        },
    };
});
export default Order;
