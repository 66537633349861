import { useSearchParams } from 'react-router-dom';

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('confirmation_token');

  window.location.replace(
    'rstlss://confirm?confirmation_token=' + confirmationToken,
  );

  return (
    <div className="mx-auto max-w-xl flex flex-col">
      <div className="w-full h-96 flex justify-center items-center">
        <div className="spinner w-20 h-20" />
      </div>
    </div>
  );
};

export default ConfirmEmail;
