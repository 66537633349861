import { getEnv } from 'mobx-state-tree';
import { getRootStore } from 'common/state/RootStore';
import { MessageTypes } from 'common/state/models/BannerMessage';
const ErrorSeparator = '|||';
export var Severity;
(function (Severity) {
    Severity[Severity["Info"] = 0] = "Info";
    Severity[Severity["Error"] = 1] = "Error";
    Severity[Severity["Success"] = 2] = "Success";
})(Severity || (Severity = {}));
export var PaymentErrors;
(function (PaymentErrors) {
    PaymentErrors["PAYMENT_ORDER_FAILED"] = "Payment for the order failed";
})(PaymentErrors || (PaymentErrors = {}));
export const extractSpreeErrorMessages = (errors) => {
    if (typeof errors === 'string') {
        return errors.split(',').map((error) => {
            const [metadata, message] = error.split(ErrorSeparator);
            return (message || metadata).trimStart();
        });
    }
    return [];
};
export const parseSceneErrors = (errors) => {
    const parsedErrors = {};
    errors
        .filter((error) => error.includes(ErrorSeparator))
        .forEach((errorMessage) => {
        const [metadata, message] = errorMessage.split(ErrorSeparator);
        const sceneMatch = metadata.match(/SCENE\[(\d+)\]/);
        const variantMatch = metadata.match(/VARIANT\[(\d+)\]/);
        if (sceneMatch && variantMatch) {
            const sceneID = sceneMatch[1];
            const variantID = variantMatch[1];
            if (parsedErrors[sceneID] === undefined) {
                parsedErrors[sceneID] = {};
            }
            if (parsedErrors[sceneID][variantID] === undefined) {
                parsedErrors[sceneID][variantID] = [message.trimStart()];
            }
            else {
                parsedErrors[sceneID][variantID] = [
                    ...parsedErrors[sceneID][variantID],
                    message.trimStart(),
                ];
            }
        }
    });
    return parsedErrors;
};
export const handleError = (error, store, prefix) => {
    const { notificationStore: { pushNotification }, } = getRootStore(store);
    if (error.message === 'In maintenance' || error.message === 'No response') {
        return;
    }
    const sentry = getEnv(store).sentry;
    sentry.captureException(error);
    if (error.summary) {
        let message = error.summary;
        if (prefix) {
            message = `${prefix},${error.summary}`;
        }
        pushNotification(MessageTypes.MessageError, message);
        return;
    }
    throw error;
};
