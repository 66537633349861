import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { ImageStyle } from 'common/state/models/ImageStyle';
import { Price } from 'common/state/models/Price';
import { ImageStyleVersion, GlyphState } from 'common/api/types';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { getRootStore } from 'common/state/RootStore';
export const Sticker = types
    .model(Nodes.Sticker, {
    id: types.identifier,
    slug: types.string,
    name: types.string,
    productId: types.string,
    price: Price,
    positionId: types.string,
    imageStyles: types.array(ImageStyle),
    special: types.boolean,
    universeId: types.string,
    available: types.boolean,
    inStock: types.boolean,
    stockStatus: types.enumeration('stockStatus', Object.values(GlyphState)),
    author: types.maybe(types.string),
    authorLink: types.maybe(types.string),
    limitedByContract: types.boolean,
})
    .views((self) => ({
    getImageByVersion: (version) => {
        return self.imageStyles.find((image) => image.version === version)?.url;
    },
    get state() {
        if (self.stockStatus !== GlyphState.Loading) {
            return self.stockStatus;
        }
        return self.inStock ? GlyphState.Available : GlyphState.SoldOut;
    },
}))
    .views((self) => ({
    get isLocked() {
        return (self.state === GlyphState.SoldOut ||
            self.state === GlyphState.ComingSoon ||
            self.state === GlyphState.Taken);
    },
}))
    .views((self) => ({
    get imageUrl() {
        const imageVersion = self.isLocked
            ? ImageStyleVersion.ZoomedGrayscale
            : ImageStyleVersion.Zoomed;
        return self.getImageByVersion(imageVersion);
    },
    get isUsedOnPendingCreations() {
        const { scenesStore, currentlyEditedStore } = getRootStore(self);
        return (currentlyEditedStore.containsGlyph(self.id) ||
            scenesStore
                .scenesWithGlyph(self.id)
                .some(({ status }) => status !== SceneStatus.purchased));
    },
}))
    .actions((self) => ({
    setStockStatus(state) {
        self.stockStatus = state;
    },
}));
