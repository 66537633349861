/* eslint-disable func-names */
import CheckoutStore from 'common/state/stores/CheckoutStore';
import DropsStore from 'common/state/stores/DropsStore';
import GarmentStore from 'common/state/stores/GarmentStore';
import NotificationStore from 'common/state/stores/NotificationStore';
import OrderStore from 'common/state/stores/OrderStore';
import EthereumStore from 'common/state/stores/EthereumStore';
import Scene from 'common/state/models/Scene';
import ScenesStore from 'common/state/stores/ScenesStore';
import SpreeAuth from 'common/state/models/SpreeAuth';
import StickerStore from 'common/state/stores/StickersStore';
import TutorialStore from 'common/state/stores/TutorialStore';
import WardrobeStore from './stores/WardrobeStore';
import UserStore from 'common/state/stores/UserStore';
import { ICurrency } from 'common/state/models/Currency';
import { Nodes } from 'common/state/nodes';
import { emptyAddress } from 'common/state/models/Address';
import { emptyPrice } from 'common/state/models/Price';
import { getCMSContent, getProductsAsync, getVersion, setupClient, } from 'common/api/spree';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { handleError } from 'common/errorHelpers';
import { flow, getEnv, getRoot, types, } from 'mobx-state-tree';
import { UserSettings } from 'common/state/models/UserSettings';
export const RootStore = types
    .model(Nodes.RootStore, {
    appLoading: types.boolean,
    splashScreenPlaying: types.boolean,
    inMaintenance: types.optional(types.boolean, false),
    serverResponding: types.optional(types.boolean, true),
    minimalMobileVersion: types.maybe(types.string),
    garmentStore: types.optional(GarmentStore, {
        garments: {},
        loading: false,
    }),
    notificationStore: types.optional(NotificationStore, {
        currentPopupId: undefined,
        popups: {},
        popupVisible: false,
        modalMessage: undefined,
        modalVisible: false,
        bannerMessage: undefined,
        bannerVisible: false,
        glassModalMessage: undefined,
        glassModalVisible: false,
        blockedModal: false,
        loading: false,
        pushNotificationsEnabled: false,
    }),
    userStore: types.optional(UserStore, {
        id: '',
        username: '',
        email: '',
        password: '',
        error: '',
        loading: false,
        incorrectCode: false,
        spreeAuth: SpreeAuth.create(),
        loginError: '',
        xpPoints: 0,
        settings: UserSettings.create(),
    }),
    stickerStore: types.optional(StickerStore, {
        universes: {},
        stickers: {},
        loading: false,
    }),
    currentlyEditedStore: types.optional(Scene, {
        selectedCurrency: ICurrency.ETH,
        selectedSpaces: {},
        status: SceneStatus.init,
        purchasedItems: [],
    }),
    checkoutStore: types.optional(CheckoutStore, {
        lineItems: [],
        shipments: {},
        isShipmentAccepted: false,
        paymentMethods: {},
        totalAmount: emptyPrice(),
        deliveryAmount: emptyPrice(),
        itemsAmount: emptyPrice(),
        taxAmount: emptyPrice(),
        currentlyEditedAddress: emptyAddress(),
        orderId: undefined,
    }),
    scenesStore: types.optional(ScenesStore, { scenes: [], loading: false }),
    dropsStore: types.optional(DropsStore, {
        drops: {},
        wasLoaded: false,
        loading: false,
        drawInProgress: false,
    }),
    tutorialStore: types.optional(TutorialStore, {
        enabledTutorial: false,
        shownSpecificPopups: [],
    }),
    orderStore: types.optional(OrderStore, {
        orders: {},
        orderIds: [],
        currentPage: 1,
        lastPageReached: false,
    }),
    ethereumStore: types.optional(EthereumStore, {}),
    wardrobeStore: types.optional(WardrobeStore, {
        ownedTokens: [],
        loading: false,
    }),
})
    .actions((self) => ({
    refetchAllContractData: flow(function* () {
        // These two must be done in 2 separate steps, because
        // garment and sticker stores rely on up-to-date allowlists,
        // and usage depends on allowlists.
        yield self.ethereumStore.fetchAllowlists();
        yield self.ethereumStore.fetchUsage();
        self.ethereumStore.updateAllowlistStatus();
        yield Promise.all([
            self.garmentStore.fetchContractStock(),
            self.stickerStore.fetchContractStock(),
        ]);
    }),
    fetchGarmentsAndStickers: flow(function* () {
        try {
            const productsResponse = yield getProductsAsync(self.userStore.spreeAuth);
            self.garmentStore.sortAndReplace(productsResponse.garments);
            self.garmentStore.fetchContractStock();
            self.stickerStore.replace(productsResponse);
            self.stickerStore.updateGlyphStates(self.userStore.spreeAuth);
            self.stickerStore.fetchContractStock();
        }
        catch (err) {
            handleError(err, self);
        }
    }),
    fetchCMSContent: flow(function* () {
        try {
            const components = yield getCMSContent(self.userStore.spreeAuth);
            return components;
        }
        catch (err) {
            console.warn(err);
        }
    }),
    setAppLoading: (loading) => {
        self.appLoading = loading;
    },
    setSplashScreenPlaying: (isFinished) => {
        self.splashScreenPlaying = isFinished;
    },
}))
    .actions((self) => ({
    loadUserData: flow(function* () {
        const requests = [
            self.scenesStore.fetchContent(),
            self.checkoutStore.fetchAvailableCountries(),
        ];
        if (self.userStore.loggedIn) {
            requests.push(self.checkoutStore.fetchContent(), self.userStore.loadUserInfo(), self.orderStore.fetch(true), self.dropsStore.fetch(), self.wardrobeStore.fetch(), self.notificationStore.fetch());
        }
        yield Promise.all(requests);
    }),
    setMaintenance: (value) => {
        self.inMaintenance = value;
    },
    setServerResponding: (value) => {
        self.serverResponding = value;
    },
    loadMinimalMobileVersion: flow(function* () {
        const res = yield getVersion();
        self.minimalMobileVersion = res.mobile.minimalVersion;
    }),
}))
    .actions((self) => ({
    loadAppData: flow(function* () {
        const maintenanceCallback = () => {
            self.setMaintenance(true);
        };
        const noResponseCallback = () => {
            self.setServerResponding(false);
        };
        const apiBaseUrl = getEnv(self).config.API_BASE_URL;
        setupClient(apiBaseUrl, maintenanceCallback, noResponseCallback, self.userStore);
        yield self.loadMinimalMobileVersion();
        yield self.tutorialStore.load();
        yield self.userStore.setSpreeTokens();
        yield self.dropsStore.fetch();
        yield self.fetchGarmentsAndStickers();
        self.setAppLoading(false);
        self.loadUserData();
        // done separately because this requires walletAddress to be present
        yield self.refetchAllContractData();
        const storage = getEnv(self).storage;
        storage?.resetValues(['credentials']);
    }),
    reloadDrops: flow(function* () {
        yield Promise.all([
            self.dropsStore.fetch(),
            self.fetchGarmentsAndStickers(),
        ]);
    }),
}));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const testRootStoreTypeEquality = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const oneWay = RootStore.create();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const orAnother = {};
};
export function getRootStore(store) {
    return getRoot(store);
}
