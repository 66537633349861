import { types, getSnapshot, applySnapshot, flow, getEnv, } from 'mobx-state-tree';
import { Color } from 'common/styles';
import { Nodes } from 'common/state/nodes';
import { extractSpreeErrorMessages } from 'common/errorHelpers';
import { getRootStore } from 'common/state/RootStore';
import { fetchPopups } from 'common/api/spree';
import { PopupFrequency, PopupType } from 'common/api/types';
import BannerMessage, { MessageTypes, MessagesDisplay, } from 'common/state/models/BannerMessage';
const formatMessage = (errors) => extractSpreeErrorMessages(errors).join('\n');
const Messages = {
    [MessageTypes.InternetBack]: {
        id: MessageTypes.InternetBack,
        display: MessagesDisplay.Banner,
        message: 'Back online',
        iconName: 'wifi',
        backgroundColor: Color.green30,
        color: Color.green90,
        replaces: [MessageTypes.InternetOff],
        timeout: 3000,
    },
    [MessageTypes.InternetOff]: {
        id: MessageTypes.InternetOff,
        display: MessagesDisplay.Banner,
        message: 'No internet connection',
        iconName: 'wifi-off',
        backgroundColor: Color.red40,
        color: Color.red90,
        timeout: null,
    },
    [MessageTypes.MessageError]: {
        id: MessageTypes.MessageError,
        display: MessagesDisplay.Modal,
        message: formatMessage,
        color: Color.red90,
        timeout: null,
    },
    [MessageTypes.UnavailableGlyph]: {
        id: MessageTypes.MessageError,
        display: MessagesDisplay.Modal,
        titleText: 'Glyph unavailable',
        message: formatMessage,
        confirmText: 'Got it',
        iconName: 'error-outline',
        color: Color.red90,
        backgroundColor: Color.red40,
        showCloseButton: true,
        timeout: null,
    },
    [MessageTypes.GlyphAlreadyUsed]: {
        id: MessageTypes.MessageError,
        display: MessagesDisplay.Modal,
        titleText: 'glyph already used',
        message: formatMessage,
        confirmText: 'Got it',
        iconName: 'error-outline',
        color: Color.red90,
        backgroundColor: Color.red40,
        showCloseButton: true,
        timeout: null,
    },
    [MessageTypes.DropExpired]: {
        id: MessageTypes.MessageError,
        display: MessagesDisplay.Modal,
        titleText: 'drop has expired',
        message: formatMessage,
        confirmText: 'Got it',
        iconName: 'error-outline',
        color: Color.red90,
        backgroundColor: Color.red40,
        showCloseButton: true,
        timeout: null,
    },
    [MessageTypes.Notification]: {
        id: MessageTypes.Notification,
        display: MessagesDisplay.Banner,
        message: formatMessage,
        iconName: '',
        backgroundColor: Color.green30,
        color: Color.green90,
        timeout: 3000,
    },
    [MessageTypes.LinkCopied]: {
        id: MessageTypes.LinkCopied,
        display: MessagesDisplay.Banner,
        message: formatMessage,
        iconName: 'check',
        backgroundColor: Color.coldGrey30,
        color: Color.coldGrey90,
        timeout: 3000,
    },
};
const GlassModalMessage = types.model(Nodes.GlassModalMessage, {
    id: types.string,
    message: types.string,
    confirmText: types.string,
    iconName: types.maybe(types.string),
});
const PopupButton = types.model(Nodes.PopupButton, {
    action: types.string,
    text: types.string,
});
const PopupData = types.model(Nodes.PopupData, {
    buttons: types.array(PopupButton),
    data: types.string,
    image_url: types.string,
    title: types.string,
});
const PopupAttr = types.model(Nodes.PopupAttr, {
    data: types.array(PopupData),
    end_date: types.Date,
    frequency: types.enumeration('frequency', Object.values(PopupFrequency)),
    mode: types.enumeration('type', Object.values(PopupType)),
    start_date: types.Date,
    title: types.string,
});
const PopupInfo = types.model(Nodes.PopupInfo, {
    attributes: PopupAttr,
    id: types.identifier,
    type: types.string,
});
const shouldReplaceMessage = (newMessage, store) => {
    if (!newMessage.replaces) {
        return true;
    }
    if (!store.bannerMessage) {
        return false;
    }
    return newMessage.replaces.includes(store.bannerMessage.id);
};
function setMessage(message, additionalData) {
    if (typeof message.message === 'function') {
        return {
            ...message,
            message: message.message(additionalData),
        };
    }
    return message;
}
let onHideCallback;
let onConfirmCallback;
let onCloseCallback;
let onCancelCallback;
let onRenderCallback;
const isFutureDay = (lastOpenedDate) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const previous = new Date(lastOpenedDate);
    previous.setHours(0, 0, 0, 0);
    return now > previous;
};
const NotificationStore = types
    .model(Nodes.NotificationStore, {
    currentPopupId: types.maybe(types.string),
    popups: types.map(PopupInfo),
    popupVisible: types.boolean,
    bannerMessage: types.maybe(BannerMessage),
    bannerVisible: types.boolean,
    modalMessage: types.maybe(BannerMessage),
    modalVisible: types.boolean,
    glassModalMessage: types.maybe(GlassModalMessage),
    glassModalVisible: types.boolean,
    blockedModal: types.boolean,
    loading: types.boolean,
    pushNotificationsEnabled: types.boolean,
})
    .actions((self) => {
    return {
        resetCallbacks: () => {
            onHideCallback = undefined;
            onConfirmCallback = undefined;
            onCloseCallback = undefined;
            onCancelCallback = undefined;
        },
        clear: () => {
            self.popups.clear();
        },
    };
})
    .actions((self) => {
    const storage = getEnv(self).storage;
    return {
        setPopupLastUsedDate: (id, userID) => {
            storage.setValue(`popup_id_${userID}_${id}`, String(Date.now()));
        },
        getPopupLastUsedDateString: (id, userID) => {
            return storage.getValue(`popup_id_${userID}_${id}`);
        },
        setLastOpenAppDate: () => {
            storage.setValue('last_open_date', String(Date.now()));
        },
        getLastOpenAppDate: () => {
            return storage.getValue('last_open_date');
        },
        forceCloseModalWithCallback: (callback) => {
            self.modalVisible = false;
            self.glassModalVisible = false;
            callback?.();
            self.resetCallbacks();
            self.modalMessage = undefined;
            self.glassModalMessage = undefined;
        },
        setModalConfirmButtonLoading: (loading) => {
            if (self.modalMessage) {
                self.modalMessage.confirmButtonLoading = loading;
            }
        },
    };
})
    .views((self) => ({
    get currentPopup() {
        return self.currentPopupId
            ? self.popups.get(self.currentPopupId)
            : undefined;
    },
    get defaultPopup() {
        return Array.from(self.popups.values()).find((popup) => {
            const startDate = popup.attributes.start_date.getTime();
            const endDate = popup.attributes.end_date.getTime();
            const currentDate = Date.now();
            return startDate < currentDate && currentDate < endDate;
        });
    },
}))
    .actions((self) => {
    return {
        fetch: flow(function* () {
            try {
                self.loading = true;
                self.clear();
                const { userStore: { spreeAuth, id: userID }, } = getRootStore(self);
                const popupsArray = yield fetchPopups(spreeAuth);
                popupsArray.forEach((popup) => {
                    self.popups.put(popup);
                });
                self.currentPopupId = self.defaultPopup?.id;
                const currentPopup = self.currentPopup;
                if (!currentPopup) {
                    return;
                }
                switch (currentPopup.attributes.frequency) {
                    case PopupFrequency.EveryTime:
                        const lastAppOpenedDate = Number(yield self.getLastOpenAppDate());
                        const lastPopupOpenedDate = Number(yield self.getPopupLastUsedDateString(currentPopup.id, userID));
                        if (lastAppOpenedDate > lastPopupOpenedDate) {
                            self.popupVisible = true;
                            self.setPopupLastUsedDate(currentPopup.id, userID);
                        }
                        break;
                    case PopupFrequency.OneTimeOnly:
                        const dateExist = !Number(yield self.getPopupLastUsedDateString(currentPopup.id, userID));
                        if (dateExist) {
                            self.popupVisible = true;
                            self.setPopupLastUsedDate(currentPopup.id, userID);
                        }
                        break;
                    case PopupFrequency.OncePerDay:
                        const lastOpenedDate = Number(yield self.getPopupLastUsedDateString(currentPopup.id, userID));
                        if (isFutureDay(lastOpenedDate)) {
                            self.popupVisible = true;
                            self.setPopupLastUsedDate(currentPopup.id, userID);
                        }
                        break;
                    default:
                        break;
                }
            }
            finally {
                self.loading = false;
            }
        }),
        showPopup: (id) => {
            self.currentPopupId = id || self.defaultPopup?.id;
            self.popupVisible = true;
        },
        hidePopup: () => {
            self.popupVisible = false;
        },
        hideBanner: (messageType) => {
            if (self.bannerMessage?.id === messageType) {
                self.bannerVisible = false;
                self.bannerMessage = undefined;
                self.resetCallbacks();
            }
        },
        closeModalWithCallback: (callback) => {
            if (self.modalMessage?.permanent) {
                callback?.();
            }
            else {
                self.forceCloseModalWithCallback(callback);
            }
        },
        blockModals: () => {
            self.blockedModal = true;
        },
        unblockModals: () => {
            self.blockedModal = false;
            self.modalVisible = !!self.modalMessage;
        },
        setPushNotificationsPermission: (value) => {
            self.pushNotificationsEnabled = value;
        },
    };
})
    .actions((self) => {
    let initialState = {};
    let timer;
    return {
        afterCreate: () => {
            initialState = getSnapshot(self);
        },
        reset: () => {
            applySnapshot(self, initialState);
            self.resetCallbacks();
        },
        pushNotification: (messageType, additionalData) => {
            const message = Messages[messageType];
            if (!message) {
                return;
            }
            if (message.display === MessagesDisplay.Modal) {
                self.modalMessage = setMessage(message, additionalData);
                if (!self.blockedModal) {
                    self.modalVisible = true;
                }
            }
            if (message.display === MessagesDisplay.Banner) {
                if (message.timeout && message.id === self.bannerMessage?.id) {
                    clearTimeout(timer);
                }
                if (shouldReplaceMessage(message, self)) {
                    if (message.timeout) {
                        timer = setTimeout(() => {
                            self.hideBanner(messageType);
                        }, message.timeout);
                    }
                    self.bannerMessage = setMessage(message, additionalData);
                    self.bannerVisible = true;
                }
            }
        },
        pushModalWithData: ({ id, titleText, message, confirmText, iconName, backgroundColor, color, blurredBackdrop, cancelText, modalIconSet, showCloseButton, buttonDirection, messageBottomMargin, permanent, confirmButtonLoading, onModalHide, onConfirm, onClose, onCancel, renderChildren, timeout, }) => {
            if (self.modalVisible === false) {
                self.modalMessage = {
                    id,
                    display: MessagesDisplay.Modal,
                    titleText,
                    message,
                    confirmText,
                    iconName,
                    backgroundColor,
                    color,
                    cancelText,
                    modalIconSet,
                    blurredBackdrop,
                    showCloseButton: showCloseButton === undefined ? !!onClose : showCloseButton,
                    buttonDirection,
                    messageBottomMargin,
                    permanent,
                    confirmButtonLoading,
                };
                onHideCallback = onModalHide;
                onConfirmCallback = onConfirm;
                onCloseCallback = onClose;
                onCancelCallback = onCancel;
                onRenderCallback = renderChildren;
                if (!self.blockedModal) {
                    self.modalVisible = true;
                }
                if (timeout) {
                    setTimeout(() => {
                        self.hideBanner(id);
                    }, timeout);
                }
            }
        },
        pushGlassModalWithData: ({ id, message, confirmText, iconName, onConfirm, onCancel, }) => {
            if (self.glassModalVisible === false) {
                self.glassModalMessage = {
                    id,
                    message,
                    confirmText,
                    iconName,
                };
                onConfirmCallback = onConfirm;
                onCancelCallback = onCancel;
                self.glassModalVisible = true;
            }
        },
        hideModal: () => {
            self.closeModalWithCallback(onHideCallback);
        },
        confirmModal: () => {
            self.closeModalWithCallback(onConfirmCallback);
        },
        closeModal: () => {
            self.closeModalWithCallback(onCloseCallback);
        },
        cancelModal: () => {
            self.closeModalWithCallback(onCancelCallback);
        },
    };
})
    .views(() => ({
    renderChildren: () => {
        return onRenderCallback && onRenderCallback();
    },
}));
export default NotificationStore;
