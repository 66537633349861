import ABI from 'common/abi_key';
import { estimateGas } from 'common/utils/utils';
function getKeyParams(web3Client, from, to, value, additionalData) {
    const rx = {
        from,
        to,
        value,
    };
    const configs = additionalData._config;
    for (let i = 0; i < configs.length; i += 1) {
        const hexSig = configs[i]._signature;
        configs[i]._signature = web3Client.utils.hexToBytes(hexSig);
        configs[i]._price = configs[i]._price.toString();
    }
    const params = [
        additionalData._token,
        additionalData._primarySplit,
        additionalData._royaltySplit,
        configs,
    ];
    rx.data =
        // @ts-ignore
        additionalData && web3Client.eth.abi.encodeFunctionCall(ABI, params);
    rx.gas = estimateGas(additionalData._token.length);
    return rx;
}
export default getKeyParams;
