import { IAddress } from 'common/state/models/Address';

export const addressLabels = [
  'First Name',
  'Last Name',
  'Phone',
  'Address',
  'Apartment, suite (optional)',
  'Town/ City',
  'State / Province',
  'ZIP / Postal code',
];

export const addressKeys = [
  'firstname',
  'lastname',
  'phone',
  'address1',
  'address2',
  'city',
  'state_name',
  'zipcode',
] as (keyof IAddress)[];

export const errorKeys = addressKeys.map((key) =>
  key === 'state_name' ? 'state' : key,
);

export const idx = Array.from(addressLabels.keys());
