import { GarmentType, GarmentSize, Space, ImageStyleVersion, } from 'common/api/types';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { CARBONWEAR_LIMIT_PER_USER, DEFAULT_SPACES } from 'common/config';
import { imageUrlByStyle } from 'common/api/util/responseParseHelpers';
import { isNull, flatten } from 'lodash';
import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { getRootStore } from 'common/state/RootStore';
import { getSpacePrice, sumPrices } from 'common/state/models/Price';
import { OrientedImages } from 'common/state/models/OrientedImage';
export const Garment = types
    .model(Nodes.Garment, {
    id: types.identifier,
    variantId: types.string,
    slug: types.string,
    name: types.string,
    positionedImages: OrientedImages,
    price: types.number,
    physicalItemPrice: types.number,
    arEffectPrice: types.number,
    currency: types.string,
    description: types.string,
    spaces: types.array(Space),
    technology: types.string,
    otherInfo: types.string,
    sizes: types.array(GarmentSize),
    garmentType: types.enumeration('GarmentType', Object.values(GarmentType)),
    disabled: types.boolean,
    blocked: types.boolean,
    limitedByContract: types.boolean,
    contractStockAvailable: types.optional(types.boolean, false),
})
    .views((self) => ({
    get selectedCurrency() {
        return self.currency.toUpperCase();
    },
    get availableStickersPositionsData() {
        const orientationByPosition = new Map();
        self.positionedImages.forEach((image) => {
            image.styles.forEach((style) => {
                style.stickerPositions.forEach((position) => {
                    if (!orientationByPosition.has(position.name) && image.orientation)
                        orientationByPosition.set(position.name, image.orientation);
                });
            });
        });
        return orientationByPosition;
    },
    getImageByOrientation(o) {
        return self.positionedImages.find((i) => i.orientation === o);
    },
    get possibleOrientations() {
        return self.positionedImages
            .map(({ orientation }) => orientation)
            .filter((o) => !isNull(o));
    },
    get positionedImageUrl() {
        return imageUrlByStyle(self.positionedImages, ImageStyleVersion.Positioned);
    },
    urls: () => flatten(self.positionedImages.map((i) => i.urls())),
    urlsForStyles: (styles) => {
        return flatten(self.positionedImages.map((i) => i.urls(styles)));
    },
    get isCarbonwear() {
        return self.garmentType === GarmentType.Carbonwear;
    },
    get isCustomizable() {
        return self.garmentType === GarmentType.Customizable;
    },
}))
    .views((self) => ({
    buttonView: () => {
        const { id, name, variantId, garmentType, disabled } = self;
        const tightlyCroppedImageUrl = imageUrlByStyle(self.positionedImages, disabled ? ImageStyleVersion.ZoomedGrayscale : ImageStyleVersion.Zoomed);
        const positionedImageUrl = imageUrlByStyle(self.positionedImages, disabled
            ? ImageStyleVersion.PositionedGrayscale
            : ImageStyleVersion.Positioned);
        const currentPrice = sumPrices(DEFAULT_SPACES.map((space) => getSpacePrice(self.spaces, space)).filter((price) => !!price));
        return {
            id,
            variantId,
            title: name,
            garmentType,
            tightlyCroppedImageUrl,
            positionedImageUrl,
            price: currentPrice,
            disabled,
        };
    },
    get isOutOfLimit() {
        if (self.isCarbonwear) {
            const { scenesStore } = getRootStore(self);
            const overallItemsNum = scenesStore
                .getVariantIdScenes(self.variantId)
                .reduce((acc, { status }) => status === SceneStatus.purchased || status === SceneStatus.cart
                ? acc + 1
                : acc, 0);
            return overallItemsNum >= CARBONWEAR_LIMIT_PER_USER;
        }
        return false;
    },
}))
    .actions((self) => ({
    setBlocked: (v) => {
        self.blocked = v;
    },
}));
