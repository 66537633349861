import LabeledInput from 'app/components/LabeledInput';
import WireButton from 'app/components/WireButton';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useStore from 'app/common/useStore';
import ErrorBanner from 'app/components/ErrorBanner';
import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import { MessageTypes } from 'common/state/models/BannerMessage';
import { AFTER_LOGIN_PAGE } from 'app/common/config';

const Home = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const pwRef = useRef<HTMLInputElement | null>(null);
  const [buttonEnabled, _setButtonEnabled] = useState(false);

  const { userStore, loadUserData, notificationStore } = useStore();
  const loggedIn = !!userStore.spreeAuth?.bearerToken;
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<null | 'email' | 'eth'>(null);
  const { state: fromPasswordReset } = useLocation();
  const { logEvent } = useAnalytics();

  const emailLogin = async (email: string, password: string) => {
    const success = await userStore.login(email, password);
    if (success) {
      loadUserData();
      navigate(AFTER_LOGIN_PAGE, {});
    } else {
      setError(userStore.passwordError!);
    }

    logEvent(AnalyticsEventName.LogInWithEmailPassword, {
      success,
    });
  };

  useEffect(() => {
    if (!!fromPasswordReset) {
      notificationStore.pushNotification(
        MessageTypes.Notification,
        'A new password has been saved. Use it to log in on mobile and web.',
      );
    }
  }, [fromPasswordReset, notificationStore]);

  useEffect(() => {
    if (loggedIn) {
      navigate(AFTER_LOGIN_PAGE, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // waiting for a "redirect"
  if (loggedIn) {
    return <div className="spinner h-12 w-12 center-xy" />;
  }

  const onKeyPress = () => {
    const email = emailRef.current?.value ?? '';
    const pw = pwRef.current?.value ?? '';
    _setButtonEnabled(email !== '' && pw !== '');
    setError('');
  };

  // const connectAndSign = async () => {
  //   logEvent(AnalyticsEventName.LogInWithWallet);
  //   try {
  //     setLoading('eth');
  //     await eth.connect();
  //     await eth.sign();
  //     await loadUserData();
  //     navigate('/pod', {});
  //   } catch (e) {
  //     console.error({ e });
  //   } finally {
  //     setLoading(null);
  //   }
  // };

  return (
    <div className="mx-auto max-w-xl flex flex-col placeholder-coldGrey-200">
      <div className="absolute left-0 right-0">
        <ErrorBanner error={!!error}>{error!}</ErrorBanner>
      </div>
      <h2 className="self-center py-16 uppercase">Welcome back, rstling</h2>
      {/*
      <OutlinedButton
        onClick={connectAndSign}
        className="h-12"
        disabled={loading !== null}
        loading={loading === 'eth'}
      >
        <div className="flex">
          <img alt="" className="mr-4" src={walletConnectIcon} />
          Log in with WalletConnect
        </div>
      </OutlinedButton>
      <div className="self-center m-2 text-coldGrey-800">or</div>
      */}
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            setLoading('email');
            await emailLogin(emailRef.current!.value, pwRef.current!.value);
          } finally {
            setLoading(null);
          }
        }}
      >
        <LabeledInput
          ref={emailRef}
          onInput={onKeyPress}
          placeholder="E-mail address"
          error={!!error}
        />
        <LabeledInput
          ref={pwRef}
          onInput={onKeyPress}
          type="password"
          placeholder="Password"
          error={!!error}
        />
        <Link
          to="/password/forgot"
          className="text-turquoise-800 mt-2"
          onClick={() => {
            logEvent(AnalyticsEventName.PressForgotPassword);
          }}
        >
          Forgot your password?
        </Link>
        <WireButton
          type="submit"
          disabled={loading !== null || !buttonEnabled}
          loading={loading === 'email'}
          className="mt-16"
        >
          LOG IN
        </WireButton>
      </form>
    </div>
  );
};

export default observer(Home);
