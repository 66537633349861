import { FunctionComponent } from 'react';

const buttonClassNames = {
  primary: 'border-2 border-turquoise-800 text-turquoise-800',
  delete: ' bg-red-700',
};

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: keyof typeof buttonClassNames;
}

const OutlinedButton: FunctionComponent<Props> = ({
  children,
  loading = false,
  disabled = false,
  className = '',
  variant = 'primary',
  ...props
}) => {
  return (
    <button
      className={`${buttonClassNames[variant]} rounded-lg py-2 my-2 w-full relative hover:shadow-transparent10 disabled:border-coldGrey-600 disabled:text-coldGrey-600 active:scale-95 transition-all ${className}`}
      disabled={disabled}
      {...props}
    >
      <div className="mx-auto w-max h-max">
        {loading ? <div className="spinner h-6 w-6" /> : <div>{children}</div>}
      </div>
    </button>
  );
};

export default OutlinedButton;
