import { GlyphState, Taxons, ImageStyleVersion, GarmentType, } from 'common/api/types';
import { getAvailableProductImages, getProductProperties, getRelationshipObjects, getTaxons, getUniverseForSticker, } from 'common/api/util/responseParseHelpers';
import { parseAddressFromSpreeResponse, } from 'common/state/models/Address';
import { ICurrency } from 'common/state/models/Currency';
import { OrderStateOptions, PaymentState, } from 'common/state/models/Order';
import { groupBy } from 'lodash';
import { flatten, uniq } from 'underscore';
const parsePayment = (payment, shipment) => {
    if (!payment) {
        return null;
    }
    return {
        method: payment.attributes.payment_method_name,
        shipping: shipment?.attributes.prices || null,
        status: payment.attributes.state,
    };
};
const parseShipping = (address, shipment) => {
    if (!address) {
        return null;
    }
    const parsedAddress = parseAddressFromSpreeResponse(address);
    const deliveryDate = shipment?.attributes.shipped_at;
    const tracking = shipment?.attributes.tracking;
    return {
        address: parsedAddress,
        deliveryDate: deliveryDate ? new Date(deliveryDate) : null,
        tracking_url: tracking,
    };
};
export function getStockStatus(included) {
    const stockStatus = {};
    included
        .filter(({ type }) => type === 'drop_item')
        .forEach(({ attributes, relationships }) => {
        const productId = relationships.product.data.id;
        stockStatus[productId] = attributes.stock_status;
    });
    return stockStatus;
}
export function formatStickers(included, data) {
    const locationOptionValues = extractOptionValues(included, 'location');
    const stickers = data.flatMap((responseItem) => {
        const images = getAvailableProductImages(responseItem, included);
        const variants = getRelationshipObjects(responseItem, 'variants', included);
        const [image] = images;
        if (!image) {
            // Stickers without images are invalid
            return [];
        }
        const universe = getUniverseForSticker(responseItem, included);
        const productProperties = getProductProperties(responseItem, included);
        const special = productProperties.exclusive === 'true';
        const { author, authorLink } = productProperties;
        return variants.map(({ id, attributes, relationships: { option_values: { data: optionValuesData }, }, }) => {
            const { attributes: { name: positionName }, } = locationOptionValues.find(({ id: lovId }) => castToArray(optionValuesData).some(({ id: ovId }) => ovId === lovId));
            // because ProductAttr doesn't have public_metadata
            // @ts-ignore
            const limitedByContract = responseItem.attributes.public_metadata?.limited_by_contract ?? false;
            return {
                id,
                slug: responseItem.attributes.slug,
                name: responseItem.attributes.name,
                productId: responseItem.id,
                price: attributes.prices,
                positionId: positionName,
                universeId: universe ? universe.id : '',
                available: !!responseItem.attributes.available,
                stockStatus: responseItem.attributes.stock_status || GlyphState.Loading,
                inStock: responseItem.attributes.in_stock,
                imageStyles: image.styles,
                special,
                author,
                authorLink,
                limitedByContract,
            };
        });
    });
    const universesSet = data.reduce((prevObj, currentSticker) => {
        const universe = getUniverseForSticker(currentSticker, included);
        if (!universe) {
            return prevObj;
        }
        const { id: universeId } = universe;
        const variants = getRelationshipObjects(currentSticker, 'variants', included);
        const stickerIds = variants.map(({ id }) => id);
        return {
            ...prevObj,
            [universeId]: {
                ...universe,
                stickers: universeId in prevObj
                    ? [...prevObj[universeId].stickers, ...stickerIds]
                    : stickerIds,
            },
        };
    }, {});
    const universes = Object.values(universesSet);
    const stickersPositions = locationOptionValues.map(({ attributes: { name } }) => name);
    return {
        stickers,
        universes,
        stickersPositions,
    };
}
function isSticker(variant, included) {
    const [product] = getRelationshipObjects(variant, 'product', included);
    return getTaxons(product, included).some(({ attributes: { name } }) => name === Taxons.Stickers);
}
function extractStickers(included) {
    const stickerVariants = included.filter((i) => i.type === 'variant' && isSticker(i, included));
    const stickerProducts = uniq(flatten(stickerVariants.map((sv) => getRelationshipObjects(sv, 'product', included))));
    const { stickers } = formatStickers(included, stickerProducts);
    return Object.fromEntries(stickers.map((sticker) => [sticker.id, sticker]));
}
const parseOrderState = (order) => {
    const { payment_state: paymentState, shipment_state: shipmentState } = order.attributes;
    if (!paymentState ||
        paymentState === PaymentState.CreditOwed ||
        paymentState === PaymentState.BalanceDue) {
        return OrderStateOptions.Processing;
    }
    if (paymentState === PaymentState.Failed) {
        return OrderStateOptions.Failed;
    }
    return shipmentState || OrderStateOptions.Digital;
};
export const parseOrder = (order, included) => {
    const { attributes: { number, completed_at, prices, exp_claimed, payment_state, token, }, } = order;
    const address = getRelationshipObjects(order, 'shipping_address', included)[0];
    const shipment = getRelationshipObjects(order, 'shipments', included)[0];
    const payment = getRelationshipObjects(order, 'payments', included)[included.length - 1];
    const parsedShipment = parseShipping(address, shipment);
    const parsedPayment = parsePayment(payment, shipment);
    return {
        id: order.id,
        number: number,
        token: token,
        date: completed_at ? new Date(completed_at) : undefined,
        state: parseOrderState(order),
        sceneIds: castToArray(order.relationships.scenes.data).map(({ id: sceneId }) => sceneId),
        price: prices,
        shipping: parsedShipment,
        payment: parsedPayment,
        expClaimed: exp_claimed,
        payment_status: payment_state || parsedPayment?.status || PaymentState.Processing,
    };
};
export const castToArray = (obj) => {
    if (!Array.isArray(obj)) {
        return [obj];
    }
    return obj;
};
const extractOptionValues = (included, optionTypeName) => {
    const optionType = included.find((o) => o.type === 'option_type' && o.attributes.name === optionTypeName);
    if (!optionType) {
        return [];
    }
    const optionValuesIds = optionType.relationships.option_values.data.map(({ id }) => id);
    return included.filter((o) => o.type === 'option_value' && optionValuesIds.includes(o.id));
};
export function formatLineItem({ attributes: { scene_id }, relationships, }) {
    return {
        sceneId: scene_id.toString(),
        variantId: relationships.variant.data.id,
    };
}
export function formatGarments(included, data) {
    const spaceOptionValues = extractOptionValues(included, 'space');
    const sizeOptionValues = extractOptionValues(included, 'size');
    const garmentItemsList = castToArray(data).map((i) => {
        const productImages = getAvailableProductImages(i, included, true);
        const productProperties = getProductProperties(i, included);
        const variantIds = i.relationships.variants.data.map(({ id }) => id);
        const variants = included.filter(({ type, id }) => type === 'variant' && variantIds.includes(id));
        const spaces = variants.flatMap(({ id: variantId, attributes, relationships: { option_values: { data: optionValuesData }, }, }) => {
            const optionValuesIds = castToArray(optionValuesData).map(({ id }) => id);
            return spaceOptionValues
                .filter(({ id }) => optionValuesIds.includes(id))
                .map(({ attributes: { presentation, description, image, slug } }) => {
                return {
                    variant_id: variantId,
                    name: presentation,
                    price: attributes.prices,
                    slug,
                    description: description || '',
                    image,
                    available: true,
                };
            });
        });
        const sizeInfo = JSON.parse(productProperties.sizeInfo || '{}');
        const allSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
        const sizes = allSizes.map((sizeName) => {
            const optionValue = sizeOptionValues.find(({ attributes: { presentation } }) => sizeName === presentation);
            const sizeVariant = variants.find(({ relationships: { option_values: { data: ovData }, }, }) => castToArray(ovData).some(({ id: oVid }) => oVid === optionValue?.id));
            return {
                title: sizeName,
                variant_id: sizeVariant ? sizeVariant.id : '',
                available: !!sizeVariant,
                sizeInfo: sizeInfo[sizeName] || [],
            };
        });
        const taxons = getTaxons(i, included);
        const isCarbonwear = taxons.some(({ attributes: { name } }) => name === GarmentType.Carbonwear);
        const garmentType = isCarbonwear
            ? GarmentType.Carbonwear
            : GarmentType.Customizable;
        const disabled = productProperties.disabled === 'true';
        const blocked = productProperties.blocked === 'true';
        const limitedByContract = i.attributes.public_metadata.limited_by_contract ?? false;
        return {
            id: i.id,
            ...i.attributes,
            variantId: i.relationships.default_variant.data.id,
            spaces,
            sizes,
            price: parseInt(i.attributes.price, 10),
            physicalItemPrice: parseInt(i.attributes.physical_item_price, 10),
            arEffectPrice: parseInt(i.attributes.ar_effect_price, 10),
            currencySymbol: i.attributes.currency_symbol,
            positionedImages: productImages,
            technology: productProperties.technology || '',
            otherInfo: productProperties.otherInfo || '',
            garmentType,
            disabled,
            blocked,
            limitedByContract,
        };
    });
    return garmentItemsList;
}
export function formatShipments(data, included) {
    return data.map((shipment) => {
        const { id, attributes: { number }, } = shipment;
        const shippingRateIds = castToArray(shipment.relationships.shipping_rates.data).map(({ id: shippingRateId }) => shippingRateId);
        const shippingRates = included
            .filter(({ type, id: shippingRateId }) => type === 'shipping_rate' && shippingRateIds.includes(shippingRateId))
            .map(({ id: shippingRateId, attributes: { name, prices, shipping_method_id, selected }, }) => {
            return {
                id: shippingRateId,
                name,
                price: prices || null,
                shippingMethodId: shipping_method_id.toString(),
                selected,
            };
        });
        const selectedShippingRateId = castToArray(shipment.relationships.selected_shipping_rate?.data)?.[0]?.id;
        return {
            id,
            number,
            shippingRates,
            selectedShippingRate: selectedShippingRateId,
        };
    });
}
export function formatScenes(scenes, included) {
    const sizeOptionValues = extractOptionValues(included, 'size');
    const stickers = extractStickers(included);
    const includedVariants = {};
    included
        .filter(({ type }) => type === 'variant')
        .forEach((variant) => {
        includedVariants[variant.id] = variant;
    });
    const includedSceneVariants = groupBy(included.filter(({ type }) => type === 'scene_variant'), (scene_variant) => scene_variant.relationships.scene.data.id);
    const sets = scenes
        .filter(({ relationships: { master_variant: { data: master_data }, }, }) => !!master_data)
        .map(({ id, attributes: { status }, relationships: { master_variant: { data: master_data }, variants: { data: variants_data }, }, }) => {
        const variant = included.find(({ type, id: variant_id }) => type === 'variant' &&
            variant_id === master_data.id);
        const garmentId = variant
            ? String(variant.relationships.product.data.id)
            : '';
        const garmentAttributes = included.find(({ type, id: garment_id }) => type === 'product' && garment_id === garmentId);
        const variants = castToArray(variants_data).map(({ id: variantId }) => includedVariants[variantId]);
        const optionValuesIds = variants.flatMap((varData) => varData.relationships.option_values.data.map(({ id: option_value_id }) => option_value_id));
        const sizeOptionValue = sizeOptionValues.find(({ id: sizeId }) => optionValuesIds.includes(String(sizeId)));
        const physicalItemSize = sizeOptionValue?.attributes?.presentation || '';
        const selectedStickers = {};
        const selectedSpaces = {};
        const privateStickers = {};
        castToArray(variants_data).forEach(({ id: variantId }) => {
            const sticker = stickers[variantId];
            if (sticker) {
                privateStickers[variantId] = sticker;
                selectedStickers[sticker.positionId] = {
                    stickerId: variantId,
                    positionId: sticker.positionId,
                    universeId: sticker.universeId,
                };
            }
            else {
                selectedSpaces[variantId] = true;
            }
        });
        const purchasedItems = includedSceneVariants[id]
            ?.filter(({ attributes: { status: item_status } }) => item_status === 'purchased')
            .map(({ relationships: { variant: { data: { id: variant_id }, }, }, }) => variant_id) || [];
        const [garmentData] = garmentAttributes
            ? formatGarments(included, [garmentAttributes])
            : [];
        return {
            id,
            garmentId,
            variantId: variant ? String(variant.id) : '',
            physicalItemSize,
            selectedCurrency: ICurrency.USD,
            selectedSpaces,
            selectedStickers,
            allSelectedStickers: selectedStickers,
            purchasedItems,
            status: status,
            garmentData,
            privateStickers,
        };
    });
    return sets;
}
export function formatPrize(prize, included) {
    const [product] = getRelationshipObjects(prize, 'product', included);
    const [image] = getAvailableProductImages(product, included);
    const [drop] = getRelationshipObjects(prize, 'drop', included);
    return {
        id: prize.id,
        productId: product.id,
        name: prize.attributes.title,
        rarity: prize.attributes.rarity,
        imageUrl: image.styles.find(({ version }) => version === ImageStyleVersion.Zoomed)
            ?.url || image.styles[0].url,
        price: product.attributes.prices,
        expiresOn: new Date(prize.attributes.expires_on),
        dropId: drop?.id,
        stockStatus: prize.attributes.stock_status,
    };
}
function formatPrizes(data, included) {
    return data.map((prize) => formatPrize(prize, included));
}
export function formatDrops(data, included) {
    return data.map((drop) => {
        const dropImages = getRelationshipObjects(drop, 'image', included);
        let imageUrl;
        let imageDesignHeight;
        if (dropImages.length > 0) {
            const productImage = dropImages[0].attributes.styles.find(({ version }) => version === 'product');
            if (productImage) {
                imageUrl = productImage.url;
                imageDesignHeight = parseInt(productImage.height, 10);
            }
        }
        const prizes = getRelationshipObjects(drop, 'drop_items', included);
        const prizesNum = prizes.length;
        return {
            id: drop.id,
            title: drop.attributes.title,
            description: drop.attributes.description,
            imageUrl,
            imageDesignHeight,
            endsOn: new Date(drop.attributes.ends_on),
            availableOn: new Date(drop.attributes.available_on),
            status: drop.attributes.status,
            availablePrizesNumber: prizesNum,
            cost: drop.attributes.cost,
            dibAvailability: drop.attributes.dib_availability,
            prizes: formatPrizes(prizes, included),
            contractAddress: drop.attributes.contract_address,
            isAvailable: drop.attributes.is_available,
        };
    });
}
