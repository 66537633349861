import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const ENABLE_SENTRY = process.env.NODE_ENV !== 'development';

export const initSentry = () => {
  if (ENABLE_SENTRY) {
    Sentry.init({
      dsn: 'https://b02c62d1871a4637800351007bd4307c@o1091392.ingest.sentry.io/6621166',
      integrations: [new BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};
