/* eslint-disable func-names */
import { Nodes } from 'common/state/nodes';
import { flow, types, getEnv } from 'mobx-state-tree';
import { getRootStore } from 'common/state/RootStore';
const TokenItemModel = types.model(Nodes.TokenItem, {
    image: types.string,
    name: types.string,
    viewerUrl: types.string,
    id: types.string,
});
const WardrobeStore = types
    .model(Nodes.WardrobeStore, {
    ownedTokens: types.array(TokenItemModel),
    loading: types.boolean,
})
    .actions((self) => ({
    reset: () => {
        self.ownedTokens.clear();
    },
    replace: (newData) => {
        self.ownedTokens.splice(0, self.ownedTokens.length, ...newData);
    },
}))
    .actions((self) => {
    return {
        fetch: flow(function* () {
            try {
                const networkName = getEnv(self).config.NETWORK_NAME;
                const alchemyApiKey = getEnv(self).config.ALCHEMY_API_KEY;
                const { userStore: { walletAddress, email }, dropsStore: { contractAddresses }, } = getRootStore(self);
                if (walletAddress) {
                    self.loading = true;
                    if (email === 'apple-reviewer@example.com') {
                        const walletTokens = [
                            {
                                image: 'https://rstlss-content.xyz/the-key/akiko/image.gif',
                                name: 'THE KEY - AKIKO',
                                viewerUrl: 'https://rstlss-content.xyz/the-key/akiko/index.html?state=model',
                                id: 'id',
                            },
                        ];
                        self.replace(walletTokens);
                        return;
                    }
                    const options = {
                        method: 'GET',
                        headers: { accept: 'application/json' },
                    };
                    const walletTokens = yield fetch(`https://${networkName}.g.alchemy.com/nft/v2/${alchemyApiKey}/getNFTs?owner=${walletAddress}&pageSize=100&contractAddresses[]=${contractAddresses.join(',')}&withMetadata=true&orderBy=transfer_time`, options)
                        .then((response) => response.json())
                        .then((response) => {
                        const tokens = response.ownedNfts
                            .filter(({ metadata }) => {
                            return (!!metadata.name &&
                                !!metadata.image &&
                                !!metadata.animation_url);
                        })
                            .map(({ metadata }, index) => {
                            return {
                                image: metadata.image,
                                name: metadata.name,
                                viewerUrl: `${metadata.animation_url}?state=model`,
                                id: `${index}_${metadata.name}`,
                            };
                        });
                        return tokens;
                    })
                        .catch(() => []);
                    self.replace(walletTokens);
                }
            }
            catch (err) {
                console.warn(err);
                self.reset();
            }
            finally {
                self.loading = false;
            }
        }),
    };
});
export default WardrobeStore;
