// @ts-nocheck
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';
import disc from './assets/disc.png';

let initialized = false;
let camera, scene, renderer, controls;
let glyphDisplayObject, glyphDisplayWrapper;
let mixer = null;

let turnBackAnimation = false;
let isMovingTimeout;
let isMoving = false;

function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}
function updateTextureEncoding(content) {
  const encoding = THREE.sRGBEncoding;
  traverseMaterials(content, (material) => {
    if (material.map) material.map.encoding = encoding;
    if (material.emissiveMap) material.emissiveMap.encoding = encoding;
    if (material.map || material.emissiveMap) material.needsUpdate = true;
  });
}

function createParticles(scene) {
  const geometry = new THREE.BufferGeometry();
  const vertices = [];
  let material;

  const sprite = new THREE.TextureLoader().load(disc);

  for (let i = 0; i < 10000; i += 1) {
    const x = 10 * Math.random() - 5;
    const y = 10 * Math.random() - 5;
    const z = 10 * Math.random() - 5;

    if (x < -1 || x > 1 || y < -1 || y > 1 || z < -1 || z > 1) {
      vertices.push(x, y, z);
    }
  }

  geometry.setAttribute(
    'position',
    new THREE.Float32BufferAttribute(vertices, 3),
  );

  material = new THREE.PointsMaterial({
    size: 0.03,
    sizeAttenuation: true,
    map: sprite,
    alphaTest: 0.5,
    transparent: true,
  });
  material.color.setHSL(1.0, 0.5, 1.0);

  const particles = new THREE.Points(geometry, material);
  scene.add(particles);
}

function init(
  container,
  garment,
  garmentScene,
  glyphDisplay,
  loading,
  loadingBar,
) {
  if (initialized) {
    return;
  }
  initialized = true;

  camera = new THREE.PerspectiveCamera(
    60,
    container.offsetWidth / container.offsetHeight,
    0.1,
    100,
  );
  scene = new THREE.Scene();
  createParticles(scene);

  let hemiLight = new THREE.HemisphereLight();
  scene.add(hemiLight);
  const light1 = new THREE.AmbientLight(0xffffff, 1);
  light1.name = 'ambient_light';
  scene.add(light1);

  const light2 = new THREE.DirectionalLight(0xffffff, 0.05);
  light2.position.set(0.5, 0, 0.833);
  light2.name = 'main_light';
  scene.add(light2);

  const light3 = new THREE.DirectionalLight(0xffffff, 0.05);
  light3.position.set(-0.5, 0, -0.833);
  light3.name = 'main_light_3';
  scene.add(light3);

  scene.background = new THREE.Color(0x15161c);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  container.appendChild(renderer.domElement);

  let pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  const loaderGLTF = new GLTFLoader();
  loaderGLTF.load(
    garment,
    function (gltf) {
      mixer = new THREE.AnimationMixer(gltf.scene);
      const action = mixer.clipAction(gltf.animations[0]);

      action.play();

      let object = gltf.scene;
      scene.add(object);

      render();

      const box = new THREE.Box3().setFromObject(object);
      const size = box.getSize(new THREE.Vector3()).length() / 1.5;
      const center = box.getCenter(new THREE.Vector3());

      object.position.x += object.position.x - center.x;
      object.position.y += object.position.y - center.y;
      object.position.z += object.position.z - center.z;

      camera.near = size / 100;
      camera.far = size * 100;
      camera.updateProjectionMatrix();

      camera.position.copy(center);
      camera.position.x += size / 2.5;
      camera.position.y -= size / 3.0;
      camera.position.z += size / 1.5;

      camera.lookAt(center);

      controls.saveState();

      updateTextureEncoding(gltf.scene);
      if (loading && loading.current) {
        setTimeout(() => {
          loading.current?.animate(
            [
              { opacity: 1 },
              { opacity: 0.3 },
              { opacity: 0.01 },
              { opacity: 0 },
            ],
            { duration: 1500 },
          );
          loading.current.style.opacity = '0';
        }, 500);
      }
    },
    (xhr) => {
      if (loadingBar) {
        const loadingState = Math.min(
          100,
          Math.max(0, (xhr.loaded / xhr.total) * 100),
        );
        loadingBar.current.style.width = `${loadingState}%`;
      }
    },
  );

  const loaderFBX = new FBXLoader();

  if (garmentScene) {
    loaderFBX.load(garmentScene, function (object) {
      object.scale.set(0.01, 0.01, 0.01);
      object.position.y -= 0.6;

      scene.add(object);
    });
  }

  glyphDisplayWrapper = new THREE.Object3D();
  if (glyphDisplay) {
    loaderFBX.load(glyphDisplay, function (object) {
      glyphDisplayObject = object;
      glyphDisplayObject.scale.set(0.005, 0.005, 0.005);
      glyphDisplayObject.position.x -= 0.5;
      glyphDisplayObject.rotation.y = 0.2;

      glyphDisplayWrapper.add(glyphDisplayObject);
      scene.add(glyphDisplayWrapper);
    });
  }

  if (garmentScene) {
    loaderFBX.load(garmentScene, function (object) {
      object.scale.set(0.01, 0.01, 0.01);
      object.position.y -= 0.6;

      scene.add(object);
    });
  }

  glyphDisplayWrapper = new THREE.Object3D();
  if (glyphDisplay) {
    loaderFBX.load(glyphDisplay, function (object) {
      glyphDisplayObject = object;
      glyphDisplayObject.scale.set(0.005, 0.005, 0.005);
      glyphDisplayObject.position.x -= 0.5;
      glyphDisplayObject.rotation.y = 0.2;

      glyphDisplayWrapper.add(glyphDisplayObject);
      scene.add(glyphDisplayWrapper);
    });
  }

  controls = new OrbitControls(camera, renderer.domElement);
  controls.listenToKeyEvents(window);
  controls.screenSpacePanning = false;

  controls.minDistance = 0.8;
  controls.maxDistance = 1.3;
  controls.enableDamping = true;
  controls.dampingFactor = 0.07;
  const availablePolarAngle = 0.3;
  controls.maxPolarAngle = Math.PI / 2 + availablePolarAngle;
  controls.minPolarAngle = Math.PI / 2 - availablePolarAngle;

  controls.screenSpacePanning = false;
  controls.update();

  window.addEventListener('resize', () => onWindowResize(container));
  container.addEventListener('dblclick', () => {
    controls.reset();
  });
  const hide3DMenu = () => {
    isMovingTimeout = setTimeout(() => {
      isMoving = true;
    }, 150);
  };
  const show3DMenu = () => {
    clearTimeout(isMovingTimeout);
    isMoving = false;
  };
  container.addEventListener('mousedown', () => {
    hide3DMenu();
  });
  container.addEventListener('mouseup', () => {
    show3DMenu();
  });
  container.addEventListener('touchstart', () => {
    hide3DMenu();
  });
  container.addEventListener('touchend', () => {
    show3DMenu();
  });

  setTimeout(() => {
    onWindowResize(container);
  }, 32);
}

function onWindowResize(container) {
  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(container.offsetWidth, container.offsetHeight);
}

function updatePositionForCamera(camera) {
  // fixed distance from camera to the object
  var dist = 1;
  var cwd = new THREE.Vector3();

  camera.getWorldDirection(cwd);

  cwd.multiplyScalar(dist);
  cwd.add(camera.position);

  glyphDisplayWrapper.position.set(cwd.x, cwd.y, cwd.z);
  glyphDisplayWrapper.setRotationFromQuaternion(camera.quaternion);
}

const clock = new THREE.Clock();
let previousTime = 0;

function animate() {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  if (mixer) {
    mixer.update(deltaTime);
  }

  if (glyphDisplayWrapper) {
    updatePositionForCamera(camera);
  }

  if (turnBackAnimation) {
    camera.position.z -= 0.05;
  }

  if (glyphDisplayObject) {
    const dir = isMoving ? -0.05 : 0.05;
    glyphDisplayObject.position.x = Math.max(
      -2.0,
      Math.min(glyphDisplayObject.position.x + dir, -0.5),
    );
  }

  controls.update();

  render();

  requestAnimationFrame(animate);
}

function render() {
  renderer.render(scene, camera);
}

export { animate, init, onWindowResize };
