import backgroundDigitalCreation from 'app/assets/backgroundDigitalCreation.png';
import backgroundDigitalCreationDisabled from 'app/assets/backgroundDigitalCreationGray.png';
import backgroundCarbonwear from 'app/assets/backgroundCarbonwear.png';
import useStore from 'app/common/useStore';
import { IScene } from 'common/state/models/Scene';
import { ImageStyleVersion, Orientation } from 'common/api/types';
import { DEFAULT_STICKER_TEMPLATE } from 'common/styles';

interface Props {
  item: IScene;
}

const DesignPreview = ({ item }: Props) => {
  const {
    garmentStore,
    stickerStore: { getStickerUrlByVersion },
  } = useStore();

  const garmentData = garmentStore.garmentById(item.garmentId!);
  const stickers = item.selectedStickersList;

  const imageStyleVersion = item.isLocked
    ? ImageStyleVersion.ZoomedGrayscale
    : ImageStyleVersion.Zoomed;

  const orientedImage = garmentData?.getImageByOrientation(Orientation.Front);
  const imageStyle = orientedImage?.getStyleByName(imageStyleVersion);

  const stickerTemplateStyle = orientedImage?.getStyleByName(
    ImageStyleVersion.ZoomedFixed,
  );

  const { height: stickerTemplateHeight, width: stickerTemplateWidth } =
    stickerTemplateStyle || DEFAULT_STICKER_TEMPLATE;

  const url = imageStyle?.url;

  const convertStickerLocationDataToMargins = ({
    width,
    height,
    leftX,
    topY,
  }: {
    width: number;
    height: number;
    leftX: number;
    topY: number;
  }) => {
    const top = `${(100 * topY) / stickerTemplateHeight}%`;
    const heightY = `${(100 * height) / stickerTemplateHeight}%`;

    const left = `${(100 * leftX) / stickerTemplateWidth}%`;
    const widthX = `${(100 * width) / stickerTemplateWidth}%`;
    return {
      maxWidth: widthX,
      maxHeight: heightY,
      height: 'auto',
      width: 'auto',
      top,
      left,
    };
  };

  const stickerViews = stickers.map((i) => {
    const stickerPosition = imageStyle?.getStickerPositionData(i.position);
    if (stickerPosition) {
      const margins = convertStickerLocationDataToMargins(stickerPosition);
      const image = getStickerUrlByVersion(i.stickerId, imageStyleVersion);
      return (
        <img
          key={i.position}
          src={image}
          alt="sticker"
          className="absolute"
          style={margins}
        />
      );
    }
    return null;
  });

  const background = garmentData.isCarbonwear
    ? backgroundCarbonwear
    : item.isLocked
    ? backgroundDigitalCreationDisabled
    : backgroundDigitalCreation;

  return (
    <div className="relative h-36 w-[7.5rem] md:h-48 md:w-40 rounded-lg overflow-hidden">
      <img alt="" className="absolute h-full w-full" src={background} />
      <div>
        <img
          alt="item"
          className="center-xy max-h-36 max-w-[7.5rem] md:max-h-48 md:max-w-40"
          src={url}
        />
        {stickerViews}
      </div>
    </div>
  );
};

export default DesignPreview;
