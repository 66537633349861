import * as spree from '@spree/storefront-api-v2-sdk';
const WALLETCONNECT_TOKEN = 'walletconnect_token';
const getVerifyPhraseParams = ({ public_key, signature, email, }) => ({
    public_key,
    signature,
    email,
    grant_type: WALLETCONNECT_TOKEN,
});
const getPhraseParams = ({ public_key }) => ({
    public_key,
    grant_type: WALLETCONNECT_TOKEN,
});
const getAppleIdParams = (params) => {
    return {
        ...params,
        grant_type: 'apple_id',
    };
};
export default class RstlssAuthentication extends spree.Http {
    tokenPath = '/spree_oauth/token';
    async getPhrase(params) {
        return await this.spreeResponse('post', this.tokenPath, {}, getPhraseParams(params));
    }
    async verifyPhrase(params) {
        return await this.spreeResponse('post', this.tokenPath, {}, getVerifyPhraseParams(params));
    }
    async verifyAppleId(params) {
        return await this.spreeResponse('post', this.tokenPath, {}, getAppleIdParams(params));
    }
}
