import * as spree from '@spree/storefront-api-v2-sdk';
export var SceneStatus;
(function (SceneStatus) {
    SceneStatus["init"] = "init";
    SceneStatus["draft"] = "draft";
    SceneStatus["cart"] = "cart";
    SceneStatus["processing"] = "processing";
    SceneStatus["purchased"] = "purchased";
})(SceneStatus || (SceneStatus = {}));
export default class Scenes extends spree.Http {
    scenes_path = '/api/v2/scenes';
    scene_path = (id) => `${this.scenes_path}/${id}`;
    save_scene_path = (id) => `${this.scenes_path}/${id}/save`;
    cancel_scene_path = (id) => `${this.scenes_path}/${id}/cancel`;
    cart_path = (scene_id) => `${this.scene_path(scene_id)}/cart`;
    items_path = (scene_id) => `${this.scene_path(scene_id)}/items`;
    update_items_path = (scene_id) => `${this.scene_path(scene_id)}/update_items`;
    item_path = (scene_id, id) => `${this.items_path(scene_id)}/${id}`;
    async list(token, params = {}) {
        return (await this.spreeResponse('get', this.scenes_path, token, params));
    }
    async show(token, sceneId, params = {}) {
        return (await this.spreeResponse('get', this.scene_path(sceneId), token, params));
    }
    async create(token, params = {}) {
        return (await this.spreeResponse('post', this.scenes_path, token, params));
    }
    async delete(token, id) {
        return (await this.spreeResponse('delete', this.scene_path(id), token));
    }
    async update(token, id, params) {
        return (await this.spreeResponse('put', this.scene_path(id), token, params));
    }
    async save(token, id) {
        return (await this.spreeResponse('put', this.save_scene_path(id), token));
    }
    async cancel(token, id) {
        return (await this.spreeResponse('put', this.cancel_scene_path(id), token));
    }
    async addToCart(token, scene_id, quantity = 1) {
        return (await this.spreeResponse('post', this.cart_path(scene_id), token, {
            scene: { quantity },
        }));
    }
    async removeFromCart(token, scene_id) {
        return (await this.spreeResponse('delete', this.cart_path(scene_id), token, { scene: { quantity: 1 } }));
    }
    async updateItems(token, scene_id, params) {
        return (await this.spreeResponse('post', this.update_items_path(scene_id), token, params));
    }
    async addItem(token, scene_id, params) {
        return (await this.spreeResponse('post', this.items_path(scene_id), token, params));
    }
    async removeItem(token, scene_id, id) {
        return (await this.spreeResponse('delete', this.item_path(scene_id, id), token));
    }
}
