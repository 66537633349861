import { useEffect, useState } from 'react';
import graphic404 from 'app/assets/graphic404.png';
import WireButton from 'app/components/WireButton';
import useStore from 'app/common/useStore';
import { useNavigate } from 'react-router-dom';
import { getStatus } from 'common/api/spree';
import { sleep } from 'common/utils/utils';

interface Props {
  title: string;
  subtitle: string;
}

const ServerErrorScreen: React.FC<Props> = ({ title, subtitle }) => {
  const {
    inMaintenance,
    serverResponding,
    setMaintenance,
    setServerResponding,
  } = useStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (serverResponding && !inMaintenance) {
      navigate('/');
    }
  }, [inMaintenance, serverResponding, navigate]);

  const retry = async () => {
    setLoading(true);
    try {
      await sleep(5000);
      await getStatus();
      setServerResponding(true);
      setMaintenance(false);
      navigate('/');
    } catch (err) {
      console.warn(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-10 mx-auto max-w-[544px]">
      <div className="h-screen w-screen absolute bg-404 rotate-180" />
      <div className="flex flex-col gap-y-8">
        <img src={graphic404} alt="error_graphic" className="mx-auto" />
        <div className="my-8">
          <h1 className="font-mussels text-white text-center text-[32px] mb-4 leading-10">
            {title}
          </h1>
          <p className="text-center regular16 text-coldGrey-700">{subtitle}</p>
        </div>
        <WireButton onClick={retry} theme="dark" loading={loading}>
          Try again
        </WireButton>
      </div>
    </div>
  );
};

export default ServerErrorScreen;
