import { Color } from 'common/styles';
import React from 'react';
import { IconType } from 'react-icons';
import Icon from 'app/components/Icon';

export interface Props {
  text: string;
  textColor?: Color;
  icon: IconType;
  iconColor?: Color;
  onPress: () => void;
}

const OptionItem: React.FC<Props> = ({
  text,
  textColor = Color.white,
  icon,
  iconColor = Color.coldGrey70,
  onPress,
}) => {
  return (
    <button
      className="py-1 pl-2 pr-16 hover:bg-coldGrey-300"
      onClick={(e) => {
        e.stopPropagation();
        onPress();
      }}
    >
      <div className="flex">
        <Icon icon={icon} size={15} iconColor={iconColor} />
        <p
          className="flex items-center regular16 ml-1"
          style={{ color: textColor }}
        >
          {text}
        </p>
      </div>
    </button>
  );
};

export default OptionItem;
