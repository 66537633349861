import { DISCORD_CHANNEL_LINK, SUPPORT_EMAIL } from 'common/config';
import LabeledInput from 'app/components/LabeledInput';
import WireButton from 'app/components/WireButton';

const ContactUs: React.FC = () => {
  return (
    <div className="mx-auto max-w-xl flex flex-col">
      <h2 className="text-center pt-16 uppercase">Contact us</h2>

      <p className="text-center regular16">
        If you have any questions, please contact us via our email address:
      </p>
      <LabeledInput
        placeholder="support email"
        initialValue={SUPPORT_EMAIL}
        disabled
        className="text-center"
      />
      <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
        <WireButton className="my-4">Send email</WireButton>
      </a>
      <p className="text-center regular16">Or visit our discord channel.</p>
      <a href={DISCORD_CHANNEL_LINK} target="_blank" rel="noreferrer">
        <WireButton className="my-4" theme="light">
          Visit discord
        </WireButton>
      </a>
    </div>
  );
};

export default ContactUs;
