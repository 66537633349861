import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { Color } from 'common/styles';
import { IconSet } from 'common/types';
export var MessageTypes;
(function (MessageTypes) {
    MessageTypes["InternetBack"] = "InternetBack";
    MessageTypes["InternetOff"] = "InternetOff";
    MessageTypes["MessageError"] = "MessageError";
    MessageTypes["UnavailableGlyph"] = "UnavailableGlyph";
    MessageTypes["GlyphAlreadyUsed"] = "GlyphAlreadyUsed";
    MessageTypes["Notification"] = "Notification";
    MessageTypes["CarouselUnavailableInBeta"] = "CarouselUnavailableInBeta";
    MessageTypes["DrawnStickerLottery"] = "DrawnStickerLottery";
    MessageTypes["GarmentListUnavailable"] = "GarmentListUnavailable";
    MessageTypes["LoginWithWC"] = "LoginWithWC";
    MessageTypes["InsufficientPoints"] = "InsufficientPoints";
    MessageTypes["GeneratorError"] = "GeneratorError";
    MessageTypes["EmptyPrizesError"] = "EmptyPrizesError";
    MessageTypes["GlyphComingSoon"] = "GlyphComingSoon";
    MessageTypes["PayWithWCError"] = "PayWithWCError";
    MessageTypes["CryptoPaymentError"] = "CryptoPaymentError";
    MessageTypes["DeleteAccountInfo"] = "DeleteAccountInfo";
    MessageTypes["DeleteAccountError"] = "DeleteAccountError";
    MessageTypes["DestroyWalletInfo"] = "AccountInfoDestroyWallet";
    MessageTypes["BetaLimit"] = "BetaLimit";
    MessageTypes["CartRemoveConfirmation"] = "CartRemoveConfirmation";
    MessageTypes["CreationDetailsSave"] = "CreationDetailsSave";
    MessageTypes["CreationDetailsDelete"] = "CreationDetailsDelete";
    MessageTypes["ShippingFetchError"] = "ShippingFetchError";
    MessageTypes["SelectShipmentError"] = "SelectShipmentError";
    MessageTypes["RemoveDraftConfirmation"] = "RemoveDraftConfirmation";
    MessageTypes["ExpireDraft"] = "ExpireDraft";
    MessageTypes["ExploreTileNotAvailable"] = "ExploreTileNotAvailable";
    MessageTypes["SaveGarmentDetail"] = "SaveGarmentDetail";
    MessageTypes["UpdateGarmentDetail"] = "UpdateGarmentDetail";
    MessageTypes["Logout"] = "Logout";
    MessageTypes["EditWardrobe"] = "EditWardrobe";
    MessageTypes["GarmentDetails"] = "GarmentDetails";
    MessageTypes["SelectionScreen"] = "SelectionScreen";
    MessageTypes["GlyphGenerator"] = "GlyphGenerator";
    MessageTypes["UpdateNeeded"] = "UpdateNeeded";
    MessageTypes["LinkCopied"] = "LinkCopied";
    MessageTypes["CartResetConfirm"] = "CartResetConfirm";
    MessageTypes["PaymentProcessingInfo"] = "PaymentProcessingInfo";
    MessageTypes["EmailVerificationError"] = "EmailVerificationError";
    MessageTypes["ConnectWalletInfo"] = "ConnectWalletInfo";
    MessageTypes["WalletAlreadyTakenError"] = "WalletAlreadyTakenError";
    MessageTypes["DisconnectWalletError"] = "DisconnectWalletError";
    MessageTypes["DropExpired"] = "DropExpired";
    MessageTypes["AllowlistWarning"] = "AllowlistWarning";
    MessageTypes["NotOnAllowlist"] = "NotOnAllowlist";
    MessageTypes["OrderCompleted"] = "OrderCompleted";
    MessageTypes["InsufficientFunds"] = "InsufficientFunds";
    MessageTypes["PuzzlePassword"] = "PuzzlePassword";
    MessageTypes["AppleIdConnectError"] = "AppleIdConnectError";
    MessageTypes["AppleIdPasswordRequired"] = "AppleIdPasswordRequired";
})(MessageTypes || (MessageTypes = {}));
export var MessagesDisplay;
(function (MessagesDisplay) {
    MessagesDisplay["Banner"] = "Banner";
    MessagesDisplay["Modal"] = "Modal";
})(MessagesDisplay || (MessagesDisplay = {}));
export var ButtonsLayoutDirection;
(function (ButtonsLayoutDirection) {
    ButtonsLayoutDirection["row"] = "row";
    ButtonsLayoutDirection["columnReverse"] = "column-reverse";
})(ButtonsLayoutDirection || (ButtonsLayoutDirection = {}));
const BannerMessage = types.model(Nodes.BannerMessage, {
    id: types.enumeration('id', Object.values(MessageTypes)),
    display: types.maybe(types.enumeration('messageDisplay', Object.values(MessagesDisplay))),
    titleText: types.maybe(types.string),
    message: types.maybe(types.string),
    confirmText: types.maybe(types.string),
    iconName: types.maybe(types.string),
    backgroundColor: types.maybe(types.enumeration('color', Object.values(Color))),
    color: types.maybe(types.enumeration('color', Object.values(Color))),
    modalIconSet: types.maybe(types.enumeration('iconSet', Object.values(IconSet))),
    blurredBackdrop: types.maybe(types.boolean),
    cancelText: types.maybe(types.string),
    showCloseButton: types.maybe(types.boolean),
    buttonDirection: types.maybe(types.enumeration('id', Object.values(ButtonsLayoutDirection))),
    messageBottomMargin: types.maybe(types.number),
    permanent: types.maybe(types.boolean),
    confirmButtonLoading: types.maybe(types.boolean),
});
export default BannerMessage;
