import RModal, { Handle as RModalHandle } from 'app/components/RModal';
import { observer } from 'mobx-react-lite';
import {
  useStripe,
  useElements,
  Elements,
  PaymentElement,
  PaymentElementProps,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createStripePaymentAsync } from 'common/api/spree';
import { STRIPE_PUBLISHABLE_KEY } from 'app/common/config';
import React, { useEffect, useState } from 'react';
import useStore from 'app/common/useStore';
import { get } from 'lodash';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface Props {
  onSuccess: () => Promise<void>;
  onFailure: (err: any) => Promise<void>;
  onEnd: () => void;
}

const StripePaymentModal = React.forwardRef<RModalHandle, Props>(
  ({ onSuccess, onFailure, onEnd }, ref) => {
    const [clientSecret, setClientSecret] = useState<string>();
    const { userStore } = useStore();
    useEffect(() => {
      createStripePaymentAsync(userStore.spreeAuth).then(
        ({ setup_intent_client_secret: setupIntentClientSecret }) =>
          setClientSecret(setupIntentClientSecret),
      );
    }, [userStore.spreeAuth]);

    return (
      <>
        {!!clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <Inner
              onSuccess={onSuccess}
              onFailure={onFailure}
              onEnd={onEnd}
              ref={ref}
              setupIntentClientSecret={clientSecret}
            />
          </Elements>
        )}
      </>
    );
  },
);

interface InnerProps extends Props {
  setupIntentClientSecret: string;
}

const Inner = React.forwardRef<RModalHandle, InnerProps>(
  ({ onSuccess, onFailure, onEnd, setupIntentClientSecret }, ref) => {
    const {
      checkoutStore: { completeCheckout, addressId },
      userStore: { getAddressById },
    } = useStore();

    const stripe = useStripe();
    const elements = useElements();

    const [enablePaymentButton, setEnablePaymentButton] = useState(false);

    const [loading, setLoading] = useState(false);

    const address = getAddressById(addressId!)!;
    const onPaymentElementChange: PaymentElementProps['onChange'] = ({
      complete,
    }) => setEnablePaymentButton(complete);

    const payWithStripe = async () => {
      try {
        if (!stripe || !elements) {
          return;
        }

        setLoading(true);

        const setup = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });

        if (setup.error) {
          throw new Error(setup.error.message);
        }

        const setupIntentResult = await stripe.retrieveSetupIntent(
          setupIntentClientSecret,
        );

        if (setupIntentResult.error) {
          throw new Error(setupIntentResult.error.message);
        }

        console.log(
          'setupIntentResult.setupIntent.payment_method',
          JSON.stringify(setupIntentResult.setupIntent.payment_method, null, 2),
        );

        const paymentId: string =
          get(setupIntentResult.setupIntent.payment_method, 'id') ||
          (setupIntentResult.setupIntent.payment_method as string);

        await completeCheckout({
          gateway_payment_profile_id: paymentId,
          name: `${address.firstname} ${address.lastname}`,
        });

        await onSuccess();
      } catch (err: any) {
        await onFailure(err.message);
      } finally {
        setLoading(false);
        onEnd();
      }
    };

    return (
      <RModal
        title="Credit Card Payment"
        kind="info"
        ref={ref}
        showIcon={false}
        onOk={payWithStripe}
        onClose={onEnd}
        okText="Pay"
        loading={loading}
        okButtonDisabled={!enablePaymentButton}
      >
        <div className="w-[400px]">
          <PaymentElement onChange={onPaymentElementChange} />
        </div>
      </RModal>
    );
  },
);

export default observer(StripePaymentModal);
