import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import Price from 'app/components/Price';
import RadioOption from 'app/components/RadioOption';
import useStore from 'app/common/useStore';
import { IDeliveryType } from 'common/state/models/DeliveryType';
import { IShipment } from 'common/state/models/Shipment';
import { observer } from 'mobx-react-lite';
import { formatPrice } from 'common/state/models/Price';

const CartShipping = () => {
  const {
    checkoutStore: {
      shipmentsList,
      addressId,
      setDeliveryType,
      totalAmount,
      orderNumber,
    },
    userStore: { spreeAuth },
  } = useStore();

  const { logEvent } = useAnalytics();

  const onDeliveryTypePress = async (
    shipment: IShipment,
    shippingMethod: IDeliveryType,
  ) => {
    await setDeliveryType(spreeAuth, shippingMethod, shipment);

    logEvent(AnalyticsEventName.SelectShipping, {
      paymentId: orderNumber,
      totalUsd: formatPrice(totalAmount.usd),
    });
  };

  const renderShippingRate = (
    shippingRate: IDeliveryType,
    shipment: IShipment,
  ) => {
    return (
      <RadioOption
        key={shippingRate.id}
        value={shipment.selectedShippingRate === shippingRate}
        onPress={() => {
          onDeliveryTypePress(shipment, shippingRate);
        }}
      >
        <div className="flex justify-between w-full">
          <div>{shippingRate.name}</div>
          {!!addressId && (
            <div className="bg-coldGrey-200 rounded-lg">
              <div className="flex justify-between">
                <Price showAllPrices price={shippingRate.price} />
              </div>
            </div>
          )}
        </div>
      </RadioOption>
    );
  };

  const renderList = (shipment: IShipment) => {
    return (
      <div key={shipment.id}>
        <p className="mb-6">Package #{shipment.number}</p>
        {shipment.listShippingRates.map((shippingRate) =>
          renderShippingRate(shippingRate, shipment),
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <h2 className="mb-6 uppercase">Shipping options</h2>
      {shipmentsList.map(renderList)}
    </div>
  );
};

export default observer(CartShipping);
