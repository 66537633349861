import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from './useAnalytics';

const AnalyticsPageChangeListener: React.FC = () => {
  const { logScreenChange } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;
    logScreenChange(page_path);
  }, [location.pathname, location.search]);

  return null;
};

export default AnalyticsPageChangeListener;
