import { GarmentType } from 'common/api/types';
import { IScene } from 'common/state/models/Scene';
import { Item } from 'firebase/analytics';
import { formatPrice } from 'common/state/models/Price';

function parseGarmentItem(scene: IScene): Item {
  const garmentData = scene.getGarmentData();
  return {
    item_id: garmentData.id,
    item_name: garmentData.name,
    item_category: 'garment',
    item_category2: garmentData.garmentType,
    index: scene.garmentSelectionListPosition,
    price:
      garmentData.garmentType === GarmentType.Carbonwear
        ? garmentData.price
        : 0,
    quantity: 1,
    ...(scene.physicalItemSize && { item_variant: scene.physicalItemSize }),
  };
}

function parseGlyphItems(scene: IScene): Item[] {
  return scene.selectedStickersList.map((sticker) => {
    const availableStickerIds = scene.getAvailableStickerIds(
      sticker.position,
      'all',
    );

    return {
      item_id: sticker.productId,
      item_name: sticker.name,
      currency: 'USD',
      item_category: 'sticker',
      item_category2: sticker.universeId,
      item_category3: 'standard',
      item_list_id: scene.garmentId,
      item_list_name: scene.getGarmentData().name,
      item_variant: sticker.position,
      price: Number(formatPrice(sticker.price.usd)),
      index: availableStickerIds.indexOf(sticker.stickerId) + 1,
      quantity: 1,
    };
  });
}

function parseSpaceItems(scene: IScene): Item[] {
  return scene.selectedSpacesList.map((space) => {
    return {
      item_id: space.slug,
      item_name: space.name,
      currency: 'USD',
      item_category: 'space',
      item_list_id: scene.getGarmentData().id,
      item_list_name: scene.getGarmentData().name,
      price: Number(formatPrice(space.price.usd)),
      quantity: 1,
      ...(scene.physicalItemSize && { item_variant: scene.physicalItemSize }),
    };
  });
}

export function parseItemsFromScene(scene: IScene): Item[] {
  const items: Item[] = [];

  items.push(parseGarmentItem(scene));

  if (scene.getGarmentData().garmentType === GarmentType.Customizable) {
    items.push(...parseGlyphItems(scene));
    items.push(...parseSpaceItems(scene));
  }
  return items;
}
