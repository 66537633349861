/* eslint-disable func-names */
import { getGarmentListAsync } from 'common/api/spree';
import { getRootStore } from 'common/state/RootStore';
import { GarmentType } from 'common/api/types';
import { handleError } from 'common/errorHelpers';
import { Nodes } from 'common/state/nodes';
import { flow, types } from 'mobx-state-tree';
import { Garment } from 'common/state/models/Garment';
import { reverse, sortBy } from 'lodash';
const garmentAcceptGarmentType = (params) => {
    if (params && params.garmentType) {
        return ({ garmentType }) => garmentType === params.garmentType;
    }
    return () => true;
};
const garmentExcludeIds = (params) => {
    if (params && params.excludedIds) {
        return ({ id }) => !params.excludedIds?.includes(id);
    }
    return () => true;
};
const GarmentStore = types
    .model(Nodes.GarmentStore, {
    garments: types.map(Garment),
    loading: types.boolean,
})
    .actions((self) => ({
    sortAndReplace: (garments) => {
        reverse(sortBy(garments, ['id'])).forEach((garment) => {
            self.garments.put(garment);
        });
    },
}))
    .actions((self) => ({
    fetch: flow(function* () {
        self.loading = true;
        try {
            const fetchedGarmentsList = yield getGarmentListAsync();
            self.sortAndReplace(fetchedGarmentsList);
            self.loading = false;
            return true;
        }
        catch (err) {
            console.warn('Error while loading garments', err);
            handleError(err, self);
            self.loading = false;
            return false;
        }
    }),
    fetchContractStock: flow(function* () {
        const { ethereumStore: { checkStock }, } = getRootStore(self);
        const possiblyLimited = Array.from(self.garments.values()).filter((g) => g.limitedByContract);
        yield Promise.all(possiblyLimited.map(async (x) => {
            if (!(await checkStock(x.slug))) {
                self.garments.get(x.id)?.setBlocked(true);
            }
        }));
    }),
}))
    .views((self) => ({
    garmentsList: (params) => Array.from(self.garments.values())
        .filter(garmentAcceptGarmentType(params))
        .filter(garmentExcludeIds(params))
        .map((i) => i.buttonView()),
    garmentById: (id) => self.garments.get(id),
}))
    .views((self) => ({
    get carbonwears() {
        return self.garmentsList({ garmentType: GarmentType.Carbonwear });
    },
    get customisable_garments() {
        return self.garmentsList({ garmentType: GarmentType.Customizable });
    },
}));
export default GarmentStore;
