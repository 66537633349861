import { SupportedImageStyleVersion, } from 'common/api/types';
import { find, get, startsWith, without } from 'lodash';
/**
 * Extracts image of given style from positionedImages
 */
export const imageUrlByStyle = (positionedImages, style) => {
    try {
        const { styles } = positionedImages.find((i) => i.orientation === 'front');
        const givenStyle = styles.find((i) => i.version === style);
        return givenStyle.url;
    }
    catch (err) {
        console.warn("Couldn't find image in the state", err);
        return '';
    }
};
export const getRelationshipObjects = (product, type, included) => {
    if (!product?.relationships?.[type]?.data) {
        return [];
    }
    const relations = product.relationships[type].data;
    const relationsArray = Array.isArray(relations) ? relations : [relations];
    const relationsObjects = relationsArray.map(({ id, type: relationType }) => {
        return find(included, (v) => v.id === id && v.type === relationType);
    });
    const relationsWithoutEmpty = without(relationsObjects, undefined);
    return relationsWithoutEmpty;
};
/**
 * Parses response and returns images with their styles for a product
 */
export const getAvailableProductImages = (product, included = [], getStickerPositions = false) => {
    const imageRelations = getRelationshipObjects(product, 'images', included);
    return imageRelations.map((imageData) => {
        const imageStyles = get(imageData, 'attributes.styles').filter(({ version }) => SupportedImageStyleVersion.includes(version));
        const newElem = {
            orientation: imageData.attributes.orientation,
            styles: imageStyles,
        };
        if (getStickerPositions) {
            const stickerPositions = get(imageData, 'attributes.locations');
            Object.assign(newElem, { stickerPositions });
        }
        return newElem;
    });
};
/**
 * Parses response and returns product properties
 */
export const getProductProperties = (product, included = []) => {
    const propertiesRelations = getRelationshipObjects(product, 'product_properties', included);
    return Object.fromEntries(propertiesRelations.map((property) => [
        property.attributes.name,
        property.attributes.value,
    ]));
};
/**
 * Retrieves all taxons for a resource
 */
export const getTaxons = (product, included) => {
    return getRelationshipObjects(product, 'taxons', included);
};
/**
 * Returns universes for a sticker hidden in the include data
 */
export const getUniverseForSticker = (sticker, included = []) => {
    const taxons = getTaxons(sticker, included);
    const universeTaxon = taxons.find((taxon) => startsWith(taxon.attributes.permalink, 'ips/'));
    if (!universeTaxon) {
        return null;
    }
    const [image] = getRelationshipObjects(universeTaxon, 'image', included);
    const imageStyle = get(image, 'attributes.styles')[1];
    return {
        id: universeTaxon?.id,
        name: universeTaxon?.attributes?.name,
        exclusive: false,
        imageUrl: imageStyle.url,
    };
};
