const Cookies = () => {
  return (
    <div className="mt-16">
      <h1 className="text-center my-12 uppercase">Cookies policy</h1>
      <ol className="my-4 medium18 text-coldGrey-500 uppercase">
        <li>
          <span>How we use cookie</span>
          <div className="mt-4 mb-12">
            <p className="regular16 normal-case my-4">
              <span>
                A cookie is a small file of letters and numbers that, if you
                agree to the cookie, we store on your browser or the hard drive
                of your device.
              </span>
            </p>
            <p className="regular16 normal-case my-4">
              <span>
                The Website and the Platform uses cookies to distinguish you
                from other users of the Website and the Platform. This
                information allows us to provide you with an enhanced experience
                when you use the Website and the Platform, and allows us to make
                improvements to the Website and the Platform. We may also use
                this cookie information for administration purposes and where we
                need to disclose cookie information about the Website and/or the
                Platform for security purposes. &nbsp;
              </span>
            </p>
          </div>
        </li>
        <li>
          <span>What types of cookies we use</span>
          <p className="regular16 normal-case my-4">
            We use the following cookies:
          </p>
          <ol className="regular16 normal-case mb-12">
            <div className="my-4 flex gap-2">
              <span>a.</span>
              <div>
                <span className="bold16">Strictly necessary cookies</span>
                <span>
                  . Cookies that are required for the operation of the Website
                  and the Platform, for example cookies that enable you to use a
                  shopping cart function.
                </span>
              </div>
            </div>
            <div className="my-4 flex gap-2">
              <span>b.</span>
              <div>
                <span className="bold16">Statistics cookies</span>
                <span>
                  . Cookies that allow us to recognise and count the number of
                  visitors to the Website or users on the Platform at any time,
                  and to see how our visitors and users use the Website and
                  Platform (as applicable). This helps us improve the
                  functionality of the Website and the Platform.
                </span>
              </div>
            </div>
            <div className="my-4 flex gap-2">
              <span>c.</span>
              <div>
                <span className="bold16">
                  Preferences and functionality cookies
                </span>
                <span>
                  . Cookies that allow us to recognise you when you return to
                  the Website or the Platform. This enables us to personalise
                  our content for you, and remember your preferences.
                </span>
              </div>
            </div>
            <div className="my-4 flex gap-2">
              <span>d.</span>
              <div>
                <span className="bold16">Marketing cookies</span>
                <span>
                  . Cookies that record your visits to the Website and the
                  Platform, the pages you have visited and the links you have
                  followed. We use this information to make our website and the
                  advertising displayed on it more relevant to your interests,
                  and we may share this information with relevant third parties
                  in relation to this purpose.
                </span>
              </div>
            </div>
          </ol>
        </li>
      </ol>
      <p className="regular16 normal-case my-4">
        <span>
          You can select if we can use the statistics, preferences and
          functionality and marketing cookies by checking the relevant boxes in
          the cookie preferences box on the Website or the Platform. However
          please note that if you use your web browser settings to block all
          cookies (including strictly necessary cookies) you may not be able to
          access all or parts of the Website and/or the Platform.
        </span>
      </p>
      <p className="regular16 normal-case my-4">
        <span>
          Social media cookies. The Website and the Platform may contain buttons
          and content from social media sites such as Instagram, Twitter or
          Facebook, to enable you to share content from the Platform or the
          Website with your social media networks. If you use these features,
          these social media sites may use their own cookies. Information on how
          these are used can be found in the respective social media site&#39;s
          terms and conditions.
        </span>
      </p>
    </div>
  );
};

export default Cookies;
