import { IAddress } from 'common/state/models/Address';

interface Props {
  address: IAddress;
}

const AddressDetails: React.FC<Props> = ({ address }) => (
  <>
    <div>
      {address.firstname} {address.lastname}
    </div>
    <div className="regular16 text-coldGrey-800">
      {address.address1}
      <br />
      {address.address2}
      {!!address.address2 && <br />}
      {address.city} {address.zipcode}, {address.country}
    </div>
  </>
);

export default AddressDetails;
