import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { Sticker } from 'common/state/models/Sticker';
export const StickerUniverse = types.model(Nodes.StickerUniverse, {
    id: types.identifier,
    name: types.string,
    exclusive: types.boolean,
    imageUrl: types.string,
    stickers: types.array(types.reference(Sticker)),
});
