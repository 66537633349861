import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
/**
 * These are the payment options supported by the current version of the app,
 * it can be compared with the backend response to determine which options are showed
 */
export var SupportedPaymentOptions;
(function (SupportedPaymentOptions) {
    SupportedPaymentOptions["WalletConnect"] = "WalletConnect";
    SupportedPaymentOptions["StoreCredit"] = "Store Credit";
    SupportedPaymentOptions["CreditCard"] = "Credit card";
})(SupportedPaymentOptions || (SupportedPaymentOptions = {}));
const PaymentMethod = types
    .model(Nodes.PaymentMethod, {
    id: types.identifier,
    name: types.string,
    description: types.string,
    isCrypto: types.boolean,
})
    .views((self) => ({
    isSupported: () => Object.values(SupportedPaymentOptions).includes(self.name),
}));
export default PaymentMethod;
