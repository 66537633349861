import { getRootStore } from 'common/state/RootStore';
import { fetchMintedCount, isItemInStock, fetchConfig, fetchMintedAllowlist, } from 'common/crypto/frogs';
import { getAllowlistUserStatus, fetchAllAllowlists, } from 'common/crypto/frogs_allowlists';
import ABI from 'common/abi_frogs';
import { Nodes } from 'common/state/nodes';
import { flow, types, getEnv } from 'mobx-state-tree';
const ContractStock = types.model({
    id: types.identifier,
    available: types.boolean,
});
const Allowlist = types.model({
    id: types.number,
    content: types.maybe(types.model({
        name: types.string,
        merkleTree: types.array(types.model({ address: types.string, quantity: types.number })),
    })),
    data: types.model({
        typedata: types.string,
        isActive: types.boolean,
        metadataUri: types.string,
        name: types.string,
        price: types.string,
        maxMintPerWallet: types.string,
        tokenPool: types.string,
        startTime: types.string,
        endTime: types.string,
        maxSupply: types.string, // "10000"
    }),
});
const EthereumStore = types
    .model(Nodes.EthereumStore, {
    itemsOutOfStock: types.map(ContractStock),
    allowlistStatus: types.maybe(types.string),
    minted: types.maybe(types.number),
    maxMintsPerWallet: types.maybe(types.number),
    // TODO These should probably be stored per drop, not globally
    allowlists: types.array(Allowlist),
    allowlistUsage: types.array(types.model({
        allowlistId: types.number,
        used: types.number,
    })),
})
    .views((self) => ({
    // TODO deduplicate this and mintLimitExceeded
    get mintLimitReached() {
        console.info('mintLimitReached now/max:', self.minted, self.maxMintsPerWallet);
        if (self.minted === undefined || self.maxMintsPerWallet === undefined) {
            return false;
        }
        const { scenesStore: { cartSections }, } = getRootStore(self);
        const countInCart = cartSections[0].data.length;
        console.info('mintLimitReached in cart:', countInCart);
        return self.minted + countInCart >= self.maxMintsPerWallet;
    },
    get mintLimitExceeded() {
        console.info('mintLimitExceeded now/max:', self.minted, self.maxMintsPerWallet);
        if (self.minted === undefined || self.maxMintsPerWallet === undefined) {
            return false;
        }
        const { scenesStore: { cartSections }, } = getRootStore(self);
        const countInCart = cartSections[0].data.length;
        console.info('mintLimitExceeded in cart:', countInCart);
        return self.minted + countInCart > self.maxMintsPerWallet;
    },
}))
    .actions((self) => {
    return {
        checkStock: flow(function* (item) {
            const { dropsStore: { availableDrop }, } = getRootStore(self);
            if (availableDrop === undefined) {
                console.warn('Drop not active');
                return;
            }
            const contractAddress = availableDrop.contractAddress;
            console.info('checkStock', item, contractAddress);
            const { web3Client } = getEnv(self);
            if (!contractAddress) {
                // Not sure if this should be true or false
                return true;
            }
            const contract = new web3Client.eth.Contract(ABI, contractAddress);
            const available = yield isItemInStock(contract, item);
            if (!available) {
                self.itemsOutOfStock.put({ id: item, available });
            }
            console.info('checkStock result', item, available);
            return available;
        }),
        fetchAllowlists: flow(function* () {
            const { dropsStore: { availableDrop }, } = getRootStore(self);
            const { web3Client } = getEnv(self);
            if (availableDrop === undefined) {
                console.warn('Drop not active');
                return;
            }
            const contractAddress = availableDrop.contractAddress;
            const contract = new web3Client.eth.Contract(ABI, contractAddress);
            const allowlists = yield fetchAllAllowlists(contract);
            self.allowlists.replace(allowlists);
        }),
        fetchUsage: flow(function* () {
            const { dropsStore: { availableDrop }, userStore: { walletAddress }, } = getRootStore(self);
            console.info('fetching usage', { walletAddress });
            console.info('fetching usage', walletAddress);
            const { web3Client } = getEnv(self);
            if (availableDrop === undefined) {
                console.warn('Drop not active');
                return;
            }
            const contractAddress = availableDrop.contractAddress;
            if (!walletAddress) {
                return;
            }
            const contract = new web3Client.eth.Contract(ABI, contractAddress);
            self.minted = yield fetchMintedCount(contract, walletAddress);
            const config = yield fetchConfig(contract);
            self.maxMintsPerWallet = parseInt(config['mintConfig'][4]);
            self.allowlistUsage = yield fetchMintedAllowlist(contract, self.allowlists, walletAddress);
        }),
        updateAllowlistStatus() {
            const { userStore: { walletAddress }, } = getRootStore(self);
            const result = getAllowlistUserStatus(self.allowlists, walletAddress);
            console.info('allowlistStatus', result);
            self.allowlistStatus = result;
        },
    };
});
export default EthereumStore;
