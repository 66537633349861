import { Link } from 'react-router-dom';
import { BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { DISCORD_CHANNEL_LINK } from 'common/config';

const Footer = () => (
  <footer className="bg-coldGrey-200 grow-1 mt-auto py-6 px-4 z-40">
    <div className="mx-auto max-w-screen-lg justify-between flex flex-row">
      <div className="flex flex-row regular14">
        <Link to="/privacy">Privacy Policy</Link>
        <div className="w-px mx-4 h-full bg-coldGrey-400" />
        <Link className="min-w-0 text-left" to="/terms">
          Terms & Conditions
        </Link>
        <div className="w-px mx-4 h-full bg-coldGrey-400" />
        <Link to="/cookies">Cookies</Link>
        <div className="w-px mx-4 h-full bg-coldGrey-400" />
        <Link to="/support">Support</Link>
      </div>
      <div className="flex flex-row ml-6 justify-between w-20">
        <a
          href="https://twitter.com/RSTLSSxyz"
          target="_blank"
          rel="noreferrer"
        >
          <BsTwitter size={24} />
        </a>
        <a href={DISCORD_CHANNEL_LINK} target="_blank" rel="noreferrer">
          <FaDiscord size={24} />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
