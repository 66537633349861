import ErrorBanner from 'app/components/ErrorBanner';
import LabeledInput from 'app/components/LabeledInput';
import WireButton from 'app/components/WireButton';
import useStore from 'app/common/useStore';
import { FormEventHandler, useCallback, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
  const {
    userStore: { resetPassword, loading, validatePassword },
  } = useStore();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('reset_password_token');

  if (!resetPasswordToken) {
    throw new Error('Missing reset password token');
  }

  const [error, setError] = useState<string>();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const passwordConfirmRef = useRef<HTMLInputElement | null>(null);

  const onKeyPress = () => {
    const password = passwordRef.current?.value || '';
    if (password.length >= 6) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  };

  const onResetPasswordSubmit: FormEventHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setError(undefined);

      if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
        setError('Passwords do not match.');
        return;
      }

      if (!validatePassword(passwordRef.current?.value!)) {
        setError('Password cannot contain emojis');
        return;
      }

      const success = await resetPassword(
        resetPasswordToken,
        passwordRef.current!.value,
        passwordRef.current!.value,
      );

      if (success) {
        navigate('/login', {
          replace: true,
          state: { fromPasswordReset: true },
        });
      } else {
        setError('Unable to change password');
      }
    },
    [resetPassword, navigate, resetPasswordToken, validatePassword],
  );

  return (
    <div className="mx-auto max-w-xl flex flex-col">
      <div className="absolute left-0 right-0">
        <ErrorBanner error={!!error}>{error!}</ErrorBanner>
      </div>

      <h2 className="text-center pt-16 uppercase">Set new password</h2>

      <form onSubmit={onResetPasswordSubmit} className="flex flex-col pt-10">
        <LabeledInput
          ref={passwordRef}
          onInput={onKeyPress}
          type="password"
          placeholder="New password"
          error={!!error}
        />
        <p className="regular14">
          Your password should include at least:
          <li>8 characters</li>
          <li>1 uppercase letter</li>
          <li>1 number</li>
          <li>1 special character</li>
        </p>
        <LabeledInput
          ref={passwordConfirmRef}
          type="password"
          placeholder="Confirm password"
          error={!!error}
        />
        <WireButton
          type="submit"
          disabled={loading || !buttonEnabled}
          loading={loading}
          className="mt-8"
        >
          Save new password
        </WireButton>
      </form>
    </div>
  );
};

export default ResetPassword;
