import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PURCHASE_RELATED_STUFF_VISIBLE } from './config';

const podRelatedTitles: Record<string, string> = {
  '/pod': 'Pod',
  '/pod/address': 'Pod: Address',
  '/pod/delivery': 'Pod: Delivery',
  '/pod/payment': 'Pod: Payment',
  '/pod/complete': 'Pod: Complete',
};

const titles: Record<string, string> = {
  ...(PURCHASE_RELATED_STUFF_VISIBLE ? podRelatedTitles : {}),
  '/thanks': 'Thanks',
  '/login': 'Log In',
  '/password/forgot': 'Recover Password',
  '/password/reset': 'Reset Password',
  '/privacy': 'Privacy Policy',
  '/terms': 'Terms and Conditions',
  '/cookies': 'Cookies Policy',
  '/support': 'Contact us',
  '/wardrobe': 'Wardrobe',
};

const useTitle = () => {
  let location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    document.title = titles[path] ? `RSTLSS - ${titles[path]}` : 'RSTLSS';
  }, [location.pathname]);
};

export default useTitle;
