import { observer } from 'mobx-react-lite';
import useStore from 'app/common/useStore';

const CartBadge = () => {
  const {
    scenesStore: { cartItems },
  } = useStore();
  const count = cartItems.length;

  if (count === 0) {
    return null;
  }

  return (
    <div className="absolute top-0 right-0 z-10">
      <div className="rounded-xl w-3 h-3 bg-turquoise-900 flex justify-center items-center">
        <div className="text-xxs text-coldGrey-100 mt-px">{count}</div>
      </div>
    </div>
  );
};

export default observer(CartBadge);
