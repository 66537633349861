import { castToArray } from 'common/api/formatters';
import graphic404 from 'app/assets/graphic404.png';
import WireButton from 'app/components/WireButton';

interface Props {
  title: string;
  infoText: string | string[];
  buttonTitle?: string;
  onPress?: () => void;
}

const WardrobeEmptyScreenWithInfo: React.FC<Props> = ({
  title,
  infoText,
  buttonTitle,
  onPress,
}) => {
  const renderInfo = castToArray(infoText).map((text) => {
    return (
      <p key={text} className="text-center regular16 text-coldGrey-700">
        {text}
      </p>
    );
  });

  return (
    <div className="mt-10 mx-auto max-w-[544px]">
      <div className="h-screen w-screen absolute bg-404 rotate-180" />
      <div className="flex flex-col gap-y-8">
        <img src={graphic404} alt="error_graphic" className="mx-auto" />
        <div className="my-8">
          <h1 className="font-mussels text-white text-center text-[32px] mb-4 leading-10">
            {title}
          </h1>
          {renderInfo}
        </div>
        {onPress && !!buttonTitle && (
          <WireButton onClick={onPress} theme="dark">
            {buttonTitle}
          </WireButton>
        )}
      </div>
    </div>
  );
};

export default WardrobeEmptyScreenWithInfo;
