import Modal from 'react-modal';
import WireButton from './components/WireButton';
import useStore from './common/useStore';
import { BsXLg } from 'react-icons/bs';
import { Outlet } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { MessageTypes } from 'common/state/models/BannerMessage';

const Layout = observer(() => {
  const {
    notificationStore: { modalMessage, modalVisible, confirmModal, closeModal },
  } = useStore();

  // Those callbacks are needed in order to avoid MobX-Flipper error with serializing cycle structure
  // Underlying pressable sends Flipper debug signal by serializing passed callback which in case of passing
  // Store's action directly causes cycle error
  const onConfirm = useCallback(() => {
    confirmModal();
  }, [confirmModal]);

  const onClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const kind =
    modalMessage?.id === MessageTypes.MessageError ? 'error' : 'generic';

  return (
    <div className="flex-1 justify-around mx-4 mb-16">
      <div className="mx-auto max-w-screen-lg">
        <Outlet />
        <Modal
          onRequestClose={onClose}
          overlayClassName="modal-bg"
          className="absolute inset-4"
          isOpen={modalVisible}
          contentLabel="Modal"
        >
          <div className="bg-coldGrey-200 rounded-2xl px-4 py-6 md:px-8 md:py-12 modal max-w-[732px] flex flex-col flex-1 items-center text-center border">
            <button onClick={onClose} className="absolute top-6 right-6">
              <BsXLg size="16" />
            </button>
            {kind === 'error' ? (
              <div className="bg-red-400 rounded-full p-7 hidden tall:block">
                <RiErrorWarningLine className=" text-red-900" size="37" />
              </div>
            ) : (
              <div className="bg-primaryBlue-200 rounded-full p-7 hidden tall:block">
                <RiErrorWarningLine className=" text-turquoise-800" size="37" />
              </div>
            )}
            <h2 className="text-xl md:text-2xl mx-8 my-6 uppercase">
              {modalMessage?.titleText}
            </h2>
            <div className="mb-8 md:mb-16 md:mx-8 text-left md:text-center whitespace-pre-wrap">
              {modalMessage?.message}
            </div>

            <WireButton
              className="min-w-[192px] max-w-[400px]"
              theme="dark"
              onClick={onConfirm}
            >
              {modalMessage?.confirmText || 'OK'}
            </WireButton>
          </div>
        </Modal>
      </div>
    </div>
  );
});

export default Layout;
