import DeliveryType from 'common/state/models/DeliveryType';
import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
import { sortBy } from 'underscore';
const Shipment = types
    .model(Nodes.Shipment, {
    id: types.identifier,
    number: types.string,
    shippingRates: types.array(DeliveryType),
    selectedShippingRate: types.reference(DeliveryType),
})
    .views((self) => ({
    get listShippingRates() {
        return sortBy(Array.from(self.shippingRates.values()), ({ name }) => name);
    },
}));
export default Shipment;
