import { XpEventName } from 'common/api/types';
import Banner from 'app/components/Banner';
import RModal, { Handle as RModalHandle } from 'app/components/RModal';
import CheckoutSteps from 'app/components/CheckoutSteps';
import SummaryBox from 'app/components/SummaryBox';
import WireButton from 'app/components/WireButton';
import { useEthereum } from 'app/EthereumContext';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';
import { useAnalytics } from 'app/common/analytics/useAnalytics';
import {
  AnalyticsEventName,
  AnalyticsEventParams,
  PurchaseEventParams,
} from 'app/common/analytics/types';
import { parseItemsFromScene } from 'app/common/analytics/helpers';
import { formatPrice } from 'common/state/models/Price';
import { DIGITAL_LIMIT_PER_USER } from 'common/config';
import { SupportedPaymentOptions } from 'common/state/models/PaymentMethod';
import StripePaymentModal from 'app/components/StripePaymentModal';
import { MOCKED_PAYMENT_ATTRIBUTES } from 'common/api/types';
import { getCartAsync } from 'common/api/spree';
import { PaymentErrors } from 'common/errorHelpers';

const allStages = [
  'start',
  'address',
  'delivery',
  'payment',
  'complete',
] as const;
const allStagesLink = [
  '',
  'address',
  'delivery',
  'payment',
  'complete',
] as const;

const Layout = () => {
  const { userStore, scenesStore, checkoutStore } = useStore();
  const navigate = useNavigate();
  const eth = useEthereum();
  const errorModal = useRef<RModalHandle>(null);
  const fetchShipmentsErrorModal = useRef<RModalHandle>(null);
  const limitModal = useRef<RModalHandle>(null);
  const longProcessingModal = useRef<RModalHandle>(null);
  const lowEthModal = useRef<RModalHandle>(null);
  const orderNotSynchronizedModal = useRef<RModalHandle>(null);
  const stripePaymentModal = useRef<RModalHandle>(null);

  const [screenLoading, setScreenLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const editedAddress = checkoutStore.currentlyEditedAddress;
  const formFilled =
    !!editedAddress &&
    editedAddress.firstname &&
    editedAddress.lastname &&
    editedAddress.phone &&
    editedAddress.address1 &&
    editedAddress.city &&
    editedAddress.zipcode &&
    editedAddress.country;

  const [digitals, carbonwear] = scenesStore.cartSections;

  const { logEvent, logPurchase } = useAnalytics();

  const getAnalyticsPodStats = (): Partial<AnalyticsEventParams> => ({
    numberOfDigitalCreations: digitals.data.length,
    numberOfCarbonwear: carbonwear.data.length,
    numberOfGarmentsInPod: digitals.data.length + carbonwear.data.length,
  });

  const stages = checkoutStore.checkoutSteps;
  const stage = (useMatch('/pod/:stage')?.params?.stage ??
    'start') as typeof stages[number];

  const showPodHeader = stage === 'start';
  const showSteps = stage !== 'start';

  const showMintBanner = stage === 'start';
  const showChangesBanner = stage === 'start' || stage === 'complete';

  const buttonText =
    stage === 'start'
      ? 'Continue to checkout'
      : stage === 'complete'
      ? 'Authorize payment'
      : 'Next step';

  const stageIndex = allStages.findIndex((x) => x === stage);

  const stepsDone = [
    scenesStore.cartItems.length > 0 &&
      !scenesStore.cartItems.some((scene) => scene.isLocked),
    !!checkoutStore.addressId || (!userStore.addresses.size && !!formFilled),
    !!checkoutStore.isDeliveryTypeSelected,
    !!checkoutStore.selectedPaymentMethodId,
    stage === 'complete',
  ];

  const allowNextStep = stepsDone[stageIndex];

  const onPaymentSuccess = async () => {
    const price = formatPrice(checkoutStore.totalAmount.usd);

    const purchaseParams: PurchaseEventParams = {
      transaction_id: checkoutStore.orderNumber,
      currency: 'USD',
      value: Number(price),
      ...getAnalyticsPodStats(),
      items: scenesStore.cartItems
        .map((value) => parseItemsFromScene(value))
        .flat(),
    };

    logPurchase(purchaseParams);

    const exp = await userStore.sendXpEvent(
      XpEventName.orderComplete,
      checkoutStore.orderId,
    );
    checkoutStore.reset();
    scenesStore.reset();
    navigate('/thanks', { state: { exp } });
  };

  const onPaymentLongProcessing = (errorMessage) => {
    console.warn(errorMessage);
    longProcessingModal?.current?.show();
  };

  // eslint-disable-next-line require-await
  const onPaymentFailure = async (errorMessage) => {
    console.warn(errorMessage);
    errorModal?.current?.show();
  };

  const onPaymentEnd = () => {
    setScreenLoading(false);
  };

  const payWithSelectedPaymentMethod = async () => {
    switch (checkoutStore.selectedPaymentMethod?.name) {
      case SupportedPaymentOptions.WalletConnect: {
        try {
          await eth.pay();
          await onPaymentSuccess();
        } catch (err: any) {
          if (err?.message.includes('insufficient funds')) {
            lowEthModal?.current?.show();
            return;
          } else if (err === PaymentErrors.PAYMENT_ORDER_FAILED) {
            onPaymentFailure(err);
          } else {
            onPaymentLongProcessing(err);
          }
        } finally {
          onPaymentEnd();
          return;
        }
      }
      case SupportedPaymentOptions.StoreCredit: {
        try {
          await checkoutStore.completeCheckout(MOCKED_PAYMENT_ATTRIBUTES);
          await onPaymentSuccess();
        } catch (err: any) {
          await onPaymentFailure(err.message);
        } finally {
          onPaymentEnd();
          return;
        }
      }
      case SupportedPaymentOptions.CreditCard: {
        stripePaymentModal?.current?.show();
        return;
      }
    }
  };

  const nextCheckoutStep = async () => {
    setCheckoutLoading(true);
    let nextStage = stageIndex === 4 ? null : allStages[stageIndex + 1];

    if (!allowNextStep) {
      return;
    }

    const refreshed = await checkoutStore.synchronizeCart();
    if (refreshed) {
      setCheckoutLoading(false);
      orderNotSynchronizedModal.current?.show();
      return;
    }

    if (stageIndex === 0) {
      if (digitals.data.length > DIGITAL_LIMIT_PER_USER) {
        limitModal?.current?.show();
        setCheckoutLoading(false);
        return;
      }

      if (!(await checkoutStore.startCheckout(userStore.spreeAuth))) {
        setCheckoutLoading(false);
        return;
      }

      if (nextStage === 'address') {
        await checkoutStore.getOrderAddresses(userStore.spreeAuth);
      }

      logEvent(AnalyticsEventName.PressContinueToCheckout, {
        totalUsd: formatPrice(checkoutStore.totalAmount.usd),
        ...getAnalyticsPodStats(),
      });

      const firstStep = checkoutStore.checkoutSteps[0];
      setCheckoutLoading(false);
      navigate(`/pod/${firstStep}`, {});
      return;
    }

    if (
      stages.length === 2 &&
      (nextStage === 'address' || nextStage === 'delivery')
    ) {
      nextStage = 'payment';
    }

    let error = false;
    if (nextStage === 'delivery') {
      if (!userStore.addresses.size) {
        try {
          const id = checkoutStore.currentlyEditedAddress.id!;
          await userStore.addOrOverwriteAddress(
            toJS(checkoutStore.currentlyEditedAddress),
          );
          await checkoutStore.setAddressId(userStore.spreeAuth, id);
        } catch (err) {
          checkoutStore.reset();
          setCheckoutLoading(false);
          return;
        }
      }

      try {
        await checkoutStore.getOrderAddresses(userStore.spreeAuth);
        await checkoutStore.nextStep();
        await checkoutStore.fetchDeliveryTypes();
        checkoutStore.clearCurrentlyEditedAddress();
      } catch (err) {
        error = true;
        fetchShipmentsErrorModal?.current?.show();
        checkoutStore.reset();
      } finally {
        setCheckoutLoading(false);
      }

      logEvent(AnalyticsEventName.DeliveryAddressPressNext, {
        paymentId: checkoutStore.orderNumber,
        totalUsd: formatPrice(checkoutStore.totalAmount.usd),
        ...getAnalyticsPodStats(),
      });
    }

    if (nextStage === 'payment') {
      try {
        const order = await getCartAsync(userStore.spreeAuth);
        if (order.attributes.state === 'delivery') {
          await checkoutStore.nextStep();
        }
        await checkoutStore.fetchContent();

        logEvent(AnalyticsEventName.ShippingPressNext, {
          paymentId: checkoutStore.orderNumber,
          totalUsd: formatPrice(checkoutStore.totalAmount.usd),
          ...getAnalyticsPodStats(),
        });
      } catch (err) {
        console.warn(err);
      } finally {
        setCheckoutLoading(false);
      }
    }

    if (nextStage === 'complete') {
      logEvent(AnalyticsEventName.PaymentPressNext, {
        paymentId: checkoutStore.orderNumber,
        totalUsd: formatPrice(checkoutStore.totalAmount.usd),
      });
      setCheckoutLoading(false);
    }

    if (nextStage) {
      if (!error) navigate(nextStage, {});
    } else {
      try {
        setCheckoutLoading(false);
        setScreenLoading(true);

        const price = formatPrice(checkoutStore.totalAmount.usd);

        logEvent(AnalyticsEventName.PressAuthorizeInWallet, {
          paymentId: checkoutStore.orderNumber,
          totalUsd: price,
          ...getAnalyticsPodStats(),
        });

        const isOrderValid = await checkoutStore.validateOrder();
        if (!isOrderValid) return;

        await payWithSelectedPaymentMethod();
      } catch (err) {
        console.error(err);
        errorModal?.current?.show();
      }
    }
  };

  const firstNonCompletedStep = Array.from(allStages.keys()).find((index) => {
    const done = stepsDone[index];
    if (!done) {
      const stepNotNecessary =
        (index === 1 || index === 2) &&
        !checkoutStore.isDeliveryAddressRequired;
      return !stepNotNecessary;
    }
    return !done;
  });

  if (
    firstNonCompletedStep === 2 &&
    stageIndex === 2 &&
    !checkoutStore.shipments.size
  ) {
    return (
      <Navigate
        replace
        to={`/cart/${allStagesLink[firstNonCompletedStep - 1]}`}
      />
    );
  }

  if (
    firstNonCompletedStep !== undefined &&
    stageIndex > firstNonCompletedStep
  ) {
    return (
      <Navigate replace to={`/pod/${allStagesLink[firstNonCompletedStep]}`} />
    );
  }

  return (
    <div>
      {screenLoading ? (
        <div className="spinner h-12 w-12 center-xy" />
      ) : (
        <>
          {showPodHeader && (
            <h2 className="mx-auto w-max mt-16 text-3xl uppercase">Your pod</h2>
          )}
          {showSteps && (
            <CheckoutSteps
              stepsEnabled={checkoutStore.checkoutSteps}
              stepsDone={stepsDone.filter((_, index) =>
                checkoutStore.checkoutSteps.includes(allStages[index]),
              )}
              currentStep={checkoutStore.checkoutSteps.indexOf(stage)}
              onClick={nextCheckoutStep}
            />
          )}
          {(showMintBanner || showChangesBanner) && <div className="h-4" />}
          {showChangesBanner && (
            <Banner className="mt-4 bg-turquoise-200">
              If you want to edit items in your pod, please return to the mobile
              app. Changes will be visible after reloading this page.
            </Banner>
          )}
          <Banner className="mt-4 bg-primaryBlue-200">
            Please make sure you select the Ethereum account that was linked to
            your RSTLSS account in your wallet to confirm the transaction. Using
            a different account might cause your transaction to fail.
          </Banner>
          <div className="flex mt-12 gap-6 flex-wrap">
            <div className="basis-5/12 flex-grow">
              <Outlet />
            </div>
            <div className="basis-4/12 flex-grow">
              <h4 className="mb-6">Summary</h4>
              <SummaryBox>
                <WireButton
                  loading={checkoutLoading}
                  onClick={nextCheckoutStep}
                  disabled={!allowNextStep}
                >
                  {buttonText}
                </WireButton>
              </SummaryBox>
            </div>
          </div>
        </>
      )}
      <RModal
        ref={longProcessingModal}
        kind="error"
        title="Something went wrong with your payment"
      >
        <h5 className="mb-4">
          Go to your connected cryptowallet and check if the latest transaction
          is registered.
        </h5>

        <ul className="list-disc list-outside ml-5">
          <li className="text-coldGrey-700">
            <h5 className="text-left text-base">
              If it’s there, wait a few minutes and go to Purchases. A new order
              should have been created.
            </h5>
          </li>
          <li className="text-coldGrey-700">
            <h5 className="text-left text-base">
              If there’s no transaction, please try to authorize payment in the
              cryptowallet once again.
            </h5>
          </li>
        </ul>
      </RModal>
      <RModal ref={errorModal} kind="error" title="Your payment failed">
        <h5 className="mb-4">Please, try again later.</h5>
      </RModal>
      <RModal
        ref={fetchShipmentsErrorModal}
        kind="error"
        title="This delivery address is not supported"
      >
        <div className="text-coldGrey-700">Please, choose another address.</div>
      </RModal>
      <RModal
        ref={orderNotSynchronizedModal}
        kind="info"
        title="Order completed"
      >
        <div className="text-coldGrey-700">
          This order probably has been completed using the other device. Your
          pod will be refreshed.
        </div>
      </RModal>
      <RModal
        ref={lowEthModal}
        kind="info"
        title="WalletConnect detected insufficient funds"
      >
        <div className="text-coldGrey-700">
          The wallet responded with too low amount of eth, so the transaction
          cannot be proceeded.
        </div>
      </RModal>
      <StripePaymentModal
        ref={stripePaymentModal}
        onSuccess={onPaymentSuccess}
        onFailure={onPaymentFailure}
        onEnd={onPaymentEnd}
      />
    </div>
  );
};

export default observer(Layout);
