import { IPaymentMethod } from 'common/state/models/PaymentMethod';
import walletConnectLogo from 'assets/walletConnectLogo.png';

interface Props {
  method: IPaymentMethod;
}

const PaymentOptionBar: React.FC<Props> = ({ method }) => {
  return (
    <>
      {method.name === 'WalletConnect' && (
        <img
          src={walletConnectLogo}
          alt="WalletConnectLogo"
          className="h-[16px] mr-2 my-auto"
        />
      )}
      {method.name}
    </>
  );
};

export default PaymentOptionBar;
