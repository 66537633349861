import * as SpreeCheckout from '@spree/storefront-api-v2-sdk/src/endpoints/Checkout';
import routes from '@spree/storefront-api-v2-sdk/src/routes';
export default class Checkout extends SpreeCheckout.default {
    async lock(token, params) {
        return this.spreeResponse('patch', `${routes.checkoutPath()}/lock`, token, params);
    }
    async validateOrder(token) {
        return this.spreeResponse('patch', `${routes.checkoutPath()}/validate`, token);
    }
    processError(error) {
        /* @ts-ignore */
        if (error.data?.errors) {
            return this.processSpreeError(error);
        }
        return super.processError(error);
    }
    async setPaymentPending(token) {
        return this.spreeResponse('patch', `${routes.checkoutPath()}/pend`, token);
    }
}
