import graphic404 from 'app/assets/graphic404.png';
import WireButton from 'app/components/WireButton';
import useStore from 'app/common/useStore';
import { useNavigate } from 'react-router-dom';
import { AFTER_LOGIN_PAGE } from 'app/common/config';

const NoMatch = () => {
  const {
    userStore: { spreeAuth },
  } = useStore();
  const navigate = useNavigate();
  const loggedIn = !!spreeAuth.token?.bearerToken;

  return (
    <div className="mt-10 mx-auto max-w-[544px]">
      <div className="h-screen w-screen absolute bg-404 rotate-180" />
      <div className="flex flex-col gap-y-8">
        <img src={graphic404} alt="error_graphic" className="mx-auto" />
        <div className="my-8">
          <h1 className="font-mussels text-white text-center text-[32px] mb-4 leading-10">
            Signal disturbance
          </h1>
          <p className="text-center regular16 text-coldGrey-700">
            Something went wrong. Please reattempt connection.
          </p>
        </div>
        <WireButton
          onClick={() => {
            loggedIn ? navigate(AFTER_LOGIN_PAGE) : navigate('/login');
          }}
          theme="dark"
        >
          Try again
        </WireButton>
      </div>
    </div>
  );
};

export default NoMatch;
