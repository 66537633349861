import { addSceneToCartAsync, completeOrder, addPaymentAsync, getCartAsync, getCartWithContentAsync, getCountriesAsync, getPaymentMethodsAsync, getShipments, isExpandedSpreeError, lockPayment, nextOrderStep, removeItemFromCartAsync, setCheckoutAddress, setDeliveryMethod, setCheckoutEmailAsync, validateOrder, setPaymentPending, resetCartStateAsync, changeCartCurrencyAsync, fetchCurrentOrder, isBasicSpreeError, } from 'common/api/spree';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { GlyphState, } from 'common/api/types';
import { handleError, parseSceneErrors, PaymentErrors, } from 'common/errorHelpers';
import Country from 'common/state/models/Country';
import { LineItem } from 'common/state/models/LineItem';
import PaymentMethod from 'common/state/models/PaymentMethod';
import { Price, sumPrices } from 'common/state/models/Price';
import Shipment from 'common/state/models/Shipment';
import { Nodes } from 'common/state/nodes';
import { forEach, isEmpty } from 'lodash';
import { getEnv, flow, getSnapshot, types } from 'mobx-state-tree';
import Address, { emptyAddress } from 'common/state/models/Address';
import { getRootStore } from 'common/state/RootStore';
import { ICurrency } from 'common/state/models/Currency';
const CheckoutStepErrorPrefix = "We can't proceed with your order because:";
const CheckoutStore = types
    .model(Nodes.CheckoutStore, {
    paymentMethods: types.map(PaymentMethod),
    shipments: types.map(Shipment),
    isShipmentAccepted: types.boolean,
    selectedPaymentMethodId: types.maybe(types.string),
    addressId: types.maybe(types.string),
    lockedAddressId: types.maybe(types.string),
    availableCountries: types.array(Country),
    checkoutSteps: types.array(types.string),
    totalAmount: Price,
    deliveryAmount: Price,
    itemsAmount: Price,
    taxAmount: Price,
    lineItems: types.array(LineItem),
    currentlyEditedAddress: Address,
    paymentInProgress: types.optional(types.boolean, false),
    orderId: types.maybe(types.string),
    orderNumber: types.maybe(types.string),
})
    .actions((self) => {
    return {
        fetchPaymentMethods: flow(function* (spreeAuth) {
            try {
                const methods = yield getPaymentMethodsAsync(spreeAuth);
                self.paymentMethods.clear();
                methods.forEach((method) => self.paymentMethods.put(method));
            }
            catch (err) {
                const sentry = getEnv(self).sentry;
                sentry.captureException(err);
                console.warn("Couldn't load payment methods", err);
            }
        }),
        clearShipments: () => {
            self.shipments.clear();
        },
        handleError: (error, prefix) => {
            handleError(error, self, prefix);
            const { scenesStore: { getSceneById }, } = getRootStore(self);
            if (isExpandedSpreeError(error) && error.errors.scenes) {
                forEach(parseSceneErrors(error.errors.scenes), (variantErrorMessage, sceneId) => {
                    const scene = getSceneById(sceneId);
                    forEach(variantErrorMessage, (errorMessage, variantId) => {
                        scene?.addErrorToGlyph(variantId, errorMessage);
                    });
                });
            }
        },
        freezeInterface: () => {
            self.paymentInProgress = true;
        },
        unfreezeInterface: () => {
            self.paymentInProgress = false;
        },
    };
})
    .actions((self) => {
    return {
        reset: () => {
            self.addressId = undefined;
            self.selectedPaymentMethodId = undefined;
            self.lockedAddressId = undefined;
            self.orderId = undefined;
        },
        setOrderNumber: (orderNumber) => {
            self.orderNumber = orderNumber;
        },
        setIsShipmentAccepted: (value) => {
            self.isShipmentAccepted = value;
        },
        setDeliveryType: flow(function* (spreeAuth, deliveryType, shipment) {
            yield setDeliveryMethod(spreeAuth, deliveryType.shippingMethodId, shipment.id);
            shipment.selectedShippingRate = deliveryType;
        }),
        setCurrentlyEditedAddress: (key, value) => {
            self.currentlyEditedAddress = {
                ...self.currentlyEditedAddress,
                [key]: value,
            };
        },
        clearCurrentlyEditedAddress: () => {
            self.currentlyEditedAddress = emptyAddress();
        },
        fetchContent: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            try {
                const { lineItems, data: { attributes }, } = yield getCartWithContentAsync(spreeAuth);
                self.checkoutSteps.replace(attributes.checkout_steps);
                self.lineItems.replace(lineItems);
                self.totalAmount = attributes.prices;
                self.deliveryAmount = attributes.ship_total_prices;
                self.itemsAmount = attributes.item_total_prices;
            }
            catch (err) {
                console.warn(err);
                handleError(err, self);
            }
            yield self.fetchPaymentMethods(spreeAuth);
        }),
        fetchAvailableCountries: flow(function* () {
            try {
                self.availableCountries.clear();
                const countries = yield getCountriesAsync();
                countries.forEach(({ attributes: { iso, name } }) => {
                    self.availableCountries.push({ country_iso: iso, name });
                });
            }
            catch (err) {
                console.warn("Couldn't load available countries", err);
                handleError(err, self);
            }
        }),
        // eslint-disable-next-line consistent-return
        startCheckout: flow(function* (spreeAuth) {
            try {
                const order = yield getCartAsync(spreeAuth);
                self.orderId = order.id;
                const isOrderValid = yield validateOrder(spreeAuth);
                if (order.attributes.state === 'cart') {
                    return yield nextOrderStep(spreeAuth);
                }
                return isOrderValid;
            }
            catch (err) {
                const sentry = getEnv(self).sentry;
                sentry.captureException(err);
                self.handleError(err, CheckoutStepErrorPrefix);
                console.warn("Couldn't start checkout process", err);
            }
        }),
        // eslint-disable-next-line consistent-return
        nextStep: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            try {
                return yield nextOrderStep(spreeAuth);
            }
            catch (err) {
                console.warn("Couldn't proceed with order", err);
                throw err;
            }
        }),
        validateOrder: flow(function* () {
            const { userStore: { spreeAuth }, scenesStore, stickerStore: { getStickerWithId }, } = getRootStore(self);
            // blockchain checks
            const garmentsBlocked = scenesStore.cartItems.some((scene) => scene.garmentData?.blocked);
            const glyphsBlocked = scenesStore.cartItems
                .flatMap((scene) => Array.from(scene.selectedStickers.values()))
                .some((sticker) => getStickerWithId(sticker.stickerId)?.stockStatus ==
                GlyphState.SoldOut);
            if (garmentsBlocked || glyphsBlocked) {
                self.unfreezeInterface();
                const err = { summary: 'Items out of stock' };
                setTimeout(() => self.handleError(err, CheckoutStepErrorPrefix), 750);
                console.warn("Couldn't proceed with order", err);
                return;
            }
            // backend check
            try {
                return yield validateOrder(spreeAuth);
            }
            catch (err) {
                self.unfreezeInterface();
                setTimeout(() => self.handleError(err, CheckoutStepErrorPrefix), 750);
                console.warn("Couldn't proceed with order", err);
            }
        }),
        fetchDeliveryTypes: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            try {
                const shipments = yield getShipments(spreeAuth);
                self.clearShipments();
                shipments.forEach((shipment) => self.shipments.put(shipment));
            }
            catch (err) {
                handleError(err, self);
                console.warn("Couldn't load shipping data", err);
            }
        }),
        clearDeliveryTypes: () => {
            self.shipments.clear();
        },
        setCheckoutEmail: flow(function* () {
            const { userStore: { spreeAuth, email }, } = getRootStore(self);
            yield setCheckoutEmailAsync(spreeAuth, email);
        }),
    };
})
    .views((self) => ({
    lineItemsForScene: (scene) => {
        return self.lineItems.filter(({ sceneId }) => sceneId === scene.id);
    },
}))
    .views((self) => ({
    variantIdsInScene: (scene) => {
        return self.lineItemsForScene(scene).map(({ variantId }) => variantId);
    },
}))
    .views((self) => ({
    sceneOutstandingPrice: (scene) => {
        const lineItemsVariantIds = self.variantIdsInScene(scene);
        const outstandingPrices = scene.selectedItemsList
            .filter(({ variant_id }) => lineItemsVariantIds.includes(variant_id))
            .map(({ price }) => price);
        return sumPrices(outstandingPrices);
    },
    get isDeliveryTypeSelected() {
        return (self.shipments.size !== 0 &&
            Array.from(self.shipments.values()).every(({ selectedShippingRate }) => !!selectedShippingRate));
    },
    get isDeliveryAddressRequired() {
        return self.checkoutSteps.includes('delivery');
    },
    get cryptoPaymentMethods() {
        return Array.from(self.paymentMethods.values()).filter((i) => i.isCrypto);
    },
    get fiatPaymentMethods() {
        return Array.from(self.paymentMethods.values()).filter((i) => !i.isCrypto);
    },
    get availableCountriesList() {
        return Array.from(self.availableCountries.values());
    },
    get shipmentsList() {
        return Array.from(self.shipments.values());
    },
    get selectedPaymentMethod() {
        if (self.selectedPaymentMethodId) {
            return self.paymentMethods.get(self.selectedPaymentMethodId);
        }
        return null;
    },
}))
    .views((self) => ({
    /** Checks whether user can purchase the cart with non-crypto payment */
    get isShippingRateSelected() {
        if (isEmpty(self.shipmentsList)) {
            return false;
        }
        return self.shipmentsList.every(({ selectedShippingRate }) => !!selectedShippingRate);
    },
    get isFiatPaymentAllowed() {
        return !isEmpty(self.fiatPaymentMethods);
    },
}))
    .actions((self) => {
    return {
        setSelectedPaymentMethod: flow(function* (paymentMethodId) {
            self.selectedPaymentMethodId = paymentMethodId;
            const { userStore: { spreeAuth }, } = getRootStore(self);
            const paymentMethod = self.selectedPaymentMethod;
            const properCurrency = paymentMethod?.isCrypto
                ? ICurrency.ETH
                : ICurrency.USD;
            yield changeCartCurrencyAsync(spreeAuth, properCurrency);
        }),
        assumeCheckoutCompleted: flow(function* () {
            const { userStore: { resetCart }, scenesStore, orderStore, } = getRootStore(self);
            yield resetCart();
            yield Promise.all([scenesStore.fetchContent(), orderStore.fetch(true)]);
            self.reset();
        }),
    };
})
    .actions((self) => {
    return {
        /**
         * Crypto prize fluctuates. This function locks and establishes the prize with the backend
         * @returns {object} LockedCryptoPaymentAttributes
         */
        getCryptoPaymentAttributes: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            const { attributes: { locked_crypto_total: lockedCryptoTotal, payment_address: paymentAddress, }, } = yield lockPayment(spreeAuth, self.selectedPaymentMethod.id);
            return {
                lockedPrice: lockedCryptoTotal,
                paymentAddress: paymentAddress,
            };
        }),
        /* Confirms and completes the payment based on result of the payment callback */
        completeCheckout: flow(function* (paymentAttributes) {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            // For orders without the delivery address we need to set the email additionally
            if (!self.isDeliveryAddressRequired) {
                yield self.setCheckoutEmail();
            }
            yield addPaymentAsync(spreeAuth, self.selectedPaymentMethod.id, paymentAttributes);
            yield completeOrder(spreeAuth);
            yield self.assumeCheckoutCompleted();
        }),
        updateLineItems: (sceneId, updateLineItems) => {
            const updateLineItemsVariantIds = updateLineItems.map(({ variant_id }) => variant_id);
            const newLineItems = self.lineItems.filter(({ variantId, sceneId: sId }) => {
                return (sceneId !== sId || !updateLineItemsVariantIds.includes(variantId));
            });
            self.lineItems.replace(newLineItems);
            updateLineItems
                .filter(({ remove }) => !remove)
                .forEach(({ variant_id: variantId }) => {
                self.lineItems.push({ variantId, sceneId });
            });
        },
        setAddressId: flow(function* (spreeAuth, addressId) {
            const { userStore: { getAddressById }, } = getRootStore(self);
            if (self.addressId !== addressId) {
                self.setIsShipmentAccepted(false);
            }
            self.addressId = addressId;
            const address = getAddressById(addressId);
            yield setCheckoutAddress(spreeAuth, address);
        }),
        addToCart: flow(function* (scene) {
            const { scenesStore, stickerStore, userStore: { spreeAuth }, } = getRootStore(self);
            try {
                yield addSceneToCartAsync(spreeAuth, scene.id);
            }
            catch (e) {
                handleError(e, self);
                return false;
            }
            scene.setStatus(SceneStatus.cart);
            scenesStore.upsertScene(getSnapshot(scene));
            yield Promise.all([self.fetchContent(), stickerStore.fetch(spreeAuth)]);
            return true;
        }),
        moveToDrafts: flow(function* (spreeAuth, sceneId) {
            try {
                yield removeItemFromCartAsync(spreeAuth, sceneId);
                const { scenesStore } = getRootStore(self);
                const scene = scenesStore.getSceneById(sceneId);
                scene.setStatus(SceneStatus.draft);
                scenesStore.upsertScene(getSnapshot(scene));
            }
            catch (err) {
                handleError(err, self);
            }
            yield self.fetchContent();
        }),
        removeFromCart: flow(function* (spreeAuth, sceneId) {
            try {
                yield removeItemFromCartAsync(spreeAuth, sceneId);
                const { scenesStore } = getRootStore(self);
                yield scenesStore.deleteScene(spreeAuth, sceneId, true);
                if (isEmpty(scenesStore.cartItems))
                    yield resetCartStateAsync(spreeAuth);
                yield self.fetchContent();
            }
            catch (err) {
                handleError(err, self);
            }
        }),
    };
})
    .actions((self) => {
    return {
        getOrderAddresses: flow(function* (spreeAuth) {
            const { relationships } = yield getCartAsync(spreeAuth);
            self.lockedAddressId = relationships.shipping_address?.data?.id;
        }),
        clearCart: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            try {
                const { scenesStore } = getRootStore(self);
                yield Promise.all(scenesStore.cartItems.map(async ({ id }) => self.removeFromCart(spreeAuth, id)));
            }
            catch (err) {
                handleError(err, self);
            }
        }),
        getMintParams: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            const { attributes: { mint_params: mintParams }, } = yield getCartAsync(spreeAuth);
            return mintParams;
        }),
        addPaymentMethod: flow(function* (paymentAttributes) {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            // For orders without the delivery address we need to set the email additionally
            if (!self.isDeliveryAddressRequired) {
                yield self.setCheckoutEmail();
            }
            try {
                const order = yield addPaymentAsync(spreeAuth, self.selectedPaymentMethod.id, paymentAttributes);
                if (order.attributes.state === 'payment') {
                    yield nextOrderStep(spreeAuth);
                }
            }
            catch (err) {
                console.warn("Couldn't proceed with order", err);
                throw err;
            }
        }),
        setPaymentPendingState: flow(function* () {
            const { userStore: { spreeAuth }, } = getRootStore(self);
            try {
                yield setPaymentPending(spreeAuth);
            }
            catch (err) {
                console.warn("Couldn't proceed with order", err);
                throw err;
            }
        }),
        checkCurrentOrderState: function () {
            return new Promise((resolve, reject) => {
                const REFRESH_TIME = 5000;
                const TIMEOUT = 90000;
                const { userStore: { spreeAuth }, } = getRootStore(self);
                const refresher = setInterval(async () => {
                    let order = null;
                    try {
                        order = await fetchCurrentOrder(spreeAuth);
                    }
                    catch (err) {
                        /// cart not found, so the order was completed
                        if (isBasicSpreeError(err) && err.serverResponse.status === 404) {
                            clearInterval(refresher);
                            clearTimeout(timeout);
                            resolve();
                        }
                    }
                    if (order?.payment?.status === 'failed') {
                        clearInterval(refresher);
                        clearTimeout(timeout);
                        reject(PaymentErrors.PAYMENT_ORDER_FAILED);
                    }
                }, REFRESH_TIME);
                const timeout = setTimeout(async () => {
                    clearInterval(refresher);
                    reject('Timeout ocurred during waiting for order completion');
                }, TIMEOUT);
            });
        },
        synchronizeCart: flow(function* () {
            const { scenesStore, orderStore, userStore } = getRootStore(self);
            try {
                if (userStore.spreeAuth) {
                    const { attributes: order } = yield getCartAsync(userStore.spreeAuth);
                    if (order.number !== self.orderNumber) {
                        console.warn('Wrong order');
                        self.orderNumber = order.number;
                        userStore.setOrderToken(order.token);
                        yield Promise.all([
                            self.fetchContent(),
                            scenesStore.fetchContent(),
                            orderStore.fetch(true),
                        ]);
                        return true;
                    }
                }
            }
            catch (e) {
                console.warn(e);
            }
            return false;
        }),
    };
})
    .actions((self) => ({
    finalizePayment: flow(function* (paymentAttributes) {
        yield self.addPaymentMethod(paymentAttributes);
        yield self.setPaymentPendingState();
        yield self.checkCurrentOrderState();
    }),
}));
export default CheckoutStore;
