interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const HighlightButton: React.FC<Props> = ({
  children,
  loading = false,
  disabled = false,
  className = '',
  ...props
}) => {
  return (
    <button
      className={`uppercase rounded-lg py-2 my-2 text-coldGrey-200 w-full h-12 relative hover:shadow-transparent30 bg-wire-active active:scale-95 transition-all ${className}`}
      disabled={disabled}
      {...props}
    >
      <div className="mx-auto w-max h-max">
        {loading ? <div className="spinner h-6 w-6" /> : <div>{children}</div>}
      </div>
    </button>
  );
};

export default HighlightButton;
