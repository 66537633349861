import { PhysicalSpaceTypes } from 'common/api/types';
export const IS_BETA = true;
export const CARBONWEAR_LIMIT_PER_USER = 10;
export const DIGITAL_LIMIT_PER_USER = Infinity;
export const DEFAULT_SPACES = [
    'ready-player-me',
    'toadally-rare-hoodie',
    PhysicalSpaceTypes.PhysicalItem,
];
export const DISCORD_CHANNEL_LINK = 'https://discord.gg/rstlssxyz';
export const SUPPORT_EMAIL = 'rstlss@rstlss.xyz';
