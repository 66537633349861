import WireButton from 'app/components/WireButton';
import { Color } from 'common/styles';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
// import { useParams } from "react-router-dom";

const AppBanner = () => {
  return (
    <a href={process.env.REACT_APP_LINK_APPLE}>
      <div className="md:hidden rounded-xl bg-turquoise-200 px-4 py-3">
        <div className="flex justify-between flex-row">
          <h1 className="text-base text-turquoise-900">Explore RSTLSS app</h1>
          <MdOutlineKeyboardArrowRight size={24} color={Color.turquoise70} />
        </div>
        <div className="text-turquoise-900">
          Check the RSTLSS mobile app to grab your personalized NFT!
        </div>
      </div>
    </a>
  );
};

const Token = ({ mobile = false }) => {
  // const { id } = useParams();

  return (
    <div className="py-16">
      {!mobile && <AppBanner />}
      <div className="flex flex-shrink flex-row flex-wrap justify-center">
        <div className="h-4 w-4" />
        <div className="basis-1/3 flex-1 flex-shrink">
          <h1>Guardian Coat</h1>
          <h5>Digital Creation • Minted NFT</h5>

          <div className="mt-6 w-full h-0 border border-[#2A2C39] " />
          <h4 className="mt-3">Owner address</h4>
          <div className="mt-3 break-all">
            0x8DbAf27420B1da445312DBcBe9657Ba52d8985A4
          </div>

          <div className="mt-6 w-full h-0 border border-[#2A2C39] " />
          <h4 className="mt-3">Token</h4>
          <div className="mt-3">2137</div>

          <div className="mt-6 w-full h-0 border border-[#2A2C39] " />
          <h4 className="mt-3">Contract</h4>
          <div className="mt-3 break-all">
            0xaEb1920127b395b2a8d634ff6bb255b10dbB876d
          </div>

          <WireButton className="mt-8" onKeyPress={() => {}}>
            View on Opensea
          </WireButton>
        </div>
      </div>
    </div>
  );
};

export default Token;
