import { Price } from 'common/state/models/Price';
import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
export var PhysicalSpaceTypes;
(function (PhysicalSpaceTypes) {
    PhysicalSpaceTypes["PhysicalItem"] = "physical-item";
    PhysicalSpaceTypes["ARItem"] = "ar-effect";
})(PhysicalSpaceTypes || (PhysicalSpaceTypes = {}));
export var Taxons;
(function (Taxons) {
    Taxons["Garments"] = "Garments";
    Taxons["Stickers"] = "Garment assets";
})(Taxons || (Taxons = {}));
export var Orientation;
(function (Orientation) {
    Orientation["Front"] = "front";
    Orientation["Left"] = "left";
    Orientation["Back"] = "back";
    Orientation["Right"] = "right";
})(Orientation || (Orientation = {}));
export var ImageColorVariants;
(function (ImageColorVariants) {
    ImageColorVariants["color"] = "color";
    ImageColorVariants["gray"] = "gray";
})(ImageColorVariants || (ImageColorVariants = {}));
export var ImageStyleVersion;
(function (ImageStyleVersion) {
    ImageStyleVersion["StickerCarousel"] = "plp_and_carousel_md";
    ImageStyleVersion["Zoomed"] = "zoomed";
    ImageStyleVersion["Positioned"] = "positioned";
    ImageStyleVersion["PositionedFixed"] = "positionedFixed";
    ImageStyleVersion["ZoomedFixed"] = "zoomedFixed";
    ImageStyleVersion["ZoomedGrayscale"] = "zoomedGrayscale";
    ImageStyleVersion["PositionedGrayscale"] = "positionedGrayscale";
    ImageStyleVersion["Mini"] = "mini";
    ImageStyleVersion["Small"] = "small";
    ImageStyleVersion["Product"] = "product";
    ImageStyleVersion["PDPThumbnail"] = "pdp_thumbnail";
    ImageStyleVersion["plp_and_carousel_xs"] = "plp_and_carousel_xs";
    ImageStyleVersion["plp_and_carousel_sm"] = "plp_and_carousel_sm";
    ImageStyleVersion["plp_and_carousel_lg"] = "plp_and_carousel_lg";
    ImageStyleVersion["large"] = "large";
    ImageStyleVersion["plp_and_carousel"] = "plp_and_carousel";
    ImageStyleVersion["plp"] = "plp";
})(ImageStyleVersion || (ImageStyleVersion = {}));
export const SupportedImageStyleVersion = Object.values(ImageStyleVersion);
export var GlyphState;
(function (GlyphState) {
    GlyphState["Loading"] = "loading";
    GlyphState["Available"] = "available";
    GlyphState["Popular"] = "popular";
    GlyphState["RunningLow"] = "running-low";
    GlyphState["SoldOut"] = "sold-out";
    GlyphState["ComingSoon"] = "coming-soon";
    GlyphState["Taken"] = "taken";
    GlyphState["TakenByUser"] = "taken-by-user";
})(GlyphState || (GlyphState = {}));
export var XpEventName;
(function (XpEventName) {
    XpEventName["login"] = "login";
    XpEventName["newDraft"] = "newDraft";
    XpEventName["orderComplete"] = "orderComplete";
    XpEventName["shareDraft"] = "shareDraft";
    XpEventName["shareCreation"] = "shareCreation";
    XpEventName["createAccount"] = "createAccount";
})(XpEventName || (XpEventName = {}));
export var PopupFrequency;
(function (PopupFrequency) {
    PopupFrequency["OneTimeOnly"] = "one_time_only";
    PopupFrequency["OncePerDay"] = "once_per_day";
    PopupFrequency["EveryTime"] = "every_time";
})(PopupFrequency || (PopupFrequency = {}));
export var PopupType;
(function (PopupType) {
    PopupType["Slider"] = "slider";
    PopupType["Long"] = "long";
})(PopupType || (PopupType = {}));
export const StickerPosition = types.model(Nodes.StickerPosition, {
    name: types.string,
    margins: types.map(types.number),
});
const SizeInfo = types.model({
    name: types.string,
    data: types.string,
});
export const GarmentSize = types.model({
    title: types.string,
    variant_id: types.optional(types.string, ''),
    available: types.boolean,
    sizeInfo: types.array(SizeInfo),
});
export const Space = types.model({
    variant_id: types.identifier,
    price: Price,
    description: types.string,
    name: types.string,
    slug: types.string,
    image: types.string,
    available: types.boolean,
});
export var GarmentType;
(function (GarmentType) {
    GarmentType["Customizable"] = "Customizable";
    GarmentType["Carbonwear"] = "Carbonwear";
})(GarmentType || (GarmentType = {}));
// This is a payment mocked for the store credit payment
export const MOCKED_PAYMENT_ATTRIBUTES = {
    cc_type: 'visa',
    month: '12',
    year: (new Date().getFullYear() + 1).toString(),
    last_digits: '1111',
    name: 'Sean Schofield',
    gateway_payment_profile_id: 'payment',
    gateway_customer_profile_id: 'BGS-1234',
};
export var ComponentType;
(function (ComponentType) {
    ComponentType["textBanner"] = "text_banner";
    ComponentType["imageBanner"] = "image_banner";
    ComponentType["productCarousel"] = "product_carousel";
    ComponentType["button"] = "button";
    ComponentType["group"] = "group";
    ComponentType["separator"] = "separator";
    ComponentType["video"] = "video";
    ComponentType["puzzle_password"] = "puzzle_password";
})(ComponentType || (ComponentType = {}));
export var ThumbnailSize;
(function (ThumbnailSize) {
    ThumbnailSize["small"] = "small";
    ThumbnailSize["large"] = "large";
})(ThumbnailSize || (ThumbnailSize = {}));
export var ThumbnailDetails;
(function (ThumbnailDetails) {
    ThumbnailDetails["imageOnly"] = "image_only";
    ThumbnailDetails["title"] = "title";
    ThumbnailDetails["titleAndPrice"] = "title_and_price";
})(ThumbnailDetails || (ThumbnailDetails = {}));
export var SeparatorSize;
(function (SeparatorSize) {
    SeparatorSize["short"] = "short";
    SeparatorSize["tall"] = "tall";
})(SeparatorSize || (SeparatorSize = {}));
// ts-prune-ignore-next
export var ImageSize;
(function (ImageSize) {
    ImageSize["tall"] = "tall";
    ImageSize["short"] = "short";
})(ImageSize || (ImageSize = {}));
export var CarouselProductType;
(function (CarouselProductType) {
    CarouselProductType["digital"] = "digital";
    CarouselProductType["carbonwear"] = "carbonwear";
    CarouselProductType["glyph"] = "glyph";
})(CarouselProductType || (CarouselProductType = {}));
