/* eslint-disable func-names */
import { flow, getEnv, types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
const ENABLED_TUTORIAL_KEY = 'ENABLED_TUTORIAL';
const SHOWN_SPECIFIC_POPUP_KEY = 'SHOWN_SPECIFIC_TUTORIAL_POPUPS';
const TutorialStore = types
    .model(Nodes.TutorialStore, {
    enabledTutorial: types.boolean,
    shownSpecificPopups: types.array(types.string),
})
    .actions((self) => {
    const storage = getEnv(self).storage;
    return {
        setValue: storage.setValue,
        getValues: storage.getValues,
    };
})
    .actions((self) => ({
    load: flow(function* () {
        let readEnabledTutorial = true;
        let readShownSpecificPopups = [];
        yield self.getValues([ENABLED_TUTORIAL_KEY, SHOWN_SPECIFIC_POPUP_KEY], (error, result) => {
            if (error || !result) {
                console.error(error);
                return;
            }
            result.forEach(([key, value]) => {
                if (value === null) {
                    return;
                }
                if (key === ENABLED_TUTORIAL_KEY) {
                    readEnabledTutorial = value === 'true';
                }
                else if (key === SHOWN_SPECIFIC_POPUP_KEY) {
                    readShownSpecificPopups = JSON.parse(value);
                }
            });
        });
        self.enabledTutorial = readEnabledTutorial;
        readShownSpecificPopups.forEach((value) => {
            self.shownSpecificPopups.push(value);
        });
    }),
    disableTutorial() {
        self.enabledTutorial = false;
        self.setValue(ENABLED_TUTORIAL_KEY, self.enabledTutorial.valueOf.toString());
    },
    setShownSpecificPopup(popupName) {
        if (!self.shownSpecificPopups.find((value) => value === popupName)) {
            self.shownSpecificPopups.push(popupName);
            self.setValue(SHOWN_SPECIFIC_POPUP_KEY, JSON.stringify(self.shownSpecificPopups));
        }
    },
    clear() {
        self.setValue(ENABLED_TUTORIAL_KEY, 'true');
        self.setValue(SHOWN_SPECIFIC_POPUP_KEY, '[]');
    },
}))
    .views((self) => ({
    wasPopupShown(popupName) {
        return (self.shownSpecificPopups.find((value) => value === popupName) !==
            undefined);
    },
}));
export default TutorialStore;
