export var Nodes;
(function (Nodes) {
    Nodes["RootStore"] = "RootStore";
    Nodes["GarmentStore"] = "GarmentStore";
    Nodes["Garment"] = "Garment";
    Nodes["UserStore"] = "UserStore";
    Nodes["StickerStore"] = "StickerStore";
    Nodes["WardrobeStore"] = "WardrobeStore";
    Nodes["Sticker"] = "Sticker";
    Nodes["StickerUniverse"] = "StickerUniverse";
    Nodes["ImageStyle"] = "ImageStyle";
    Nodes["ImageStyleVersion"] = "ImageStyleVersion";
    Nodes["GarmentImageOrientation"] = "GarmentImageOrientation";
    Nodes["CurrentlyEditedSet"] = "CurrentlyEditedSet";
    Nodes["StickerPosition"] = "StickerPosition";
    Nodes["StickerPositionName"] = "StickerPositionName";
    Nodes["SelectedSticker"] = "SelectedSticker";
    Nodes["Address"] = "Address";
    Nodes["CheckoutStore"] = "CheckoutStore";
    Nodes["PaymentMethod"] = "PaymentMethod";
    Nodes["ShippingRate"] = "ShippingRate";
    Nodes["SpreeAuth"] = "SpreeAuth";
    Nodes["Drop"] = "Drop";
    Nodes["DropsStore"] = "DropsStore";
    Nodes["Prize"] = "Prize";
    Nodes["Country"] = "Country";
    Nodes["Experience"] = "Experience";
    Nodes["TutorialStore"] = "TutorialStore";
    Nodes["ScenesStore"] = "ScenesStore";
    Nodes["NotificationStore"] = "NotificationStore";
    Nodes["BannerMessage"] = "BannerMessage";
    Nodes["GlassModalMessage"] = "GlassModalMessage";
    Nodes["OrderStore"] = "OrderStore";
    Nodes["OrderState"] = "OrderState";
    Nodes["PaymentState"] = "PaymentState";
    Nodes["Shipment"] = "Shipment";
    Nodes["Package"] = "Package";
    Nodes["TokenItem"] = "TokenItem";
    Nodes["PopupData"] = "PopupData";
    Nodes["PopupAttr"] = "PopupAttr";
    Nodes["PopupInfo"] = "PopupInfo";
    Nodes["PopupButton"] = "PopupButton";
    Nodes["EthereumStore"] = "EthereumStore";
    Nodes["UserSettings"] = "UserSettings";
})(Nodes || (Nodes = {}));
