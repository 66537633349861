export var IconSet;
(function (IconSet) {
    IconSet["AntDesign"] = "AntDesign";
    IconSet["MaterialIcons"] = "MaterialIcons";
    IconSet["MaterialCommunityIcons"] = "MaterialCommunityIcons";
    IconSet["FontAwesome5"] = "FontAwesome5";
    IconSet["RstlssIcons"] = "RstlssIcons";
    IconSet["Octicons"] = "Octicons";
    IconSet["Feather"] = "Feather";
    IconSet["Ionicons"] = "Ionicons";
})(IconSet || (IconSet = {}));
