import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Analytics,
  getAnalytics,
  logEvent as firebaseLogEvent,
} from 'firebase/analytics';
import useStore from 'app/common/useStore';
import {
  AnalyticsEventName,
  AnalyticsEventParams,
  PurchaseEventParams,
} from 'app/common/analytics/types';

const firebaseConfig = {
  apiKey: 'AIzaSyA9B1I3qQ1JbBqGLdO0F8Dcf7gSWPZ9Ebc',
  authDomain: 'rstlss-swm.firebaseapp.com',
  projectId: 'rstlss-swm',
  storageBucket: 'rstlss-swm.appspot.com',
  messagingSenderId: '5861137211',
  appId: '1:5861137211:web:00b826da05e3a89654f51e',
  measurementId: 'G-X2NJ9S182L',
};

const ANALYTICS_ENABLED = process.env.REACT_APP_ENV !== 'development';

let firebaseApp: FirebaseApp;
let analytics: Analytics;

if (ANALYTICS_ENABLED) {
  firebaseApp = initializeApp(firebaseConfig);
  analytics = getAnalytics(firebaseApp);
}

const logScreenChange = (screenName: string) => {
  firebaseLogEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
    firebase_screen_class: screenName,
  });
};

const logPurchase = (params: PurchaseEventParams) => {
  firebaseLogEvent(analytics, 'purchase', params);
};

const emptyFunc = () => {};

interface UseAnalytics {
  logEvent: (event: AnalyticsEventName, params?: AnalyticsEventParams) => void;
  logScreenChange: (screenName: string) => void;
  logPurchase: (params: PurchaseEventParams) => void;
}

export function useAnalytics(): UseAnalytics {
  const {
    userStore: { id: userId },
    checkoutStore: { orderNumber },
  } = useStore();

  if (!ANALYTICS_ENABLED) {
    return {
      logEvent: emptyFunc,
      logScreenChange: emptyFunc,
      logPurchase: emptyFunc,
    };
  }

  const logEvent = (
    event: AnalyticsEventName,
    params?: AnalyticsEventParams,
  ) => {
    firebaseLogEvent(analytics, event.toString(), {
      userId: userId || orderNumber,
      ...params,
    });
  };

  return { logEvent, logScreenChange, logPurchase };
}
