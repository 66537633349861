import { types } from 'mobx-state-tree';
import { Nodes } from 'common/state/nodes';
import { ImageStyleVersion, StickerPosition } from 'common/api/types';
export const ImageStyle = types
    .model(Nodes.ImageStyle, {
    url: types.string,
    width: types.number,
    height: types.number,
    version: types.enumeration(Nodes.ImageStyleVersion, Object.values(ImageStyleVersion)),
    stickerPositions: types.array(StickerPosition),
})
    .views((self) => ({
    getStickerPositionData: (p) => {
        const position = self.stickerPositions.find((j) => j.name === p);
        if (position) {
            return {
                leftX: position.margins.get('leftX'),
                topY: position.margins.get('topY'),
                width: position.margins.get('width'),
                height: position.margins.get('height'),
                perspective: position.margins.get('perspective'),
                rotateX: position.margins.get('rotateX'),
                rotateY: position.margins.get('rotateY'),
                rotateZ: position.margins.get('rotateZ'),
            };
        }
        return null;
    },
}));
