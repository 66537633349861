import Modal from 'react-modal';
import {
  forwardRef,
  memo,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';
import WireButton from './WireButton';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BsXLg } from 'react-icons/bs';

export interface Handle {
  show: () => void;
}

interface Props {
  title: string;
  okText?: string;
  okButtonDisabled?: boolean;
  children: ReactNode;
  kind: 'error' | 'info';
  onOk?: () => Promise<void>;
  onClose?: () => void;
  showIcon?: boolean;
  loading?: boolean;
}

const RModal = forwardRef<Handle, Props>(
  (
    {
      title,
      okText = 'Understood',
      okButtonDisabled = false,
      kind,
      onOk,
      onClose,
      showIcon = true,
      loading = false,
      children,
    }: Props,
    ref,
  ) => {
    const [visible, setVisible] = useState(false);

    const hide = () => setVisible(false);
    const show = () => setVisible(true);

    useImperativeHandle(ref, () => ({ show }));

    const onWireButtonClick = async () => {
      await onOk?.();
      hide();
    };

    const onCloseClick = () => {
      onClose?.();
      hide();
    };

    return (
      <Modal
        onRequestClose={onCloseClick}
        overlayClassName="modal-bg"
        className="absolute inset-4"
        isOpen={visible}
        contentLabel="Modal"
      >
        <div className="bg-coldGrey-200 rounded-2xl px-4 py-6 md:px-8 md:py-12 modal max-w-[732px] flex flex-col flex-1 items-center text-center border">
          <button onClick={onCloseClick} className="absolute top-6 right-6">
            <BsXLg size="16" />
          </button>
          {showIcon &&
            (kind === 'error' ? (
              <div className="bg-red-400 rounded-full p-7 hidden tall:block">
                <RiErrorWarningLine className=" text-red-900" size="37" />
              </div>
            ) : (
              <div className="bg-primaryBlue-200 rounded-full p-7 hidden tall:block">
                <RiErrorWarningLine className=" text-turquoise-800" size="37" />
              </div>
            ))}
          <h2 className="text-xl md:text-2xl mx-8 my-6 uppercase">{title}</h2>
          <div className="mb-8 md:mb-16 md:mx-8 text-left md:text-center">
            {children}
          </div>

          <WireButton
            className="min-w-[192px] max-w-[400px]"
            theme="dark"
            onClick={onWireButtonClick}
            loading={loading}
            disabled={okButtonDisabled}
          >
            {okText}
          </WireButton>
        </div>
      </Modal>
    );
  },
);

export default memo(RModal);
