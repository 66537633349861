import createAxiosFetcher from 'common/api/createAxiosFetcher';
import { errors } from '@spree/storefront-api-v2-sdk';
import { getEnv } from 'mobx-state-tree';
import { sleep } from 'common/utils/utils';
const isFetchError = (error) => error instanceof errors.FetchError;
const isFetchErrorWithResponse = (error) => isFetchError(error) && error.response;
const isFetchErrorWithoutResponse = (error) => isFetchError(error) && !error.response;
const isMaintenanceError = (error) => {
    if (!isFetchErrorWithResponse(error)) {
        return false;
    }
    const response = error.response;
    return response.status === 503 && response.data.error === 'In maintenance';
};
const isAccessTokenExpiredError = (error) => {
    if (!isFetchErrorWithResponse(error)) {
        return false;
    }
    const response = error.response;
    return (response.status === 401 &&
        response.data.error === 'The access token expired');
};
const createRstlssFetcher = (onMaintenance, noResponseCallback, userStore) => (fetcherOptions) => {
    const { fetch: originalFetch } = createAxiosFetcher(fetcherOptions);
    const fetch = async (options) => {
        try {
            return await originalFetch(options);
        }
        catch (error) {
            if (isFetchErrorWithoutResponse(error)) {
                const sentry = getEnv(userStore).sentry;
                sentry.captureException(error);
                for (let retriesNum = 2; retriesNum > 0; retriesNum -= 1) {
                    try {
                        await sleep(200);
                        return await originalFetch(options);
                    }
                    catch (retryError) {
                        if (!isFetchErrorWithoutResponse(retryError)) {
                            throw retryError;
                        }
                    }
                }
                noResponseCallback();
                throw new Error('No response');
            }
            else if (isMaintenanceError(error)) {
                onMaintenance();
                throw new Error('In maintenance');
            }
            if (isAccessTokenExpiredError(error) &&
                userStore.spreeAuth.refreshToken) {
                await userStore.refreshToken();
                options.headers['Authorization'] = `Bearer ${userStore.spreeAuth.bearerToken}`;
                return await fetch(options);
            }
            throw error;
        }
    };
    return {
        fetch,
    };
};
export default createRstlssFetcher;
