import { captureMessage } from '@sentry/core';
import { isEmpty } from 'lodash';
const PUBLIC_ALLOWLIST_TYPEDATA = '0x0000000000000000000000000000000000000000000000000000000000000000';
// Can be caught and displayed to a user to indicate what went wrong with their mint attempt.
// Only for checks done before sending anything to metamask, e.g. stock and allowlist checks.
class ContractCheckError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ContractCheckError';
    }
}
// fetches any allowlist metadata
// returns null if not allowlist active
async function fetchAllowlist(contract, id) {
    const _data = await contract.methods.getAllowlistById(id).call();
    const data = {
        typedata: _data[0],
        isActive: _data[1],
        metadataUri: _data[2],
        name: _data[3],
        price: _data[4],
        maxMintPerWallet: _data[5],
        tokenPool: _data[6],
        startTime: _data[7],
        endTime: _data[8],
        maxSupply: _data[9],
    };
    const ipfsGatewayUrl = data.metadataUri.replace('ipfs://', 'https://nftstorage.link/ipfs/');
    const isPublic = data.typedata === PUBLIC_ALLOWLIST_TYPEDATA;
    if (isPublic) {
        return {
            id,
            data,
            content: undefined,
        };
    }
    const r = await fetch(ipfsGatewayUrl);
    const content = await r.json();
    return {
        id,
        data,
        content: {
            name: content.name,
            merkleTree: content.merkleTree.map(([address, quantity]) => ({
                address: address.toLowerCase(),
                quantity,
            })),
        },
    };
}
export async function fetchAllAllowlists(contract) {
    const allowlistRange = await contract.methods.allowlists().call();
    const allowlistIds = [...Array(parseInt(allowlistRange.count)).keys()];
    const currentStartId = parseInt(allowlistRange.currentStartId);
    const allowlists = await Promise.all(allowlistIds.map((id) => fetchAllowlist(contract, currentStartId + id)));
    return allowlists;
}
// helpers
function isAllowlistPublic(allowlist) {
    return allowlist.data.typedata === PUBLIC_ALLOWLIST_TYPEDATA;
}
function findPublicAllowlist(allowlists) {
    return allowlists.find((x) => isAllowlistPublic(x));
}
function filterPrivateAllowlist(allowlists) {
    return allowlists.filter((x) => !isAllowlistPublic(x));
}
function filterActive(allowlists) {
    return allowlists.filter((x) => {
        const now = Math.round(Date.now() / 1000);
        const outOfTimeBounds = parseInt(x.data.startTime) > now || now > parseInt(x.data.endTime);
        return !outOfTimeBounds && x.data.isActive;
    });
}
function filterAllowed(allowlists, address) {
    console.info('filterAllowed', address);
    return allowlists
        .map((x) => {
        if (isAllowlistPublic(x)) {
            console.info('public', x.id);
            // public is always allowed
            return x;
        }
        const findResult = x.content.merkleTree?.find((entry) => entry.address.toLowerCase() == address.toLowerCase());
        if (findResult !== undefined) {
            console.info('included', x.id, JSON.stringify(findResult));
            return x;
        }
        else {
            console.info('not included', x.id);
            return null;
        }
    })
        .filter((x) => x !== null);
}
// important logic in the functions below
// TODO frog drop
// this could be still smarter in how this picks allowlists
// e.g. split 2 mints across 2 allowlists.
// and merge this with mergeConfigs
export function selectPreferredAllowlists(allowlists, allowlistUsage, quantities, address) {
    console.info('selectPreferredAllowlists:', address);
    // from *active* allowlists, select the one with max id
    console.info('allowlists total:', allowlists.length);
    const possibleAllowlists = filterAllowed(filterActive(allowlists), address);
    console.info('allowlists possible:', possibleAllowlists.length);
    const selectedAllowlists = [];
    // Limit per allowlist, e.g. 4 mints out of 6 allowed is [4,6]
    // When it starts out, first number is just the number fetched from the blockchain.
    // It's incremented during assignment later in this function.
    const limits = possibleAllowlists.map((x) => {
        // There should be 1 allowlistUsage per allowlist,
        // but this is more general e.g. handles the case where we failed to fetch it
        const usedArray = allowlistUsage
            .filter((usage) => usage.allowlistId == x.id)
            .map((x) => x.used);
        if (isEmpty(usedArray)) {
            console.error('Allowlist mint count was not found');
            captureMessage('Allowlist mint count was not found', {
                extra: { allowlistId: x.id, address },
            });
        }
        const used = isEmpty(usedArray) ? 0 : usedArray.reduce((a, b) => a + b);
        return { id: x.id, used, max: parseInt(x.data.maxMintPerWallet) };
    });
    quantities.forEach((quantity) => {
        let maxIdOfPossibleAllowlists = null;
        limits.forEach((limit) => {
            // if this quantity fits within the limit of this allowlist
            if (limit.used + quantity <= limit.max) {
                if (maxIdOfPossibleAllowlists === null) {
                    maxIdOfPossibleAllowlists = limit.id;
                }
                else {
                    maxIdOfPossibleAllowlists = Math.max(limit.id, maxIdOfPossibleAllowlists);
                }
            }
        });
        if (maxIdOfPossibleAllowlists == null) {
            throw new ContractCheckError('Failed to find allowlists with your address and sufficient allowances');
        }
        const allowlist = allowlists.find((x) => x.id === maxIdOfPossibleAllowlists);
        if (allowlist === undefined) {
            throw new ContractCheckError('Failed to find allowlists with your address and sufficient allowances');
        }
        selectedAllowlists.push(allowlist);
    });
    return selectedAllowlists;
}
export function getAllowlistUserStatus(allowlists, address) {
    // if no active allowlists, not started
    const active = filterActive(allowlists);
    if (isEmpty(active)) {
        return 'not-started';
    }
    // some allowlists started, user not connected
    const activePublicAllowlist = findPublicAllowlist(active);
    if (!!activePublicAllowlist) {
        // public allowlist started
        return 'allowed';
    }
    // user not connected
    if (address === undefined) {
        return 'not-allowed';
    }
    const activePrivateAllowlists = filterPrivateAllowlist(active);
    // some allowlists started, user connected, not allowed
    const allowed = filterAllowed(activePrivateAllowlists, address);
    if (isEmpty(allowed)) {
        return 'not-allowed';
    }
    // some allowlists started, user connected, allowed
    return 'allowed';
}
