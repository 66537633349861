/* eslint-disable func-names */
import { PhysicalSpaceTypes, GarmentType, } from 'common/api/types';
import { Nodes } from 'common/state/nodes';
import { applySnapshot, flow, getSnapshot, types, } from 'mobx-state-tree';
import { createSceneAsync, deleteSceneAsync, saveChangesSceneAsync, cancelChangesSceneAsync, addItemToSceneAsync, removeItemFromSceneAsync, updateItemsInSceneAsync, isExpandedSpreeError, isSpreeFieldErrors, } from 'common/api/spree';
import { SceneStatus } from 'common/api/spreeClient/scenes';
import { Currency } from 'common/state/models/Currency';
import { sumPrices } from 'common/state/models/Price';
import { MessageTypes } from 'common/state/models/BannerMessage';
import { DROP_ERROR_INFO } from 'common/texts';
import StringSet from 'common/state/models/StringSet';
import { SelectedSticker } from 'common/state/models/SelectedSticker';
import { Garment, } from 'common/state/models/Garment';
import { Sticker } from 'common/state/models/Sticker';
import { getRootStore } from 'common/state/RootStore';
import { Mutex } from 'async-mutex';
function isPhysicalItemsSpace({ slug }) {
    return (slug === PhysicalSpaceTypes.PhysicalItem ||
        slug === PhysicalSpaceTypes.ARItem);
}
const PositionedStickers = types.map(SelectedSticker);
function stickersToUpdateItems(selectedStickers, allSelectedStickers) {
    const updateItems = [];
    allSelectedStickers.forEach(({ stickerId, positionId }) => {
        const positionItem = selectedStickers.get(positionId);
        const addItem = positionItem && positionItem.stickerId === stickerId;
        updateItems.push({
            variant_id: stickerId,
            quantity: 1,
            remove: !addItem,
        });
    });
    return updateItems;
}
/**
 * Store for currently customized/edited garment set
 */
const Scene = types
    .model(Nodes.CurrentlyEditedSet, {
    id: types.maybe(types.string),
    garmentId: types.maybe(types.string),
    variantId: types.maybe(types.string),
    physicalItemSize: types.maybe(types.string),
    allSelectedStickers: PositionedStickers,
    selectedCurrency: Currency,
    selectedSpaces: types.map(types.boolean),
    selectedStickers: PositionedStickers,
    status: types.enumeration('status', Object.values(SceneStatus)),
    purchasedItems: types.array(types.string),
    garmentData: types.maybeNull(Garment),
    privateStickers: types.map(Sticker),
    glyphErrors: types.map(StringSet),
})
    .views((self) => ({
    getGarmentData: () => {
        if (self.garmentData && self.garmentData.id === self.garmentId) {
            return self.garmentData;
        }
        const { garmentStore } = getRootStore(self);
        return garmentStore.garmentById(self.garmentId);
    },
}))
    .views((self) => ({
    get spaces() {
        const garment = self.getGarmentData();
        const uniqueSpaces = {}; /// FIXME: Quick and dirty workaround, because garmentData has spaces repeated in includes.
        garment.spaces.forEach((space) => (uniqueSpaces[space.variant_id] = space));
        return garment ? Array.from(Object.values(uniqueSpaces)) : [];
    },
    isSpacePurchased: (space) => {
        return self.purchasedItems.some((variant_id) => space.variant_id === variant_id);
    },
}))
    .actions((self) => {
    return {
        handleDropError: (errorMessage, stickerId) => {
            const { notificationStore: { pushNotification }, fetchGarmentsAndStickers, stickerStore, } = getRootStore(self);
            switch (errorMessage) {
                case 'has no more of this item':
                    if (stickerStore
                        .glyphsWithSameProductId(stickerId)
                        .some((glyph) => glyph.isUsedOnPendingCreations)) {
                        pushNotification(MessageTypes.GlyphAlreadyUsed, DROP_ERROR_INFO.removeGlyphFromDraft);
                    }
                    else {
                        pushNotification(MessageTypes.UnavailableGlyph, DROP_ERROR_INFO.glyphUnavailable);
                        fetchGarmentsAndStickers();
                    }
                    break;
                case 'is not active':
                    pushNotification(MessageTypes.DropExpired, DROP_ERROR_INFO.dropExpired);
                    break;
                default:
                    pushNotification(MessageTypes.MessageError, 'Something went wrong. If the issue persists, please contact the support');
            }
        },
    };
})
    .actions((self) => {
    let initialState = {};
    return {
        afterCreate: () => {
            initialState = getSnapshot(self);
        },
        reset: () => {
            applySnapshot(self, initialState);
        },
        createScene: flow(function* () {
            const { userStore } = getRootStore(self);
            const sceneId = yield createSceneAsync(userStore.spreeAuth, {
                master_variant_id: self.variantId,
            });
            // TODO: add garment background (not as product)
            self.id = sceneId;
            return self.id;
        }),
        addErrorToGlyph: (stickerId, glyphError) => {
            if (!self.glyphErrors.has(stickerId)) {
                self.glyphErrors.set(stickerId, StringSet.create());
            }
            const currentError = self.glyphErrors.get(stickerId);
            glyphError.forEach((gError) => {
                currentError.push(gError);
            });
        },
        handleError: (error, stickerId) => {
            const dropErrors = error.getErrors(['drop']);
            if (dropErrors && stickerId) {
                if (!isSpreeFieldErrors(dropErrors))
                    return;
                const errorMessage = dropErrors[0];
                self.handleDropError(errorMessage, stickerId);
            }
        },
    };
})
    .actions((self) => {
    const mutex = new Mutex();
    return {
        deleteScene: flow(function* (spreeAuth) {
            if (self.id) {
                yield deleteSceneAsync(spreeAuth, self.id);
            }
        }),
        setStatus: (status) => {
            self.status = status;
        },
        updateLineItems: (lineItems) => {
            const { checkoutStore } = getRootStore(self);
            if (self.status === SceneStatus.cart) {
                checkoutStore.updateLineItems(self.id, lineItems);
            }
        },
        ensureId: flow(function* () {
            yield mutex.runExclusive(async () => {
                if (!self.id) {
                    await self.createScene();
                }
            });
            return self.id;
        }),
    };
})
    .actions((self) => {
    return {
        loadSnapshot: (scene) => {
            applySnapshot(self, scene);
        },
        saveScene: flow(function* () {
            const { userStore } = getRootStore(self);
            yield self.ensureId();
            yield saveChangesSceneAsync(userStore.spreeAuth, self.id);
        }),
        cancelChangesScene: flow(function* () {
            const { userStore } = getRootStore(self);
            yield self.ensureId();
            yield cancelChangesSceneAsync(userStore.spreeAuth, self.id);
        }),
        saveVariants: flow(function* (addVariantIds, removeVariantIds = []) {
            const { userStore } = getRootStore(self);
            const addUpdateItems = addVariantIds.map((variant_id) => ({
                variant_id,
                quantity: 1,
                remove: false,
            }));
            const removeUpdateItems = removeVariantIds.map((variant_id) => ({
                variant_id,
                quantity: 1,
                remove: true,
            }));
            const updateItems = [...addUpdateItems, ...removeUpdateItems];
            yield self.ensureId();
            yield updateItemsInSceneAsync(userStore.spreeAuth, self.id, updateItems);
            self.updateLineItems(updateItems);
        }),
        saveVariant: flow(function* (variantId) {
            const { userStore } = getRootStore(self);
            yield self.ensureId();
            yield addItemToSceneAsync(userStore.spreeAuth, self.id, variantId);
            self.updateLineItems([{ variant_id: variantId, remove: false }]);
        }),
        removeVariant: flow(function* (variantId) {
            const { userStore } = getRootStore(self);
            yield self.ensureId();
            removeItemFromSceneAsync(userStore.spreeAuth, self.id, variantId);
            self.updateLineItems([{ variant_id: variantId, remove: true }]);
        }),
        savePhysicalItemScreenState: (selectedSpaces, physicalItemSize, physicalItemSizeVariantId) => {
            Object.entries(selectedSpaces).forEach((entry) => {
                const [spaceSlug, val] = entry;
                if (val !== undefined) {
                    self.spaces
                        .filter(({ slug }) => slug === spaceSlug)
                        .forEach((space) => {
                        let isSelected = val;
                        if (space.slug === PhysicalSpaceTypes.PhysicalItem &&
                            physicalItemSizeVariantId) {
                            isSelected = space.variant_id === physicalItemSizeVariantId;
                        }
                        self.selectedSpaces.set(space.variant_id, isSelected);
                    });
                }
            });
            self.physicalItemSize = physicalItemSize;
        },
        saveSpacesState: flow(function* (selectedSpaces) {
            const { userStore } = getRootStore(self);
            const updateItems = [];
            Object.entries(selectedSpaces).forEach((entry) => {
                const [spaceSlug, addItem] = entry;
                if (addItem !== undefined) {
                    const space = self.spaces.find((s) => s.slug === spaceSlug);
                    if (space) {
                        self.selectedSpaces.set(space.variant_id, addItem);
                        updateItems.push({
                            variant_id: space.variant_id,
                            quantity: 1,
                            remove: !addItem,
                        });
                    }
                }
            });
            yield self.ensureId();
            self.updateLineItems(updateItems);
            return updateItemsInSceneAsync(userStore.spreeAuth, self.id, updateItems);
        }),
        saveStickers: flow(function* () {
            const { userStore } = getRootStore(self);
            const updateItems = stickersToUpdateItems(self.selectedStickers, self.allSelectedStickers);
            if (updateItems.length === 0) {
                return;
            }
            try {
                yield self.ensureId();
                yield updateItemsInSceneAsync(userStore.spreeAuth, self.id, updateItems);
                self.updateLineItems(updateItems);
            }
            catch (err) {
                throw err;
            }
        }),
    };
})
    .views((self) => ({
    isStickerSelected: (positionId, stickerId) => {
        return self.selectedStickers.get(positionId)?.stickerId === stickerId;
    },
    isSpaceSelected: (spaceSlug) => {
        return !!self.spaces.find(({ variant_id, slug }) => {
            if (slug === spaceSlug) {
                if (self.selectedSpaces.get(variant_id)) {
                    return true;
                }
            }
            return false;
        });
    },
    get selectedUniverses() {
        const universes = new Set();
        self.selectedStickers.forEach((item) => {
            universes.add(item.universeId);
        });
        return universes;
    },
    get selectedStickersList() {
        const stickers = [];
        const { stickerStore } = getRootStore(self);
        self.selectedStickers.forEach((item) => {
            const { price, name, imageUrl, productId, universeId, state } = self.privateStickers.get(item.stickerId) ||
                stickerStore.getStickerWithId(item.stickerId);
            stickers.push({
                position: item.positionId,
                stickerId: item.stickerId,
                imageUrl: imageUrl,
                price,
                name,
                productId,
                universeId,
                state,
            });
        });
        return stickers;
    },
    get selectedSpacesList() {
        return self.spaces.filter(({ variant_id }) => self.selectedSpaces.get(variant_id));
    },
}))
    .views((self) => ({
    get errors() {
        let err = [];
        self.glyphErrors.forEach((error, errorStickerId) => {
            if (self.selectedStickersList.some(({ stickerId }) => errorStickerId === stickerId)) {
                err = err.concat(Array.from(error.all));
            }
        });
        return err;
    },
    get selectedStickersDescription() {
        const isError = (stickerId) => self.glyphErrors.has(stickerId) &&
            !self.glyphErrors.get(stickerId).isEmpty;
        return self.selectedStickersList.map(({ name, price, position, stickerId }) => ({
            name,
            itemInfo: position,
            variantId: stickerId,
            prices: price,
            key: `${position}_${name}`,
            isError: isError(stickerId),
        }));
    },
    get physicalItemSectionDescription() {
        const spacesList = self.selectedSpacesList.filter(isPhysicalItemsSpace);
        return spacesList.map(({ name, price, variant_id }) => ({
            name: name === 'Physical' && self.physicalItemSize
                ? `Size ${self.physicalItemSize}`
                : name,
            variantId: variant_id,
            prices: price,
        }));
    },
    get spacesPrice() {
        return sumPrices(self.selectedSpacesList
            .filter((space) => !isPhysicalItemsSpace(space))
            .map(({ price }) => price));
    },
    get spacesTotalPrice() {
        return sumPrices(self.selectedSpacesList.map(({ price }) => price));
    },
    get stickersTotalPrice() {
        return sumPrices(self.selectedStickersList.map(({ price }) => price));
    },
    get selectedItemsList() {
        const selectedStickers = self.selectedStickersList.map((attrs) => ({
            ...attrs,
            variant_id: attrs.stickerId,
        }));
        return [...selectedStickers, ...self.selectedSpacesList];
    },
}))
    .views((self) => ({
    get totalPrice() {
        return sumPrices([self.spacesTotalPrice, self.stickersTotalPrice]);
    },
    get generalSpaces() {
        return self.spaces.filter((s) => !isPhysicalItemsSpace(s));
    },
    get physicalItemSpaces() {
        return self.spaces.filter(isPhysicalItemsSpace);
    },
    get size() {
        return self.selectedSpaces.size + self.selectedStickers.size;
    },
    get spacesDescription() {
        const spacesList = self.selectedSpacesList.filter((space) => {
            return !isPhysicalItemsSpace(space);
        });
        return spacesList.map(({ name, price, variant_id }) => ({
            name,
            variantId: variant_id,
            prices: price,
        }));
    },
    get checkoutDescription() {
        const { garmentType } = self.getGarmentData();
        const sizeString = self.physicalItemSize &&
            (garmentType === GarmentType.Customizable
                ? `Carbonwear (Size ${self.physicalItemSize})`
                : `Size ${self.physicalItemSize}`);
        const graphicsString = self.selectedStickers.size > 0 &&
            `${self.selectedStickers.size} ${self.selectedStickers.size > 1 ? 'Glyphs' : 'Glyph'}`;
        const spacesList = self.selectedSpacesList.filter((space) => {
            return !isPhysicalItemsSpace(space);
        });
        const spacesString = spacesList.length > 0 &&
            `${spacesList.length} Digital Utilit${spacesList.length > 1 ? 'ies' : 'y'}`;
        const graphicsAndSpaces = [graphicsString, spacesString]
            .filter((i) => i)
            .join(' • ');
        return [graphicsAndSpaces, sizeString].filter((i) => i).join('\n');
    },
    checkoutWebDescription(sceneId, garment, lineItems) {
        const repeatedPurchase = self.purchasedItems.length > 0;
        const lastLine = repeatedPurchase ? 'Repeated purchase' : '';
        const inLineItems = (space) => undefined !==
            lineItems.find((lineItem) => lineItem.sceneId === sceneId &&
                space.variant_id === lineItem.variantId);
        if (garment.garmentType === GarmentType.Customizable) {
            const stickers = Array.from(self.selectedStickers.values()).filter((x) => lineItems.find((y) => y.variantId === x.stickerId));
            let secondLine = `${stickers.length} Glyph${stickers.length > 1 ? 's' : ''}`;
            const spacesList = self.selectedSpacesList.filter((space) => !isPhysicalItemsSpace(space) && inLineItems(space));
            const includesCarbonwear = self.selectedSpacesList.filter((space) => isPhysicalItemsSpace(space) && inLineItems(space));
            if (spacesList.length > 0) {
                const suffix = spacesList.length > 1 ? 'ies' : 'y';
                secondLine =
                    secondLine + ` • ${spacesList.length} Digital Utilit${suffix}`;
            }
            let thirdLine = includesCarbonwear.length > 0
                ? `Carbonwear (Size ${self.physicalItemSize})`
                : '';
            return ['Digital Creation (NFT)', secondLine, thirdLine, lastLine];
        }
        else {
            let secondLine = `Size ${self.physicalItemSize}`;
            const arEffect = self.selectedSpacesList.find((space) => space.slug === 'ar-effect' && inLineItems(space));
            if (arEffect) {
                secondLine = secondLine + ' • AR Effect';
            }
            return ['Physical item', secondLine, lastLine];
        }
    },
}))
    .actions((self) => {
    return {
        duplicateScene: flow(function* () {
            yield self.createScene();
            self.status = SceneStatus.draft;
            if (self.selectedSpacesList.length > 0 ||
                self.selectedStickers.size > 0) {
                const variantIds = self.selectedSpacesList.map(({ variant_id }) => variant_id);
                const stickerVariantIds = self.selectedStickersList.map(({ stickerId }) => stickerId);
                yield self.saveVariants([...variantIds, ...stickerVariantIds]);
            }
        }),
        changeCurrentGarment: (variantId, garmentId) => {
            self.reset();
            self.variantId = variantId;
            self.garmentId = garmentId;
        },
        addStickerToSelected: flow(function* (positionId, stickerId, universeId) {
            const sticker = {
                positionId,
                stickerId,
                universeId,
            };
            self.selectedStickers.put(sticker);
            self.allSelectedStickers.put(sticker);
            try {
                yield self.saveStickers();
            }
            catch (err) {
                self.selectedStickers.delete(positionId);
                self.allSelectedStickers.delete(positionId);
                if (isExpandedSpreeError(err)) {
                    self.handleError(err, stickerId);
                }
            }
        }),
        clearPosition: flow(function* (positionId) {
            try {
                self.selectedStickers.delete(positionId);
                yield self.saveStickers();
            }
            catch (err) {
                if (isExpandedSpreeError(err)) {
                    self.handleError(err);
                }
            }
        }),
        clearStickers: flow(function* () {
            try {
                self.selectedStickers.clear();
                yield self.saveStickers();
            }
            catch (err) {
                if (isExpandedSpreeError(err)) {
                    self.handleError(err);
                }
            }
        }),
    };
})
    .views((self) => {
    return {
        isCreationUpdate() {
            // Creation Update - updating of the already bought Digital Creation (NFT)
            return (self.status === SceneStatus.cart && self.purchasedItems.length > 0);
        },
        get isLocked() {
            const { stickerStore } = getRootStore(self);
            return (self.errors.length > 0 ||
                Array.from(self.selectedStickers.values()).some(({ stickerId }) => stickerStore.getStickerWithId(stickerId)?.isLocked));
        },
    };
})
    .views((self) => {
    return {
        get checkoutItemType() {
            const { garmentType } = self.getGarmentData();
            if (garmentType === GarmentType.Customizable) {
                return self.isCreationUpdate()
                    ? 'Creation Update'
                    : 'Digital Creation (NFT)';
            }
            return 'Physical item';
        },
        get garmentSelectionListPosition() {
            const { garmentType } = self.getGarmentData();
            const { garmentStore: { garmentsList }, } = getRootStore(self);
            const garments = garmentsList({
                garmentType,
            });
            const garmentPosition = garments.findIndex(({ id }) => id === self.garmentId);
            return garmentPosition !== -1 ? garmentPosition + 1 : garmentPosition;
        },
        getAvailableStickerIds(positionId, universeId) {
            const { stickerStore: { getStickersWithUniverseId }, } = getRootStore(self);
            const specialStickersIds = getStickersWithUniverseId(universeId, positionId, true);
            const standardStickersIds = getStickersWithUniverseId(universeId, positionId);
            return [specialStickersIds, standardStickersIds].flat();
        },
        containsGlyph: (glyphId) => {
            return Array.from(self.selectedStickers.values()).some(({ stickerId }) => stickerId === glyphId);
        },
    };
});
export default Scene;
