/* eslint-disable func-names */
import { getDropListAsync, drawDropAsync, isExpandedSpreeError, isSpreeFieldErrors, } from 'common/api/spree';
import { Drop, DropStatus } from 'common/state/models/Drop';
import { Nodes } from 'common/state/nodes';
import { getEnv, flow, types } from 'mobx-state-tree';
import { IS_BETA } from 'common/config';
import { GlyphState } from 'common/api/types';
import { getRootStore } from 'common/state/RootStore';
import { isEmpty } from 'lodash';
export var DrawPrizeStatus;
(function (DrawPrizeStatus) {
    DrawPrizeStatus[DrawPrizeStatus["SUCCESS"] = 0] = "SUCCESS";
    DrawPrizeStatus[DrawPrizeStatus["NO_AVAILABLE_PRIZES"] = 1] = "NO_AVAILABLE_PRIZES";
    DrawPrizeStatus[DrawPrizeStatus["UNKNOWN_FAILURE"] = 2] = "UNKNOWN_FAILURE";
})(DrawPrizeStatus || (DrawPrizeStatus = {}));
const DropsStore = types
    .model(Nodes.DropsStore, {
    drops: types.map(Drop),
    wasLoaded: types.boolean,
    loading: types.boolean,
    drawInProgress: types.boolean, // For drawing indicator
})
    .actions((self) => ({
    fetch: flow(function* () {
        try {
            self.loading = true;
            const { userStore: { loadUserInfo, spreeAuth }, } = getRootStore(self);
            const fetchedDropsList = yield getDropListAsync(spreeAuth);
            fetchedDropsList.forEach((drop) => {
                self.drops.put(drop);
            });
            // Need to refetch the info about the won glyph
            yield loadUserInfo();
        }
        catch (err) {
            const sentry = getEnv(self).sentry;
            sentry.captureException(err);
            console.warn('Error while loading drops', err);
        }
        self.wasLoaded = true;
        self.loading = false;
    }),
    drawPrize: flow(function* (dropId) {
        const { userStore: { spreeAuth, setStickerPrizeFromLottery, refreshExperience }, stickerStore, } = getRootStore(self);
        try {
            self.drawInProgress = true;
            const prize = yield drawDropAsync(spreeAuth, dropId);
            if (prize) {
                setStickerPrizeFromLottery(prize);
                yield Promise.all([
                    stickerStore.fetch(spreeAuth, prize.productId),
                    refreshExperience(),
                ]);
            }
            return DrawPrizeStatus.SUCCESS;
        }
        catch (error) {
            if (isExpandedSpreeError(error)) {
                const prizesErrors = error.getErrors(['prizes']);
                if (isSpreeFieldErrors(prizesErrors) && prizesErrors[0] === 'empty') {
                    return DrawPrizeStatus.NO_AVAILABLE_PRIZES;
                }
            }
            console.warn('Error while drawing drop', error);
        }
        finally {
            self.drawInProgress = false;
        }
        return DrawPrizeStatus.UNKNOWN_FAILURE;
    }),
}))
    .views((self) => ({
    get activeDrops() {
        return Array.from(self.drops.values()).filter(({ status }) => status === DropStatus.Available ||
            status === DropStatus.Won ||
            status === DropStatus.Used);
    },
    get dropsList() {
        if (self.drops) {
            return Array.from(self.drops.values());
        }
        return [];
    },
    get activeState() {
        if (IS_BETA) {
            return 'available';
        }
        return (Array.from(self.drops.values()).map(({ status }) => {
            if (status === DropStatus.Available) {
                return 'available';
            }
            if (status === DropStatus.Empty) {
                return 'waitForReturn';
            }
            return 'waitForNextDrop';
        })?.[0] || 'waitForNextDrop');
    },
    get lotteryTileIsVisible() {
        const { userStore: { loading: loadingUserInfo, stickerPrizeFromLottery }, } = getRootStore(self);
        if (self.drawInProgress) {
            return true;
        }
        return (!loadingUserInfo &&
            !self.loading &&
            (!stickerPrizeFromLottery || stickerPrizeFromLottery.isReadyToShow));
    },
}))
    .views((self) => ({
    // FIXME this returns different values depending on if a past drop was used
    get currentDrop() {
        const [firstDrop] = self.activeDrops;
        return firstDrop;
    },
    // This used only in frog contract-related logic, i.e. fetching stock, allowlists, etc.
    // TODO Maybe picking the drop should somehow be dependent on its contractType?
    get availableDrop() {
        return Array.from(self.drops.values()).filter((d) => d.isAvailable)[0];
    },
}))
    .views((self) => ({
    get isPostBeta() {
        return self.wasLoaded && isEmpty(self.drops);
    },
    get nextAvailableDrop() {
        const startingDate = new Date();
        return self.dropsList.find(({ availableOn }) => availableOn > startingDate);
    },
}))
    .views((self) => ({
    get notAvailableGlyphState() {
        return self.nextAvailableDrop
            ? GlyphState.ComingSoon
            : GlyphState.SoldOut;
    },
    get contractAddresses() {
        return self.dropsList
            .map(({ contractAddress }) => contractAddress)
            .filter((x) => !!x);
    },
}));
export default DropsStore;
