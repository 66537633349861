import { createRef, useEffect, useState } from 'react';
import * as address from 'app/common/addressFormLabels';
import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import CountryPicker, { CountryOption } from 'app/components/CountryPicker';
import LabeledInput from 'app/components/LabeledInput';
import { getStatesAsync } from 'common/api/spree';

const _AddNewAddress = () => {
  const {
    userStore: { addressErrorsList },
    checkoutStore: { availableCountriesList, setCurrentlyEditedAddress },
  } = useStore();

  const [refs] = useState(() => {
    return address.idx.map((_) => createRef<HTMLInputElement>());
  });

  const [isSelectorFocused, setIsSelectorFocused] = useState(false);

  const [country, setCountry] = useState({
    name: '',
    iso: '',
  });

  const [statesAvailable, setStatesAvailable] = useState(false);

  useEffect(() => {
    if (country.name) {
      getStates(country.iso);
    }
  }, [country]);

  const getStates = async (countryIso: string) => {
    const statesList = await getStatesAsync(countryIso);
    setStatesAvailable(statesList.length > 0);
  };

  const onInput = (i: number) => {
    setCurrentlyEditedAddress(
      address.addressKeys[i] as string,
      refs[i].current?.value,
    );
  };

  const onCountrySelect = (option: CountryOption) => {
    setCountry({
      name: option.label,
      iso: option.value,
    });
    setCurrentlyEditedAddress('country', option.label);
    setCurrentlyEditedAddress('country_iso', option.value);
  };

  const countriesOptions = availableCountriesList
    .map((country) => {
      return {
        value: country.country_iso,
        label: country.name,
      };
    })
    .sort((obj, comp) => (obj.label < comp.label ? -1 : 1));

  return (
    <div className="p-4">
      {address.idx.map(
        (i) =>
          !(address.addressKeys[i] === 'state_name' && !statesAvailable) && (
            <LabeledInput
              key={i}
              ref={refs[i]}
              placeholder={address.addressLabels[i]}
              error={
                addressErrorsList[address.errorKeys[i].toString()] !== undefined
              }
              onInput={() => onInput(i)}
            />
          ),
      )}
      <CountryPicker
        options={countriesOptions}
        focused={isSelectorFocused || country.name !== ''}
        onSelect={onCountrySelect}
        onBlur={() => setIsSelectorFocused(false)}
        onFocus={() => setIsSelectorFocused(true)}
      />
    </div>
  );
};

export const AddNewAddress = observer(_AddNewAddress);
