const Privacy = ({ showHeader = true }) => {
  return (
    <div>
      {showHeader && (
        <h1 className="text-center mt-16 mb-12 uppercase">Privacy policy</h1>
      )}
      <ol className="my-4 medium18 text-coldGrey-500 uppercase">
        <li>
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Introduction
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              Welcome to the RSTLSS privacy policy.{' '}
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              RSTLSS respects your privacy and is committed to protecting your
              personal data. This privacy policy will inform you as to how we
              look after your personal data when you visit our website (
            </span>
            <span className="regular16 normal-case">
              <a href="https://www.google.com/url?q=https://rstlss.xyz/&amp;sa=D&amp;source=editors&amp;ust=1657796553279231&amp;usg=AOvVaw2VI-quc2hzSNERV6n_jxXL">
                https://rstlss.xyz/
              </a>
            </span>
            <span className="regular16 normal-case">) (the &#39;</span>
            <span className="bold16 normal-case">Website</span>
            <span className="regular16 normal-case">
              &#39;) or use our NFT platform services (the &#39;
            </span>
            <span className="bold16 normal-case">Platform</span>
            <span className="regular16 normal-case">
              &#39;) and tells you about your privacy rights and how the law
              protects you.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We strive to ensure that we are clear about how we will use the
              personal data that we collect. We process personal data in
              accordance with the UK General Data Protection Regulation (GDPR),
              Data Protection Act 2018, Privacy and Electronic Communications
              Regulations 2003 (as amended) and other applicable laws that
              regulate protection and privacy of your personal data, each as
              amended from time to time (the &#39;
            </span>
            <span className="bold16 normal-case">data protection laws</span>
            <span className="regular16 normal-case">&#39;). </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Purpose of this privacy policy
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              This privacy policy aims to give you information on how RSTLSS
              collects and processes your personal data through your use of its
              Website and Platform, including any data you may provide where you
              register an account, sign up to our mailing list, take part in a
              competition or purchase a product or service through the Platform.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              It is important that you read this privacy policy together with
              any other privacy policy or fair processing policy we may provide
              on specific occasions when we are collecting or processing
              personal data about you so that you are fully aware of how and why
              we are using your data. This privacy policy supplements other
              notices and privacy policies and is not intended to override them.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">RSTLSS, Inc. (&#39;</span>
            <span className="bold16 normal-case">RSTLSS</span>
            <span className="regular16 normal-case">
              &#39;) a company registered in Delaware, USA, whose principal
              place of business is at 129, 14 New Crane Place, London, is the
              controller and responsible for your personal data.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            How to contact us
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              If you have any questions about this privacy policy or our privacy
              practices, please contact our data privacy manager via email at{' '}
            </span>
            <span className="regular16 normal-case">
              <a href="mailto:support@rstlss.xyz">support@rstlss.xyz</a>
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              You have the right to make a complaint at any time to the
              Information Commissioner&#39;s Office (ICO), the UK regulator for
              data protection issues (
            </span>
            <span className="regular16 normal-case">www.ico.org.uk</span>
            <span className="regular16 normal-case">
              ). We would, however, appreciate the chance to deal with your
              concerns before you approach the ICO, so please contact us in the
              first instance.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Change to this privacy policy
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              We keep our privacy policy under regular review. It is important
              that you check for updated to this privacy policy each time you
              use the Website or the Platform. Historic versions can be obtained
              by contacting us.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              It is important that the personal data we hold about you is
              accurate and current. Please keep us informed if your personal
              data changes during your relationship with us.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            The data we collect about you
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. It
              does not include data where the identity has been removed
              (anonymous data).
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </span>
          </p>
          <ul className="list-disc list-inside">
            <li>
              <span className="bold16 normal-case">Identity Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes your name, Platform username or similar
                identifier, title, date of birth and gender.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Contact Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes your billing address, delivery address, email
                address and telephone numbers.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Financial Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes details of your cryptocurrency wallet, and any
                bank account or payment card details you provide to us.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Transaction Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes details about payments to and from you via the
                Platform and other details of products and services you have
                purchased from us.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Technical Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes internet protocol (IP) address, your login data,
                browser type and version, time zone setting and location,
                browser plug-in types and versions, operating system and
                platform, and other technology on the devices you use to access
                the Website or the Platform.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Profile Data </span>
              <span className="regular16 normal-case">
                includes your Platform username and password, user data in
                relation to any cryptocurrency wallet services, purchases or
                orders made by you, your interests, preferences, feedback and
                survey responses. &nbsp;
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">Usage Data</span>
              <span className="regular16 normal-case">
                &nbsp;includes information about how you use the Website, the
                Platform and our products and services.
              </span>
            </li>
            <li>
              <span className="bold16 normal-case">
                Marketing and Communications Data{' '}
              </span>
              <span className="regular16 normal-case">
                includes your preferences in receiving marketing from us and our
                third parties and your communication preferences.
              </span>
            </li>
          </ul>
          <p className="my-4">
            <span className="regular16 normal-case">
              We also collect, use and share{' '}
            </span>
            <span className="bold16 normal-case">Aggregated Data</span>
            <span className="regular16 normal-case">
              &nbsp;such as statistical or demographic data for any purpose.
              Aggregated Data could be derived from your personal data but is
              not considered personal data in law as this data will{' '}
            </span>
            <span className="bold16 normal-case">not</span>
            <span className="regular16 normal-case">
              &nbsp;directly or indirectly reveal your identity. For example, we
              may aggregate your Usage Data to calculate the percentage of users
              accessing a specific website feature. However, if we combine or
              connect Aggregated Data with your personal data so that it can
              directly or indirectly identify you, we treat the combined data as
              personal data which will be used in accordance with this privacy
              policy.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We do not collect any{' '}
            </span>
            <span className="bold16 normal-case">
              Special Categories of Personal Data
            </span>
            <span className="regular16 normal-case">
              &nbsp;about you (this includes details about your race or
              ethnicity, religious or philosophical beliefs, sex life, sexual
              orientation, political opinions, trade union membership,
              information about your health, and genetic and biometric data). We
              do not collect any information about criminal convictions and
              offences. The Website and Platform are not intended for people
              aged under 18 and we do not knowingly collect data relating to
              people aged under 18.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Please note that where we need to collect personal data from you
              (either as required by law or to enable us to provide goods or
              services to you) and you fail to provide such data when requested,
              we may not be able to provide you with the relevant goods or
              services. In this case, we may have to cancel a product or service
              you have with us but we will always notify you first if this is
              the case.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Visiting third party websites from the Website or the Platform
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              Please note that the Website and the Platform may include links to
              third-party websites, plug-ins and applications. Clicking on those
              links or enabling those connections may allow third parties to
              collect or share data about you. We do not control these
              third-party websites and are not responsible for their privacy
              statements and we encourage you to read the privacy policy of
              every third party website you visit.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            How we collect your personal data
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              We use different methods to collect data from and about you
              including:
            </span>
          </p>
          <div className="overflow-x-auto">
            <table className="border border-collapse border-coldGrey-300 ">
              <thead className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Method</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Type(s) of personal data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Examples of data collection</p>
                </td>
              </thead>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Direct interactions with RSTLSS</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Identity Data</p>
                  <p className="regular16">Contact Data</p>
                  <p className="regular16">Financial Data</p>
                  <p className="regular16">Profile Data</p>
                  <p className="regular16">Usage Data</p>
                  <p className="regular16">Marketing and Communications Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Order our products or services</p>
                  <p className="regular16">
                    Create an account on the Website or the Platform
                  </p>
                  <p className="regular16">
                    Subscribe to our marketing communications
                  </p>
                  <p className="regular16">
                    Enter a competition, promotion or survey
                  </p>
                  <p className="regular16">
                    Give us feedback or otherwise contact us
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Automated technologies or interactions
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Technical Data</p>
                  <p className="regular16">Usage Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Data automatically collected as your use the Website and the
                    Platform, including data about your equipment, browsing
                    actions and patterns. We collected this data by using
                    cookies and similar technologies.
                  </p>
                  <p className="regular16">
                    We may also receive Technical Data about you if you visit
                    other websites employing our cookies.
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Third party partners</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Identity Data</p>
                  <p className="regular16">Contact Data</p>
                  <p className="regular16">Financial Data</p>
                  <p className="regular16">Transaction Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Providers of technical, payment and delivery services based
                    worldwide, such as our payment processing partners.
                  </p>
                  <p className="regular16">
                    Artists partnering with RSTLSS to provide bespoke NFT design
                    services to you.
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Third party analysis providers</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Technical Data</p>
                  <p className="regular16">Profile Data</p>
                  <p className="regular16">Usage Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Analytics providers based inside and outside the UK and EEA.
                  </p>
                  <p className="regular16">
                    Advertising networks and search information providers based
                    inside and outside the UK and EEA.
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            How we use your personal data
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              We will only use your personal data when the applicable data
              protection laws allows us to. Most commonly, we will use your
              personal data in the following circumstances:
            </span>
          </p>
          <ul className="list-disc list-inside">
            <li>
              <span className="regular16 normal-case">
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                Where you have given your consent for us to use your personal
                data in this way, such as when we are sending you third party
                direct marketing communications. Please note that you have the
                right to withdraw consent to marketing communications (either
                from RSTLSS or third parties) at any time by contacting us.
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                Where we need to comply with a legal obligation.
              </span>
            </li>
          </ul>
          <p className="my-4 bold16 normal-case ">
            Purposes for which we will use your personal data
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Set out below is a description of all the ways we plan to use your
              personal data, and which of the legal bases we rely on to do so.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We may process your personal data for more than one lawful ground
              depending on the specific purpose for which we are using your
              data. Please contact us if you need details about the specific
              legal ground we are relying on to process your personal data where
              more than one ground has been set out in the table below.
            </span>
          </p>
          <div className="overflow-x-auto">
            <table className="border border-collapse border-coldGrey-300 ">
              <thead className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Purpose/Activity</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Type of data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">
                    Lawful basis for processing including basis of legitimate
                    interest
                  </p>
                </td>
              </thead>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To register your account on the Platform or the Website, or
                    to otherwise register you as a new customer
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    (a) Performance of a contract with you{' '}
                  </p>
                  <p className="regular16">
                    (b) Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To process and deliver orders you place via the Platform
                    including:
                  </p>
                  <p className="regular16">
                    (a) Manage payments, fees and charges
                  </p>
                  <p className="regular16">
                    (b) Collect money owed to us for goods and services you use
                    via the Platform
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Financial Data</p>
                  <p className="regular16">(d) Transaction Data</p>
                  <p className="regular16">(e) Profile Data</p>
                  <p className="regular16">
                    (f) Marketing and Communications Data
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    (a) Performance of a contract with you{' '}
                  </p>
                  <p className="regular16">
                    (b) Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To manage our relationship with you which will include:
                  </p>
                  <p className="regular16">
                    (a) Notifying you about changes to our terms and conditions
                    or privacy policy
                  </p>
                  <p className="regular16">
                    (b) Asking you to leave a review or take a survey
                  </p>
                  <p className="regular16">
                    (c) To enable you to partake in a prize draw or competition
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Profile Data</p>
                  <p className="regular16">(d) Usage Data</p>
                  <p className="regular16">
                    (e) Marketing and Communications Data
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    (a) Performance of a contract with you{' '}
                  </p>
                  <p className="regular16">
                    (b) Necessary to comply with a legal obligation
                  </p>
                  <p className="regular16">
                    (c) Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To administer and protect our business, the Website and the
                    Platform (including troubleshooting, data analysis, testing,
                    system maintenance, support, reporting, network security,
                    prevention of fraud and other activities relating to the
                    hosting of data) &nbsp;
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Technical Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    (a) Necessary for our legitimate interests{' '}
                  </p>
                  <p className="regular16">
                    (b) Necessary to comply with a legal obligation
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    (a) To deliver relevant Website and Platform content and
                    advertisements to you
                  </p>
                  <p className="regular16">
                    (b) To grow our business by measuring or understanding the
                    effectiveness of the advertising we provide to you and by
                    studying our customers&#39; use of our products and services
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Profile Data</p>
                  <p className="regular16">(d) Usage Data</p>
                  <p className="regular16">
                    (e) Marketing and Communications Data
                  </p>
                  <p className="regular16">(f) Technical Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To use data analytics to improve the Website, the Platform
                    and our products and services, including our customer
                    relationships and experiences
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Technical Data</p>
                  <p className="regular16">(b) Profile Data</p>
                  <p className="regular16">(c) Usage Data</p>
                  <p className="regular16">(d) Transaction Data</p>
                  <p className="regular16">
                    (e) Marketing and Communications Data
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    To make suggestions and recommendations to you about goods
                    or services that may be of interest to you, to help develop
                    our products and services and to grow our business
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Technical Data</p>
                  <p className="regular16">(d) Usage Data</p>
                  <p className="regular16">(e) Profile Data</p>
                  <p className="regular16">
                    (f) Marketing and Communications Data
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Necessary for our legitimate interests{' '}
                  </p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">
                    Third party marketing communications
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Identity Data</p>
                  <p className="regular16">(b) Contact Data</p>
                  <p className="regular16">(c) Technical Data</p>
                  <p className="regular16">(d) Usage Data</p>
                  <p className="regular16">(e) Profile Data</p>
                  <p className="regular16">
                    (f) Marketing and Communications Data
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">Express opt-in consent </p>
                </td>
              </tr>
            </table>
          </div>
          <p className="my-4 bold16 normal-case ">
            Opting out of marketing communications
          </p>

          <p className="my-4">
            <span className="regular16 normal-case">
              You can ask us or third parties to stop sending you marketing
              messages at any time by: (i) logging into your account on the
              Platform and checking or unchecking relevant boxes to adjust your
              marketing preferences; (ii) by following the opt-out links on any
              marketing message sent to you; or (iii) by contacting us at any
              time.
            </span>
          </p>

          <p className="my-4">
            <span className="regular16 normal-case">
              Where you opt out of receiving these marketing messages, this will
              not apply to personal data provided to us as a result of a product
              or service you have purchased from RSTLS, communications relating
              to a product or service experience or communications regarding any
              other transactions on the Platform, such as the resale of an NFT.
            </span>
          </p>

          <p className="my-4 bold16 normal-case">Change of purpose</p>

          <p className="my-4">
            <span className="regular16 normal-case">
              We will only use your personal data for the purposes for which we
              collected it, unless we reasonably consider that we need to use it
              for another reason and that reason is compatible with the original
              purpose. If you wish to get an explanation as to how the
              processing for the new purpose is compatible with the original
              purpose, please contact us.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              If we need to use your personal data for an unrelated purpose, we
              will notify you and we will explain the legal basis which allows
              us to do so.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Disclosures of your personal data
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              Other than as expressly set out in this privacy policy or as
              otherwise permitted under applicable data protection law, we will
              not share, sell or distribute any of the personal data you provide
              to us without your consent.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We may share your personal data with third parties under this
              privacy policy, for the purposes set out in the table above, in
              the following circumstances:
            </span>
          </p>
          <ul className="list-disc list-inside">
            <li>
              <span className="regular16 normal-case">
                to NFT artists who are providing design services to you;
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                to our service providers, who handle that data on our behalf and
                in accordance with our instructions under contract (called
                &quot;data processors&quot;). These include IT suppliers, third
                party data analysis providers, data hosting providers and
                agencies we use to conduct fraud and due diligence checks;
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                to any affiliate or third-party companies and/or organisations
                to whom disclosure is necessary to enable us to provide you with
                the services or products you have purchased such as providers of
                shipping and delivery services or payment processors;
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                if we are under a duty to disclose or share your personal data
                to comply with any legal obligation (e.g. where we have a duty
                to provide data to the police or courts);
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                if our business or substantially all of our assets are acquired
                by a third party, in which case personal data held by us about
                our customers will be one of the transferred assets although
                your data will continue to be used for the same purposes; and
              </span>
            </li>
            <li>
              <span className="regular16 normal-case">
                where disclosure is necessary to enable us to enforce our legal
                rights, or to protect the rights, property or safety of our
                employees or where such disclosure may be required by law
                (including without limitation to any legal adviser, court,
                regulator or other relevant authority).
              </span>
            </li>
          </ul>
          <p className="my-4">
            <span className="regular16 normal-case">
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. Unless
              you have otherwise consented to such use, we do not allow our
              third-party service providers, including artists or their agents,
              to use your personal data for their own purposes and we otherwise
              only permit them to process your personal data for specified
              purposes and in accordance with our instructions.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            When will we send your data internationally?
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              The personal data that we collect from you may be transferred to,
              and stored in United Kingdom, countries of the European Economic
              Area (EEA) and the United States as part of the provision of our
              IT systems and data analysis services. &nbsp;Our IT and
              back-office service providers who act as our data processors may
              use IT equipment located in the United States and other countries
              outside of the EEA to hold your data. The United States is one of
              the countries outside the EEA whose laws are currently not
              considered to meet the same legal standards of protection for
              personal data as those set out under the data protection laws of
              the UK and the EU.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              However, in order to safeguard your personal data, we will only
              permit such a transfer of your data outside the UK and/or the EEA
              under a contract or another appropriate mechanism which is
              authorised under the applicable data protection laws. This is to
              make sure that your personal data is safeguarded in accordance
              with the same legal standards that apply to us and to your
              personal data in the UK and EEA.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Data security
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              We employ appropriate technological and operational security
              measures to protect personal data we hold against any unauthorised
              access, any unlawful processing or loss. We have procedures in
              place to deal with any suspected personal data breach and we will
              notify you and any applicable data privacy regulator of a personal
              data breach where we are required to do so under applicable data
              protection laws.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Data transmission over the internet is not 100% secure, and
              although we employ appropriate technological and organisational
              security measures to protect your data from unauthorised access we
              cannot guarantee the security of any data sent over the internet
              or by public communications services.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Data retention
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              We will only retain your personal data for as long as is
              reasonably necessary to fulfil the purposes we collected it for.
              This includes for the purposes of satisfying any legal,
              regulatory, tax, accounting or reporting requirements. We may
              retain your personal data for a longer period in the event of a
              complaint or if we reasonably believe there is a prospect of
              litigation in respect to our relationship with you.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              By law we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              six years after they cease being customers for tax purposes.{' '}
            </span>
            <span className="regular16 normal-case">
              Personal data that we no longer need to hold is securely disposed
              of and/or anonymised, so you can no longer be identified from it.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              In some circumstances we will anonymise your personal data (so
              that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Your legal rights
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              Under certain circumstances, you have rights under applicable data
              protection laws in relation to your personal data. If you wish to
              exercise any of these rights please contact us.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              You will not normally have to pay a fee to access your personal
              data (or to exercise any of the other rights). However, we may
              charge a reasonable fee (or refuse to comply with your request) if
              your request is clearly unfounded, repetitive or excessive.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              We try to respond to all legitimate requests within one month.
              Occasionally it could take us longer than a month if your request
              is particularly complex or you have made a number of requests, in
              which case we will keep you updated regarding these timings.
            </span>
          </p>
          <p className="my-4 bold16 normal-case">Your legal rights:</p>
          <p className="my-4">
            <span className="bold16 normal-case">Request access </span>
            <span className="regular16 normal-case">
              to your personal data (commonly known as a &quot;data subject
              access request&quot;). This enables you to receive a copy of the
              personal data we hold about you and to check that we are lawfully
              processing it.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Request correction </span>
            <span className="regular16 normal-case">
              of the personal data that we hold about you. This enables you to
              have any incomplete or inaccurate data we hold about you
              corrected, though we may need to verify the accuracy of the new
              data you provide to us.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Request erasure </span>
            <span className="regular16 normal-case">
              of your personal data. This enables you to ask us to delete or
              remove personal data where there is no good reason for us
              continuing to process it. You also have the right to ask us to
              delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Object to processing </span>
            <span className="regular16 normal-case">
              of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about
              your particular situation which makes you want to object to
              processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing
              purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which
              override your rights and freedoms.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">
              Request restriction of processing{' '}
            </span>
            <span className="regular16 normal-case">
              of your personal data. This enables you to ask us to suspend the
              processing of your personal data if: (i) you want us to establish
              the accuracy of personal data; (ii) where our use of your personal
              data is unlawful but you do not want us to erase it; (iii) were
              you need us to hold the personal data even if we no longer require
              it, as you need it to establish, exercise or defend legal claims;
              or (iv) you have objected to our use of your personal data but we
              need to verify whether we have overriding legitimate grounds to
              use it.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Request the transfer </span>
            <span className="regular16 normal-case">
              of your personal data to you or to a third party. We will provide
              to you, or a third party you have chosen, your personal data in a
              structured, commonly used, machine-readable format. Note that this
              right only applies to automated information which you initially
              provided consent for us to use or where we used the information to
              perform a contract with you.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">
              Withdraw consent at any time{' '}
            </span>
            <span className="regular16 normal-case">
              where we are relying on consent to process your personal data.
              However, this will not affect the lawfulness of any processing
              carried out before you withdraw your consent. If you withdraw your
              consent, we may not be able to provide certain products or
              services to you. We will advise you if this is the case at the
              time you withdraw your consent.
            </span>
          </p>
        </li>
        <li className="mt-12 mb-4">
          <span className="my-4 medium18 text-coldGrey-500 uppercase">
            Compliance with CCPA
          </span>
          <p className="my-4">
            <span className="regular16 normal-case">
              This section only applies to residents in the State of California,
              USA (&#39;
            </span>
            <span className="bold16 normal-case">Residents</span>
            <span className="regular16 normal-case">
              &#39;) and it outlines how we collect, use and share
              Residents&#39; &#39;
            </span>
            <span className="bold16 normal-case">Personal Information</span>
            <span className="regular16 normal-case">
              &#39; (as defined in the California Consumer Privacy Act of 2018
              (&#39;
            </span>
            <span className="bold16 normal-case">CCPA</span>
            <span className="regular16 normal-case">
              &#39;) but not including information exempted from the CCPA&#39;s
              scope) to operate the RSTLSS business (including the Website and
              the Platform) and the rights of Residents relating to such
              Personal Information.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Residents have the rights listed in the table below, however
              please note that in certain circumstances we may decline a request
              in relation to these rights where permitted under the CCPA or any
              other applicable law.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Please note that you are entitled to exercise these rights free
              from any discrimination, and we will not take any of the following
              actions: (i) deny you access to our goods or services; (ii)
              increase the price of our goods or services; (iii) decrease the
              quality of the service you receive from us; or (iv) otherwise
              suggest that we may discriminate against you in the ways outlined
              above if you exercise your rights under the CCPA.
            </span>
          </p>
          <p className="my-4 bold16 normal-case">Your CCPA rights:</p>
          <p className="my-4">
            <span className="bold16 normal-case">Request information</span>
            <span className="regular16 normal-case">
              . You can request the following categories of information
              regarding how in the previous 12 months we have collected and used
              your Personal Information: (i) categories of Personal Information;
              (ii) categories of sources used to collect your Personal
              Information from; (iii) the business purpose for collecting and
              using your Personal Information; (iv) the categories of third
              parties which we share your Personal Information with; (v) details
              of whether we have disclosed any of your Personal Information for
              a business purpose, including the categories of Personal
              Information received by any third party who we disclosed your
              Personal Information with; and (vi) details of whether we have
              sold any of your Personal Information, including the categories of
              Personal Information received by any third party who we have sold
              your Personal Information to.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Access</span>
            <span className="regular16 normal-case">
              . You can request access to a copy of any of your Personal
              Information which we have collected in the previous 12 months.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">Deletion</span>
            <span className="regular16 normal-case">
              . You can request that we delete any Personal Information which we
              have collected from you.
            </span>
          </p>
          <p className="my-4">
            <span className="bold16 normal-case">
              Opt-out of sale of your Personal Information
            </span>
            <span className="regular16 normal-case">
              . If we sell your Personal Information, you can opt out of our
              right to do this. Where you do this, we will also consider it a
              request under the applicable data protection laws to stop sharing
              your Personal Information with any third parties for their direct
              marketing purposes. Please note that the following actions qualify
              as{' '}
            </span>
            <span className="regular16 normal-case">
              &#39;selling your Personal Information&#39;
            </span>
            <span className="regular16 normal-case">
              &nbsp;for the purposes of the CCPA: (i) supplying your Personal
              Information to third parties where you have provided your express
              opt-in consent; (ii) supplying your Personal Information to list
              brokers where you have provided your express opt-in consent; (iii)
              sharing your Personal Information with our third party marketing
              partners for commercial use; (iv) sharing your Personal
              Information with third parties which provide online content
              services to us, and which as part of these services collect
              information from the use of the Website and the Platform to target
              adverts and content to your preferences.
            </span>
          </p>
          <p className="my-4 bold16 normal-case">How to exercise your rights</p>
          <p className="my-4">
            <span className="regular16 normal-case">
              You may exercise your rights under the CCPA by contacting us with
              the relevant details of the rights you wish to exercise.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              You can also opt-out of the sale of your Personal Information by
              logging into your account on the Platform and checking or
              unchecking relevant boxes to adjust your marketing preferences, or
              by following the opt-out links on any marketing message sent to
              you.
            </span>
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              Please note that we will need to confirm your identity and that
              you are a Resident when processing any request to exercise your
              CCPA rights. We will not be able to process any such request
              unless we have provided us with sufficient information to action
              your request.
            </span>
          </p>
          <p className="my-4 bold16 normal-case">
            Our collection, use and sharing of Personal Information under the
            CCPA
          </p>
          <p className="my-4">
            <span className="regular16 normal-case">
              The table below outlines how in the previous 12 months we have
              collected, used and shared Personal Information, with reference to
              the categories of Personal Information as described in the CCPA.
            </span>
          </p>
          <div className="overflow-x-auto">
            <table className="border border-collapse border-coldGrey-300 ">
              <thead className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Category</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Source of Personal Information</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">Collection purpose</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">
                    Categories of third parties we disclose Personal Information
                    with for our business purposes
                  </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="my-4">
                    Categories of third parties we sell Personal Information to
                  </p>
                </td>
              </thead>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Identifiers</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Financial Information</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery</p>
                  <p className="regular16">(b) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">N/A</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Commercial Information</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                  <p className="regular16"></p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Geolocation Data</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                  <p className="regular16">(c) Public sources</p>
                  <p className="regular16">(d) Automatic collection</p>
                  <p className="regular16"></p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Inferences</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                  <p className="regular16"></p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Internet or Network Information</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You</p>
                  <p className="regular16">(b) Automatic-collection </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
              <tr className="border border-collapse border-coldGrey-300 bold16 normal-case text-center">
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="bold16">Online Identifiers </p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) You </p>
                  <p className="regular16">(b) Our business partners</p>
                  <p className="regular16"></p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service delivery </p>
                  <p className="regular16">(b) Research and development</p>
                  <p className="regular16">(c) Marketing</p>
                  <p className="regular16">(d) Compliance and operations</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Service-related third parties</p>
                  <p className="regular16">(b) Professional advisors</p>
                  <p className="regular16">(c) Authorities, and others</p>
                  <p className="regular16">(d) Business transferees</p>
                </td>
                <td className="border border-collapse border-coldGrey-300 p-2">
                  <p className="regular16">(a) Third-party partners </p>
                  <p className="regular16">(b) Advertising partners</p>
                  <p className="regular16">(c) Service-related third parties</p>
                </td>
              </tr>
            </table>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default Privacy;
