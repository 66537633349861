import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
import { Price } from 'common/state/models/Price';
export var GlyphPrizeVariant;
(function (GlyphPrizeVariant) {
    GlyphPrizeVariant["common"] = "common";
    GlyphPrizeVariant["ultraRare"] = "ultraRare";
    GlyphPrizeVariant["rare"] = "rare";
    GlyphPrizeVariant["special"] = "special";
})(GlyphPrizeVariant || (GlyphPrizeVariant = {}));
export const Prize = types
    .model(Nodes.Prize, {
    id: types.identifier,
    productId: types.maybe(types.string),
    name: types.string,
    rarity: types.enumeration('rarity', Object.values(GlyphPrizeVariant)),
    imageUrl: types.maybe(types.string),
    imageDesignHeight: types.maybe(types.number),
    price: Price,
    expiresOn: types.Date,
    isReadyToShow: types.optional(types.boolean, false),
    dropId: types.maybe(types.string),
    stockStatus: types.maybe(types.string),
})
    .actions((self) => ({
    readyToShow: () => {
        self.isReadyToShow = true;
    },
}));
