import { Price } from 'common/state/models/Price';
import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
const DeliveryType = types.model(Nodes.ShippingRate, {
    id: types.identifier,
    name: types.string,
    price: Price,
    shippingMethodId: types.string,
    selected: types.boolean,
});
export default DeliveryType;
