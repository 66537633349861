export const NON_EXPIRED_GLYPH_INFO = {
    infoTitle: 'How generated glyphs work?',
    infoDescription: 'Generated glyphs can only be obtained by using the glyph generator. They are limited in number and only available once per glyph drop. Once generated you must use it on a creation or its elemental code will expire and it will die a horrible, painful death. I recommend using them.',
};
export const EXPIRED_GLYPH_INFO = {
    infoTitle: 'Why has my glyph expired?',
    infoDescription: 'Generated glyphs are available for a limited time. If they are not claimed by a RSTLing their elemental code expires and they die a horrible death. Now you need to wait for the next drop to use the generator again.',
};
export var ScreenNames;
(function (ScreenNames) {
    ScreenNames["Carbonwear"] = "Carbonwear";
    ScreenNames["DigitalCreation"] = "Digital Creation";
})(ScreenNames || (ScreenNames = {}));
export const GLYPH_DISPLAY_NAMES = {
    common: 'Standard glyph',
    ultraRare: 'Legendary glyph',
    rare: 'So rare! glyph',
    special: 'Generated glyph',
};
export const DROP_ERROR_INFO = {
    removeGlyphFromDraft: 'Remove the glyph from your draft design to use it in the new one.',
    glyphUnavailable: 'The glyph you want to use is currently unavailable.',
    dropExpired: 'The sticker is no longer available because the drop has expired',
};
