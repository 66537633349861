import { Nodes } from 'common/state/nodes';
import { types } from 'mobx-state-tree';
import { Prize } from 'common/state/models/Prize';
export var DropStatus;
(function (DropStatus) {
    DropStatus["Available"] = "available";
    DropStatus["Won"] = "won";
    DropStatus["Empty"] = "empty";
    DropStatus["Depleted"] = "depleted";
    DropStatus["Expired"] = "expired";
    DropStatus["Used"] = "used";
    DropStatus["Pending"] = "pending";
})(DropStatus || (DropStatus = {}));
export const Drop = types
    .model(Nodes.Drop, {
    id: types.identifier,
    title: types.string,
    description: types.string,
    imageUrl: types.maybe(types.string),
    imageDesignHeight: types.maybe(types.number),
    endsOn: types.Date,
    availableOn: types.Date,
    status: types.enumeration('status', Object.values(DropStatus)),
    availablePrizesNumber: types.number,
    cost: types.number,
    dibAvailability: types.number,
    prizes: types.array(Prize),
    contractAddress: types.maybe(types.string),
    isAvailable: types.boolean,
})
    .views((self) => ({
    getPrizesList: () => Array.from(self.prizes.values()),
}));
