import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import useStore from 'app/common/useStore';
import { useState } from 'react';
import Modal from 'react-modal';
import HighlightButton from './HighlightButton';
import OutlinedButton from './OutlinedButton';
import { formatPrice } from 'common/state/models/Price';

interface Props {
  id: string | null;
  setId: (id: string | null) => void;
}

const RemoveItemModal: React.FC<Props> = ({ id, setId }) => {
  const {
    userStore: { spreeAuth },
    scenesStore: { deleteScene, getSceneById },
    checkoutStore: { removeFromCart, orderNumber },
  } = useStore();

  const [removing, setRemoving] = useState(false);

  const { logEvent } = useAnalytics();

  return (
    <Modal
      onRequestClose={() => {
        if (!removing) {
          setId(null);
        }
      }}
      overlayClassName="modal-bg"
      className="bg-coldGrey-200 rounded-lg p-8 modal max-w-[400px] flex flex-col items-center text-center"
      isOpen={!!id}
      contentLabel="Example Modal"
    >
      <h2 className="text-base mx-4 uppercase">
        Remove this creation from the pod?
      </h2>
      <h5 className="pt-4 pb-6">Customization will be cleared.</h5>
      <div className="flex w-full justify-between">
        <OutlinedButton
          className="font-mussels flex-1"
          disabled={removing}
          onClick={() => {
            setId(null);
          }}
        >
          CLOSE
        </OutlinedButton>
        <div className="w-4" />
        <HighlightButton
          className="font-mussels flex-1"
          loading={removing}
          onClick={async () => {
            if (id) {
              const scene = getSceneById(id)!;
              const garmentType = scene.getGarmentData().garmentType;
              const creationPriceUSD = formatPrice(scene.totalPrice.usd);

              setRemoving(true);
              await removeFromCart(spreeAuth, id);
              await deleteScene(spreeAuth, id);
              setId(null);
              setRemoving(false);

              logEvent(AnalyticsEventName.RemoveFromPod, {
                paymentId: orderNumber,
                creationPriceUSD,
                garmentType,
              });
            }
          }}
        >
          REMOVE
        </HighlightButton>
      </div>
    </Modal>
  );
};

export default RemoveItemModal;
