import * as spree from '@spree/storefront-api-v2-sdk';
import Scenes from 'common/api/spreeClient/scenes';
import RstlssAuthentication from 'common/api/spreeClient/auth';
import Drops from 'common/api/spreeClient/drops';
import Events from 'common/api/spreeClient/events';
import Checkout from 'common/api/spreeClient/checkout';
import Account from 'common/api/spreeClient/account';
import Stripe from 'common/api/spreeClient/stripe';
import Popups from 'common/api/spreeClient/popups';
import CMSContent from 'common/api/spreeClient/cms_content';
import ExpoToken from 'common/api/spreeClient/expo_tokens';
export default class Client extends spree.Client {
    scenes;
    drops;
    events;
    auth;
    /* @ts-ignore */
    checkout;
    extendedAccount;
    stripe;
    popups;
    cmsContent;
    expoToken;
    constructor(customOptions) {
        super(customOptions);
        this.scenes = this.makeScenes();
        this.drops = this.makeDrops();
        this.events = this.makeEvents();
        this.auth = this.makeRstlssAuthentication();
        this.checkout = this.makeExtendedCheckout();
        this.extendedAccount = this.makeExtendedAccount();
        this.stripe = this.makeStripe();
        this.popups = this.makePopups();
        this.cmsContent = this.makeCMSContent();
        this.expoToken = this.makeExpoToken();
    }
    makeRstlssAuthentication() {
        return new RstlssAuthentication({ fetcher: this.fetcher });
    }
    makeExtendedAccount() {
        return new Account({ fetcher: this.fetcher });
    }
    makeExtendedCheckout() {
        return new Checkout({ fetcher: this.fetcher });
    }
    makeScenes() {
        return new Scenes({ fetcher: this.fetcher });
    }
    makeDrops() {
        return new Drops({ fetcher: this.fetcher });
    }
    makeEvents() {
        return new Events({ fetcher: this.fetcher });
    }
    makeStripe() {
        return new Stripe({ fetcher: this.fetcher });
    }
    makePopups() {
        return new Popups({ fetcher: this.fetcher });
    }
    makeCMSContent() {
        return new CMSContent({ fetcher: this.fetcher });
    }
    makeExpoToken() {
        return new ExpoToken({ fetcher: this.fetcher });
    }
}
