const ABI = [
    { inputs: [], name: 'AllowlistInactive', type: 'error' },
    { inputs: [], name: 'AllowlistSupplyExhausted', type: 'error' },
    { inputs: [], name: 'ConfigurationSupply', type: 'error' },
    { inputs: [], name: 'InsufficientFunds', type: 'error' },
    { inputs: [], name: 'MalformedArgs', type: 'error' },
    { inputs: [], name: 'MaxPerWallet', type: 'error' },
    { inputs: [], name: 'MaxSupply', type: 'error' },
    { inputs: [], name: 'MerkleProofInvalid', type: 'error' },
    { inputs: [], name: 'MintClosed', type: 'error' },
    { inputs: [], name: 'MintQuantityInvalid', type: 'error' },
    { inputs: [], name: 'MintZeroQuantity', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'operator', type: 'address' }],
        name: 'OperatorNotAllowed',
        type: 'error',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
            },
            { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
        ],
        name: 'ApprovalForAll',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' },
        ],
        name: 'Initialized',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'tokens',
                type: 'string',
            },
        ],
        name: 'Minted',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'prevOwner',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnerUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'Paused',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32',
            },
            {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32',
            },
        ],
        name: 'RoleAdminChanged',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
        ],
        name: 'RoleGranted',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
        ],
        name: 'RoleRevoked',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'from', type: 'address' },
            { indexed: true, internalType: 'address', name: 'to', type: 'address' },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'Unpaused',
        type: 'event',
    },
    {
        inputs: [],
        name: 'DEFAULT_ADMIN_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'SANCTIONS_CONTRACT',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'bytes32', name: 'typedata', type: 'bytes32' },
                    { internalType: 'bool', name: 'isActive', type: 'bool' },
                    { internalType: 'string', name: 'metadataUri', type: 'string' },
                    { internalType: 'string', name: 'name', type: 'string' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'uint256',
                        name: 'maxMintPerWallet',
                        type: 'uint256',
                    },
                    { internalType: 'uint256', name: 'tokenPool', type: 'uint256' },
                    { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                ],
                internalType: 'struct IAllowlist.Allowlist',
                name: '_allowlist',
                type: 'tuple',
            },
        ],
        name: 'addAllowlist',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'allowlists',
        outputs: [
            { internalType: 'uint256', name: 'currentStartId', type: 'uint256' },
            { internalType: 'uint256', name: 'count', type: 'uint256' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'operator', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'burn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256[]', name: '_quantities', type: 'uint256[]' },
            { internalType: 'uint256[]', name: '_allowlistIds', type: 'uint256[]' },
            { internalType: 'string[][]', name: '_config', type: 'string[][]' },
        ],
        name: 'calculateCost',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'config',
        outputs: [
            {
                components: [
                    { internalType: 'bool', name: 'isActive', type: 'bool' },
                    { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxPerWallet', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxPerTxn', type: 'uint256' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                ],
                internalType: 'struct IConfig.Mint',
                name: 'mintConfig',
                type: 'tuple',
            },
            {
                components: [
                    { internalType: 'string', name: 'metadataUri', type: 'string' },
                ],
                internalType: 'struct IConfig.Token',
                name: 'tokenConfig',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'string', name: '', type: 'string' }],
        name: 'configSupplyLimit',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'contractType',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [],
        name: 'contractURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'contractVersion',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_allowlistId', type: 'uint256' },
        ],
        name: 'getAllowlistById',
        outputs: [
            {
                components: [
                    { internalType: 'bytes32', name: 'typedata', type: 'bytes32' },
                    { internalType: 'bool', name: 'isActive', type: 'bool' },
                    { internalType: 'string', name: 'metadataUri', type: 'string' },
                    { internalType: 'string', name: 'name', type: 'string' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'uint256',
                        name: 'maxMintPerWallet',
                        type: 'uint256',
                    },
                    { internalType: 'uint256', name: 'tokenPool', type: 'uint256' },
                    { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                ],
                internalType: 'struct IAllowlist.Allowlist',
                name: 'allowlist',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_allowlistId', type: 'uint256' },
        ],
        name: 'getMintedByAllowlist',
        outputs: [{ internalType: 'uint256', name: 'minted_', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
        name: 'getRoleAdmin',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
        ],
        name: 'getRoleMember',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
        name: 'getRoleMemberCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'grantRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '_role', type: 'bytes32' }],
        name: 'hasMinRole',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'hasRole',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_defaultAdmin', type: 'address' },
            { internalType: 'string', name: '_name', type: 'string' },
            { internalType: 'string', name: '_symbol', type: 'string' },
            { internalType: 'string', name: '_baseURI', type: 'string' },
            {
                internalType: 'address[]',
                name: '_trustedForwarders',
                type: 'address[]',
            },
            { internalType: 'address', name: '_receivingWallet', type: 'address' },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'operator', type: 'address' },
        ],
        name: 'isApprovedForAll',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_operatorAddress', type: 'address' },
        ],
        name: 'isSanctioned',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }],
        name: 'isTrustedForwarder',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256[]', name: '_quantities', type: 'uint256[]' },
            { internalType: 'bytes32[][]', name: '_proofs', type: 'bytes32[][]' },
            { internalType: 'uint256[]', name: '_allotments', type: 'uint256[]' },
            { internalType: 'uint256[]', name: '_allowlistIds', type: 'uint256[]' },
            { internalType: 'string[][]', name: '_config', type: 'string[][]' },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'minted',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256', name: '', type: 'uint256' },
        ],
        name: 'mintedByAllowlist',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'string', name: '', type: 'string' }],
        name: 'mintedByConfig',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes[]', name: 'data', type: 'bytes[]' }],
        name: 'multicall',
        outputs: [{ internalType: 'bytes[]', name: 'results', type: 'bytes[]' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'operator', type: 'address' }],
        name: 'onlyAllowedOperatorApproval',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'paused',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'string', name: '', type: 'string' }],
        name: 'priceByConfig',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'project',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rWallet',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'receivingWallet',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'renounceRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'revokeRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
            { internalType: 'uint256', name: '_salePrice', type: 'uint256' },
        ],
        name: 'royaltyInfo',
        outputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256', name: '', type: 'uint256' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'bytes32', name: 'typedata', type: 'bytes32' },
                    { internalType: 'bool', name: 'isActive', type: 'bool' },
                    { internalType: 'string', name: 'metadataUri', type: 'string' },
                    { internalType: 'string', name: 'name', type: 'string' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'uint256',
                        name: 'maxMintPerWallet',
                        type: 'uint256',
                    },
                    { internalType: 'uint256', name: 'tokenPool', type: 'uint256' },
                    { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                ],
                internalType: 'struct IAllowlist.Allowlist[]',
                name: '_allowlists',
                type: 'tuple[]',
            },
        ],
        name: 'setAllowlists',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'operator', type: 'address' },
            { internalType: 'bool', name: 'approved', type: 'bool' },
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'string', name: '_baseURI', type: 'string' }],
        name: 'setBaseURI',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'bool', name: 'isActive', type: 'bool' },
                            { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                            { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                            { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                            {
                                internalType: 'uint256',
                                name: 'maxPerWallet',
                                type: 'uint256',
                            },
                            { internalType: 'uint256', name: 'maxPerTxn', type: 'uint256' },
                            { internalType: 'uint256', name: 'price', type: 'uint256' },
                        ],
                        internalType: 'struct IConfig.Mint',
                        name: 'mintConfig',
                        type: 'tuple',
                    },
                    {
                        components: [
                            { internalType: 'string', name: 'metadataUri', type: 'string' },
                        ],
                        internalType: 'struct IConfig.Token',
                        name: 'tokenConfig',
                        type: 'tuple',
                    },
                ],
                internalType: 'struct IConfig.Config',
                name: '_config',
                type: 'tuple',
            },
        ],
        name: 'setConfig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'string', name: '_uri', type: 'string' }],
        name: 'setContractURI',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_receivingWallet', type: 'address' },
            { internalType: 'address', name: '_rWallet', type: 'address' },
        ],
        name: 'setInternals',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_newOwner', type: 'address' }],
        name: 'setOwner',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_project', type: 'address' }],
        name: 'setProject',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
        name: 'setReceivingWallet',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint96', name: '_royalty', type: 'uint96' }],
        name: 'setRoyalty',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
        name: 'setRoyaltyWallet',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
        name: 'setSactionsContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256[]', name: 'tokenId', type: 'uint256[]' },
            { internalType: 'address', name: '_receiverAddress', type: 'address' },
            { internalType: 'uint96', name: '_royalty', type: 'uint96' },
        ],
        name: 'setTokenRoyalty',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bool', name: '_isPaused', type: 'bool' }],
        name: 'togglePause',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'tokenByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
        ],
        name: 'tokenOfOwnerByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'totalMintedByAllowlist',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'bytes32', name: 'typedata', type: 'bytes32' },
                    { internalType: 'bool', name: 'isActive', type: 'bool' },
                    { internalType: 'string', name: 'metadataUri', type: 'string' },
                    { internalType: 'string', name: 'name', type: 'string' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'uint256',
                        name: 'maxMintPerWallet',
                        type: 'uint256',
                    },
                    { internalType: 'uint256', name: 'tokenPool', type: 'uint256' },
                    { internalType: 'uint256', name: 'startTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'endTime', type: 'uint256' },
                    { internalType: 'uint256', name: 'maxSupply', type: 'uint256' },
                ],
                internalType: 'struct IAllowlist.Allowlist',
                name: '_allowlist',
                type: 'tuple',
            },
            { internalType: 'uint256', name: 'i', type: 'uint256' },
        ],
        name: 'updateAllowlistByIndex',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'string', name: '_config', type: 'string' },
            { internalType: 'uint256', name: '_supply', type: 'uint256' },
            { internalType: 'uint256', name: '_price', type: 'uint256' },
        ],
        name: 'upsertConfigSettings',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export default ABI;
