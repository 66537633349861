import { Color } from 'common/styles';
import React from 'react';
import { IconType } from 'react-icons';
import { RiLockFill } from 'react-icons/ri';

interface Props {
  icon: IconType;
  iconColor?: Color;
  disabledIcon?: IconType;
  disabled?: boolean;
  disabledColor?: Color;
  onPress?: () => void;
  className?: string;
  size?: number;
}

const Icon: React.FC<Props> = ({
  icon,
  iconColor = Color.turquoise80,
  disabledIcon = RiLockFill,
  disabled = false,
  disabledColor = Color.coldGrey60,
  onPress,
  className,
  size = 16,
}) => {
  const IconComponent = disabled ? disabledIcon : icon;

  return (
    <button
      className={`p-1 rounded-full transition-all ${
        !disabled && onPress && 'click-highlight'
      } ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        onPress?.();
      }}
      disabled={disabled}
    >
      <IconComponent size={size} color={disabled ? disabledColor : iconColor} />
    </button>
  );
};

export default Icon;
