import useStore from 'app/common/useStore';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import logo from '../assets/logo.png';
import avatar from '../assets/graphic404.png';
import { ITokenItemIn } from 'common/state/stores/WardrobeStore';
import WardrobeEmptyScreenWithInfo from 'app/screens/WardrobeEmptyScreenWithInfo';
import { useNavigate } from 'react-router-dom';
import { PURCHASE_RELATED_STUFF_VISIBLE } from 'app/common/config';

type Token = {
  index: number;
  item: ITokenItemIn;
};

const WebWardrobe = () => {
  const {
    userStore: { walletAddress, loggedIn },
    wardrobeStore: { ownedTokens, loading, fetch },
  } = useStore();
  const navigate = useNavigate();

  const [currentlySelectedToken, setCurrentlySelectedToken] = useState<Token>();
  const [viewerUrl, setViewerUrl] = useState<string>();
  const [isWardrobeEmpty, setIsWardrobeEmpty] = useState(false);
  const [afterLoading, setAfterLoading] = useState(false);

  const onPressNFT = (token: ITokenItemIn, index: number) => {
    setViewerUrl(token.viewerUrl);
    setCurrentlySelectedToken({ index, item: token });
  };

  const createItemList = (tokens) => {
    return tokens.map(({ id, image, name, viewerUrl }, index: number) => {
      return (
        <div
          key={id}
          className={`bg-coldGrey-300 m-4 rounded-xl p-2 text-center ${
            index === currentlySelectedToken?.index
              ? 'border-shiny'
              : 'border-shiny-transparent'
          }`}
          onClick={() => {
            onPressNFT({ id, image, name, viewerUrl }, index);
          }}
        >
          <img
            src={image}
            alt={'nftImage'}
            className="p-2"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatar;
            }}
          />
          <div className="h-8 items-center justify-center flex pt-1">
            {name}
          </div>
        </div>
      );
    });
  };

  const items = useMemo(() => {
    if (ownedTokens.length === 0) {
      return [];
    }
    return createItemList(ownedTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownedTokens, currentlySelectedToken]);

  useEffect(() => {
    if (ownedTokens.length > 0) {
      onPressNFT(ownedTokens[0], 0);
      setIsWardrobeEmpty(false);
    } else if (!loading) {
      setIsWardrobeEmpty(true);
    }
  }, [ownedTokens, loading]);

  useEffect(() => {
    fetch().then(() => {
      setTimeout(() => {
        setAfterLoading(true); // to prevent different empty states when things load
      }, 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  const podButtonProps: Partial<
    React.ComponentProps<typeof WardrobeEmptyScreenWithInfo>
  > = {
    buttonTitle: 'Go to pod',
    onPress: () => navigate('/pod'),
  };

  if (!loading && afterLoading) {
    if (!loggedIn) {
      return (
        <WardrobeEmptyScreenWithInfo
          title="You are not logged in!"
          infoText="Please log in or create an account to see your wardrobe."
          buttonTitle="Log in"
          onPress={() => navigate('/login')}
        />
      );
    }
    if (!walletAddress) {
      return (
        <WardrobeEmptyScreenWithInfo
          title="You have no wallet connected"
          infoText="In order to see your wardrobe you need to connect your wallet."
          {...(PURCHASE_RELATED_STUFF_VISIBLE ? podButtonProps : {})}
        />
      );
    }
    if (isWardrobeEmpty) {
      return (
        <WardrobeEmptyScreenWithInfo
          title="Empty wardrobe"
          infoText={[
            'There is nothing in your wardrobe yet.',
            PURCHASE_RELATED_STUFF_VISIBLE
              ? 'Try to connect different wallet, or go to pod to complete your purchase.'
              : '',
          ]}
          {...(PURCHASE_RELATED_STUFF_VISIBLE ? podButtonProps : {})}
        />
      );
    }
  }

  return (
    <div className="flex flex-1 max-h-full">
      <div className="flex max-h-full w-full border-shiny-right">
        {viewerUrl ? (
          <iframe
            title="Wardrobe3dView"
            className="flex max-h-full w-full"
            src={viewerUrl}
          />
        ) : (
          <div className="flex max-h-full w-full justify-center items-center">
            <img
              alt="loadingLogo"
              className="w-2/6 my-4 object-contain"
              src={logo}
            />
          </div>
        )}
      </div>
      <div className="overflow-y-clip scroll w-64 max-h-full">
        {loading ? (
          <div className="justify-center items-center flex h-full">
            <div className="spinner h-12 w-12" />
          </div>
        ) : (
          <div className="fixed max-h-full h-full overflow-y-auto pb-48">
            {items}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(WebWardrobe);
