/* eslint-disable func-names */
import { Nodes } from 'common/state/nodes';
import { fetchOrders } from 'common/api/spree';
import Order from 'common/state/models/Order';
import { flow, types } from 'mobx-state-tree';
import { getRootStore } from 'common/state/RootStore';
import { XpEventName } from 'common/api/types';
const OrderStore = types
    .model(Nodes.OrderStore, {
    orders: types.map(Order),
    orderIds: types.array(types.string),
    currentPage: types.number,
    lastPageReached: types.boolean,
})
    .views((self) => {
    return {
        getOrderById: (id) => {
            return self.orders.get(id);
        },
    };
})
    .actions((self) => {
    return {
        fetch: flow(function* (reset = false) {
            if (reset) {
                self.lastPageReached = false;
                self.currentPage = 1;
            }
            if (!self.lastPageReached) {
                const { userStore: { spreeAuth }, } = getRootStore(self);
                const { orders: nextPageOrders, lastPageReached, } = yield fetchOrders(spreeAuth, self.currentPage);
                if (reset) {
                    self.orders.clear();
                    self.orderIds.clear();
                }
                if (nextPageOrders.length === 0) {
                    self.lastPageReached = true;
                }
                else {
                    nextPageOrders.forEach((order) => {
                        self.orderIds.push(order.id);
                        self.orders.put(order);
                    });
                    self.currentPage += 1;
                    self.lastPageReached = lastPageReached;
                }
            }
            self.orders.forEach((order) => {
                order.checkAndSendEvent(XpEventName.orderComplete);
            });
        }),
        reset: () => {
            self.orders.clear();
            self.orderIds.clear();
            self.currentPage = 1;
            self.lastPageReached = false;
        },
    };
})
    .views((self) => {
    return {
        get orderStates() {
            return Array.from(self.orders.values()).map((order) => order.state);
        },
    };
});
export default OrderStore;
