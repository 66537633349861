import qs from 'qs';
import { errors } from '@spree/storefront-api-v2-sdk';
import Axios from 'axios';
const createAxiosFetcher = (fetcherOptions) => {
    const axios = Axios.create({
        baseURL: fetcherOptions.host,
        headers: { 'Content-Type': 'application/json' },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
    return {
        fetch: async (fetchOptions) => {
            try {
                const { url, params, method, headers, responseParsing } = fetchOptions;
                let payload;
                switch (method.toUpperCase()) {
                    case 'PUT':
                    case 'POST':
                    case 'DELETE':
                    case 'PATCH':
                        payload = { data: params };
                        break;
                    default:
                        payload = { params };
                }
                let responseType;
                const isBrowser = true;
                switch (responseParsing) {
                    case 'json':
                    case 'text':
                        responseType = responseParsing;
                        break;
                    case 'stream':
                        responseType = isBrowser ? 'blob' : 'stream';
                        break;
                    default:
                        // @ts-ignore
                        responseType = undefined;
                }
                const response = await axios({
                    url,
                    method: method.toUpperCase(),
                    headers,
                    responseType,
                    ...payload,
                });
                if (responseParsing === 'stream' && isBrowser) {
                    return { data: response.data.stream() };
                }
                return { data: response.data };
            }
            catch (error) {
                if (Axios.isAxiosError(error)) {
                    const { response } = error;
                    if (!response) {
                        throw new errors.FetchError(null, error.request, null, error.message);
                    }
                    const reducedResponse = { ...response };
                    // Reduce logging by removing the 'enumerable' flag on some keys in AxiosResponse.
                    Object.defineProperties(reducedResponse, {
                        config: { enumerable: false },
                        data: { enumerable: false },
                        headers: { enumerable: false },
                        request: { enumerable: false },
                    });
                    throw new errors.FetchError(reducedResponse, error.request, reducedResponse.data, error.message);
                }
                throw new errors.FetchError(null, null, null, error.message);
            }
        },
    };
};
export default createAxiosFetcher;
