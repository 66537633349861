import { RiInformationLine } from 'react-icons/ri';

interface Props {
  children: string;
  className?: string;
}

const Banner = ({ children, className = '' }: Props) => {
  return (
    <div
      className={`flex items-center flex-row bg-blue py-4 px-5 rounded-lg text-sm text-blue ${className}`}
    >
      <RiInformationLine className="mr-3 min-w-[22px]" size="22" />
      {children}
    </div>
  );
};

export default Banner;
