import LeftWire from '../assets/left-wires.svg';
import RightWire from '../assets/right-wires.svg';

const themes = {
  light:
    'bg-coldGrey-100 text-turquoise-800 border-2 border-turquoise-800 disabled:text-coldGrey-600 disabled:border-coldGrey-300',
  dark: 'bg-coldGrey-300 text-coldGrey-100 disabled:text-coldGrey-600',
} as const;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  theme?: keyof typeof themes;
}

const WireButton: React.FC<Props> = ({
  children,
  loading = false,
  disabled = false,
  className = '',
  theme: themeName = 'dark',
  ...props
}) => {
  const theme = themes[themeName];

  return (
    <button
      className={`font-mussels rounded-lg flex items-center overflow-hidden w-full h-12
        relative
        ${!disabled && 'active:scale-95 transition-all'}
        ${
          themeName !== 'dark'
            ? 'hover:shadow-transparent10'
            : 'hover:shadow-transparent30'
        }
        disabled:hover:shadow-none
        ${!disabled && themeName === 'dark' && 'bg-wire-active'}
        ${theme} ${className}`}
      disabled={disabled}
      {...props}
    >
      <img
        alt=""
        className="absolute left-0 pointer-events-none"
        src={LeftWire}
      />
      <img
        alt=""
        className="absolute right-0 pointer-events-none"
        src={RightWire}
      />
      <div className="w-fit center-xy">
        {loading ? (
          <div className="spinner w-8 h-8" />
        ) : (
          <div className="px-2 uppercase">{children}</div>
        )}
      </div>
    </button>
  );
};

export default WireButton;
