// @ts-nocheck
import { useEffect, useRef } from 'react';
import { init, animate, onWindowResize } from '../viewer';
import garment from '../assets/acid-wash-tee.glb';
import logo from '../assets/logo.png';

const CarbonwearPreview = () => {
  const tt = useRef<HTMLDivElement | null>(null);
  const ttwrapper = useRef<HTMLDivElement | null>(null);
  const loading = useRef<HTMLDivElement | null>(null);
  const loadingBar = useRef<HTMLDivElement | null>(null);

  const loadObjects = () => {
    if (tt.current) {
      init(
        ttwrapper.current,
        garment,
        undefined,
        undefined,
        loading,
        loadingBar,
      );
      animate();
      onWindowResize(ttwrapper.current);
    }
  };

  useEffect(() => {
    loadObjects();
  }, []);

  return (
    <div className="flex w-screen h-screen justify-center items-center fixed z-50">
      <div
        ref={loading}
        className="absolute flex w-screen h-screen flex-col justify-center items-center bg-coldGrey-100 pointer-events-none"
      >
        <img
          alt="loadingLogo"
          className="w-2/6 my-4 object-contain"
          src={logo}
        />
        <div className="w-2/6 my-2 flex justify-center">
          <div ref={loadingBar} className="h-1 w-0 bg-white" />
        </div>
        <div className="animation-pulse-loading medium18">LOADING</div>
      </div>
      <div
        ref={ttwrapper}
        className="flex-1 basis-2/3 aspect-square w-screen h-screen justify-center items-center"
      >
        <div className="" ref={tt} />
      </div>
    </div>
  );
};

export default CarbonwearPreview;
