import { StoreContext } from 'app/components/StoreProvider';
import { IStore } from 'common/state/RootStore';
import { useContext } from 'react';

function useStore(): IStore {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('StoreProvider is not defined');
  }

  return store;
}

export default useStore;
