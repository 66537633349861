import { MdLocationOn, MdPayment } from 'react-icons/md';
import { BiPackage } from 'react-icons/bi';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { AiOutlineDropbox } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const check = (
  <div className="absolute bottom-0 right-0">
    <RiCheckboxCircleFill size={12} color="#77D1E3" />
  </div>
);

interface Props {
  stepsEnabled: string[];
  stepsDone: boolean[];
  currentStep: number;
  onClick: () => void;
}

interface CheckoutStepInfo {
  title: string;
  icon: JSX.Element;
  link: string;
}

const stepsMeta: Record<string, CheckoutStepInfo> = {
  address: {
    title: 'Address',
    icon: <MdLocationOn size={24} />,
    link: 'address',
  },
  delivery: {
    title: 'Shipping',
    icon: <AiOutlineDropbox className="mr-1" size={24} />,
    link: 'delivery',
  },
  payment: {
    title: 'Payment method',
    icon: <MdPayment className="mr-1" size={24} />,
    link: 'payment',
  },
  confirm: {
    title: 'Confirm',
    icon: <MdPayment className="mr-1" size={24} />,
    link: 'complete',
  },
  complete: {
    title: 'Summary',
    icon: <BiPackage size={24} />,
    link: 'complete',
  },
};

const CheckoutSteps = ({
  stepsEnabled,
  stepsDone,
  currentStep,
  onClick,
}: Props) => {
  return (
    <div className="w-full flex flex-row justify-center mt-16 mb-8">
      {stepsEnabled.map((stepName, index) => {
        const step = stepsMeta[stepName];
        const highlighted = index <= currentStep;

        const bg = highlighted ? 'bg-white' : 'bg-coldGrey-400';
        const text = highlighted ? 'text-white' : 'text-coldGrey-400';

        const shouldClick =
          index > currentStep &&
          currentStep + 1 < stepsEnabled.length &&
          !stepsDone[currentStep + 1];
        return (
          <Link
            replace
            to={`/pod/${step.link}`}
            key={step.title}
            className={`basis-1/4 ${text}`}
            onClick={(e) => {
              if (shouldClick) {
                e.preventDefault();
                onClick();
              }
            }}
          >
            <div className="flex justify-center mb-2">
              <div className="relative">
                {step.icon}
                {stepsDone[index] === true && check}
              </div>
            </div>
            <div>
              <div className={`${bg} m-1 h-1`} />
              <div className="pt-4 text-center medium16">{step.title}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default CheckoutSteps;
