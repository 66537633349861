import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import useStore from 'app/common/useStore';
import Avatar from 'app/assets/avatar.png';
import { useAnalytics } from 'app/common/analytics/useAnalytics';
import { AnalyticsEventName } from 'app/common/analytics/types';
import { useNavigate } from 'react-router-dom';

const LogoutPopup = forwardRef((_, ref) => {
  const {
    userStore: { email, logout, loggedIn },
  } = useStore();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const popupTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    toggle: () => {
      setPopupHide(false);
      setShowLogoutPopup((x) => !x);
    },
  }));

  const setPopupHide = (startNew: boolean) => {
    if (popupTimeout.current) {
      clearTimeout(popupTimeout.current);
    }
    if (startNew) {
      popupTimeout.current = setTimeout(() => {
        setShowLogoutPopup(false);
      }, 1000);
    }
  };

  // 1206 == screen-lg + w-52 - icon_w = 1024 + 208 - 26
  return (
    <div className="mx-auto z-10 max-w-[1206px] h-0 flex flex-row justify-end w-screen">
      <div
        onPointerEnter={() => setPopupHide(false)}
        onPointerLeave={() => setPopupHide(true)}
        className={`mt-2 rounded-lg transition-all bg-coldGrey-200 border border-coldGrey-400 w-52 h-min flex flex-col space-between ${
          showLogoutPopup ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        <div className="bg-violet-900 h-12 w-12 mt-3 mx-auto rounded-full">
          <img alt="Avatar" src={Avatar}></img>
        </div>
        <div className="mx-4 my-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
          {email}
        </div>
        {loggedIn && (
          <>
            <div className="h-px w-full bg-coldGrey-400"></div>
            <button
              onClick={() => {
                setPopupHide(false);
                setShowLogoutPopup(false);
                navigate('/wardrobe', {});
              }}
              className="mx-auto w-max my-3 text-turquoise-700"
            >
              Wardrobe
            </button>
          </>
        )}
        <div className="h-px w-full bg-coldGrey-400"></div>
        <button
          onClick={async () => {
            setPopupHide(false);
            setShowLogoutPopup(false);
            await logout();
            logEvent(AnalyticsEventName.PressLogout);
            navigate('/login', {});
          }}
          className="mx-auto w-max my-3 text-turquoise-700"
        >
          Log out
        </button>
      </div>
    </div>
  );
});

export default LogoutPopup;
