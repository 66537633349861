import { extractSuccess } from '@spree/storefront-api-v2-sdk/src/helpers/result';
import createRstlssFetcher from 'common/api/fetcher';
import { castToArray, formatDrops, formatGarments, formatLineItem, formatPrize, formatScenes, formatShipments, formatStickers, getStockStatus, parseOrder, } from 'common/api/formatters';
import { makeClient } from 'common/api/spreeClient';
import { Taxons, } from 'common/api/types';
import { setUser } from '@sentry/core';
import { parseAddressFromSpreeResponse, } from 'common/state/models/Address';
import { SupportedPaymentOptions, } from 'common/state/models/PaymentMethod';
import { isArray, isEmpty, range, sortBy } from 'lodash';
let client;
const CART_AVAILABLE_STATES = [
    'cart',
    'address',
    'delivery',
    'payment',
    'confirm',
];
const NOT_FINALIZED_ORDER_STATES = ['cart', 'address', 'delivery', 'payment'];
export function setupClient(apiBaseUrl, onMaintenance, noResponseCallback, userStore) {
    client = makeClient({
        host: apiBaseUrl,
        fetcherType: 'custom',
        createFetcher: createRstlssFetcher(onMaintenance, noResponseCallback, userStore),
    });
}
export async function getStatus() {
    const { data } = await extractSuccess(client.extendedAccount.status());
    return data;
}
export async function getVersion() {
    const version = await extractSuccess(client.extendedAccount.version());
    return version;
}
export function isExpandedSpreeError(error) {
    return error?.name === 'ExpandedSpreeError';
}
export function isBasicSpreeError(error) {
    return error?.name === 'BasicSpreeError';
}
export const isSpreeFieldErrors = (err) => {
    return !!err && isArray(err);
};
function joinResultsData(results) {
    const resultData = [];
    const allIncluded = [];
    results.forEach((response) => {
        resultData.push(...response.data);
        allIncluded.push(...response.included);
    });
    return { data: resultData, included: allIncluded };
}
async function getAllData(firstPageRequestFunc, auth, params) {
    const response = await extractSuccess(firstPageRequestFunc(auth, params));
    const { meta } = response;
    if (meta.total_pages <= 1) {
        return joinResultsData([response]);
    }
    const responses = await Promise.all(range(2, meta.total_pages + 1).map((page) => extractSuccess(firstPageRequestFunc(auth, { ...params, page }))));
    return joinResultsData([response, ...responses]);
}
export const getCartAsync = async (spreeAuth) => {
    const response = await client.cart.show(spreeAuth.token);
    if (response.isFail()) {
        const { message } = response.fail();
        throw new Error(message);
    }
    const { data } = response.success();
    return data;
};
export const getCartWithContentAsync = async (spreeAuth) => {
    const { data, included } = await extractSuccess(client.cart.show(spreeAuth.token, {
        include: 'line_items',
    }));
    const lineItems = included
        .filter(({ type }) => type === 'line_item')
        .map(formatLineItem);
    return {
        data: data,
        lineItems,
    };
};
export const lockPayment = async (spreeAuth, paymentMethodId) => {
    const { data } = await extractSuccess(client.checkout.lock(spreeAuth.token, {
        payment_method_id: paymentMethodId,
    }));
    return data;
};
export const addPaymentAsync = async (spreeAuth, paymentMethodId, props) => {
    const response = await extractSuccess(client.checkout.addPayment(spreeAuth.token, {
        payment_method_id: paymentMethodId,
        source_attributes: props,
    }));
    return response.data;
};
export const completeOrder = async (spreeAuth) => {
    const response = await extractSuccess(client.checkout.complete(spreeAuth.token));
    return response.data;
};
function filterByTaxon(productsData, included, taxonName) {
    const taxon = included.find(({ type, attributes: { name } }) => type === 'taxon' && name === taxonName);
    if (!taxon) {
        return [];
    }
    return productsData.filter(({ relationships: { taxons: { data }, }, }) => castToArray(data).some(({ id }) => id === taxon.id));
}
export const getProductsAsync = async (spreeAuth) => {
    const requestFunc = (auth, params) => client.products.list(auth, params);
    const { data, included } = await getAllData(requestFunc, spreeAuth.token, {
        include: [
            'images',
            'option_types',
            'product_properties',
            'taxons',
            'taxons.image',
            'variants',
            'variants.option_values',
        ].join(','),
        filter: { taxons: [Taxons.Stickers, Taxons.Garments].join(',') },
    });
    const garmentsData = filterByTaxon(data, included, Taxons.Garments);
    const stickersData = filterByTaxon(data, included, Taxons.Stickers);
    const garments = formatGarments(included, garmentsData);
    const { stickers, universes, stickersPositions } = formatStickers(included, stickersData);
    return {
        garments,
        stickers,
        universes,
        stickersPositions,
    };
};
export const getGarmentListAsync = async () => {
    const requestFunc = (auth, params) => client.products.list(auth, params);
    const { data, included } = await getAllData(requestFunc, {}, {
        include: [
            'images',
            'variants',
            'option_types',
            'variants.option_values',
            'taxons',
            'product_properties',
        ].join(','),
        filter: { taxons: Taxons.Garments },
    });
    const garmentsData = filterByTaxon(data, included, Taxons.Garments);
    return formatGarments(included, garmentsData);
};
export const loginAsync = async (username, password) => {
    const response = await extractSuccess(client.authentication.getToken({
        username,
        password,
    }));
    return response;
};
export const refreshAccessTokenAsync = async (refreshToken) => {
    const response = await extractSuccess(client.authentication.refreshToken({
        refresh_token: refreshToken,
    }));
    return response;
};
export const getChallengePhraseAsync = async (publicKey) => {
    const { phrase } = await extractSuccess(client.auth.getPhrase({
        public_key: publicKey,
    }));
    return phrase;
};
export const verifyChallengePhraseAsync = async (publicKey, signature, email) => {
    const response = await client.auth.verifyPhrase({
        public_key: publicKey,
        signature,
        email,
    });
    if (response.isFail()) {
        const error = response.fail();
        if (['invalid_email', 'confirmation_required'].includes(error.summary)) {
            return {
                error: error.summary,
            };
        }
        if (error.summary === 'missing_beta_token') {
            error.summary = 'You need Beta Pass NFT';
        }
        throw error;
    }
    const { access_token: bearerToken, refresh_token: refreshToken } = response.success();
    return { bearerToken, refreshToken };
};
export const disconnectWalletAsync = async (spreeAuth) => {
    await extractSuccess(client.extendedAccount.disconnectWallet(spreeAuth));
};
/**
 * Returns order token of the newly generated cart,
 * can be used to save the cart
 */
export const getOrderTokenAsync = async (bearerToken) => {
    const { data } = await extractSuccess(client.cart.create(bearerToken ? { bearerToken } : undefined));
    return {
        orderToken: data.attributes.token,
        orderNumber: data.attributes.number,
    };
};
export const createNewUserAsync = async (email, password, user_settings, apple_id) => {
    const { data } = await extractSuccess(client.account.create({
        user: {
            email,
            password,
            apple_id,
            public_metadata: user_settings,
        },
    }));
    return data;
};
export const deleteUserAsync = async (spreeAuth, userId) => {
    return await extractSuccess(client.extendedAccount.delete(spreeAuth.token, userId));
};
export const updateUserAsync = async (spreeAuth, params) => {
    const { data } = await extractSuccess(client.account.update(spreeAuth.token, { user: params }));
    return data;
};
export const verifyEmailAsync = async (verificationCode) => {
    const { data } = await extractSuccess(client.account.confirm(verificationCode));
    return data;
};
export const getStickersAsync = async (spreeAuth) => {
    const requestFunc = (auth, params) => client.products.list(auth, params);
    const { data, included } = await getAllData(requestFunc, spreeAuth.token, {
        include: [
            'variants',
            'variants.option_values',
            'images',
            'taxons',
            'option_types',
            'taxons.image',
            'product_properties',
            'drop_items',
        ].join(','),
        filter: { taxons: Taxons.Stickers },
    });
    const stickersData = filterByTaxon(data, included, Taxons.Stickers);
    if (stickersData.length === 0) {
        return {
            stickers: [],
            universes: [],
            stickersPositions: [],
        };
    }
    return formatStickers(included, stickersData);
};
export const getStockStatusAsync = async (spreeAuth) => {
    const { included } = await extractSuccess(client.drops.show(spreeAuth.token, {
        include: 'drop_items',
    }));
    return getStockStatus(included);
};
const getUserPrizeInfo = (included) => {
    const prizes = included.filter(({ type }) => type === 'prize');
    if (isEmpty(prizes)) {
        return undefined;
    }
    const newestPrize = prizes.reduce((previous, current) => {
        const previousDate = new Date(previous.attributes.expires_on);
        const currentDate = new Date(current.attributes.expires_on);
        return previousDate >= currentDate ? previous : current;
    });
    return formatPrize(newestPrize, included);
};
export const getUserInfoAsync = async (spreeAuth) => {
    const { data, included } = await extractSuccess(client.account.accountInfo(spreeAuth.token, {
        include: ['prizes', 'prizes.product.images', 'prizes.drop'].join(','),
    }));
    const accountData = data;
    const userPrize = getUserPrizeInfo(included);
    const { data: addressData } = await extractSuccess(client.account.addressesList(spreeAuth.token));
    setUser({ email: accountData.attributes.email });
    return {
        id: accountData.id,
        email: accountData.attributes.email,
        experience: accountData.attributes.experience,
        addresses: addressData.map(parseAddressFromSpreeResponse),
        prize: userPrize,
        walletAddress: accountData.attributes.wallet_address || undefined,
        settings: accountData.attributes.public_metadata.user_settings,
        appleId: accountData.attributes.apple_id || undefined,
    };
};
export const nextOrderStep = async (spreeAuth) => {
    // Move function to the next checkout step, see https://dev-docs.spreecommerce.org/internals/orders#order-shipment-states
    return extractSuccess(client.checkout.orderNext(spreeAuth.token));
};
export const validateOrder = async (spreeAuth) => {
    return extractSuccess(client.checkout.validateOrder(spreeAuth.token));
};
export const getPaymentMethodsAsync = async (spreeAuth) => {
    const { data } = await extractSuccess(client.checkout.paymentMethods(spreeAuth.token));
    const methods = data.map((item) => ({
        id: item.id,
        description: item.attributes.description,
        name: item.attributes.name,
        isCrypto: item.attributes.name === SupportedPaymentOptions.WalletConnect,
    }));
    return methods;
};
export const setCheckoutAddress = async (spreeAuth, addressAttributes) => {
    await extractSuccess(client.checkout.orderUpdate(spreeAuth.token, {
        order: {
            /* @ts-ignore */
            bill_address_attributes: addressAttributes,
            /* @ts-ignore */
            ship_address_attributes: addressAttributes,
        },
    }));
};
export const setCheckoutEmailAsync = async (spreeAuth, email) => {
    await extractSuccess(client.checkout.orderUpdate(spreeAuth.token, {
        order: {
            email,
        },
    }));
};
export const setDeliveryMethod = async (spreeAuth, shippingMethodId, shipmentId) => {
    await extractSuccess(client.checkout.selectShippingMethod(spreeAuth.token, {
        shipping_method_id: shippingMethodId,
        shipment_id: shipmentId,
    }));
};
export const getShipments = async (spreeAuth) => {
    const { data, included } = await extractSuccess(client.checkout.shippingRates(spreeAuth.token, {
        include: 'shipping_rate',
    }));
    return formatShipments(data, included);
};
const prepareAddressParams = (inAddress) => {
    const { country, ...tempAddress } = inAddress;
    const address = {
        ...tempAddress,
        address2: inAddress.address2 || undefined,
        state_name: inAddress.state_name || '',
    };
    return {
        address,
    };
};
export const createOrUpdateAddressAsync = async (spreeAuth, inAddress, isUpdate) => {
    const addressParams = prepareAddressParams(inAddress);
    let response;
    if (isUpdate) {
        response = await extractSuccess(client.account.updateAddress(spreeAuth.token, inAddress.id, addressParams));
    }
    else {
        response = await extractSuccess(client.account.createAddress(spreeAuth.token, addressParams));
    }
    return parseAddressFromSpreeResponse(response.data);
};
export const deleteAddressAsync = async (spreeAuth, addressId) => {
    await extractSuccess(client.account.removeAddress(spreeAuth.token, addressId));
    return addressId;
};
export const createSceneAsync = async (spreeAuth, param) => {
    const { data } = await extractSuccess(client.scenes.create(spreeAuth.token, {
        scene: param || { liked: false },
    }));
    return data.id;
};
export const cancelChangesSceneAsync = async (spreeAuth, sceneId) => {
    await client.scenes.cancel(spreeAuth.token, sceneId);
    return sceneId;
};
export const saveChangesSceneAsync = async (spreeAuth, sceneId) => {
    await client.scenes.save(spreeAuth.token, sceneId);
    return sceneId;
};
export const deleteSceneAsync = async (spreeAuth, sceneId) => {
    await client.scenes.delete(spreeAuth.token, sceneId);
    return sceneId;
};
export const getScenesListAsync = async (spreeAuth) => {
    // NOTE: For some reason passing just client.scenes.list doesn't work
    const requestFunc = (auth, params) => client.scenes.list(auth, params);
    const { data, included } = await getAllData(requestFunc, spreeAuth.token, {
        include: [
            'master_variant',
            'master_variant.product.images',
            'master_variant.product.option_types',
            'master_variant.product.product_properties',
            'master_variant.product.taxons',
            'master_variant.product.taxons.image',
            'master_variant.product.variants',
            'master_variant.product.variants.option_values',
            'scene_variants',
            'variants',
            'products.images',
            'products.option_types',
            'products.product_properties',
            'products.taxons',
            'products.taxons.image',
            'products.variants',
            'products.variants.option_values',
        ].join(','),
    });
    return formatScenes(data, included);
};
export const updateItemsInSceneAsync = async (spreeAuth, sceneId, updateItems) => {
    const { data } = await extractSuccess(client.scenes.updateItems(spreeAuth.token, sceneId, updateItems));
    return data;
};
export const addItemToSceneAsync = async (spreeAuth, sceneId, variantId) => {
    const { data } = await extractSuccess(client.scenes.addItem(spreeAuth.token, sceneId, {
        variant_id: variantId,
        quantity: 1,
    }));
    return data.id;
};
export const removeItemFromSceneAsync = async (spreeAuth, sceneId, variantId) => {
    const { data } = await extractSuccess(client.scenes.removeItem(spreeAuth.token, sceneId, variantId.toString()));
    return data.id;
};
export const addSceneToCartAsync = async (spreeAuth, sceneId) => {
    const { data } = await extractSuccess(client.scenes.addToCart(spreeAuth.token, sceneId));
    return data.id;
};
export const removeItemFromCartAsync = async (spreeAuth, itemId) => {
    const { data } = await extractSuccess(client.scenes.removeFromCart(spreeAuth.token, itemId));
    return data.id;
};
export const resetCartStateAsync = async (spreeAuth) => {
    const { data } = await extractSuccess(client.cart.emptyCart(spreeAuth.token));
    return data;
};
export const changeCartCurrencyAsync = async (spreeAuth, currency) => {
    const { data } = await extractSuccess(client.cart.changeCurrency(spreeAuth.token, {
        new_currency: currency.toUpperCase(),
    }));
    return data;
};
export const getDropListAsync = async (spreeAuth) => {
    const requestFunc = (auth, params) => client.drops.show(auth, params);
    const { data, included } = await getAllData(requestFunc, spreeAuth, {
        include: [
            'image',
            'drop_items',
            'drop_items.product',
            'drop_items.product.images',
        ].join(','),
    });
    return sortBy(formatDrops(data, included), ({ availableOn }) => availableOn);
};
export const drawDropAsync = async (spreeAuth, dropId) => {
    if (!spreeAuth.token) {
        throw new Error('User must be logged in');
    }
    const { data, included } = await extractSuccess(client.drops.draw(spreeAuth.token, dropId, {
        include: ['product', 'product.images', 'drop'].join(','),
    }));
    return formatPrize(data, included);
};
export const getCountriesAsync = async () => {
    const { data } = await extractSuccess(client.countries.list());
    return data;
};
export const getStatesAsync = async (countryISO) => {
    const { included } = await extractSuccess(client.countries.show(countryISO, {
        include: 'states',
    }));
    return included || [];
};
export const createXpEventAsync = async (spreeAuth, event, orderId) => {
    if (!spreeAuth.token) {
        throw new Error('User must be logged in');
    }
    const params = { name: event, order_id: orderId };
    const { data: { attributes }, } = await extractSuccess(client.events.create(spreeAuth.token, { event: params }));
    return {
        userExperience: attributes.user_experience,
        value: attributes.value,
    };
};
export const fetchLastCartOrder = async (spreeAuth, pageNumber) => {
    const { data } = await extractSuccess(client.extendedAccount.ordersList(spreeAuth.token, {
        page: pageNumber,
        sort: '-created_at',
    }));
    if (data.length > 0 &&
        CART_AVAILABLE_STATES.includes(data[0].attributes.state)) {
        return {
            token: data[0].attributes.token,
            number: data[0].attributes.number,
        };
    }
    return {};
};
export const fetchOrders = async (spreeAuth, pageNumber) => {
    const { data, included, meta } = await extractSuccess(client.account.completedOrdersList(spreeAuth.token, {
        page: pageNumber,
        include: ['shipments', 'shipping_address', 'payments'].join(','),
        sort: '-completed_at',
    }));
    const orders = data
        .filter(({ attributes: { state } }) => !NOT_FINALIZED_ORDER_STATES.includes(state))
        .map((order) => parseOrder(order, included));
    return {
        orders,
        lastPageReached: meta.total_pages <= pageNumber,
    };
};
export const sendPasswordRecoveryEmailAsync = async (email) => {
    await extractSuccess(client.account.forgotPassword({ user: { email } }));
};
export const resendConfirmationCode = async (email) => {
    await client.extendedAccount.resendConfirmationCode({
        user: { email },
    });
};
export const resetPassword = async (resetPasswordToken, password, passwordConfirmation) => {
    await extractSuccess(client.account.resetPassword(resetPasswordToken, {
        user: {
            password,
            password_confirmation: passwordConfirmation,
        },
    }));
};
export const createStripePaymentAsync = async (spreeAuth) => {
    const test = await extractSuccess(client.stripe.create(spreeAuth.token));
    return test;
};
export const setPaymentPending = async (spreeAuth) => {
    const response = await extractSuccess(client.checkout.setPaymentPending(spreeAuth.token));
    return response.data;
};
export const fetchCurrentOrder = async (spreeAuth) => {
    const { data, included } = await extractSuccess(client.cart.show(spreeAuth.token, {
        include: 'payments',
    }));
    return parseOrder(data, included);
};
export const fetchPopups = async (spreeAuth) => {
    const { data } = await extractSuccess(client.popups.get(spreeAuth.token));
    return data.map(({ attributes, id, type }) => {
        return {
            attributes: {
                ...attributes,
                start_date: new Date(attributes.start_date),
                end_date: new Date(attributes.end_date),
            },
            id,
            type,
        };
    });
};
export const getCMSContent = async (spreeAuth) => {
    const { data } = await extractSuccess(client.cmsContent.getActive(spreeAuth.token));
    return data.attributes.parts;
};
export const saveExpoToken = async (spreeAuth, expoToken) => {
    await extractSuccess(client.expoToken.create(spreeAuth, expoToken));
};
export const verifyAppleId = async (appleId, identityToken) => {
    const result = await extractSuccess(client.auth.verifyAppleId({
        apple_id: appleId,
        identity_token: identityToken,
    }));
    return {
        bearerToken: result.access_token,
        refreshToken: result.refresh_token,
    };
};
