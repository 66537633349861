import { types } from 'mobx-state-tree';
import { Currency, ICurrency } from 'common/state/models/Currency';
const SingleCurrencyPrice = types.model({
    amount: types.number,
    currency: Currency,
    currencySymbol: types.string,
    decimalPlaces: types.number,
});
export const Price = types
    .model({
    eth: SingleCurrencyPrice,
    usd: SingleCurrencyPrice,
})
    .views((self) => ({
    isZero() {
        return self.usd.amount === 0 && self.eth.amount === 0;
    },
    showDollarPrice() {
        return self.usd.amount > 0;
    },
}))
    .views((self) => ({
    showEthPrice() {
        return self.eth.amount > 0 || !self.showDollarPrice();
    },
}));
const currencyPlaceholderFactory = (currency) => ({
    amount: 0,
    currency,
    currencySymbol: '',
    decimalPlaces: 0,
});
export const emptyPrice = () => Price.create({
    eth: {
        amount: 0,
        currency: ICurrency.ETH,
        currencySymbol: 'ETH',
        decimalPlaces: 18,
    },
    usd: {
        amount: 0,
        currency: ICurrency.USD,
        currencySymbol: '$',
        decimalPlaces: 2,
    },
});
export const sumPrices = (allPrices) => {
    var summedPrices = {
        usd: currencyPlaceholderFactory(ICurrency.USD),
        eth: currencyPlaceholderFactory(ICurrency.ETH),
    };
    for (const price of allPrices) {
        if (summedPrices.usd.currencySymbol === '' &&
            summedPrices.eth.currencySymbol === '') {
            summedPrices = { usd: { ...price.usd }, eth: { ...price.eth } };
        }
        else {
            summedPrices = {
                usd: {
                    ...summedPrices.usd,
                    amount: price.usd.amount + summedPrices.usd.amount,
                },
                eth: {
                    ...summedPrices.eth,
                    amount: price.eth.amount + summedPrices.eth.amount,
                },
            };
        }
    }
    return Price.create(summedPrices);
};
export function getSpacePrice(spaces, spaceSlug) {
    const space = spaces.find(({ slug }) => slug === spaceSlug);
    if (!space) {
        return null;
    }
    return space.price || null;
}
const setDecimalPlaces = (amount, decimalPlaces, maxDecimalPlaces = 2) => {
    const main = Math.floor(amount / 10 ** decimalPlaces);
    const cents = `${amount % 10 ** decimalPlaces}`
        .padStart(decimalPlaces, '0')
        .slice(0, maxDecimalPlaces);
    return `${main}.${cents}`;
};
export const formatPrice = (singleCurrencyPrice, showCurrencySymbol, maxDecimalPlaces) => {
    if (!singleCurrencyPrice)
        return '';
    const { amount, currencySymbol, decimalPlaces } = singleCurrencyPrice;
    const formattedAmount = setDecimalPlaces(amount, decimalPlaces, maxDecimalPlaces);
    return `${showCurrencySymbol ? currencySymbol : ''}${formattedAmount}`;
};
