import {
  MdOutlineRadioButtonUnchecked as CircleOff,
  MdOutlineRadioButtonChecked as CircleOn,
} from 'react-icons/md';
import { IconContext } from 'react-icons';
import { Color } from 'common/styles';
import { ReactNode } from 'react';

const RadioOption = ({
  value,
  onPress,
  children,
  className = '',
  showCircles = false,
}: {
  value: boolean;
  onPress: () => void;
  children: ReactNode;
  className?: string;
  showCircles?: boolean;
}) => {
  return (
    <IconContext.Provider value={{ color: Color.turquoise80 }}>
      <div
        onClick={onPress}
        className={`mb-4 bg-coldGrey-200 rounded-lg flex transition-all p-4 medium18 border-2 active:border-turquoise-800 ${
          value
            ? 'border-shiny'
            : 'border-[#00000000] hover:border-turquoise-800'
        } ${className}`}
      >
        {showCircles && (
          <div className="my-auto p-1 mr-2 click-highlight transition-all">
            {value ? <CircleOn /> : <CircleOff />}
          </div>
        )}
        {children}
      </div>
    </IconContext.Provider>
  );
};

export default RadioOption;
